import { t } from 'i18next'

export enum ESignUpGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export const GENDER_OPTION = [
  {
    label: t('common:male'),
    value: ESignUpGender.MALE,
  },
  {
    label: t('common:female'),
    value: ESignUpGender.FEMALE,
  },
  {
    label: t('common:dontShareGender'),
    value: ESignUpGender.OTHER,
  },
]

export const ALLOW_PERSONAL_INFOR_FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/jpg',
]
export const MAX_PERSONAL_INFOR_FILE_SIZE = 5
