/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { Step } from './Step'
import { LineChartWrapper } from './LineChartWrapper'
import LineChartComponent from './LineChartComponent'
import { selectDashboardLoading, useAppSelector } from '@redux'
import { EStepChart, thunkActionLoading } from '@configs'
import { Skeleton } from 'antd'

type Props = {}

export const StatisticChart = (props: Props) => {
  const [step, setStep] = useState(EStepChart.ONE_WEEK)

  const getStatisticUserLoading = useAppSelector((state) =>
    selectDashboardLoading(
      state,
      thunkActionLoading.GET_STATISTIC_CHART_LOADING
    )
  )
  const getStatisticIncomeLoading = useAppSelector((state) =>
    selectDashboardLoading(
      state,
      thunkActionLoading.GET_STATISTIC_CHART_INCOME_LOADING
    )
  )

  const onChangeStep = (value: EStepChart) => {
    setStep(value)
  }
  return (
    <div className="pt-3 relative mt-8 pl-[15px]">
      {getStatisticIncomeLoading || getStatisticUserLoading ? (
        <div style={{ height: '500px', width: '100%' }}>
          <Skeleton
            active
            loading={!getStatisticIncomeLoading || !getStatisticUserLoading}
          />
          <Skeleton
            active
            loading={!getStatisticIncomeLoading || !getStatisticUserLoading}
          />
          <Skeleton
            active
            loading={!getStatisticIncomeLoading || !getStatisticUserLoading}
          />
        </div>
      ) : (
        <>
          <Step setStep={setStep} step={step} onChange={onChangeStep} />
          <LineChartComponent step={step} />
        </>
      )}
    </div>
  )
}
