import { PATH_CHALLENGE_DETAIL_MISSION } from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAppDispatch } from '@redux'
import { Skeleton, message } from 'antd'
import { ReactNode, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button, Input, SwitchButton, TextArea } from 'src/common'
import { UploadListImage } from 'src/common/upload/UploadListImage'
import {
  IChallengeItem,
  IMissionFormCreate,
} from 'src/interfaces/habit-management'
import {
  createMissionAction,
  getListMissionAction,
  getListMissionByIdAction,
  updateMissionAction,
} from 'src/redux/actions/habit-management'
import styled from 'styled-components'
import { z } from 'zod'

type ChallengeHabitModalProps = {
  isLoading: boolean
  title?: string
  content?: ReactNode
  item?: IChallengeItem | null
  changeLoading?: (value: boolean) => void
  noDefault: number
}

const addCoreValueSchema = z.object({
  mission: z
    .string()
    .trim()
    .nonempty('This is a required field')
    .max(100, 'Mission name should not excess 100 characters'),
  missionInDutch: z
    .string()
    .trim()
    .nonempty('This is a required field')
    .max(100, 'Mission name should not excess 100 characters'),
  // mediaIds: z.array(z.number()).nonempty('This is a required field'),
  description: z
    .string()
    .trim()
    .nonempty('This is a required field')
    .max(2000, 'Description should not excess 2000 characters'),
  descriptionInDutch: z
    .string()
    .trim()
    .nonempty('This is a required field')
    .max(2000, 'description should not excess 2000 characters'),
  challengeId: z.number().optional(),
  status: z.boolean().optional(),
  position: z.number().min(1),
})
const DetailMission = ({ changeLoading }: ChallengeHabitModalProps) => {
  const { ms, clId } = useParams<any>()

  const { state } = useLocation()

  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [defaultStatus, setDefaultStatus] = useState(false)
  const [total, setTotal] = useState(0)
  const [imageDest, setImageDest] = useState<any>([])
  const defaultValue: any = {
    mission: '',
    missionInDutch: '',
    // mediaIds: [],
    description: '',
    descriptionInDutch: '',
    status: false,
    position: total || 1,
  }
  const { control, handleSubmit, setValue } = useForm<IMissionFormCreate>({
    defaultValues: defaultValue,
    resolver: zodResolver(addCoreValueSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const getChallenge = async () => {
    if (!ms || !clId) {
      return
    }
    try {
      setIsLoading(true)

      const response: any = await dispatch(
        getListMissionByIdAction(+ms)
      ).unwrap()
      setValue('mission', response.data?.mission ?? '')
      setValue('missionInDutch', response.data?.missionInDutch ?? '')
      // setValue('mediaIds', response.data?.mediaIds ?? 0)
      setValue('descriptionInDutch', response.data?.descriptionInDutch ?? '')
      setValue('description', response.data?.description ?? '')
      // setImageDest(response.data?.mediaList.map((item: any) => item?.url || ''))
      setValue('status', response.data?.status ?? false)
      setDefaultStatus(response.data?.status ?? false)
      setValue('position', response.data?.position ?? 1)
      setTotal(response.data?.position + 1 ?? 1)
    } catch (error: any) {
      message.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }
  const onSave = async (data: IMissionFormCreate) => {
    if (!clId) {
      return
    }
    try {
      setIsLoading(true)
      if (ms && ms !== 'create') {
        const response = await dispatch(
          updateMissionAction({
            data,
            id: +ms,
          })
        ).unwrap()
        if (response?.success) message.success(response.message)
      } else {
        const response = await dispatch(
          createMissionAction({
            ...data,
            challengeId: +clId,
          })
        ).unwrap()
        if (response?.success) message.success(response.message)
      }
      navigate(PATH_CHALLENGE_DETAIL_MISSION.replace(':clId', clId))
    } catch (error: any) {
      message.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }
  const setPos = async () => {
    if (!clId) {
      return
    }
    setIsLoading(true)

    const responseV2 = await dispatch(
      getListMissionAction({
        page: 1,
        limit: 10,
        challengeId: +clId,
      })
    ).unwrap()
    setTotal(responseV2.data?.total + 1 ?? 1)
    setValue('position', responseV2.data?.total + 1 ?? 1)
    setIsLoading(false)
  }
  useEffect(() => {
    if (!!clId && !!ms && ms != 'create') {
      getChallenge()
    } else {
      setPos()
    }
  }, [])
  return (
    <SingleBlogStyled className="px-20 bg-white">
      <div className="flex items-center justify-center ">
        <div>
          <h1 className="mb-5 mt-5 text-[32px] text-center ">
            {ms && ms !== 'create' ? 'Mission detail' : 'Create new mission'}
          </h1>
        </div>
      </div>
      <div className="mt-6">
        {isLoading ? (
          <Skeleton
            paragraph={{ rows: 4 }}
            style={{
              marginTop: 10,
            }}
          />
        ) : (
          <form className="gap-5 flex flex-col">
            <Controller
              name="position"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="Position"
                    name="position"
                    min="0"
                    required
                    onChange={(e) => {
                      onChange(parseInt(e.target.value || '0', 10))
                    }}
                    value={value.toString()}
                    inputMode={'numeric'}
                    type="number"
                    alignment="col"
                    errors={error?.message}
                    disabled={state?.isDisabled}
                  />
                )
              }}
            />
            <Controller
              name="mission"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label={`Mission ${total} - EN`}
                    name="mission"
                    required
                    onChange={onChange}
                    value={value.toString()}
                    errors={error?.message}
                    alignment="col"
                    disabled={state?.isDisabled}
                  />
                )
              }}
            />
            <Controller
              name="description"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <TextArea
                    label="Detail EN"
                    name="description"
                    required
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                    disabled={state?.isDisabled}
                  />
                )
              }}
            />
            <Controller
              name="missionInDutch"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label={`Mission ${total} - NL`}
                    name="missionInDutch"
                    required
                    onChange={onChange}
                    value={value.toString()}
                    errors={error?.message}
                    alignment="col"
                    disabled={state?.isDisabled}
                  />
                )
              }}
            />
            <Controller
              name="descriptionInDutch"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <TextArea
                    label="Detail NL"
                    name="descriptionInDutch"
                    required
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                    containerClassName="mt-3"
                    disabled={state?.isDisabled}
                  />
                )
              }}
            />
            {/* <Controller
              name={'mediaIds'}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <div className="w-full mt-[10px] ">
                    <label
                      // htmlFor={}
                      className={`Input__label  inline-flex items-center mb-2  mr-[1.25rem]   col-span-2`}
                    >
                      Photo(max=10)
                      <span className="required text-[#B91C1C] font-bold">
                        *
                      </span>
                    </label>
                    <UploadListImage
                      onLoadEnd={(e) => {
                        setValue('mediaIds', [...value, e.dataValues.id])
                        onChange([...value, e.dataValues.id])
                      }}
                      maxCount={10}
                      changeLoading={changeLoading}
                      initResource={imageDest}
                      onRemoveItem={(index) => {
                        const tempValue = [...value]
                        tempValue.splice(index, 1)
                        setValue('mediaIds', tempValue)
                        onChange(tempValue)
                      }}
                      allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                      errors={error?.message}
                      limitFileSize={5}
                    />
                  </div>
                )
              }}
            /> */}
            <div className="mt-[80px] pb-10">
              <div className="flex items-center mt-5 justify-center">
                <Button
                  htmlType="submit"
                  type="primary"
                  size="middle"
                  disabled={total >= 30 || state?.isDisabled}
                  className="submit__btn login-btn"
                  loading={isLoading}
                  onClick={handleSubmit(onSave, (e) => {})}
                >
                  {'Save'}
                </Button>
              </div>
            </div>
          </form>
        )}
      </div>
    </SingleBlogStyled>
  )
}

export default DetailMission

const SingleBlogStyled = styled('div')(() => {
  // return {
  //   '.ant-tabs-nav': {
  //     marginBottom: 0,
  //     paddingLeft: '1.25rem',

  //     '& .ant-tabs-tab': {
  //       borderRadius: '0.75rem 0.75rem 0 0 !important',

  //       '&.ant-tabs-tab-active': {
  //         span: {
  //           color: '#009BB7',
  //           fontWeight: '500',
  //         },
  //       },
  //     },
  //   },

  //   '.ant-upload.ant-upload-select-picture-card': {
  //     width: '100%',
  //     height: 'auto',
  //   },
  // }
  return {}
})
