import { Card, Typography } from 'antd'
import { t } from 'i18next'
import { isNil } from 'lodash'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { Button } from 'src/common'
import { ReferenceSection } from './ReferenceSection'
import { TCoursesReference } from 'src/pages/teacher-request-detail/fill-in-teacher-information/types'

export const CoursesReference = ({ isViewMode }: TCoursesReference) => {
  const { control, getValues, setValue } = useFormContext()

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'specificInformation.courseReference',
    rules: {
      minLength: 0,
      maxLength: 5,
    },
  })
  const removableField = fields?.length > 1 && !isViewMode
  const [isAddableField, setAddableField] = React.useState<boolean>(false)

  const verifyAddableField = () => {
    const maxFieldsLength = 5
    const courseReference = getValues(`specificInformation.courseReference`)
    const areAllFieldsFilled = courseReference.every((obj: any) => {
      return Object.values(obj).every((value) => !isNil(value) && value !== '')
    })
    return !!areAllFieldsFilled && courseReference?.length < maxFieldsLength
  }

  const handleRemoveField = (id?: number) => {
    remove(id)
    setAddableField(verifyAddableField())
  }

  const handleSaveField = (id?: number) => {
    if (isNil(id)) {
      return
    }
    update(id, getValues(`specificInformation.courseReference[${id}]`))
    setAddableField(verifyAddableField())
  }

  const handleAddField = () => {
    const defaultFieldValue = {
      name: '',
      url: '',
      editable: true,
    }
    append(defaultFieldValue)
    setAddableField(false)
  }

  const handleEitField = (id?: number) => {
    if (isNil(id)) {
      return
    }
    setValue(`specificInformation.courseReference[${id}].editable`, true)
    update(id, getValues(`specificInformation.courseReference[${id}]`))
  }

  return (
    <CoursesReferenceStyled className="flex items-center justify-center w-full">
      <Typography.Title level={4} className="text-left">
        Course Reference
      </Typography.Title>
      {fields?.map((field: any, index) => {
        return (
          <ReferenceSection
            key={field.id}
            data={field}
            index={index}
            onRemove={removableField ? handleRemoveField : undefined}
            onSave={handleSaveField}
            onEdit={isViewMode ? undefined : handleEitField}
          />
        )
      })}
      {isAddableField && (
        <div className="flex items-center max-[800px] justify-center my-5">
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            className="min-[600px] max-[600px]:!w-full"
            onClick={handleAddField}
          >
            {t('common:addExperience')}
          </Button>
        </div>
      )}
    </CoursesReferenceStyled>
  )
}

const CoursesReferenceStyled = styled(Card)`
  .ant-card-body {
  }

  .reset-form {
    > div:last-child {
      justify-content: center;
    }
  }
`
