import { createAsyncThunk } from '@reduxjs/toolkit'

import { roleManagementAPI } from 'src/api/role-management'
import {
  IAddNewRolePayload,
  IGetRolesParams,
  IPostRolePayload,
  IStaffDeleteParams,
  IStaffPayload,
  TUpdateUserData,
} from 'src/interfaces'

export const getPermissions = createAsyncThunk(
  'role/getPermissions',
  async () => {
    try {
      const res = await roleManagementAPI.getPermissions()
      return res
    } catch (error) {
      throw error
    }
  }
)

export const getRolesAction = createAsyncThunk(
  'role/getRolesAction',
  async ({ limit = 10, page, search, sort, isDefault }: IGetRolesParams) => {
    try {
      const res = await roleManagementAPI.getRoles({
        limit,
        page,
        search,
        sort,
        isDefault,
      })
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const getRoleByIDAction = createAsyncThunk(
  'role/getRoleByIDAction',
  async (id: number) => {
    try {
      const res = await roleManagementAPI.getRoleByID(id)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const createRoleAction = createAsyncThunk(
  'role/createRoleAction',
  async (payload: IPostRolePayload) => {
    try {
      const res = await roleManagementAPI.createRole(payload)
      return res
    } catch (error) {
      throw error
    }
  }
)

export const updateRole = createAsyncThunk(
  'role/updateRole',
  async ({
    roleId,
    payload,
  }: {
    roleId: number
    payload: IAddNewRolePayload
  }) => {
    const response = await roleManagementAPI.updateRoleByID({ roleId, payload })
    return response || {}
  }
)

export const deleteRoleByID = createAsyncThunk(
  'role/deleteRoleByID',
  async (id: number) => {
    const response = await roleManagementAPI.deleteRoleByID(id)
    return response || {}
  }
)

export const getAdminListAction = createAsyncThunk(
  'role/getAdminListAction',
  async ({ limit = 10, page, search }: IGetRolesParams) => {
    try {
      const res = await roleManagementAPI.getAdminList({
        limit,
        page,
        search,
      })
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const createAdminAction = createAsyncThunk(
  'role/createAdminAction',
  async (payload: IStaffPayload) => {
    try {
      const res = await roleManagementAPI.createAdmin(payload)

      return res.data
    } catch (error: any) {
      throw new Error(
        error.errors?.length && typeof error.errors !== 'string'
          ? error.errors[0]
          : error.errors
          ? error.errors
          : error.message
      )
    }
  }
)

export const deleteAdminAction = createAsyncThunk(
  'role/deleteAdminAction',
  async ({ isSoft = false, id }: IStaffDeleteParams) => {
    try {
      const res = await roleManagementAPI.deleteAdmin({
        id,
      })

      return res
    } catch (error) {
      throw error
    }
  }
)

export const getAdminByIdAction = createAsyncThunk(
  'role/getAdminByIdAction',
  async (id: string) => {
    try {
      const res = await roleManagementAPI.getAdminById(id)

      return res
    } catch (error) {
      throw error
    }
  }
)

export const updateAdminByIdAction = createAsyncThunk(
  'role/updateAdminByIdAction',
  async (payload: Partial<TUpdateUserData>) => {
    try {
      const res = await roleManagementAPI.updateAdminById(payload)
      return res
    } catch (error: any) {
      throw new Error(
        error.errors?.length && typeof error.errors !== 'string'
          ? error.errors[0]
          : error.errors
          ? error.errors
          : error.message
      )
    }
  }
)

export const getListPermissionRoles = createAsyncThunk(
  'role/getListPermissionRoles',
  async () => {
    const response = await roleManagementAPI.getListPermissionRoles()

    return response?.data || {}
  }
)

export const createNewRole = createAsyncThunk(
  'role/createNewRole',
  async (payload: IAddNewRolePayload) => {
    const response = await roleManagementAPI.createNewRole(payload)

    return response || {}
  }
)

export const duplicateRole = createAsyncThunk(
  'role/duplicateRole',
  async (roleId: number) => {
    const response = await roleManagementAPI.duplicateRole(roleId)

    return response || {}
  }
)
