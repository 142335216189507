import { useEffect } from 'react'
import ChatProvider, { useChatContext } from './ChatContext'
import ChatContent from './content'
import ChatLeftSideBar from './left-side-bar'
import useSocket from 'src/utils/hooks/useSocket'
import { useParams } from 'react-router-dom'
import { Socket } from 'socket.io-client'

function ChatTab() {
  const { socket, setSocket } = useChatContext()
  const { id: courseId } = useParams()

  const socketInstance = useSocket({
    url: process.env.REACT_APP_PUBLIC_API_URL
      ? process.env.REACT_APP_PUBLIC_API_URL.replace('/api', '/course-chat')
      : '',
    queryParams: {
      courseId: String(courseId) || '',
    },
  })

  useEffect(() => {
    setSocket(socketInstance as Socket)
  }, [setSocket, socketInstance])

  useEffect(() => {
    if (!socket) return
    socket?.connect()

    return () => {
      socket?.disconnect()
    }
  }, [socket])

  return (
    <div className="flex items-start justify-center gap-1 bg-white w-full h-[75dvh] 2xl:h-[80dvh]">
      <ChatLeftSideBar />

      <ChatContent />
    </div>
  )
}

export default function ChatTabWithContext() {
  return (
    <ChatProvider>
      <ChatTab />
    </ChatProvider>
  )
}
