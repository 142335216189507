import { EHabitGoalStatus, EUserStatus } from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, SwitchButton } from 'src/common'
import { AppModal } from 'src/components/AppModal'
import { XCloseIcon } from 'src/components/Icon'
import { HabitGoalFormData } from 'src/interfaces/habit-management'
import { z } from 'zod'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import { EMediaCategory, EMediaType } from 'src/interfaces/gallery'

type IAddUpdateGoalProps = {
  goal?: any
  open: boolean
  isLoading: boolean
  onClose: () => void
  onSubmit: (data: HabitGoalFormData) => void
}

const goalSchema = z.object({
  goal: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(255, {
      message: t('error:goal_length_error'),
    }),
  goalInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(255, {
      message: t('error:goal_length_error'),
    }),
  status: z.boolean(),
  isDailyRoutine: z.boolean(),
  isChallenge: z.boolean(),
  mediaId: z.number().min(1, { message: t('error:field_required') as string }),
})

const AddUpdateGoalModal = (props: IAddUpdateGoalProps) => {
  const { open, goal, isLoading, onClose, onSubmit } = props
  const { control, handleSubmit, setValue } = useForm<HabitGoalFormData>({
    defaultValues: {
      goal: goal?.goal || '',
      goalInDutch: goal?.goalInDutch || '',
      status: goal && goal?.status === true ? true : false,
      mediaId: 0,
      isDailyRoutine: true,
      isChallenge: true,
    },
    resolver: zodResolver(goalSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const isEditGoal = !!goal

  const onChangeDesktopRef = useRef<any>(null)
  const [imageSrc, setImageSrc] = useState<string>('')

  useEffect(() => {
    if (!!goal) {
      setValue('goal', goal.goal ?? '')
      setValue('goalInDutch', goal.goalInDutch ?? '')
      setValue('status', goal.status)
      setValue('mediaId', goal.mediaId ?? 0)
      setValue('isChallenge', !!goal.isChallenge)
      setValue('isDailyRoutine', !!goal.isDailyRoutine)
      goal?.media?.url && setImageSrc(goal?.media?.url)
    }
  }, [goal, goal?.goal, goal?.goalInDutch, goal?.status, setValue])

  return (
    <AppModal open={open} onClose={onClose}>
      <div className="flex items-center justify-between ">
        <div>
          <h1 className="m-0 text-[20px]">
            {isEditGoal ? 'Edit goal' : 'Create new goal'}
          </h1>
        </div>
        <div className="hover:opacity-75 cursor-pointer">
          <XCloseIcon width={16} height={16} onClick={onClose} />
        </div>
      </div>
      <div className="mt-6">
        <form>
          <Controller
            name="goal"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  label="Goal English"
                  name="goalEn"
                  required
                  onChange={onChange}
                  value={value}
                  errors={error?.message}
                  disabled={!!goal?.deletedAt}
                />
              )
            }}
          />

          <Controller
            name="goalInDutch"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  containerClassName="mt-4"
                  label="Goal Netherlands"
                  name="goalLn"
                  onChange={onChange}
                  value={value}
                  errors={error?.message}
                  required
                  disabled={!!goal?.deletedAt}
                />
              )
            }}
          />

          <Controller
            name="mediaId"
            control={control}
            render={({ field: { onChange }, fieldState: { error } }) => {
              onChangeDesktopRef.current = onChange
              return (
                <CustomDragger
                  label="Image"
                  name="mediaId"
                  id="mediaId"
                  containerClassName="mt-3 "
                  required
                  onLoadEnd={(data) => {
                    setValue('mediaId', data?.id ?? 0)
                    onChange(data?.id ?? 0)
                  }}
                  errors={error?.message}
                  initResource={imageSrc}
                  // changeLoading={changeLoading}
                  allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                  limitFileSize={5}
                  labelClassName="w-1/2"
                  uploadType={EMediaType.IMAGE as any}
                  uploadCategory={EMediaCategory.ABOUT_US}
                  disabled={!!goal?.deletedAt}
                />
              )
            }}
          />

          <Controller
            name="status"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              console.log('VALUE CREATE', value)
              return (
                <div className="grid grid-cols-7 gap-3 mt-4">
                  <div className="col-span-2 flex items-center">
                    <p className="mb-0 mr-4">Status</p>
                  </div>
                  <div className="col-span-5">
                    <SwitchButton
                      size="default"
                      checked={value}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      disabled={!!goal?.deletedAt}
                    />
                  </div>
                </div>
              )
            }}
          />

          <Controller
            name="isChallenge"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <div className="grid grid-cols-7 gap-3 mt-4">
                  <div className="col-span-2 flex items-center">
                    <p className="mb-0 mr-4">Apply for challenges</p>
                  </div>
                  <div className="col-span-5">
                    <SwitchButton
                      size="default"
                      checked={value}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      disabled={!!goal?.deletedAt}
                    />
                  </div>
                </div>
              )
            }}
          />

          <Controller
            name="isDailyRoutine"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <div className="grid grid-cols-7 gap-3 mt-4">
                  <div className="col-span-2 flex items-center">
                    <p className="mb-0 mr-4">Apply for daily routine</p>
                  </div>
                  <div className="col-span-5">
                    <SwitchButton
                      size="default"
                      checked={value}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      disabled={!!goal?.deletedAt}
                    />
                  </div>
                </div>
              )
            }}
          />

          <div className="flex items-center mt-5 justify-end">
            <Button
              type="ghost"
              size="large"
              className="submit__btn login-btn mr-2"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              className="submit__btn login-btn"
              loading={isLoading}
              disabled={!!goal?.deletedAt}
              onClick={handleSubmit(onSubmit)}
            >
              {isEditGoal ? 'Save' : 'Create'}
            </Button>
          </div>
        </form>
      </div>
    </AppModal>
  )
}

export default AddUpdateGoalModal
