import { DeleteOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons'
import {
  ETableTitleAboutUsManagementCoreValue,
  PATH_CHALLENGE_DETAIL_NEW_MISSION,
} from '@configs'
import { getListCoreValues, useAppDispatch } from '@redux'
import { Card, Skeleton, Tooltip, message } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Input, Loading, SharedTable } from 'src/common'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import { useDebounce } from 'src/hooks/useDebounce'
import {
  IChallengeItem,
  IGetGoalParams,
  IMissionsItem,
} from 'src/interfaces/habit-management'
import {
  deleteMission,
  getListMissionAction,
} from 'src/redux/actions/habit-management'

const MissionPage = () => {
  const { clId } = useParams<any>()

  const [isModalLoading, setIsModalLoading] = useState(false)

  const [reset, setReset] = useState(false)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [selectedAboutUsId, setSelectedAboutUsId] = useState<number | null>(
    null
  )
  const [listMissions, setListMissions] = useState<IMissionsItem[]>([])

  const [searchText, setSearchText] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [paging, setPaging] = useState({
    total: 0,
    page: 1,
    limit: 10,
  })
  const debouncedValue = useDebounce<string | null>(searchText, 800)
  const { t } = useTranslation(['error', 'common'])
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { state } = useLocation()

  const onOpen = (id?: string) => {
    navigate(
      `${PATH_CHALLENGE_DETAIL_NEW_MISSION.replace(':ms', id || '').replace(
        ':clId',
        clId || ''
      )}`
    )
  }

  const onCloseConfirmModal = () => {
    setOpenConfirmDeleteModal(false)
  }
  const getListChallenger = async (params?: IGetGoalParams) => {
    try {
      setIsSearching(true)
      if (clId) {
        const response = await dispatch(
          getListMissionAction({
            page: params?.page || paging.page,
            limit: 10,
            search:
              debouncedValue !== null && debouncedValue.trim()
                ? debouncedValue.trim()
                : undefined,
            challengeId: +clId,
          })
        ).unwrap()
        setListMissions(response.data?.items)
        setPaging({
          page: response.data.page,
          limit: response.data.limit,
          total: response.data.total,
        })
      }
    } catch (err) {}

    setIsSearching(false)
  }
  useEffect(() => {
    if (debouncedValue !== null) {
      getListChallenger()
    }
  }, [debouncedValue])

  const columns = [
    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{ETableTitleAboutUsManagementCoreValue.NO}</p>
          </div>
        )
      },
      dataIndex: 'position',
      align: 'center',
      key: 'position',
      width: '5%',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      width: '20%',
    },
    {
      title: 'Title EN',
      dataIndex: 'mission',
      key: 'challenge',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      width: '10%',
    },
    {
      title: 'Title NL',
      dataIndex: 'missionInDutch',
      key: 'missionInDutch',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      width: '10%',
    },
    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">
              {ETableTitleAboutUsManagementCoreValue.ACTION}
            </p>
          </div>
        )
      },
      key: 'action',
      dataIndex: 'action',
      width: '15%',
      render: (record: IChallengeItem) => (
        <div className="flex space-x-4 items-center justify-center w-full">
          <Tooltip title={t('common:view_detail')}>
            <EyeOutlined
              className="text-lg font-light mr-2.5 cursor-pointer text-[#184f64]"
              onClick={() => {
                navigate(
                  `${PATH_CHALLENGE_DETAIL_NEW_MISSION.replace(
                    ':ms',
                    record.id.toString() || ''
                  ).replace(':clId', clId || '')}`,
                  { state: { isDisabled: state?.isChallengePublished } }
                )
              }}
            />
          </Tooltip>
          {!(record?.status || state?.isChallengePublished) && (
            <Tooltip
              title={
                record?.status
                  ? "Can't delete published mission"
                  : t('common:delete')
              }
            >
              <DeleteOutlined
                className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 ${
                  record?.status || state?.isChallengePublished
                    ? '!text-red-100'
                    : '!text-red-600 '
                }`}
                onClick={() => {
                  if (record?.status || state?.isChallengePublished) return
                  setOpenConfirmDeleteModal(true)
                  setSelectedAboutUsId(record.id)
                }}
              />
            </Tooltip>
          )}
        </div>
      ),
      ellipsis: true,
    },
  ]

  const onDelete = async () => {
    //on delete about us
    try {
      if (selectedAboutUsId) {
        setIsModalLoading(true)
        const response = await dispatch(
          deleteMission(selectedAboutUsId)
        ).unwrap()
        setReset((r) => !r)
        if (response?.success) {
          message.success(response.message)
          setIsModalLoading(false)
          setOpenConfirmDeleteModal(false)
          dispatch(getListCoreValues())
        }
      } else {
      }
    } catch (error: any) {
      message.error(error.message)
    }
  }
  useEffect(() => {
    getListChallenger()
  }, [reset])
  return (
    <Card>
      <div className="flex items-center justify-between py-[1.25rem] w-full">
        <>
          <div className="max-w-[700px] flex-1 flex gap-8">
            <Input
              prefix={<SearchOutlined className="pl-2" />}
              placeholder="Enter mission name"
              value={searchText === null ? '' : searchText}
              onChange={(e) => setSearchText(e.target.value)}
              suffix={isSearching ? <Loading /> : undefined}
            />
          </div>
          {!state?.isChallengePublished && (
            <div>
              <Button
                type="primary"
                disabled={paging.total >= 30 || state?.isChallengePublished}
                onClick={() => {
                  onOpen('create')
                }}
              >
                Add new
              </Button>
            </div>
          )}
        </>
      </div>
      {isSearching ? (
        <Skeleton
          paragraph={{ rows: 4 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <SharedTable
          columns={columns as any}
          dataSource={listMissions?.map((item, index) => {
            return {
              ...item,
              key: item?.id,
              no: index + 1,
              action: item,
            }
          })}
          sticky
          paginationProps={{
            total: !!paging.total ? +paging.total : undefined,
            pageSize: +paging.limit || 10,
            current: +paging.page,
            showSizeChanger: false,
            onChange(page) {
              getListChallenger({
                page: +page,
              })
            },
          }}
        />
      )}

      {openConfirmDeleteModal && (
        <ConfirmDeleteModal
          title="Delete this mission"
          content="Are you sure to delete this mission"
          open={openConfirmDeleteModal}
          isLoading={!!isModalLoading}
          onDelete={onDelete}
          onClose={onCloseConfirmModal}
        />
      )}
    </Card>
  )
}

export default MissionPage
