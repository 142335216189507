import { InputProps } from 'antd'
import React, { useMemo } from 'react'
import styled from 'styled-components'

interface Input extends InputProps {
  title: string
  suffix: string
  error?: string | null
  inputValues: any
  keyNote: string
  onClick?: any
}
function CustomInput({
  title,
  suffix,
  onChange,
  error,
  disabled,
  inputValues,
  keyNote,
  onClick,
  onFocus,
}: Input) {
  // const value: any = inputValues?.filter(
  //   (item: any) => item.slug === keyNote
  // )[0]
  //   ? Object.values(
  //       inputValues?.filter((item: any) => item.slug === keyNote)[0]
  //     )[1]
  //   : undefined
  const inputVal = useMemo(
    () => (!!inputValues ? inputValues : undefined),
    [inputValues]
  )
  return (
    <InputStyled>
      <label
        style={{
          color: disabled ? 'gray' : 'black',
        }}
      >
        {title}:
        <input
          style={{
            border: 'none',
            borderBottom: disabled
              ? '1px solid gray'
              : error
              ? '1px solid red'
              : '1px solid #000',
            margin: '0 5px',
            outline: 'none',
          }}
          onFocus={onFocus}
          type="number"
          disabled={disabled}
          onChange={onChange}
          value={inputVal}
          onClick={onClick}
        />
        {suffix}
      </label>
      {error && (
        <div className="grid grid-cols-7 w-full ">
          <div
            className={`Input__text-error mt-2 text-sm col-span-7 sm:col-span-7 text-left`}
          >
            {error}
          </div>
        </div>
      )}
    </InputStyled>
  )
}
const InputStyled = styled('div')<{ $isDisableShadow?: boolean }>`
  .Input {
    .ant-select-disabled {
      & .ant-select-selector {
        background-color: white !important;
        & .ant-select-selection-item {
          color: black;
        }
      }
    }
    .Input__field-container {
      --tw-shadow: ${(props) =>
        props.$isDisableShadow ? 'none' : '0 1px 2px 0 rgb(0 0 0 / 0.05)'};
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
    .ant-input {
      border-radius: 0.375rem;
      border-color: rgb(var(--color-slate-200) / var(--tw-border-opacity));
      font-size: 0.875rem;
      line-height: 1.25rem;
      background-color: #fff;
      border-width: 1px;
      padding: 0.5rem 0.75rem;
      appearance: none;
      --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

      &.ant-input-view {
        color: black;
      }

      &.ant-input-lg {
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 1.125rem;
        line-height: 1.75rem;
      }

      &.ant-input-sm {
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 0.75rem;
        line-height: 1rem;
      }

      &.ant-input-status-error,
      &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless) {
        --tw-border-opacity: 1;
        border-color: rgb(var(--color-danger) / var(--tw-border-opacity));
      }

      &:focus {
        border-color: rgb(var(--color-primary) / var(--tw-border-opacity));
        --tw-border-opacity: 0.4;
        --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
          var(--tw-ring-offset-width) var(--tw-ring-offset-color);
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
          calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
          var(--tw-shadow, 0 0 #0000);
        --tw-ring-color: rgb(var(--color-primary) / var(--tw-ring-opacity));
        --tw-ring-opacity: 0.2;
      }
    }

    .ant-input-affix-wrapper {
      padding: 0 12px 0 0;
      border-radius: 0.375rem;
      border-color: rgb(var(--color-slate-200) / var(--tw-border-opacity));
      --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

      .ant-input {
        border-radius: 0.375rem;
        border-color: rgb(var(--color-slate-200) / var(--tw-border-opacity));
        font-size: 0.875rem;
        line-height: 1.25rem;
        --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
          var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        background-color: #fff;
        border-width: 1px;
        padding: 0.5rem 0.75rem;
        appearance: none;

        &.ant-input-lg {
          padding-top: 0.375rem;
          padding-bottom: 0.375rem;
          padding-left: 1rem;
          padding-right: 1rem;
          font-size: 1.125rem;
          line-height: 1.75rem;
        }

        &.ant-input-sm {
          padding-top: 0.375rem;
          padding-bottom: 0.375rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          font-size: 0.75rem;
          line-height: 1rem;
        }

        &:focus {
          border-color: rgb(var(--color-primary) / var(--tw-border-opacity));
          --tw-border-opacity: 0.4;
          --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
            var(--tw-ring-offset-width) var(--tw-ring-offset-color);
          --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
            calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
          box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
            var(--tw-shadow, 0 0 #0000);
          --tw-ring-color: rgb(var(--color-primary) / var(--tw-ring-opacity));
          --tw-ring-opacity: 0.2;
        }
      }
      &.ant-input-affix-wrapper-focused,
      &:focus {
        border-color: rgb(var(--color-primary) / var(--tw-border-opacity));
        --tw-border-opacity: 0.4;
        --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
          var(--tw-ring-offset-width) var(--tw-ring-offset-color);
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
          calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
          var(--tw-shadow, 0 0 #0000);
        --tw-ring-color: rgb(var(--color-primary) / var(--tw-ring-opacity));
        --tw-ring-opacity: 0.2;
      }

      &.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
      &.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
        --tw-border-opacity: 1;
        border-color: rgb(var(--color-danger) / var(--tw-border-opacity));
      }

      &.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
        box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
        border-right-width: 1px;
        outline: 0;
      }
    }
  }

  .Input__text-error {
    --tw-text-opacity: 1;
    color: rgb(var(--color-danger) / var(--tw-text-opacity));
  }
`

export default CustomInput
