import moment, { Moment } from 'moment'

export function momentToMinutes(time?: moment.Moment | null) {
  if (!time) return 0
  return time.hours() * 60 + time.minutes() + time.seconds() / 60
}

export function momentToSeconds(time?: moment.Moment | null): number {
  if (!time) return 0

  const hours = time.hours()
  const minutes = time.minutes()
  const seconds = time.seconds()
  return hours * 3600 + minutes * 60 + seconds
}

export function minutesToMoment(totalMinutes: number) {
  if (!totalMinutes) return null
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  return moment().startOf('day').add(hours, 'hours').add(minutes, 'minutes')
}

export function secondsToMoment(totalSeconds: number) {
  const hours = Math.floor(totalSeconds / 3600)
  const remainingSecondsAfterHours = totalSeconds % 3600
  const minutes = Math.floor(remainingSecondsAfterHours / 60)
  const seconds = remainingSecondsAfterHours % 60

  return moment()
    .startOf('day')
    .add(hours, 'hours')
    .add(minutes, 'minutes')
    .add(seconds, 'seconds')
}
