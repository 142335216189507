import { CHUNK_SIZE } from '@configs'
import { galleryAPI } from 'src/api/gallery'

export class Uploader {
  chunkSize: number
  threadsQuantity: number
  file: File
  fileName: string
  fileType: string
  aborted: boolean
  uploadedSize: number
  progressCache: any
  activeConnections: any
  parts: {
    partNumber: number
    signedUrl: string
  }[]
  uploadedParts: {
    PartNumber: number
    ETag: string
  }[]
  uploadId: string | null
  key: string | null
  onProgressFn: (data: any) => void
  onErrorFn: (error?: any) => void
  onComplete: (data: any) => void
  data: any

  constructor(options: any) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.chunkSize = CHUNK_SIZE
    // number of parallel uploads
    this.threadsQuantity = Math.min(5, 15)
    this.file = options.file
    this.fileName = options.fileName
    this.fileType = options.fileType
    this.aborted = false
    this.uploadedSize = 0
    this.progressCache = {}
    this.activeConnections = {}
    this.parts = []
    this.uploadedParts = []
    this.uploadId = null
    this.key = null
    this.onProgressFn = () => {}
    this.onErrorFn = () => {}
    this.onComplete = () => {}
  }

  private uploadCompletionCallback?: () => void

  // Method to check if uploadedParts is filled with enough data
  // private checkUploadCompletion() {
  //   const numberOfParts = Math.ceil(this.file.size / this.chunkSize)
  //   if (this.uploadedParts.length === numberOfParts) {
  //     if (this.uploadCompletionCallback) {
  //       this.uploadCompletionCallback()
  //     }
  //   }
  // }

  // Method to set the callback for upload completion
  setUploadCompletionCallback(callback: () => void) {
    this.uploadCompletionCallback = callback
  }

  // starting the multipart upload request
  async start() {
    await this.initialize()
  }

  async initialize() {
    try {
      let fileName = this.fileName
      const fileType = this.fileType

      // initializing the multipart request
      const videoInitializationUploadInput = {
        fileName,
        fileType,
      }
      const initializeResponse = await galleryAPI.initMultipartUpload(
        videoInitializationUploadInput
      )

      const AWSFileDataOutput = initializeResponse.data

      this.uploadId = AWSFileDataOutput.uploadId
      this.key = AWSFileDataOutput.key

      // retrieving the pre-signed URLs
      const numberOfParts = Math.ceil(this.file.size / this.chunkSize)

      const AWSMultipartFileDataInput = {
        uploadId: this.uploadId,
        key: this.key,
        parts: numberOfParts,
      }

      const urlsResponse = await galleryAPI.getMulitpartPresignedUrl(
        AWSMultipartFileDataInput
      )

      const newParts = urlsResponse.data
      this.parts.push(...newParts)

      this.sendNext()
    } catch (error) {
      await this.complete(error)
    }
  }

  sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length

    if (activeConnections >= this.threadsQuantity) {
      return
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete()
      }

      return
    }

    const part = this.parts.pop()
    if (this.file && part) {
      const sentSize = (part.partNumber - 1) * this.chunkSize
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize)

      const sendChunkStarted = () => {
        this.sendNext()
      }

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext()
        })
        .catch((error: any) => {
          this.parts.push(part)
          this.complete(error)
          throw Error(error)
        })
    }
  }

  // terminating the multipart upload request on success or failure
  async complete(error?: any) {
    if (error && !this.aborted) {
      this.onErrorFn(error)
      return
    }

    if (error) {
      this.onErrorFn(error)
      return
    }

    if (this.uploadCompletionCallback) {
      this.uploadCompletionCallback()
    }
  }

  // finalizing the multipart upload request on success by calling
  // the finalization API
  async sendCompleteRequest() {
    if (this.uploadId && this.key && this.file) {
      const videoFinalizationMultiPartInput = {
        uploadId: this.uploadId,
        key: this.key,
        partsInfo: this.uploadedParts,
      }

      try {
        const finalResult = await galleryAPI.completeUploadMultipart(
          videoFinalizationMultiPartInput
        )
        if (finalResult.data) {
          return finalResult.data
        }
      } catch (error: any) {
        throw new Error(error)
      }
    }
  }

  sendChunk(
    chunk: Blob,
    part: {
      partNumber: number
      signedUrl: string
    },
    sendChunkStarted: () => void
  ) {
    return new Promise((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error('Failed chunk upload'))
            return
          }
          resolve('success')
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  // calculating the current progress of the multipart upload request
  handleProgress(part: any, event: any) {
    if (this.file) {
      if (
        event.type === 'progress' ||
        event.type === 'error' ||
        event.type === 'abort'
      ) {
        this.progressCache[part] = event.loaded
      }

      if (event.type === 'uploaded') {
        this.uploadedSize += this.progressCache[part] || 0
        delete this.progressCache[part]
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0)

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size)

      const total = this.file.size

      const percentage = Math.round((sent / total) * 100)

      this.onProgressFn({
        sent: sent,
        total: total,
        percentage: percentage,
      })
    }
  }

  // uploading a part through its pre-signed URL
  upload(
    file: Blob,
    part: {
      partNumber: number
      signedUrl: string
    },
    sendChunkStarted: () => void
  ) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.uploadId && this.key) {
        // - 1 because partNumber is an index starting from 1 and not 0
        const xhr = (this.activeConnections[part.partNumber - 1] =
          new XMLHttpRequest())

        sendChunkStarted()

        const progressListener = this.handleProgress.bind(
          this,
          part.partNumber - 1
        )

        xhr.upload.addEventListener('progress', progressListener)

        xhr.addEventListener('error', progressListener)
        xhr.addEventListener('abort', progressListener)
        xhr.addEventListener('loadend', progressListener)

        xhr.open('PUT', part.signedUrl)

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // retrieving the ETag parameter from the HTTP headers
            const ETag = xhr.getResponseHeader('ETag')

            if (ETag) {
              const uploadedPart = {
                PartNumber: part.partNumber,
                // removing the " enclosing carachters from
                // the raw ETag
                ETag: ETag.replaceAll('"', ''),
              }

              this.uploadedParts.push(uploadedPart)

              resolve(xhr.status)
              delete this.activeConnections[part.partNumber - 1]
            }
          }
        }

        xhr.onerror = (error) => {
          reject(error)
          delete this.activeConnections[part.partNumber - 1]
        }

        xhr.onabort = () => {
          reject(new Error('Upload canceled by user'))
          delete this.activeConnections[part.partNumber - 1]
        }

        xhr.send(file)
      }
    })
  }
}
