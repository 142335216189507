import {
  DEFAULT_PAGE,
  DEFAULT_ROWS_PER_PAGE,
  PATH_COURSE_REQUEST_MANAGEMENT,
} from '@configs'
import { Skeleton, Tooltip } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SharedTable } from 'src/common'

import { FormProvider, useForm } from 'react-hook-form'
import {
  RootState,
  getListCourseRequest,
  updateCourseRequestFilterValue,
  useAppDispatch,
} from '@redux'
import { useSelector } from 'react-redux'
import { useDebounce } from 'src/hooks/useDebounce'
import moment from 'moment'
import styled from 'styled-components'

import { EyeOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import {
  SEARCH_FORM_FIELDS,
  SEARCH_FORM_FIELD_DEFAULT_VALUES,
  TABLE_COLUMNS,
} from 'src/pages/course-request-management/config'
import CourseRequestTableToolbar from './CourseRequestTableToolbar'
import { ICourseRequestItem } from 'src/interfaces/course-request-management'
import MiniDashboard from './MiniDashboard'
import useDeepCompareEffect from 'use-deep-compare-effect'

export default function ListCourseRequest() {
  const { t } = useTranslation(['teacherRequest'])
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const { data: listCourseRequestData, isLoading } = useSelector(
    (state: RootState) => state.courseRequestManagement.listCourseRequest
  )
  const courseRequestFilterValue = useSelector(
    (state: RootState) => state.courseRequestManagement.courseRequestFilterValue
  )

  const [page, setPage] = useState(DEFAULT_PAGE)

  const methods = useForm({
    defaultValues: {
      ...SEARCH_FORM_FIELD_DEFAULT_VALUES,
      [SEARCH_FORM_FIELDS.SEARCH]: courseRequestFilterValue?.search || '',
      [SEARCH_FORM_FIELDS.DATE_RANGE]:
        courseRequestFilterValue?.dateRange || [],
      [SEARCH_FORM_FIELDS.REQUEST_STATUS]:
        courseRequestFilterValue?.requestStatus || [],
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })
  const { watch } = methods

  const searchDebounceValue = useDebounce(watch(SEARCH_FORM_FIELDS.SEARCH))
  const requestStatusValue = watch(SEARCH_FORM_FIELDS.REQUEST_STATUS)
  const dateRangeValue = watch(SEARCH_FORM_FIELDS.DATE_RANGE)

  const dateRangeParams = useMemo(() => {
    if (!Array.isArray(dateRangeValue) || !dateRangeValue?.length) return

    const deadlineFrom = moment(dateRangeValue?.[0]).startOf('day')
    const deadlineTo = moment(dateRangeValue?.[1]).endOf('day')

    return {
      // From 00:00:00 of start day
      startDate: deadlineFrom.toISOString(),
      // To 23:59:59 of end day
      endDate: deadlineTo.toISOString(),
    }
  }, [dateRangeValue])

  const queryParams = useMemo(() => {
    return {
      ...(!!searchDebounceValue && {
        search: String(searchDebounceValue).trim(),
      }),
      ...(Array.isArray(requestStatusValue) &&
        !!requestStatusValue.length && {
          statusRequest: requestStatusValue.join(','),
        }),
      ...(dateRangeParams?.startDate && {
        startDate: dateRangeParams?.startDate,
      }),
      ...(dateRangeParams?.endDate &&
        ({
          endDate: dateRangeParams?.endDate,
        } as any)),
    }
  }, [
    searchDebounceValue,
    requestStatusValue,
    dateRangeParams?.startDate,
    dateRangeParams?.endDate,
  ])

  const columns = useMemo(() => {
    return Object.values(TABLE_COLUMNS)
      ?.map((column) => {
        return {
          ...column,
          ellipsis: true,
          title: column.title ? t(String(column.title) as any) : '',
          ...(TABLE_COLUMNS.COURSE_REQUEST_ACTION.key === column.key && {
            render: (_: any, record: any) => {
              const { deletedAt } = record || {}

              return (
                <div className="flex items-center justify-center h-full w-full gap-2">
                  <Tooltip
                    title={deletedAt ? 'Course has been deleted' : 'View'}
                  >
                    <EyeOutlined
                      style={{
                        ...(!!deletedAt && {
                          color: '#00000040',
                          cursor: 'not-allowed',
                        }),
                      }}
                      onClick={() => {
                        if (!!deletedAt) return

                        navigate(
                          `${PATH_COURSE_REQUEST_MANAGEMENT}/${record?.courseId}`
                        )
                      }}
                    />
                  </Tooltip>
                </div>
              )
            },
          }),
        }
      })
      .filter(Boolean)
  }, [navigate, t])

  const listTeacherRequestFmt = useMemo(() => {
    if (
      !Array.isArray(listCourseRequestData?.items) ||
      !listCourseRequestData?.items.length
    )
      return []

    return listCourseRequestData.items.map(
      (it: ICourseRequestItem, idx: number) => {
        const {
          id,
          course,
          user: teacher,
          createdAt: requestedDate,
          updatedAt,
          deletedAt,
          responseData,
          status,
        } = it || {}
        return {
          index: idx + 1,
          id,
          courseId: course?.id,
          courseName: course?.title,
          teacherName: teacher?.name,
          requestedDate,
          updatedAt,
          responseData,
          status,
          deletedAt,
        }
      }
    )
  }, [listCourseRequestData.items])

  useDeepCompareEffect(() => {
    dispatch(
      getListCourseRequest({
        page,
        limit: DEFAULT_ROWS_PER_PAGE,
        ...queryParams,
      })
    )
  }, [dispatch, page, queryParams])

  useEffect(() => {
    setPage(DEFAULT_PAGE)
  }, [
    requestStatusValue,
    dateRangeParams?.startDate,
    dateRangeParams?.endDate,
    searchDebounceValue,
  ])

  useEffect(() => {
    return () => {
      dispatch(
        updateCourseRequestFilterValue({
          requestStatus: requestStatusValue,
          dateRange: dateRangeValue,
          search: searchDebounceValue,
        })
      )
    }
  }, [dateRangeValue, dispatch, requestStatusValue, searchDebounceValue])

  return (
    <>
      <div className="bg-white rounded-md p-4">
        <FormProvider {...methods}>
          <div className="w-full">
            <MiniDashboard />

            <form className="mt-10">
              <CourseRequestTableToolbar />
            </form>
          </div>
        </FormProvider>

        {isLoading ? (
          <Skeleton
            paragraph={{ rows: 10 }}
            style={{
              marginTop: 10,
            }}
          />
        ) : (
          <SharedTableStyled>
            <SharedTable
              columns={columns as any}
              dataSource={listTeacherRequestFmt}
              totalResults={Number(listCourseRequestData?.total)}
              paginationProps={{
                total: !!listCourseRequestData?.total
                  ? Number(listCourseRequestData?.total)
                  : undefined,
                pageSize: 10,
                showSizeChanger: false,
                current: page,
                onChange(page) {
                  setPage(page)
                },
              }}
              tableProps={{
                scroll: {
                  y: 600,
                },
              }}
            />
          </SharedTableStyled>
        )}
      </div>
    </>
  )
}

const SharedTableStyled = styled.div`
  .ant-table-wrapper {
    margin: 0 !important;
    margin-top: 16px !important;
    max-height: unset;
  }
`
