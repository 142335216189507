import { themes } from '@theme'
import { Tabs } from 'antd'
import { useState } from 'react'
import { Text } from 'src/common'
import styled from 'styled-components'
import { TEACHER_DETAIL_TAB_KEYS } from './config'
import TeacherOverviewWithContext from './TeacherOverview'
import { useTranslation } from 'react-i18next'

export default function TeacherDetailPage() {
  const { t } = useTranslation(['teacher'])
  const [activeTab, setActiveTab] = useState<string>(
    TEACHER_DETAIL_TAB_KEYS.TEACHER_INFO
  )

  const items = [
    {
      label: <Text>{t('teacher:teacherInfo')}</Text>,
      key: TEACHER_DETAIL_TAB_KEYS.TEACHER_INFO,
      children: <TeacherOverviewWithContext />,
    },
    {
      label: <Text>{t('teacher:teacherLog')}</Text>,
      key: TEACHER_DETAIL_TAB_KEYS.TEACHER_LOG,
      children: <div>Teacher log</div>,
    },
    {
      label: <Text>{t('teacher:withdrawal')}</Text>,
      key: TEACHER_DETAIL_TAB_KEYS.WITHDRAWAL,
      children: <div>Withdrawal</div>,
    },
    {
      label: <Text>{t('teacher:transactionHistory')}</Text>,
      key: TEACHER_DETAIL_TAB_KEYS.TRANSACTION_HISTORY,
      children: <div>Transaction history</div>,
    },
  ]

  return (
    <TeacherDetailContainerStyled>
      <Tabs
        destroyInactiveTabPane={true}
        defaultActiveKey="1"
        type="card"
        items={items}
        defaultChecked={true}
        activeKey={activeTab}
        onChange={(e) => {
          setActiveTab(e)
        }}
      />
    </TeacherDetailContainerStyled>
  )
}

const TeacherDetailContainerStyled = styled('div')(() => {
  return {
    '.ant-tabs-nav': {
      marginBottom: 0,
      paddingLeft: '1.25rem',

      '& .ant-tabs-tab': {
        borderRadius: '0.75rem 0.75rem 0 0 !important',

        '&.ant-tabs-tab-active': {
          span: {
            color: `${themes.theme.light.colors.primary}`,
            fontWeight: '500',
          },
        },

        '&.ant-tabs-tab-disabled': {
          background: '#b8b8b8 !important',
          '& .ant-tabs-tab-btn': {
            '& .ant-typography': {
              color: `${themes.theme.light.colors.secondary}`,
            },
          },
        },
      },
    },
  }
})
