import { RouterParams } from '@interfaces'
import { useParams } from 'react-router-dom'
import ContentBottom from 'src/components/chatChannel/chatContent/ContentBottom'
import { ContentTop } from 'src/components/chatChannel/chatContent/ContentTop'
import MainContent from 'src/components/chatChannel/chatContent/MainContent'

interface Props {
  className?: string
}

const ChatContent = ({ className }: Props) => {
  const { chatId } = useParams<RouterParams['ChatDetail']>()
  return (
    <div className="chat-box h-full w-full flex flex-col relative">
      <div className="chat-box-header w-full absolute top-0 left-0 h-[82px] ">
        <ContentTop chatId={chatId as string} />
      </div>
      <div className="chat-box-main w-full absolute top-[82px] left-0 bottom-[80px]">
        <MainContent chatId={chatId as string} />
      </div>
      <div className="chat-box-input w-full absolute bottom-0 left-0 h-[80px]">
        <ContentBottom chatId={chatId as string} />
      </div>
    </div>
  )
}

export default ChatContent
