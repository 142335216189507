/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import { PLACEHOLDER_IMAGE_AVATAR, thunkActionLoading } from '@configs'
import { Card, message, Skeleton, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  addNewCEOQuotes,
  deleteCEOQuotes,
  getListCEOQuotes,
  RootState,
  selectAboutUsLoading,
  updateCEOQuotes,
  useAppDispatch,
} from '@redux'
import { useTranslation } from 'react-i18next'
import { IPhotoMedia } from 'src/interfaces'
import {
  IAddCEOQuotesRequest,
  ICommonContentDetail,
} from 'src/interfaces/aboutus-management'
import { CEOquotesValueDetailModal } from './CEOquotesTabDetailModal'
import { Button, SharedTable } from 'src/common'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'

type Props = {}

const CEOquotesTab = (props: Props) => {
  const [showModal, setShowModal] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [selectedProviding, setSelectedProviding] = useState<
    ICommonContentDetail | undefined | null
  >()
  const [reset, setReset] = useState(false)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [selectedAboutUsId, setSelectedProvidingId] = useState<number | null>(
    null
  )
  const [noDefault, setNoDefault] = useState(0)

  const { t } = useTranslation(['error', 'common'])

  const dispatch = useAppDispatch()
  const getListAboutUsLoading = useSelector((state: RootState) =>
    selectAboutUsLoading(state, thunkActionLoading.GET_LIST_CEO_QUOTES_LOADING)
  )
  const addNewCEOQuoteLoading = useSelector((state: RootState) =>
    selectAboutUsLoading(state, thunkActionLoading.ADD_CEO_QUOTES_LOADING)
  )

  const { ceoQuotes } = useSelector(
    (state: RootState) => state.aboutUsManagement
  )

  const onOpen = () => {
    setSelectedProviding(null)
    setShowModal(true)
  }

  const onClose = () => {
    setShowModal(false)
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      align: 'center',
      key: 'no',
      width: '7%',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
    },
    {
      title: 'Author',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },

    {
      title: 'Quote',
      dataIndex: 'content',
      key: 'content',
      ellipsis: true,
    },
    {
      title: 'Image',
      align: 'center',
      dataIndex: 'media',
      key: 'media',
      render: (record: IPhotoMedia) => (
        <div className="flex items-center justify-center w-full">
          <img
            src={record?.url ?? PLACEHOLDER_IMAGE_AVATAR}
            alt={record?.url}
            className="object-cover w-14 h-14"
          />
        </div>
      ),
    },
    {
      title: 'Image mobile',
      align: 'center',
      dataIndex: 'mediaMobile',
      key: 'mediaMobile',
      render: (record: IPhotoMedia) => (
        <div className="flex items-center justify-center w-full">
          <img
            src={record?.url ?? PLACEHOLDER_IMAGE_AVATAR}
            alt={record?.url}
            className="object-cover w-14 h-14"
          />
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">Action</p>
          </div>
        )
      },
      key: 'action',
      dataIndex: 'action',
      width: '15%',
      render: (record: ICommonContentDetail) => (
        <div className="flex space-x-4 items-center justify-center w-full">
          <Tooltip title={t('common:view_detail')}>
            <EyeOutlined
              className="text-lg font-light mr-2.5 cursor-pointer text-[#184f64]"
              onClick={() => {
                setSelectedProviding(record)
                setShowModal(true)
              }}
            />
          </Tooltip>
          <DeleteOutlined
            className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 !text-red-600`}
            onClick={() => {
              setOpenConfirmDeleteModal(true)
              setSelectedProvidingId(record.id)
            }}
          />
        </div>
      ),
      ellipsis: true,
    },
  ]

  const onSave = async (data: IAddCEOQuotesRequest) => {
    //on save about us
    try {
      if (!!selectedProviding) {
        const response = await dispatch(
          updateCEOQuotes({ ...data, id: selectedProviding.id })
        ).unwrap()
        setShowModal(false)
        setReset((r) => !r)
        if (response?.aboutUsCeoQuote) message.success(response.message)
      } else {
        const response = await dispatch(addNewCEOQuotes({ ...data })).unwrap()
        setShowModal(false)
        setReset((r) => !r)
        if (response?.aboutUsCeoQuote) message.success(response.message)
      }
      dispatch(getListCEOQuotes())
    } catch (error: any) {
      message.error(error.message)
    }
  }
  useEffect(() => {
    setNoDefault(ceoQuotes?.length || 1)
  }, [ceoQuotes])

  const onDelete = async () => {
    //on delete about us
    try {
      if (selectedAboutUsId) {
        setIsModalLoading(true)

        const response = await dispatch(
          deleteCEOQuotes(selectedAboutUsId)
        ).unwrap()
        setShowModal(false)
        setReset((r) => !r)
        if (response?.success) {
          message.success(response.data.message)
          setIsModalLoading(false)
          setOpenConfirmDeleteModal(false)
          dispatch(getListCEOQuotes())
        }
      } else {
        setShowModal(false)
      }
    } catch (error: any) {
      message.error(error.message)
    }
  }

  const onCloseConfirmModal = () => {
    setOpenConfirmDeleteModal(false)
  }

  useEffect(() => {
    dispatch(getListCEOQuotes())
  }, [reset])

  return (
    <Card>
      <div className="flex">
        <div className="flex-1 justify-end flex">
          <Button
            htmlType="submit"
            type="primary"
            size="middle"
            className="submit__btn loginselected-btn"
            onClick={onOpen}
            style={{
              alignSelf: 'flex-end',
            }}
          >
            {'Add new'}
          </Button>
        </div>
      </div>
      {getListAboutUsLoading ? (
        <Skeleton
          paragraph={{ rows: 4 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <SharedTable
          columns={columns as any}
          dataSource={ceoQuotes?.map((item) => {
            return {
              ...item,
              key: item?.id,
              no: item?.no,
              title: item?.title
                ? item?.title
                : !!item?.mediaTitle?.length
                ? item?.mediaTitle
                : `-${t('error:no_data')}-`,
              content: item?.content ?? `-${t('error:no_data')}-`,
              record: item,
              action: item,
            }
          })}
          sticky
        />
      )}
      {showModal && (
        <CEOquotesValueDetailModal
          open={showModal}
          onClose={onClose}
          isLoading={!!isModalLoading || !!addNewCEOQuoteLoading}
          changeLoading={setIsModalLoading}
          onSave={onSave}
          item={selectedProviding}
          noDefault={noDefault + 1 || 1}
        />
      )}

      {openConfirmDeleteModal && (
        <ConfirmDeleteModal
          title="Delete value"
          content="Are you sure to delete this value?"
          open={openConfirmDeleteModal}
          isLoading={!!isModalLoading}
          onDelete={onDelete}
          onClose={onCloseConfirmModal}
        />
      )}
    </Card>
  )
}

export default CEOquotesTab
