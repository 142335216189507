import { ECourseType } from '@configs'
import { t } from 'i18next'
import { ECourseRequestStatus } from 'src/interfaces/course-request-management'

export const COURSE_DETAIL_TABS = {
  COURSE_OVERVIEW: 'course-overview',
  COURSE_CONTENT: 'course-content',
  COURSE_SEO_CONFIG: 'course-seo-config',
}

export const REASON_CHARACTER_LIMITATION = {
  MAX_LENGTH: 500,
  MIN_LENGTH: 2,
}

export const RESPONSE_STATUS_CONTENT: Record<
  ECourseRequestStatus,
  { color: string; responseTitle: string }
> = {
  [ECourseRequestStatus.ACCEPTED]: {
    color: '#1DD75BFF',
    responseTitle: 'Status: Accepted by',
  },
  [ECourseRequestStatus.REJECTED]: {
    color: '#DE3B40FF',
    responseTitle: 'Status: Rejected by',
  },
  [ECourseRequestStatus.PENDING]: {
    color: '#FE763EFF',
    responseTitle: '',
  },
}

export const COURSE_TYPE_OPTIONS = [
  {
    value: ECourseType.FREE,
    label: t('courseManagement:free'),
  },
  {
    value: ECourseType.PREMIUM,
    label: t('courseManagement:premium'),
  },
  {
    value: ECourseType.MEMBER_COURSE,
    label: t('courseManagement:memberCourse'),
  },
]
