import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '.'

import { thunkActionLoading } from '@configs'
import { TTeacherPackage } from '@interfaces'
import {
  createTeacherPackageAction,
  getAllTeacherPackagesAction,
  getTeacherPackageDetailsByIdAction,
  updateTeacherPackageByIdAction,
} from 'src/redux/actions'

interface ITeacherPackageState {
  teacherPackages: TTeacherPackage[]
  teacher?: TTeacherPackage
  teacherPackagesCurrentPage: number
  teacherPackagesTotalPage: number
  teacherPackagesTotalItems: number
  limitTeacherPackagesPerPage: number
  selectedTeacherPackage?: TTeacherPackage
  loadings: Record<string, boolean | undefined>
}

const initialState: ITeacherPackageState = {
  teacherPackages: [],
  teacherPackagesCurrentPage: 1,
  teacherPackagesTotalPage: 0,
  teacherPackagesTotalItems: 0,
  limitTeacherPackagesPerPage: 10,
  selectedTeacherPackage: undefined,
  loadings: {},
}

const teacherPackageSlice = createSlice({
  name: 'teacherPackages',
  initialState: initialState,
  reducers: {
    resetTeacherPackages: (state) => {
      state.teacherPackages = initialState.teacherPackages
      state.teacherPackagesCurrentPage = initialState.teacherPackagesCurrentPage
      state.teacherPackagesTotalPage = initialState.teacherPackagesTotalPage
      state.teacherPackagesTotalItems = initialState.teacherPackagesTotalItems
      state.limitTeacherPackagesPerPage =
        initialState.limitTeacherPackagesPerPage
      state.selectedTeacherPackage = initialState.selectedTeacherPackage
      state.loadings = initialState.loadings
    },

    clearSelectedPackage: (state) => {
      state.selectedTeacherPackage = initialState.selectedTeacherPackage
    },
    setTeacherPackagePage: (state, action) => {
      state.teacherPackagesCurrentPage = action.payload?.page
    },
  },
  extraReducers: (builder) => {
    // GET ALL TEACHER PACKAGES
    builder.addCase(getAllTeacherPackagesAction.pending, (state) => {
      state.loadings[thunkActionLoading.GET_ALL_TEACHER_PACKAGE_LOADING] = true
    })
    builder.addCase(getAllTeacherPackagesAction.fulfilled, (state, action) => {
      state.loadings[thunkActionLoading.GET_ALL_TEACHER_PACKAGE_LOADING] = false
      state.teacherPackages = action.payload?.items ?? []
      state.teacherPackagesCurrentPage = Number(action.payload?.page) ?? 0
      state.limitTeacherPackagesPerPage = Number(action.payload?.limit) ?? 10
      state.teacherPackagesTotalItems = action.payload?.total ?? 0
      state.teacherPackagesTotalPage = Math.ceil(
        action.payload?.total / (action.payload?.limit as number)
      )
    })
    builder.addCase(getAllTeacherPackagesAction.rejected, (state) => {
      state.loadings[thunkActionLoading.GET_ALL_TEACHER_PACKAGE_LOADING] = false
    })

    // GET TEACHER PACKAGE BY ID
    builder.addCase(getTeacherPackageDetailsByIdAction.pending, (state) => {
      state.loadings[thunkActionLoading.GET_TEACHER_PACKAGE_DETAILS_LOADING] =
        true
    })
    builder.addCase(
      getTeacherPackageDetailsByIdAction.fulfilled,
      (state, action) => {
        state.loadings[thunkActionLoading.GET_TEACHER_PACKAGE_DETAILS_LOADING] =
          false
        state.selectedTeacherPackage = action.payload.teacherPackage
      }
    )
    builder.addCase(getTeacherPackageDetailsByIdAction.rejected, (state) => {
      state.loadings[thunkActionLoading.GET_TEACHER_PACKAGE_DETAILS_LOADING] =
        false
    })

    // CREATE TEACHER PACKAGE
    builder.addCase(createTeacherPackageAction.pending, (state) => {
      state.loadings[thunkActionLoading.CREATE_EDIT_TEACHER_PACKAGE_LOADING] =
        true
    })
    builder.addCase(createTeacherPackageAction.fulfilled, (state, action) => {
      state.loadings[thunkActionLoading.CREATE_EDIT_TEACHER_PACKAGE_LOADING] =
        false
    })
    builder.addCase(createTeacherPackageAction.rejected, (state) => {
      state.loadings[thunkActionLoading.CREATE_EDIT_TEACHER_PACKAGE_LOADING] =
        false
    })

    // EDIT TEACHER PACKAGE
    builder.addCase(updateTeacherPackageByIdAction.pending, (state) => {
      state.loadings[thunkActionLoading.CREATE_EDIT_TEACHER_PACKAGE_LOADING] =
        true
    })
    builder.addCase(
      updateTeacherPackageByIdAction.fulfilled,
      (state, action) => {
        state.loadings[thunkActionLoading.CREATE_EDIT_TEACHER_PACKAGE_LOADING] =
          false
      }
    )
    builder.addCase(updateTeacherPackageByIdAction.rejected, (state) => {
      state.loadings[thunkActionLoading.CREATE_EDIT_TEACHER_PACKAGE_LOADING] =
        false
    })
  },
})

export const teacherPackagesActions = {
  ...teacherPackageSlice.actions,
}

export const { setTeacherPackagePage } = teacherPackageSlice.actions

export const selectTeacherPackagesLoading = (state: RootState, name: string) =>
  state.plans.loadings[`${name}Loading`]

export default teacherPackageSlice.reducer
