import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { Controller, useForm } from 'react-hook-form'

import { Button, Input } from 'src/common'
import { AppModal } from 'src/components/AppModal'
import { XCloseIcon } from 'src/components/Icon'
import { TUpdateUserData } from 'src/interfaces'
import { ShareSelectInput } from 'src/components/shared'
import { RootState, useAppDispatch, useAppSelector } from 'src/redux'
import { useEffect } from 'react'
import { getListPackageAction } from 'src/redux/actions/package-management'
import { addUserSchema } from './config'
import { EPackagePrivacy } from '@configs'

type Props = {
  open: boolean
  isLoading?: boolean
  onClose: () => void
  onSave: (data: TUpdateUserData) => Promise<void>
}

const AddUserModal = ({ open, onClose, onSave, isLoading }: Props) => {
  const { control, handleSubmit, setValue, reset } = useForm<TUpdateUserData>({
    defaultValues: {
      email: '',
      name: '',
      packageId: undefined,
      phone: undefined,
      status: 'active',
      username: '',
    },
    resolver: zodResolver(addUserSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const { packages } = useAppSelector((state: RootState) => state.package)
  const dispatch = useAppDispatch()

  const packagesOptions = packages?.map((packageItem) => {
    return {
      label: packageItem?.name,
      value: packageItem?.id?.toString(),
      note:
        packageItem?.type === EPackagePrivacy.PRIVATE ? packageItem?.type : '',
    }
  })

  useEffect(() => {
    dispatch(getListPackageAction({ page: 1, limit: 10000 }))
  }, [dispatch])

  useEffect(() => {
    if (open) {
      setValue('phone', undefined)
    }
    return () => {
      reset()
    }
  }, [open, setValue, reset])

  return (
    <AppModal open={open} onClose={onClose}>
      <div className="flex items-center justify-between ">
        <div>
          <h1 className="m-0 text-[20px]">Add new user</h1>
        </div>
        <div className="hover:opacity-75 cursor-pointer">
          <XCloseIcon width={16} height={16} onClick={onClose} />
        </div>
      </div>
      <div className="mt-6 w-[500px]">
        <form>
          <div>
            <Controller
              name="name"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    alignment="col"
                    label={t('common:full_name') as string}
                    name="email"
                    className="input"
                    value={value}
                    type="text"
                    onChange={onChange}
                    errors={error?.message}
                    required
                  />
                )
              }}
            />
          </div>

          <div className="mt-[16px]">
            <Controller
              name="username"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    alignment="col"
                    label={t('common:user_name') as string}
                    name="username"
                    className="input"
                    value={value}
                    type="text"
                    onChange={onChange}
                    errors={error?.message}
                    required
                  />
                )
              }}
            />
          </div>
          <div className="mt-[16px]">
            <Controller
              name="email"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    alignment="col"
                    label={t('common:email') as string}
                    name="email"
                    className="input "
                    value={value}
                    type="text"
                    onChange={onChange}
                    errors={error?.message}
                    required
                  />
                )
              }}
            />
          </div>
          <div className="mt-[16px]">
            <Controller
              name="phone"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    alignment="col"
                    label={t('common:phone_number') as string}
                    name="phone"
                    className="input "
                    value={value}
                    type="text"
                    onChange={(e) => {
                      if (e.target.value) {
                        onChange(e.target.value)
                        return
                      }
                      onChange(undefined)
                    }}
                    errors={error?.message}
                  />
                )
              }}
            />
          </div>

          <div className="mt-[16px]">
            <Controller
              name="packageId"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <ShareSelectInput
                    value={value}
                    data={packagesOptions as any}
                    label="Assign package"
                    placeholder="Choose package"
                    onChange={(data) => {
                      onChange(data)
                    }}
                  />
                )
              }}
            />
          </div>

          <div className="mt-6">
            <div className="flex items-center mt-5 justify-end">
              <Button
                type="ghost"
                size="middle"
                className="submit__btn login-btn mr-10"
                onClick={onClose}
              >
                {'Cancel'}
              </Button>
              <Button
                htmlType="button"
                type="primary"
                size="middle"
                className="submit__btn login-btn"
                loading={isLoading}
                onClick={handleSubmit(onSave)}
              >
                {'Save'}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </AppModal>
  )
}

export default AddUserModal
