import { EPermissions } from './enum'

export interface IPermissions {
  [EPermissions.USER_MANAGEMENT]: boolean
  [EPermissions.PACKAGE_MANAGEMENT]: boolean
  [EPermissions.NEWLETTER_MANAGEMENT]: boolean
  [EPermissions.EMAIL_MANAGEMENT]: boolean
  [EPermissions.GROUP_MANAGEMENT]: boolean
  [EPermissions.CATEGORY_MANAGEMENT]: boolean
  [EPermissions.SUB_CATEGORY_MANAGEMENT]: boolean
  [EPermissions.BLOG_MANAGEMENT]: boolean
  [EPermissions.COMMUNITY_MANAGEMENT]: boolean
  [EPermissions.GOAL_MANAGEMENT]: boolean
  [EPermissions.QUESTION_MANAGEMENT]: boolean
  [EPermissions.CHALLENGE_MANAGEMENT]: boolean
  [EPermissions.DAILY_ROUTINE_MANAGEMENT]: boolean
  [EPermissions.COURSE_MANAGEMENT]: boolean
  [EPermissions.TEST_MANAGEMENT]: boolean
  [EPermissions.TEACHER_MANAGEMENT]: boolean
  [EPermissions.STAFF_MANAGEMENT]: boolean
  [EPermissions.ROLE_MANAGEMENT]: boolean
  [EPermissions.DASHBOARD]: boolean
  [EPermissions.STARTING_SCREEN]: boolean
  [EPermissions.CHAT_CHANNEL]: boolean
}

export enum EStatusCode {
  NOT_FOUND = 404,
  RESTRICTED_ACCESS = 403,
}
