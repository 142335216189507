import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '.'

import { thunkActionLoading } from '@configs'
import { ITeacherItem } from 'src/interfaces/teacher'
import { getAllTeachersAction, getTeacherByIdAction } from 'src/redux/actions'

interface ITeacherState {
  teachers: ITeacherItem[]
  teacher?: ITeacherItem
  teachersCurrentPage: string | number
  teachersTotalPage: string | number
  teachersTotalItems: string | number
  limitTeachersPerPage: string | number
  selectedTeacher?: ITeacherItem | null
  loadings: Record<string, boolean | undefined>
}

const initialState: ITeacherState = {
  teachers: [],
  teachersCurrentPage: 1,
  teachersTotalPage: 0,
  teachersTotalItems: 0,
  limitTeachersPerPage: 10,
  loadings: {},
  selectedTeacher: null,
}

const teachersSlice = createSlice({
  name: 'teachers',
  initialState: initialState,
  reducers: {
    resetTeacher: (state) => {
      state = {
        ...initialState,
      }
    },
  },
  extraReducers: (builder) => {
    // GET ALL TEACHER
    builder.addCase(getAllTeachersAction.pending, (state) => {
      state.loadings[thunkActionLoading.GET_ALL_TEACHER_LOADING] = true
    })
    builder.addCase(getAllTeachersAction.fulfilled, (state, action) => {
      state.loadings[thunkActionLoading.GET_ALL_TEACHER_LOADING] = false
      state.teachers = action.payload?.items ?? []
      state.teachersCurrentPage = action.payload?.page ?? 0
      state.limitTeachersPerPage = action.payload?.limit ?? 10
      state.teachersTotalPage = Math.ceil(
        action.payload?.total / (action.payload?.limit as number)
      )
      state.teachersTotalItems = action.payload?.total ?? 0
    })
    builder.addCase(getAllTeachersAction.rejected, (state) => {
      state.loadings[thunkActionLoading.GET_ALL_TEACHER_LOADING] = false
    })

    // GET TEACHER BY ID
    builder.addCase(getTeacherByIdAction.pending, (state) => {
      state.loadings[thunkActionLoading.GET_TEACHER_BY_ID_LOADING] = true
    })
    builder.addCase(getTeacherByIdAction.fulfilled, (state, action) => {
      state.loadings[thunkActionLoading.GET_TEACHER_BY_ID_LOADING] = false
      state.selectedTeacher = action.payload
    })
    builder.addCase(getTeacherByIdAction.rejected, (state) => {
      state.loadings[thunkActionLoading.GET_TEACHER_BY_ID_LOADING] = false
      state.selectedTeacher = null
    })
  },
})

export const teachersActions = {
  ...teachersSlice.actions,
}

export const selectTeachersLoading = (state: RootState, name: string) =>
  state.plans.loadings[`${name}Loading`]

export default teachersSlice.reducer
