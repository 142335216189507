import axios from 'axios'
import axiosClient from 'src/api/axiosClient'

export const dowloadFile = async (endpoint: string) => {
  try {
    const res = await axiosClient.get(`/${endpoint}`, {
      responseType: 'blob',
    })

    const fileName = res.headers['content-disposition'].split('=')
    const downloadUrl = window.URL.createObjectURL(res.data)
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', fileName[1]) //any other extension
    document.body.appendChild(link)
    link.click()
    link.remove()
  } catch (error: any) {
    if (error.response.status === '401') {
      throw new Error('Unauthorized')
    }
    throw new Error('Can not export file')
  }
}

const handleDownloadFileByBlob = (url: string, response: any) => {
  const anchor = document.createElement('a')
  const filename =
    response?.headers?.['content-disposition']?.split('filename=')[1] ||
    url.substring(url.lastIndexOf('/') + 1)
  const objectUrl = window.URL.createObjectURL(response?.data)

  document.body.appendChild(anchor)

  anchor.href = objectUrl
  anchor.target = '_blank'
  anchor.download = filename

  anchor.click()
  anchor.remove()

  window.URL.revokeObjectURL(objectUrl)
}

export const downloadFileFromUrl = async (url: string) => {
  try {
    if (!url) throw new Error(`File url isn't existed!`)

    const response = await axios.get(url, {
      responseType: 'blob',
    })

    handleDownloadFileByBlob(url, response)
  } catch (error: any) {
    throw new Error(error)
  }
}
