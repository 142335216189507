import { themes } from '@theme'
import ChatsTab from 'src/components/chatChannel/ChatsTab'
import styled from 'styled-components'

interface Props {
  className?: string
}

const ChatSideBar = ({ className }: Props) => {
  return (
    <ChatSidebarStyled className={className}>
      <ChatsTab />
    </ChatSidebarStyled>
  )
}

export default ChatSideBar

const ChatSidebarStyled = styled('div')(() => {
  return {
    '.ant-tabs-nav': {
      marginBottom: 0,
      paddingLeft: '1.25rem',
      marginLeft: '-1.25rem',

      '& .ant-tabs-tab': {
        borderRadius: '0.75rem 0.75rem 0 0 !important',

        '&.ant-tabs-tab-active': {
          span: {
            color: '#009BB7',
            fontWeight: '500',
          },
        },
      },

      '.ant-tabs-nav-list': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      },

      '.ant-tabs-tab': {
        width: '50%',
        justifyContent: 'center',
      },

      '.ant-tabs-ink-bar': {
        backgroundColor: themes.theme.light.colors.primary,
        width: '50%',
      },

      '.ant-tabs-tab+.ant-tabs-tab': {
        marginLeft: '0',
      },
    },
  }
})
