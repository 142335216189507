import styled from 'styled-components'

export const InputWrapperGridStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  gap: 16px;

  & > div {
    min-width: 450px;
    grid-column: span 4 / span 4;
  }

  .textarea {
    margin-top: 10px;
    min-height: 120px;
    height: 100%;
  }

  .Input__field-container {
    width: 100%;
  }
`
