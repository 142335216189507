import { Popover } from 'antd'
import { TooltipPlacement } from 'antd/es/tooltip'
import React from 'react'
import styled from 'styled-components'

interface IProps {
  open: boolean
  onOpenChange?: (open: boolean) => void
  content?: React.ReactNode
  children?: React.ReactNode
  trigger?: 'click' | 'hover'
  title?: React.ReactNode
  placement?: TooltipPlacement
  overlayClassName?: string
  onConfirm?: () => void
  allowOpen?: boolean
}

export const PopoverPopup = (props: IProps) => {
  const {
    open,
    content,
    trigger = 'click',
    title,
    placement,
    children,
    overlayClassName,
    onConfirm,
    onOpenChange,
    ...other
  } = props

  return (
    <Popover
      open={open}
      trigger={trigger}
      onOpenChange={onOpenChange}
      title={title}
      placement={placement}
      overlayClassName={overlayClassName}
      content={<StyledPopover>{content}</StyledPopover>}
      {...other}
    >
      {children}
    </Popover>
  )
}

const StyledPopover = styled.div`
  .btn {
    padding: 0.4rem 0.3rem;
  }
  .sketch-picker {
    background: none !important;
    box-shadow: none !important;
  }
`
