import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Checkbox, Divider } from 'antd'
import React, { useEffect, useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import styled from 'styled-components'

function CheckListItem({
  packageBenefit,
  checkedList,
  onCheck,
  control,
}: {
  packageBenefit: any
  checkedList: any
  onCheck: any
  control: Control<any>
}) {
  const [isChildrenVisible, setChildrenVisible] = useState(true)

  return (
    <div key={packageBenefit.key}>
      <div className="flex items-center">
        {!!packageBenefit?.name ? (
          <Controller
            name={packageBenefit.name}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <CheckBoxStyled className="col-span-1">
                  <Checkbox
                    checked={
                      packageBenefit?.checked ||
                      checkedList?.includes(packageBenefit.key)
                    }
                    disabled={packageBenefit.disabled}
                    onChange={(e) => {
                      onChange(e.target.value)
                      packageBenefit?.onChange()
                      !packageBenefit?.checked &&
                        onCheck(packageBenefit.key, packageBenefit.key)
                    }}
                  >
                    {packageBenefit.title}
                  </Checkbox>
                </CheckBoxStyled>
              )
            }}
          />
        ) : (
          <CheckBoxStyled className="col-span-1">
            <Checkbox
              checked={
                packageBenefit?.checked ||
                checkedList?.includes(packageBenefit.key)
              }
              disabled={packageBenefit.disabled}
              onChange={() => {
                packageBenefit?.onChange()
                !packageBenefit?.checked &&
                  onCheck(packageBenefit.key, packageBenefit.key)
              }}
            >
              {packageBenefit.title}
            </Checkbox>
          </CheckBoxStyled>
        )}

        <div className="flex-1 my-5">{/* <Divider /> */}</div>
      </div>
      <div>
        {isChildrenVisible && (
          <div className="grid grid-cols-2 gap-y-3 ml-20">
            {packageBenefit.children?.map((item: any, index: any) => {
              return (
                <>
                  {!!item?.name ? (
                    <Controller
                      name={item.name}
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <CheckBoxStyled className="col-span-1" key={index}>
                            <Checkbox
                              checked={value}
                              disabled={item.disabled}
                              onChange={(e) => {
                                onChange(e.target.checked)
                                item?.onChange()
                              }}
                            >
                              <p>{item.title}</p>
                            </Checkbox>
                          </CheckBoxStyled>
                        )
                      }}
                    />
                  ) : (
                    <CheckBoxStyled className="col-span-1" key={index}>
                      <Checkbox
                        checked={
                          packageBenefit?.checked ||
                          checkedList?.includes(item.key)
                        }
                        disabled={item.disabled}
                        onChange={(e) => {
                          if (
                            packageBenefit?.hasChildUnchecked &&
                            !item.isUncheckRest
                          ) {
                            onCheck(
                              item.key,
                              packageBenefit.key,
                              undefined,
                              true
                            )
                            return
                          }
                          if (item.isUncheckRest) {
                            onCheck(item.key, packageBenefit.key, true)
                            return
                          }

                          packageBenefit.single
                            ? onCheck(item.key, packageBenefit.key)
                            : onCheck(item.key)
                        }}
                      >
                        <p>{item.title}</p>
                      </Checkbox>
                    </CheckBoxStyled>
                  )}
                </>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
const CheckBoxStyled = styled.div`
  .ant-checkbox {
    &:hover {
      .ant-checkbox-inner {
        border-color: ${({ theme }) => {
          return theme.colors?.primary
        }};
      }
    }
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme }) => {
      return theme.colors?.primary
    }};
  }

  .ant-checkbox-checked {
    :after {
      border-color: ${({ theme }) => {
        return theme.colors?.primary
      }};
    }

    .ant-checkbox-inner {
      border-color: ${({ theme }) => theme.colors?.primary};
      background-color: ${({ theme }) => {
        return theme.colors?.primary
      }};
    }
  }
`

export default CheckListItem
