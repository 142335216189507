import { t } from 'i18next'
import moment from 'moment'
import { z } from 'zod'

import {
  MIN_DOB,
  MOMENT_YEARS,
  REGEX_FULLNAME,
  REGEX_PHONENUMBER,
  VALID_URL_REGEX,
} from '@configs'

const validateName = z
  .string()
  .trim()
  .min(1, {
    message: t('error:thisFieldRequired'),
  })
  .min(2, {
    message: t('error:thisFieldMin2'),
  })
  .max(255, { message: t('error:thisFieldMax255') })

export const personalInformationSchema = z.object({
  firstname: z
    .string()
    .trim()
    .min(1, {
      message: t('error:thisFieldRequired'),
    })
    .max(50, {
      message: t('error:thisFieldMax50') as string,
    })
    .min(2, {
      message: t('error:name_min_length_2_error') as string,
    })
    .refine(
      (value) => REGEX_FULLNAME.test(value ?? ''),
      t('error:name_invalid')
    ),
  lastname: z
    .string()
    .trim()
    .min(1, {
      message: t('error:thisFieldRequired'),
    })
    .max(50, {
      message: t('error:thisFieldMax50') as string,
    })
    .min(2, {
      message: t('error:name_min_length_2_error') as string,
    })
    .refine(
      (value) => REGEX_FULLNAME.test(value ?? ''),
      t('error:name_invalid')
    ),
  gender: z.string().min(1, {
    message: t('error:thisFieldRequired'),
  }),
  dateOfBirth: z
    .string()
    .min(1, {
      message: t('error:thisFieldRequired'),
    })
    .refine(
      (value) => {
        const date = moment(value)
        return (
          date.isValid() &&
          date.isBefore(moment().subtract(MIN_DOB, MOMENT_YEARS))
        )
      },
      {
        message: t('error:mustBeAtLeast18YearsOld'),
      }
    ),
  phone: z
    .string()
    .trim()
    .min(1, {
      message: t('error:thisFieldRequired'),
    })
    .min(8, {
      message: t('error:phone_length_error'),
    })
    .max(13, {
      message: t('error:phone_length_error'),
    })
    .refine((value) => REGEX_PHONENUMBER.test(value ?? ''), {
      message: t('error:phone_format_error'),
    }),
  description: z.optional(
    z.union([
      z
        .string()
        .trim()
        .max(500, {
          message: t('error:descriptionMaxError'),
        }),
      z.string().trim().length(0),
    ])
  ),
  avatarId: z.number().min(1, {
    message: t('error:thisFieldRequired'),
  }),
})

export const educationSchema = z
  .object({
    institution: validateName,
    educationLevel: z.string().min(1, {
      message: t('error:thisFieldRequired'),
    }),
    qualification: z
      .string()
      .trim()
      .min(1, {
        message: t('error:thisFieldRequired'),
      })
      .min(2, {
        message: t('error:thisFieldMin2'),
      })
      .max(255, {
        message: t('error:thisFieldMax255'),
      }),
    description: z
      .string()
      .trim()
      .min(1, {
        message: t('error:thisFieldRequired'),
      })
      .min(2, {
        message: t('error:thisFieldMin2'),
      })
      .max(500, { message: t('error:descriptionMaxError') }),
    startDate: z
      .string()
      .min(1, {
        message: t('error:thisFieldRequired'),
      })
      .refine(
        (data) => {
          if (new Date(data) > new Date()) {
            return false
          }
          return true
        },
        { message: t('error:dateCannotInTheFuture') }
      ),
    endDate: z
      .string()
      .min(1, {
        message: t('error:thisFieldRequired'),
      })
      .refine(
        (data) => {
          if (new Date(data) > new Date()) {
            return false
          }
          return true
        },
        { message: t('error:dateCannotInTheFuture') }
      ),
  })
  .refine(
    (data) => {
      if (new Date(data.startDate) >= new Date(data.endDate)) {
        return false
      }
      return true
    },
    {
      message: t('error:startDateCannotMoreThanEndDate'),
      path: ['startDate'],
    }
  )
  .refine(
    (data) => {
      if (new Date(data.endDate) <= new Date(data.startDate)) {
        return false
      }
      return true
    },
    {
      message: t('error:endDateCannotLessThanStartDate'),
      path: ['endDate'],
    }
  )

export const workExperienceSchema = z
  .object({
    companyName: validateName,
    jobTitle: z
      .string()
      .trim()
      .min(1, {
        message: t('error:thisFieldRequired'),
      })
      .min(2, {
        message: t('error:thisFieldMin2'),
      })
      .max(255, {
        message: t('error:thisFieldMax255'),
      }),
    description: z
      .string()
      .trim()
      .min(1, {
        message: t('error:thisFieldRequired'),
      })
      .min(2, {
        message: t('error:thisFieldMin2'),
      })
      .max(500, { message: t('error:descriptionMaxError') }),
    startDate: z
      .string()
      .min(1, {
        message: t('error:thisFieldRequired'),
      })
      .refine(
        (data) => {
          if (new Date(data) > new Date()) {
            return false
          }
          return true
        },
        { message: t('error:dateCannotInTheFuture') }
      ),
    endDate: z
      .string()
      .min(1, {
        message: t('error:thisFieldRequired'),
      })
      .refine(
        (data) => {
          if (new Date(data) > new Date()) {
            return false
          }
          return true
        },
        { message: t('error:dateCannotInTheFuture') }
      ),
  })
  .refine(
    (data) => {
      if (new Date(data.startDate) >= new Date(data.endDate)) {
        return false
      }
      return true
    },
    {
      message: t('error:startDateCannotMoreThanEndDate'),
      path: ['startDate'],
    }
  )
  .refine(
    (data) => {
      if (new Date(data.endDate) <= new Date(data.startDate)) {
        return false
      }
      return true
    },
    {
      message: t('error:endDateCannotLessThanStartDate'),
      path: ['endDate'],
    }
  )

export const degreeSchema = z.object({
  name: validateName,
  fileId: z.number().min(1, {
    message: t('error:thisFieldRequired'),
  }),
})

export const courseReferenceSchema = z.object({
  name: validateName,
  url: z
    .string()
    .trim()
    .min(1, {
      message: t('error:thisFieldRequired'),
    })
    .refine((data) => VALID_URL_REGEX.test(data || ''), {
      message: t('error:enterValidUrl'),
    }),
})

export const specificInformationSchema = z.object({
  education: z.array(educationSchema),
  workExperience: z.array(workExperienceSchema),
  degree: z.array(degreeSchema),
  courseReference: z.array(courseReferenceSchema),
})

export const teacherInfoSchema = z.object({
  personalInformation: personalInformationSchema,
  specificInformation: specificInformationSchema,
})

export const defaultFormValues = {
  personalInformation: {
    firstname: '',
    lastname: '',
    gender: '',
    avatarId: 0,
    dateOfBirth: '',
    phone: '',
    description: '',
    email: '',
    password: '',
  },
  specificInformation: {
    education: [
      {
        institution: '',
        educationLevel: '',
        qualification: '',
        description: '',
        startDate: '',
        endDate: '',
        editable: true,
        isPresent: false,
        degree: {
          file: {
            name: '',
            fileId: 0,
          },
        },
      },
    ],
    workExperience: [
      {
        companyName: '',
        jobTitle: '',
        description: '',
        startDate: '',
        endDate: '',
        editable: true,
        isPresent: false,
      },
    ],
    courseReference: [
      {
        name: '',
        url: '',
        editable: true,
      },
    ],
  },
}
