import { Avatar, Button, Image, Typography, message } from 'antd'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'

import {
  CameraOutlined,
  CloseCircleOutlined,
  SendOutlined,
} from '@ant-design/icons'
import ReactPlayer from 'react-player'
import { Loading, TextArea } from 'src/common'
import { TCommentItem } from 'src/interfaces/post-management'
import { useAppDispatch, useAppSelector } from 'src/redux'
import {
  getChildComments,
  postReaction,
  replyComment,
} from 'src/redux/actions/post-management'
import { formatDateCreated } from 'src/utils'
import {
  ECallApiStatus,
  EReactionType,
  POST_MEDIA_FILTER,
  VIDEO_FILTER,
  thunkActionLoading,
} from 'src/configs'
import { ApiClient } from 'src/api'
import { selectPostLoading } from 'src/redux/Slices/post-management'
import ReactionsButton, {
  ReactionItems,
} from 'src/common/button/ReactionsButton'
import { set } from 'lodash'
import { is } from 'immer/dist/internal'
import { IMAGES } from '@assets'

type Props = {
  item?: TCommentItem
  isParentComments?: boolean
  onReacton?: () => void
}

const CommentItem = (props: Props) => {
  const { item, isParentComments } = props

  const [image, setImage] = useState<{ file: File; src: string }>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [content, setContent] = useState<string>('')
  const [localComment, setLocalComment] = useState<TCommentItem | undefined>(
    item
  )
  const [chilComments, setChildComments] = useState<{
    page: number
    total: number
    items: TCommentItem[]
  }>({
    page: 1,
    total: 0,
    items: [],
  })
  const [page, setPage] = useState<number>(1)
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const errors = !content && !image
  const isHasMore =
    (chilComments?.items?.length ?? 0) < (chilComments?.total ?? 0)

  const reaction = ReactionItems?.find(
    (item) => item.key === localComment?.reaction?.type
  )

  const dispatch = useAppDispatch()

  const loadingComments = useAppSelector((state) =>
    selectPostLoading(state, thunkActionLoading.GET_CHILD_COMMENTS)
  )

  const onReaction = async (key?: string) => {
    try {
      const selectedItem = ReactionItems?.find((item) => item?.key === key)
      const response: any = await dispatch(
        postReaction({
          type: selectedItem ? selectedItem?.key : 'none',
          commentId: localComment?.id,
        })
      ).unwrap()
      if (response?.commentId === localComment?.id) {
        setLocalComment((prev: any) => ({
          ...(prev ?? {}),
          reaction: !selectedItem ? null : response,
        }))
      }
    } catch (error) {}
  }

  const onClickLike = async () => {
    onReaction(reaction ? undefined : EReactionType.LIKE)
  }

  const onReply = () => {
    setIsEdit(true)
  }
  const getChildCommentsActions = async (page = 1) => {
    if (item?.id && item?.repliedCommentNumber) {
      try {
        const response: any = await dispatch(
          getChildComments({ id: Number(item?.id), page })
        ).unwrap()
        if (response) {
          setChildComments((prev) => {
            if (page === 1) {
              return response
            }
            return {
              ...response,
              items: [...prev?.items, ...response?.items],
            }
          })
          setPage(response?.page)
        }
      } catch (error: any) {
        console.log(error)
        message.error(error?.data?.message)
      }
    }
  }

  const onRemoveImage = () => {
    setImage(undefined)
  }

  const handleChangeMedia = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) return

    const file = e.target.files[0]
    const isMatchMediaSize = Number(file.size) / 1024 / 1024 < 10
    const isMatchMediaType = POST_MEDIA_FILTER.test(file.type)

    const allowedInputType = ['mp4', 'mpeg', 'jpg', 'jpeg', 'png', 'gif']
      .join('/')
      .toUpperCase()

    if (!isMatchMediaType) {
      message.error(`You can only upload ${allowedInputType}file!`)
      return
    }

    if (!isMatchMediaSize) {
      message.error(`Media file must smaller than ${10}MB!`)
      return
    }

    setImage({ file, src: URL.createObjectURL(file) })
  }

  const onSave = async () => {
    setIsLoading(true)
    if (!image && !content) {
      message.error('The content field or image is required')
      setIsLoading(false)
      return
    }

    if (content.length > 2200) {
      message.error('The maximum length of content field is 2200 characters')
      setIsLoading(false)
      return
    }

    message.loading({
      content: 'Uploading comment',
      duration: 0,
    })

    try {
      const payload: any = {
        id: localComment?.id,
        content,
      }

      if (image) {
        const formData = new FormData()

        formData.append('file', image?.file)
        const resMedia: any = await ApiClient.post(
          `/media/post-media`,
          formData
        )
        if (resMedia) {
          payload.mediaId = resMedia?.data?.dataValues?.id
        }
      }

      const response: any = await dispatch(replyComment(payload)).unwrap()
      message.destroy()
      message.success('Comment successfully')
      setChildComments((prev: any) => {
        return {
          ...(prev ?? {}),
          items: [
            {
              ...response,
            },
            ...(prev?.items ?? []),
            ,
          ],
        }
      })
      setContent('')
      setImage(undefined)
    } catch (error: any) {
      message.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const renderActions = () => {
    return (
      <div className="p-[12px] flex flex-col items-start border-t border-neutral-100">
        {!!image && (
          <div className="flex items-center justify-start gap-[16px] flex-wrap mb-[12px]">
            {VIDEO_FILTER.test(image.file?.type) ? (
              <div className="w-[200px] h-[200px] rounded-[8px] flex items-center justify-center rounded-[8px] relative border">
                <div
                  className="flex items-center justify-center absolute right-[-10px] top-[-10px] cursor-pointer z-10 bg-white rounded-full"
                  onClick={() => {
                    onRemoveImage()
                  }}
                >
                  <CloseCircleOutlined className=" text-[20px]" />
                </div>
                <ReactPlayer
                  url={image?.src}
                  loop
                  controls={true}
                  style={{
                    width: '100%',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                    borderRadius: '8px',
                  }}
                  height={200}
                  width={200}
                  className="react-player !rounded-[8px] !overflow-hidden"
                />
              </div>
            ) : (
              <div key={image.src} className="border rounded-[8px] relative">
                <div
                  className="flex items-center justify-center absolute right-[-10px] top-[-10px] cursor-pointer z-10 bg-white rounded-full"
                  onClick={() => {
                    onRemoveImage()
                  }}
                >
                  <CloseCircleOutlined className=" text-[20px]" />
                </div>
                <img
                  src={image.src}
                  alt="iamge"
                  className="w-[200px] h-[200px] object-contain rounded-[8px]"
                />
              </div>
            )}
          </div>
        )}
        <div className="flex w-full items-center justify-between">
          <label
            className="flex items-center justify-center p-[8px] rounded-full w-fit cursor-pointer hover:bg-neutral-100 transition-all duration-300"
            htmlFor="post-files"
          >
            <CameraOutlined className="text-[20px]" />
          </label>
          <input
            type="file"
            className="hidden"
            id="post-files"
            multiple={false}
            onChange={handleChangeMedia}
          />

          <div className="flex items-center gap-[12px] w-fit">
            <label
              className={`flex items-center justify-center p-[8px] rounded-full w-fit an   opacity-100 cursor-pointer hover:bg-neutral-100 transition-all duration-300`}
              onClick={() => {
                setIsEdit(false)
              }}
            >
              <CloseCircleOutlined className="text-[20px]" />
            </label>
            <label
              className={`flex items-center justify-center p-[8px] rounded-full w-fit   ${
                errors
                  ? 'opacity-75 cursor-default'
                  : 'opacity-100 cursor-pointer hover:bg-neutral-100 transition-all duration-300'
              }
                ${isLoading ? 'cursor-not-allowed' : ''}
              `}
              onClick={() => {
                if (isLoading) return
                onSave()
              }}
            >
              <SendOutlined className="text-[20px]" />
            </label>
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    getChildCommentsActions()
  }, [item])

  return (
    <div className="mt-[20px]">
      <div className="w-full flex items-center justify-start gap-[8px] mb-[12px]">
        <Avatar
          size={32}
          src={
            localComment?.user?.profilePhoto?.url ??
            IMAGES.logo_defaultProfileImage
          }
        />
        <Typography className="text-[14px] text-black font-semibold">
          {localComment?.user?.name}
        </Typography>
      </div>
      <div className="">
        {localComment?.mediaId &&
          !VIDEO_FILTER.test(localComment?.media?.type ?? '') && (
            <Image
              preview={false}
              width={200}
              src={localComment?.media?.url}
              className="rounded-[20px] object-contain mb-[12px]"
              placeholder={
                <Image
                  preview={false}
                  src={localComment?.media?.url}
                  width={200}
                />
              }
            />
          )}
        {localComment?.mediaId &&
          VIDEO_FILTER.test(localComment?.media?.type ?? '') && (
            <div className="w-[200px] h-[200px] rounded-[8px] flex items-center justify-center relative border mb-[12px]">
              <ReactPlayer
                url={localComment?.media?.url}
                loop
                controls={true}
                style={{
                  width: '100%',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                  borderRadius: '8px',
                }}
                height={200}
                width={200}
                className="react-player !rounded-[8px] !overflow-hidden"
              />
            </div>
          )}
        {localComment?.content && (
          <div className="px-[16px] py-[8px] rounded-[8px] bg-sidebar_bg  w-fit">
            <Typography className="text-[16px] text-main font-normal">
              {localComment?.content}
            </Typography>
          </div>
        )}
      </div>
      <div className="flex items-center justify-start gap-[12px] mt-[8px]">
        <Typography className="text-[14px] text-black font-normal">
          {formatDateCreated(localComment?.createdAt ?? '', undefined, 'ago')}
        </Typography>
        <ReactionsButton
          onSelect={(key: string) => {
            onReaction(key)
          }}
        >
          <Button type="text" className={`!text-main`} onClick={onClickLike}>
            <Typography
              style={{
                fontWeight: reaction ? '600' : '400',
                color: reaction ? reaction?.color : '#009BB7',
              }}
            >
              {!!reaction ? reaction?.label : 'Like'}
            </Typography>
          </Button>
        </ReactionsButton>
        {isParentComments && (
          <Button
            type="text"
            className="!text-main"
            onClick={() => {
              localComment?.id && onReply?.()
            }}
          >
            Reply
          </Button>
        )}
      </div>
      {isParentComments && isEdit && (
        <form
          className={`${
            isEdit ? 'animate-fadeOut' : 'animate-fadeIn'
          } mt-[12px]`}
        >
          <TextArea
            name="content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            rows={4}
            placeholder="Write your comment"
            disableResize={true}
            footerActions={renderActions()}
          />
        </form>
      )}
      {isParentComments && (
        <div className="px-[64px]">
          {chilComments?.items?.map((comment, index) => {
            return (
              <CommentItem
                item={comment}
                key={comment?.id}
                isParentComments={false}
              />
            )
          })}
          {loadingComments && (
            <div className="w-full flex items-center justify-start mt-[24px]">
              <Loading />
            </div>
          )}
          {isHasMore && !loadingComments && (
            <Button
              type="text"
              className="mt-[12px]"
              onClick={() => {
                getChildCommentsActions(page + 1)
              }}
            >
              -------- Show more replies
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default CommentItem
