import { HOME_SEO } from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import { RootState, useAppDispatch, useAppSelector } from '@redux'
import { Skeleton, message } from 'antd'
import { t } from 'i18next'
import { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from 'src/common'
import { IHomeSeoDetail } from 'src/interfaces/content-management'
import { EMediaCategory } from 'src/interfaces/gallery'
import MetaTab from 'src/pages/blog/content/MetaTab'
import { createHomeSeo, getHomeSeo } from 'src/redux/actions/content-management'
import * as z from 'zod'

interface IHomeSeoPageProps {
  activeTab?: string
}

const HomeSeoPage = ({ activeTab }: IHomeSeoPageProps) => {
  const [isUploading, setIsUploading] = useState(false)

  const dispatch = useAppDispatch()

  const detailHomeSeo = useAppSelector(
    (state: RootState) => state.content?.homeSeo
  )

  const isGetHomeSeoLoading = useAppSelector(
    (state: RootState) => state.content?.loadings?.GET_HOME_SEO_LOADING
  )

  const isCreateHomeSeoLoading = useAppSelector(
    (state: RootState) => state.content?.loadings?.CREATE_HOME_SEO_LOADING
  )

  const homeSeoSchema = z.object({
    metaTitle: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(100, {
        message: t('error:metaTitle_length_error') as string,
      }),
    metaDescription: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:metaDescription_length_error') as string,
      }),
    canonical: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:canonical_length_error') as string,
      }),
    schemaMarkup: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:metaDescription_length_error') as string,
      }),
    metaImage: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      }),
    keywords: z
      .string({
        invalid_type_error: t('error:field_required') as string,
      })
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:metaKeyword_length_error') as string,
      }),
  })

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    clearErrors,
    setError,
    getValues,
    trigger,
    reset,
  } = useForm<IHomeSeoDetail>({
    defaultValues: {
      metaTitle: detailHomeSeo?.metaData?.metaTitle || '',
      metaDescription: detailHomeSeo?.metaData?.metaDescription || '',
      canonical: detailHomeSeo?.metaData?.canonical || '',
      schemaMarkup: detailHomeSeo?.metaData?.schemaMarkup || 'schema mark up',
      metaImage: detailHomeSeo?.metaData?.metaImage || '',
      keywords: detailHomeSeo?.metaData?.keywords || '',
    },
    resolver: zodResolver(homeSeoSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (data: IHomeSeoDetail) => {
    try {
      const response = await dispatch(createHomeSeo(data)).unwrap()

      if (response.success) {
        message.success(response?.message ?? '')
      }
    } catch (error: any) {
      message.error(error?.message ?? '')
    }
  }

  const handleClickSave = () => {
    handleSubmit(onSubmit)()
  }
  const handleClickCancel = () => {
    setValue('metaTitle', detailHomeSeo?.metaData?.metaTitle || '')
    setValue('metaDescription', detailHomeSeo?.metaData?.metaDescription || '')
    setValue('canonical', detailHomeSeo?.metaData?.canonical || '')
    // setValue('schemaMarkup', detailHomeSeo?.metaData?.schemaMarkup || '')
    setValue('metaImage', detailHomeSeo?.metaData?.metaImage || '')
    setValue('keywords', detailHomeSeo?.metaData?.keywords || '')
  }

  useEffect(() => {
    if (activeTab === HOME_SEO) {
      const getHomeSeoData = async () => {
        try {
          const response = await dispatch(getHomeSeo()).unwrap()
          if (response.success) {
            setValue('metaTitle', response.data?.metaData?.metaTitle)
            setValue(
              'metaDescription',
              response.data?.metaData?.metaDescription
            )
            setValue('canonical', response.data?.metaData?.canonical)
            // setValue('schemaMarkup', response.data?.metaData?.schemaMarkup)
            setValue('metaImage', response.data?.metaData?.metaImage)
            setValue('keywords', response.data?.metaData?.keywords)
          }
        } catch (error: any) {
          message.error(error?.message ?? '')
        }
      }
      getHomeSeoData()
    }
  }, [activeTab])

  return (
    <Fragment>
      {isGetHomeSeoLoading ? (
        <>
          <Skeleton
            paragraph={{ rows: 4 }}
            style={{
              marginTop: 10,
            }}
          />
        </>
      ) : (
        <div className="bg-white">
          <MetaTab
            activeTab={activeTab}
            control={control}
            getValues={getValues}
            setValue={setValue}
            setIsUploading={setIsUploading}
            isShowSelectMedia
            category={EMediaCategory.HOME_PAGE}
          />

          <div className="term-policy-action mr-5 pb-5 flex items-center justify-end gap-6">
            <Button
              type="default"
              size="small"
              id="blog-form"
              onClick={() => {
                handleClickCancel()
              }}
            >
              Reset
            </Button>
            <Button
              type="primary"
              size="small"
              id="blog-form"
              onClick={() => {
                handleClickSave()
              }}
              loading={isCreateHomeSeoLoading || isCreateHomeSeoLoading}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default HomeSeoPage
