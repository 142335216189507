/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import {
  ETableTitleAboutUsManagementCoreValue,
  EUserPostStatus,
  PATH_CHALLENGE_DETAIL,
  PATH_CHALLENGE_DETAIL_MISSION,
  PATH_CHALLENGE_DETAIL_V2,
  PATH_SUB_HABIT_GOAL,
  PLACEHOLDER_IMAGE_AVATAR,
  POST_DISPLAY_NAME,
  thunkActionLoading,
} from '@configs'
import { IPhotoMedia } from '@interfaces'
import {
  RootState,
  getListCoreValues,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { Card, Skeleton, Tag, Tooltip, message } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Input, Loading, SharedTable } from 'src/common'
import ReactPlayer from 'react-player'
import { useNavigate } from 'react-router-dom'

import { ShareSelectInput } from '@components'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import { useDebounce } from 'src/hooks/useDebounce'
import { IChallengeItem, IGetGoalParams } from 'src/interfaces/habit-management'
import {
  deleteChallenge,
  duplicateChallengeAction,
  getListChallengerAction,
  getListGoalsAction,
} from 'src/redux/actions/habit-management'

const ChallengeHabitPage = () => {
  const [isModalLoading, setIsModalLoading] = useState(false)

  const [reset, setReset] = useState(false)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [selectedAboutUsId, setSelectedAboutUsId] = useState<number | null>(
    null
  )
  const { goal } = useAppSelector((state) => state.habit)

  const [goals, setGoals] = useState<string>('All')

  const [searchText, setSearchText] = useState('')
  const [isSearching, setIsSearching] = useState(false)

  const debouncedValue = useDebounce<string | null>(searchText, 800)
  const { t } = useTranslation(['error', 'common'])
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const getListCoreValuesLoading = useAppSelector(
    (state: RootState) =>
      state.habit.loadings[thunkActionLoading.GET_LIST_CHALLENGES_LOADING]
  )
  const goalLoading = useAppSelector(
    (state) => state.habit.loadings[thunkActionLoading.GET_LIST_GOALS_LOADING]
  )

  const listCoreValues = useAppSelector(
    (state: RootState) => state.habit?.challenges.challenges
  )
  const { total, page, limit } = useAppSelector(
    (state: RootState) => state.habit?.challenges
  )

  const onOpen = (id?: string) => {
    navigate(`${PATH_CHALLENGE_DETAIL.replace(':clId', id || 'create')}`)
  }
  const handleChaneStatus = (value: string | number) => {
    setGoals(value as any)
    getListChallenger({
      goal: +value,
    })
  }

  const onCloseConfirmModal = () => {
    setOpenConfirmDeleteModal(false)
  }
  const getListChallenger = async (params?: IGetGoalParams) => {
    setIsSearching(true)

    dispatch(
      getListChallengerAction({
        page: params?.page || page,
        limit: 10,
        search:
          debouncedValue !== null && debouncedValue.trim()
            ? debouncedValue.trim()
            : undefined,
        goalId: params?.goal === 0 ? undefined : params?.goal,
      })
    )
    setIsSearching(false)
  }
  const onDuplicateChallenge = async (id: number) => {
    try {
      const response = await dispatch(duplicateChallengeAction({ id: id }))
      message.success('Duplicate challenge information successfully')
      setReset((r) => !r)
    } catch (error) {
      console.log('error', JSON.stringify(error, null, 2))
    }
  }
  useEffect(() => {
    if (debouncedValue !== null) {
      getListChallenger()
    }
  }, [debouncedValue])

  const columns = [
    {
      title: 'File',
      align: 'center',
      dataIndex: 'media',
      key: 'media',
      width: '10%',

      render: (record: IPhotoMedia) => {
        if (record) {
          if (['image/png', 'image/jpeg', 'image/jpg'].includes(record.type)) {
            return (
              <div className="flex items-center justify-center w-full">
                <img
                  src={record?.url ?? PLACEHOLDER_IMAGE_AVATAR}
                  alt={record?.url}
                  className="object-cover w-14 h-14"
                />
              </div>
            )
          }

          return (
            <div className="flex items-center justify-center w-full">
              <div className="w-14 h-14">
                <ReactPlayer
                  url={record?.url}
                  controls={true}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              </div>
            </div>
          )
        } else {
          return (
            <div className="flex items-center justify-center w-full">
              <img
                src={PLACEHOLDER_IMAGE_AVATAR}
                alt={'image'}
                className="object-cover w-14 h-14"
              />
            </div>
          )
        }
      },
    },
    {
      title: 'Challenge EN',
      dataIndex: 'challenge',
      key: 'challenge',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      width: '16%',
    },
    {
      title: 'Challenge NL',
      dataIndex: 'challengeInDutch',
      key: 'challengeInDutch',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      width: '16%',
    },
    {
      title: 'Mission',
      key: 'missionsNumber',
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
      width: '8%',

      sorter: (a: any, b: any) => a.missionsNumber - b.missionsNumber,
      render: (goal: IChallengeItem) => (
        <div className="flex w-full items-center ">
          <a
            className="m-0 underline text-center"
            onClick={() => {
              if (goal.id) {
                navigate(
                  PATH_CHALLENGE_DETAIL_MISSION.replace(
                    ':clId',
                    goal.id.toString()
                  ),
                  {
                    state: {
                      isChallengePublished: !goal?.isDraft,
                    },
                  }
                )
              }
            }}
          >
            {goal.missionsNumber || 0}
          </a>
        </div>
      ),
    },
    {
      title: 'Goals',
      dataIndex: 'goal',
      key: 'goal',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      width: '8%',

      render: (goal: any, item: { record: IChallengeItem }) => {
        return (
          <div className="flex w-full items-center  flex-wrap gap-2">
            <a
              className="m-0 underline text-center"
              onClick={() => {
                handleChaneStatus(goal.id)
              }}
            >
              {goal?.goal}
            </a>
            {!goal.status && (
              <Tag color={'red'} key={item.record.id}>
                {'Inactive'}
              </Tag>
            )}
          </div>
        )
      },
    },
    {
      title: 'Status',
      key: 'status',
      width: '10%',
      sorter: (a: any, b: any) => a.status - b.status,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      render: (challenge: IChallengeItem) => (
        <div className="flex  w-full">
          <p className={`m-0 bold ${!!challenge?.deletedAt && 'text-danger'}`}>
            {challenge?.deletedAt
              ? 'Deleted'
              : challenge?.status
              ? 'Active'
              : 'InActive'}
          </p>
        </div>
      ),
    },
    {
      title: 'Published/Draft',
      key: 'isDraft',
      dataIndex: 'isDraft',
      width: '10%',
      align: 'center',
      ellipsis: true,
      render: (isDraft: boolean) => (
        <div className="flex  w-full">
          <p className="m-0 bold">
            {isDraft
              ? POST_DISPLAY_NAME[EUserPostStatus.FRIEND]
              : POST_DISPLAY_NAME[EUserPostStatus.PUBLIC]}
          </p>
        </div>
      ),
    },

    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">
              {ETableTitleAboutUsManagementCoreValue.ACTION}
            </p>
          </div>
        )
      },
      key: 'action',
      dataIndex: 'action',
      width: '10%',
      render: (record: IChallengeItem) => (
        <div className="flex  flex-col  justify-center items-center w-full">
          <div className="flex gap-4   justify-start w-full">
            <Tooltip title={t('common:edit')}>
              <EditOutlined
                className={`text-lg font-light cursor-pointer !text-[#184f64] hover:opacity-80 ${
                  record?.deletedAt ? '!text-black opacity-20' : ''
                }`}
                onClick={() => {
                  if (record?.deletedAt) return
                  onOpen(record.id.toString())
                }}
              />
            </Tooltip>

            <Tooltip title={t('common:view_detail')}>
              <EyeOutlined
                className="text-lg font-light mr-2.5 cursor-pointer text-[#184f64]"
                onClick={() => {
                  // setShowModal(true)
                  navigate(
                    `${PATH_CHALLENGE_DETAIL_V2.replace(
                      ':clId',
                      record.id.toString() || ''
                    )}`
                  )
                }}
              />
            </Tooltip>
          </div>
          <div className="flex  mt-4 gap-4 justify-start w-full">
            <Tooltip title={'Duplicate challenge'}>
              <CopyOutlined
                className={`text-lg font-light cursor-pointer  hover:opacity-80  ${
                  record?.deletedAt ? '!text-black opacity-20' : ''
                }`}
                disabled={!!record.deletedAt}
                onClick={() => {
                  if (record?.deletedAt) return
                  onDuplicateChallenge(record.id)
                }}
              />
            </Tooltip>
            <Tooltip title={'Delete challenge'}>
              <DeleteOutlined
                disabled={isModalLoading}
                className={`text-lg font-light cursor-pointer hover:opacity-80  ${
                  record?.deletedAt ? '!text-red-100 ' : '!text-red-600 '
                }`}
                onClick={() => {
                  if (record?.deletedAt) return
                  if (!record?.isDraft) {
                    setOpenConfirmDeleteModal(true)
                    setSelectedAboutUsId(record.id)
                  } else {
                    onDelete(record?.id)
                  }
                }}
              />
            </Tooltip>
          </div>
        </div>
      ),
      ellipsis: true,
    },
  ]
  const onDelete = async (id?: number) => {
    //on delete about us
    try {
      if (selectedAboutUsId) {
        setIsModalLoading(true)
        const response = await dispatch(
          deleteChallenge(selectedAboutUsId)
        ).unwrap()
        if (response?.success) {
          message.success(response.message)
          setIsModalLoading(false)
          setOpenConfirmDeleteModal(false)
        }
        return
      }
      if (id) {
        setIsModalLoading(true)
        const response = await dispatch(deleteChallenge(id)).unwrap()
        if (response?.success) {
          message.success(response.message)
          setIsModalLoading(false)
          setOpenConfirmDeleteModal(false)
        }
      }
    } catch (error: any) {
      message.error(error.message)
    } finally {
      setIsModalLoading(false)
      getGoals()
      dispatch(
        getListChallengerAction({
          page: 1,
          limit: 10,
          search:
            debouncedValue !== null && debouncedValue.trim()
              ? debouncedValue.trim()
              : undefined,
        })
      )
    }
  }

  const getGoals = async (params?: IGetGoalParams) => {
    dispatch(
      getListGoalsAction({
        page: 1,
        limit: 100000,
        isChallenge: true,
      })
    )
  }
  useEffect(() => {
    getGoals()
    dispatch(
      getListChallengerAction({
        page: 1,
        limit: 10,
        search:
          debouncedValue !== null && debouncedValue.trim()
            ? debouncedValue.trim()
            : undefined,
      })
    )
  }, [reset])
  return (
    <Card>
      <div className="flex items-center justify-between py-[1.25rem] w-full">
        {!goalLoading && goal && goal.goals && (
          <>
            <div className="max-w-[700px] flex-1 flex gap-8">
              <Input
                prefix={<SearchOutlined className="pl-2" />}
                placeholder="Search challenge"
                value={searchText === null ? '' : searchText}
                onChange={(e) => setSearchText(e.target.value)}
                suffix={isSearching ? <Loading /> : undefined}
              />
              <div className="wrapper-status-filter flex items-center justify-center gap-1">
                <div className="label-status">Goals</div>
                <ShareSelectInput
                  data={[
                    {
                      value: 0,
                      label: 'ALl',
                    },
                    ...goal.goals.map((item) => {
                      return {
                        value: item.id,
                        label: item.goal,
                      }
                    }),
                  ]}
                  // defaultValue={optionsFilterStatus[0].label}
                  value={goals}
                  style={{ width: '160px' }}
                  onChange={handleChaneStatus}
                />
              </div>
            </div>
            <div>
              <Button
                type="primary"
                onClick={() => {
                  onOpen()
                }}
              >
                Add new
              </Button>
            </div>
          </>
        )}
      </div>
      {getListCoreValuesLoading || isSearching ? (
        <Skeleton
          paragraph={{ rows: 4 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <SharedTable
          columns={columns as any}
          dataSource={listCoreValues
            // .filter((item) =>
            //   goals === 'All' || +goals == 0 ? true : item.goal.id == +goals
            // )
            ?.map((item, index) => {
              return {
                ...item,
                key: item?.id,
                no: index + 1,
                media: item?.media ?? `--`,
                record: item,
                goal: item?.goal ?? `--`,
                isDraft: item?.isDraft,
                action: item,
                missionsNumber: item?.missionsNumber ?? 0,
              }
            })}
          sticky
          paginationProps={{
            total: !!total ? +total : undefined,
            pageSize: +limit || 10,
            current: +page,
            showSizeChanger: false,
            onChange(page) {
              getListChallenger({
                page: +page,
              })
            },
          }}
        />
      )}

      {openConfirmDeleteModal && (
        <ConfirmDeleteModal
          title="Do you  want to delete this challenge"
          content="This challenge was published , this deletion will affect to others participating in this!"
          open={openConfirmDeleteModal}
          isLoading={!!isModalLoading}
          onDelete={onDelete}
          onClose={onCloseConfirmModal}
          highlight
        />
      )}
    </Card>
  )
}

export default ChallengeHabitPage
