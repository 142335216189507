import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import {
  enumTableTitleInContentManagementAboutUs,
  PATH_SUB_CATEGORY,
  PLACEHOLDER_IMAGE_AVATAR,
} from '@configs'
import { Card, Skeleton, Tooltip, message } from 'antd'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { ICategory, IPhotoMedia } from '@interfaces'
import {
  RootState,
  deleteCategoryAction,
  getAllCategoriesAction,
  useAppDispatch,
} from '@redux'
import { useTranslation } from 'react-i18next'
import { Button, SharedTable } from 'src/common'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import { selectCategoryLoading } from 'src/redux/Slices/category-management'
import EditCategoryModal from './EditCategoryTitle'
import { useNavigate } from 'react-router-dom'

export const Category = () => {
  const { categories } = useSelector((state: RootState) => state.categories)
  const [openEditModal, setOpenEditModal] = useState<{
    status: boolean
    item?: ICategory
  }>({
    status: false,
    item: undefined,
  })
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState<{
    status: boolean
    id?: number
  }>({
    status: false,
    id: undefined,
  })

  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const { t } = useTranslation(['error', 'common'])

  const dispatch = useAppDispatch()
  const getCategoriesLoading = useSelector((state: RootState) =>
    selectCategoryLoading(state, 'getListCategoriesActionLoading')
  )
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getAllCategoriesAction())
  }, [dispatch])
  const resetList = async () => {
    dispatch(getAllCategoriesAction())
  }
  const columns = [
    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleInContentManagementAboutUs.NO}</p>
          </div>
        )
      },
      dataIndex: 'no',
      align: 'center',
      key: 'no',
      width: '5%',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,

      ellipsis: true,
    },
    {
      title: 'Image',
      align: 'center',
      dataIndex: 'media',
      key: 'media',
      render: (record: IPhotoMedia) => (
        <div className="flex items-center justify-center w-full">
          <img
            src={record?.url ?? PLACEHOLDER_IMAGE_AVATAR}
            alt={record?.url}
            className="object-cover w-14 h-14"
          />
        </div>
      ),
    },
    {
      title: 'Category name',
      dataIndex: 'title',
      key: 'title',
      width: '55%',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      render: (title: string, record: ICategory) => {
        return (
          <div
            className="h-auto items-center flex"
            onClick={() => {
              navigate(PATH_SUB_CATEGORY + '?q=' + record.id)
            }}
          >
            <p className="my-auto">{title}</p>
          </div>
        )
      },
    },

    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">
              {enumTableTitleInContentManagementAboutUs.ACTION}
            </p>
          </div>
        )
      },
      key: 'action',
      dataIndex: 'action',
      width: '15%',
      render: (record: ICategory) => (
        <div className="flex space-x-4 items-center justify-center w-full">
          <Tooltip title={t('common:edit')}>
            <EditOutlined
              className="text-lg font-light mr-2.5 cursor-pointer text-[#184f64]"
              onClick={() => {
                setOpenEditModal({
                  status: true,
                  item: record,
                })
              }}
            />
          </Tooltip>
          <DeleteOutlined
            className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 !text-red-600`}
            onClick={() => {
              setOpenConfirmDeleteModal({
                status: true,
                id: record.id,
              })
            }}
          />
        </div>
      ),
      ellipsis: true,
    },
  ]
  const deleteCategory = async () => {
    if (!openConfirmDeleteModal.id || isDeleting) {
      return
    }
    setIsDeleting(true)
    try {
      const response = await dispatch(
        deleteCategoryAction(openConfirmDeleteModal.id)
      ).unwrap()
      message.success({
        content: response.message,
      })
      setOpenConfirmDeleteModal({
        status: false,
        id: undefined,
      })
      resetList()
    } catch (error: any) {
      message.error({
        content: error.message,
      })
    } finally {
      setIsDeleting(false)
    }
  }
  return (
    <Card>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        />
        <Button
          htmlType="submit"
          type="primary"
          size="middle"
          className="submit__btn loginselected-btn"
          onClick={() => {
            setOpenEditModal({
              status: true,
              item: undefined,
            })
          }}
          style={{
            alignSelf: 'flex-end',
          }}
        >
          {'New category'}
        </Button>
      </div>
      {getCategoriesLoading ? (
        <Skeleton
          paragraph={{ rows: 4 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <SharedTable
          columns={columns as any}
          dataSource={categories?.map((item, index) => {
            return {
              ...item,
              key: item?.id,
              no: index + 1,
              title: item?.name ?? `--`,
              record: item,
              action: item,
            }
          })}
          sticky
        />
      )}
      {openConfirmDeleteModal && (
        <ConfirmDeleteModal
          title="Delete Category"
          content="Are you sure to delete this category?"
          open={openConfirmDeleteModal.status}
          isLoading={false}
          onDelete={deleteCategory}
          onClose={() => {
            setOpenConfirmDeleteModal({
              status: false,
              id: undefined,
            })
          }}
        />
      )}
      {openEditModal && (
        <EditCategoryModal
          title="Edit category "
          open={openEditModal.status}
          isLoading={false}
          item={openEditModal.item}
          onSuccess={() => {
            resetList()
            setOpenEditModal({
              status: false,
              item: undefined,
            })
          }}
          onClose={() => {
            setOpenEditModal({
              status: false,
              item: undefined,
            })
          }}
        />
      )}
    </Card>
  )
}
