import { EAboutUsPageTabs } from '@configs'
import { Tabs } from 'antd'
import { useState } from 'react'
import { Text } from 'src/common'

import styled from 'styled-components'
import AcademySeo from './components/AcademySeo'
import LandingTab from './components/LandingTab'

interface IAcademyManagementProps {}

const AcademyManagement = ({}: IAcademyManagementProps) => {
  const [activeTab, setActiveTab] = useState<string>(EAboutUsPageTabs.LANDING)

  const tabItems = [
    {
      label: <Text>{EAboutUsPageTabs.LANDING}</Text>,
      key: EAboutUsPageTabs.LANDING,
      children: <LandingTab activeTab={activeTab} />,
    },
    {
      label: <Text>{EAboutUsPageTabs.ABOUT_US_PAGE_SEO}</Text>,
      key: EAboutUsPageTabs.ABOUT_US_PAGE_SEO,
      children: <AcademySeo activeTab={activeTab} />,
    },
  ]

  return (
    <AboutUsPageStyled>
      <Tabs
        destroyInactiveTabPane={true}
        defaultActiveKey={EAboutUsPageTabs.LANDING}
        type="card"
        size={'small'}
        items={tabItems}
        defaultChecked={true}
        activeKey={activeTab}
        onChange={(e) => {
          setActiveTab(e)
        }}
      />
    </AboutUsPageStyled>
  )
}

export default AcademyManagement

const AboutUsPageStyled = styled('div')(() => {
  return {
    '.ant-tabs-nav': {
      marginBottom: 0,
      paddingLeft: '1.25rem',

      '& .ant-tabs-tab': {
        borderRadius: '0.75rem 0.75rem 0 0 !important',

        '&.ant-tabs-tab-active': {
          span: {
            color: '#009BB7',
            fontWeight: '500',
          },
        },
      },
    },
  }
})
