import { ApiClient } from '@api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { EAssignStatus, EGradingStatus } from 'src/pages/test-management/type'
import {
  IAssignTeacherRequestData,
  IGradeTestDetailData,
  IGradedTestDataItem,
  IGradingResultData,
  IListStudentTestData,
  ISubmitGradeTest,
  IMiniDashboard,
  IRejectionData,
  IRejectItem,
} from 'src/pages/test-management/type'
import {
  API_ACCEPT_ASSIGNMENT,
  API_ASSIGN_TEACHER,
  API_GET_MINI_DASHBOARD,
  API_LIST_REJECTION,
  API_LIST_TEACHER_MANAGEMENT,
  API_LIST_TEST_MANAGEMENT,
  API_REJECT_ASSIGNMENT,
  API_SUBMIT_GRADE_TEST,
} from 'src/routes/api'

interface ITest {
  listTest: {
    data: IListStudentTestData
    isLoading: boolean
  }
  listTeacher: {
    data: any
    isLoading: boolean
  }
  gradedTestData: IGradedTestDataItem[]
  gradeTestDetail: {
    data: IGradeTestDetailData
    isLoading: boolean
    error: any
  }
  gradingResult: {
    data: IGradingResultData
    isLoading: boolean
    error: any
  }
  miniDashboard: IMiniDashboard
  listRejection: IRejectionData
  currentQuestion: number
  prevQuestion: number
}

interface IGetTestParams {
  page: number
  limit: number
  search?: string | null
  assignTo?: string
  assignStatus?: EAssignStatus
  gradingStatus?: EGradingStatus
  deadlineFrom?: string | null
  deadlineTo?: string | null
}

export const getListTest = createAsyncThunk(
  'testManagement/getListTest',
  async (params: IGetTestParams) => {
    const response = await ApiClient.get(`${API_LIST_TEST_MANAGEMENT}`, {
      params,
    })

    return response?.data || []
  }
)

export const getListTeacher = createAsyncThunk(
  'testManagement/getListTeacher',
  async () => {
    const response = await ApiClient.get(`${API_LIST_TEACHER_MANAGEMENT}`, {
      params: {
        types: 'teacher_llc',
        status: 'active',
        limit: 10000,
      },
    })

    return response?.data
  }
)

export const getGradeTestDetail = createAsyncThunk(
  'testManagement/getGradeTestDetail',
  async (
    {
      finalTestId,
      studentId,
    }: {
      finalTestId: string | number
      studentId: string | number
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await ApiClient.get(
        `${API_LIST_TEST_MANAGEMENT}/${finalTestId}/practice-result/${studentId}`
      )

      return response?.data || {}
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getGradingResult = createAsyncThunk(
  'testManagement/getGradingResult',
  async (
    {
      finalTestId,
      studentId,
    }: {
      finalTestId: string | number
      studentId: string | number
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await ApiClient.get(
        `${API_LIST_TEST_MANAGEMENT}/${finalTestId}/grading-result/${studentId}`
      )

      return response?.data || {}
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const submitGradingTest = createAsyncThunk(
  'testManagement/submitGradingTest',
  async (data: ISubmitGradeTest) => {
    const response = await ApiClient.put(API_SUBMIT_GRADE_TEST, data)

    return response?.data || {}
  }
)

export const assignTeacher = createAsyncThunk(
  'testManagement/assignTeacher',
  async (data: IAssignTeacherRequestData) => {
    const response = await ApiClient.put(API_ASSIGN_TEACHER, data)

    return response?.data || {}
  }
)

export const getMiniDashboard = createAsyncThunk(
  'testManagement/getMiniDashboard',
  async (params: { deadlineFrom: string; deadlineTo: string }) => {
    const response = await ApiClient.get<IMiniDashboard>(
      `${API_GET_MINI_DASHBOARD}`,
      {
        params: {
          deadlineFrom: params.deadlineFrom,
          deadlineTo: params.deadlineTo,
        },
      }
    )

    return response?.data
  }
)

export const acceptTestAssignment = createAsyncThunk(
  'testManagement/acceptTestAssignment',
  async (finalTestHistoryId: number) => {
    const response = await ApiClient.put(
      `${API_ACCEPT_ASSIGNMENT}/${finalTestHistoryId}`
    )

    return response?.data || {}
  }
)

export const rejectTestAssignment = createAsyncThunk(
  'testManagement/rejectTestAssignment',
  async (data: { finalTestHistoryId: number; reason: string }) => {
    const response = await ApiClient.post(API_REJECT_ASSIGNMENT, data)

    return response?.data || {}
  }
)

export const getListRejection = createAsyncThunk(
  'testManagement/getListRejection',
  async (historyId: number) => {
    const response = await ApiClient.get(
      `${API_LIST_REJECTION}/${historyId}/list-rejected`
    )

    return response?.data || {}
  }
)

const initialState: ITest = {
  listTest: {
    data: {} as IListStudentTestData,
    isLoading: false,
  },
  listTeacher: {
    data: {},
    isLoading: false,
  },
  gradeTestDetail: {
    data: {} as IGradeTestDetailData,
    isLoading: false,
    error: {},
  },
  miniDashboard: {
    totalUnAssignedOrPending: 0,
    totalGradedTest: 0,
    deadlineInWeek: 0,
  },
  gradingResult: {
    data: {} as IGradingResultData,
    isLoading: false,
    error: {},
  },
  listRejection: {
    data: [],
    isLoading: false,
  },
  gradedTestData: [],
  currentQuestion: 1,
  prevQuestion: 1,
}

const testManamentSlice = createSlice({
  name: 'testManagement',
  initialState,
  reducers: {
    updateListGradedTestData(state, action) {
      const { id } = action?.payload || {}

      const isExisted = state?.gradedTestData?.some(
        (it) => String(it?.id) === String(id)
      )

      if (isExisted) {
        state.gradedTestData = state.gradedTestData?.map((it) => {
          if (String(it?.id) === String(id))
            return {
              ...it,
              comment: action?.payload?.result?.comment || '',
              feedback: action?.payload?.result?.feedback || '',
              status: action?.payload?.result?.status || '',
            }

          return it
        })
      } else {
        state.gradedTestData = state.gradedTestData.concat([
          {
            id,
            comment: action?.payload?.result?.comment || '',
            feedback: action?.payload?.result?.feedback || '',
            status: action?.payload?.result?.status || '',
          },
        ])
      }
    },
    resetListGradedTestData(state) {
      state.gradedTestData = []
      state.currentQuestion = 1
      state.prevQuestion = 1
    },
    updateCurrentQuestion(state, action) {
      state.prevQuestion = state.currentQuestion
      state.currentQuestion = action.payload?.currentQuestion
    },
    updatePrevQuestion(state, action) {
      state.prevQuestion = action.payload?.prevQuestion
    },
    resetGradeTestError(state) {
      state.gradeTestDetail.error = {}
      state.gradingResult.error = {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListTest.pending, (state) => {
        state.listTest.data = {} as IListStudentTestData
        state.listTest.isLoading = true
      })
      .addCase(getListTest.fulfilled, (state, action) => {
        state.listTest.data = action.payload as IListStudentTestData
        state.listTest.isLoading = false
      })
      .addCase(getListTest.rejected, (state) => {
        state.listTest.isLoading = false
      })
      .addCase(getListTeacher.pending, (state) => {
        state.listTeacher.data = {}
        state.listTeacher.isLoading = true
      })
      .addCase(getListTeacher.fulfilled, (state, action) => {
        state.listTeacher.data = action.payload
        state.listTeacher.isLoading = false
      })
      .addCase(getListTeacher.rejected, (state) => {
        state.listTeacher.isLoading = false
      })
      .addCase(getGradeTestDetail.pending, (state) => {
        state.gradeTestDetail.data = {} as IGradeTestDetailData
        state.gradeTestDetail.isLoading = true
        state.gradeTestDetail.error = {}
      })
      .addCase(getGradeTestDetail.fulfilled, (state, action) => {
        state.gradeTestDetail.data = action.payload as IGradeTestDetailData
        state.gradeTestDetail.isLoading = false
        state.gradeTestDetail.error = {}
      })
      .addCase(getGradeTestDetail.rejected, (state, action) => {
        state.gradeTestDetail.isLoading = false
        state.gradeTestDetail.error = action.payload
      })
      .addCase(getMiniDashboard.pending, (state) => {
        state.miniDashboard = initialState.miniDashboard
      })
      .addCase(getMiniDashboard.fulfilled, (state, action) => {
        state.miniDashboard = action.payload
      })
      .addCase(getMiniDashboard.rejected, (state) => {})
      .addCase(getGradingResult.pending, (state) => {
        state.gradingResult.data = {} as IGradingResultData
        state.gradingResult.isLoading = true
        state.gradingResult.error = {}
      })
      .addCase(getGradingResult.fulfilled, (state, action) => {
        state.gradingResult.data = action.payload as IGradingResultData
        state.gradingResult.isLoading = false
        state.gradingResult.error = {}
      })
      .addCase(getGradingResult.rejected, (state, action) => {
        state.gradingResult.isLoading = false
        state.gradingResult.error = action.payload
      })
      .addCase(getListRejection.pending, (state) => {
        state.listRejection.data = [] as IRejectItem[]
        state.listRejection.isLoading = true
      })
      .addCase(getListRejection.fulfilled, (state, action) => {
        state.listRejection.data = action.payload as IRejectItem[]
        state.listRejection.isLoading = false
      })
      .addCase(getListRejection.rejected, (state) => {
        state.listRejection.isLoading = false
      })
  },
})

export const {
  updateListGradedTestData,
  updateCurrentQuestion,
  updatePrevQuestion,
  resetListGradedTestData,
  resetGradeTestError,
} = testManamentSlice.actions

export default testManamentSlice.reducer
