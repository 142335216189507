import { Avatar, Typography } from 'antd'
import { Trans } from 'react-i18next'
import {
  EAssignStatus,
  EGradingStatus,
  EStudentAnswerResult,
  FinalTestSectionResults,
  IGradedTestDataItem,
  ITestItem,
} from './type'
import { format } from 'date-fns'
import { capitalize } from 'lodash'
import { isWhiteSpaceString } from '@utils'

export enum EMiniDashBoardColor {
  LIGHT_BLUE = '#C7F9EE',
  LIGHT_PINK = '#FCEAE6',
  LIGHT_YELLOW = '#F7F4BF',
  LIGHT_PURPLE = '#F1F2FDFF',
  BLUE = '#6DFOO2',
  PINK = '#F0A58F',
  YELLOW = '#E7E34E',
}

export const AssignStatusColor = {
  [EAssignStatus.ACCEPTED]: '#19ba8c',
  [EAssignStatus.PENDING]: '#f5bb00',
  [EAssignStatus.REJECTED]: '#de3b40',
  [EAssignStatus.NA]: '#000000',
  [EAssignStatus.ALL]: '#000000',
}

export const GradingStatusColor = {
  [EGradingStatus.GRADED]: '#19ba8c',
  [EGradingStatus.IN_PROGRESS]: '#56cfe1',
  [EGradingStatus.NA]: '#000000',
}

export const SEARCH_FORM_FIELDS = {
  SEARCH: 'search',
  DATE_RANGE: 'dateRange',
  ASSIGNED_TO: 'assignTo',
  ASSIGN_STATUS: 'assignStatus',
  GRADING_STATUS: 'gradingStatus',
}

export const SEARCH_FORM_FIELD_DEFAULT_VALUES = {
  [SEARCH_FORM_FIELDS.SEARCH]: '',
  [SEARCH_FORM_FIELDS.DATE_RANGE]: null,
  [SEARCH_FORM_FIELDS.ASSIGNED_TO]: [],
  [SEARCH_FORM_FIELDS.ASSIGN_STATUS]: EAssignStatus.ALL,
  [SEARCH_FORM_FIELDS.GRADING_STATUS]: [],
}

export const ASSIGN_TEACHER_FORM_FIELDS = {
  ASSIGN_TO: 'assignTo',
  DEADLINE: 'deadline',
}

export const ASSIGN_TEACHER_FORM_FIELD_DEFAULT_VALUES = {
  [ASSIGN_TEACHER_FORM_FIELDS.DEADLINE]: null,
  [ASSIGN_TEACHER_FORM_FIELDS.ASSIGN_TO]: 0,
}

export const ASSIGN_STATUS_OPTIONS = Object.entries(EAssignStatus).map((it) => {
  return {
    label:
      it[0] === 'ALL' ? (
        <Trans i18nKey={'testManagement:allAssignStatus' as any} />
      ) : (
        capitalize(it[0].replace('_', ' '))
      ),
    value: it[1],
  }
})

export const GRADING_STATUS_OPTIONS = Object.entries(EGradingStatus).map(
  (it) => {
    return {
      label:
        it[0] === 'ALL' ? (
          <Trans i18nKey={'testManagement:allGradingStatus' as any} />
        ) : (
          it[0]
        ),
      value: it[1],
    }
  }
)

export const STUDENT_ANSWER_TYPES = {
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
  PDF: 'PDF',
  WRITING: 'WRITING',
}

export const STUDENT_ANSWER_FILE_TYPE = {
  VIDEO: ['video/mp4'],
  IMAGE: [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/heic',
    'image/heif',
    'image/mpeg',
  ],
  PDF: ['application/pdf'],
}

export const getStudentAnswerType = (type: string) => {
  if (STUDENT_ANSWER_FILE_TYPE.VIDEO.includes(type))
    return STUDENT_ANSWER_TYPES.VIDEO

  if (STUDENT_ANSWER_FILE_TYPE.PDF.includes(type))
    return STUDENT_ANSWER_TYPES.PDF

  if (String(type).includes('image')) return STUDENT_ANSWER_TYPES.IMAGE

  if (type === STUDENT_ANSWER_TYPES.WRITING) return STUDENT_ANSWER_TYPES.WRITING

  return ''
}

export const STUDENT_ANSWER_STATUS = {
  GRADED: 'graded',
  NOT_YET: 'notYet',
  CURRENT: 'current',
}

export const TABLE_COLUMNS = {
  NO: {
    title: 'testManagement:no',
    dataIndex: 'index',
    key: 'index',
    align: 'center',
  },
  TEST_NAME: {
    title: 'testManagement:testName',
    dataIndex: 'testName',
    key: 'index',
    align: 'center',
    render: (_: any, record: ITestItem) => {
      const { testName } = record || {}
      return (
        <Typography
          style={{
            maxWidth: 280,
            minWidth: 250,
            textWrap: 'wrap',
          }}
        >
          {testName}
        </Typography>
      )
    },
  },
  COURSE_NAME: {
    title: 'testManagement:courseName',
    dataIndex: 'courseName',
    key: 'courseName',
    align: 'center',
    render: (_: any, record: ITestItem) => {
      const { courseName } = record || {}
      return (
        <Typography
          style={{
            maxWidth: 280,
            minWidth: 250,
            textWrap: 'wrap',
          }}
        >
          {courseName}
        </Typography>
      )
    },
  },
  STUDENT_NAME: {
    title: 'testManagement:studentName',
    dataIndex: 'studentName',
    key: 'studentName',
    align: 'center',
    render: (_: any, record: ITestItem) => {
      const { avatar, studentName } = record || {}
      return (
        <div className="flex items-center justify-start w-full gap-2 min-w-[250px]">
          <Avatar
            size={{ xs: 24 }}
            style={{ minWidth: 24, minHeight: 24 }}
            src={avatar}
          />

          <Typography
            style={{
              maxWidth: 280,
              textWrap: 'wrap',
            }}
          >
            {studentName}
          </Typography>
        </div>
      )
    },
  },
  ASSIGNEE: {
    title: 'testManagement:assignee',
    dataIndex: 'assignee',
    key: 'assignee',
    align: 'center',
  },
  ASSIGN_STATUS: {
    title: 'testManagement:assignStatus',
    dataIndex: 'assignStatus',
    key: 'assignStatus',
    align: 'center',
    render: (_: any, record: ITestItem) => {
      const { assignStatus } = record || {}
      return (
        <div className="flex items-center justify-center h-full w-full gap-2">
          <Typography
            style={{
              color: AssignStatusColor?.[assignStatus || EAssignStatus.NA],
            }}
          >
            {assignStatus || EAssignStatus.NA}
          </Typography>
        </div>
      )
    },
  },
  STUDENT_SUBMISSON_DATE: {
    title: 'testManagement:studentSubmissionDate',
    dataIndex: 'studentSubmissionDate',
    key: 'studentSubmissionDate',
    align: 'center',
    render: (_: any, record: ITestItem) => {
      const { studentSubmissionDate } = record || {}
      return (
        <div className="flex items-center justify-center h-full w-full gap-2">
          <Typography>
            {studentSubmissionDate
              ? format(new Date(studentSubmissionDate), 'dd/MM/yyyy')
              : null}
          </Typography>
        </div>
      )
    },
  },
  GRADING_STATUS: {
    title: 'testManagement:gradingStatus',
    dataIndex: 'gradingStatus',
    key: 'gradingStatus',
    align: 'center',
    render: (_: any, record: ITestItem) => {
      const { gradingStatus } = record || {}
      return (
        <div className="flex items-center justify-center h-full w-full gap-2">
          <Typography
            style={{
              color: GradingStatusColor?.[gradingStatus || EGradingStatus.NA],
            }}
          >
            {capitalize(gradingStatus.replace('_', ' ')) || EGradingStatus.NA}
          </Typography>
        </div>
      )
    },
  },
  GRADED_DATE: {
    title: 'testManagement:gradedDate',
    dataIndex: 'gradedDate',
    key: 'gradedDate',
    align: 'center',
    render: (_: any, record: ITestItem) => {
      const { gradedDate } = record || {}
      return (
        <div className="flex items-center justify-center h-full w-full gap-2">
          <Typography>
            {gradedDate ? format(new Date(gradedDate), 'dd/MM/yyyy') : null}
          </Typography>
        </div>
      )
    },
  },
  GRADING_DEADLINE: {
    title: 'testManagement:gradingDeadline',
    dataIndex: 'gradingDeadline',
    key: 'gradingDeadline',
    align: 'center',
    render: (_: any, record: ITestItem) => {
      const { gradingDeadline } = record || {}
      return (
        <div className="flex items-center justify-center h-full w-full gap-2">
          <Typography>
            {gradingDeadline
              ? format(new Date(gradingDeadline), 'dd/MM/yyyy')
              : null}
          </Typography>
        </div>
      )
    },
  },
  GRADE_ACTION: {
    title: '',
    dataIndex: 'gradeAction',
    key: 'gradeAction',
    align: 'center',
  },
  VIEW_GRADED_ACTION: {
    title: '',
    dataIndex: 'viewGradedAction',
    key: 'viewGradedAction',
    align: 'center',
  },
  VIEW_TEST_ACTION: {
    title: '',
    dataIndex: 'viewTestAction',
    key: 'viewTestAction',
    align: 'center',
  },
}

export const STUDENT_ANSWER_RESULT_OPTION: {
  label: React.ReactElement
  value: string
  disabled?: boolean
}[] = [
  {
    label: <Trans i18nKey={'testManagement:pass' as any} />,
    value: EStudentAnswerResult.PASS,
  },
  {
    label: <Trans i18nKey={'testManagement:fail' as any} />,
    value: EStudentAnswerResult.FAIL,
  },
  {
    label: <Trans i18nKey={'testManagement:notYet' as any} />,
    value: EStudentAnswerResult.NOT_YET,
  },
]

export const checkNotPassedValidationYet = (it: IGradedTestDataItem) => {
  if (!it) return true

  if (
    !it?.comment ||
    isWhiteSpaceString(it?.comment) ||
    ![EStudentAnswerResult.PASS, EStudentAnswerResult.FAIL].includes(
      it?.status
    ) ||
    String(it?.comment)?.length > 500
  )
    return true

  if (Array.isArray(it?.feedback) && !!it?.feedback?.length) {
    const hasNotPassedFeedbackValidationYet = it?.feedback?.some((it) => {
      return (
        !it?.photoImage ||
        !it?.photoCaption ||
        isWhiteSpaceString(it?.photoCaption) ||
        String(it?.photoCaption)?.length > 500
      )
    })

    return hasNotPassedFeedbackValidationYet
  }

  return false
}

export const validateAllGradedTestData = (
  data: IGradedTestDataItem[],
  initialData: FinalTestSectionResults[]
) => {
  if (data?.length < initialData?.length) return false

  const hasNotPassedValidationYet = data?.some((it) => {
    return checkNotPassedValidationYet(it)
  })

  return !hasNotPassedValidationYet
}
