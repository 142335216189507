import { SearchOutlined } from '@ant-design/icons'
import { Input, Loading } from 'src/common'
import ListConversation from './ListConversation'
import {
  addNewMessageToChat,
  removeSendingMessageId,
  resetListChat,
  updateLastestMessage,
  updateUnreadNumber,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { useEffect, useState } from 'react'
import { getListChat } from 'src/redux/actions/course'
import { useParams } from 'react-router-dom'
import { useChatContext } from '../ChatContext'
import { useDebounce } from 'src/hooks/useDebounce'
import { DEFAULT_PAGE, ESocketMessage } from '@configs'

export default function ChatLeftSideBar() {
  const { socket, selectedConversation } = useChatContext()
  const { id: courseId } = useParams()
  const dispatch = useAppDispatch()
  const {
    data: listChatData,
    isLoading,
    total: totalRecord,
  } = useAppSelector((state) => state.course.listChat)
  const [searchValue, setSearchValue] = useState('')
  const searchDebounceValue = useDebounce(searchValue.trim(), 500)
  const [page, setPage] = useState(DEFAULT_PAGE)

  useEffect(() => {
    dispatch(
      getListChat({
        courseId: String(courseId),
        params: {
          limit: 10,
          page,
          search: searchDebounceValue,
        },
      })
    )
  }, [courseId, dispatch, searchDebounceValue, page])

  useEffect(() => {
    if (!socket) return

    socket?.on(ESocketMessage.RECEIVE_MESSAGE, (data) => {
      dispatch(
        updateLastestMessage({
          lastestMessage: data?.content,
          createdAt: data?.createdAt,
          user: data?.user,
          senderId: data?.enrollCourseId,
          type: data?.type,
          userId: data?.userId,
          isRead: data?.enrollCourseId === selectedConversation?.enrollCourseId,
          enrollCourseId: data?.enrollCourseId,
        })
      )

      if (
        !selectedConversation?.userId ||
        selectedConversation?.enrollCourseId !== data?.enrollCourseId
      )
        return

      dispatch(addNewMessageToChat({ message: { ...data, isReceived: true } }))
      dispatch(removeSendingMessageId({ sendingMessageId: data?.tempId }))
    })

    return () => {
      socket?.off(ESocketMessage.RECEIVE_MESSAGE)
    }
  }, [
    socket,
    dispatch,
    selectedConversation?.userId,
    selectedConversation?.enrollCourseId,
  ])

  useEffect(() => {
    if (!socket) return

    socket.on(ESocketMessage.RECEIVER_UNREAD_NUMBER, (data) => {
      dispatch(
        updateUnreadNumber({
          isRead: true,
          senderId: data?.enrollCourseId,
        })
      )
    })

    return () => {
      socket?.off(ESocketMessage.RECEIVER_UNREAD_NUMBER)
    }
  }, [dispatch, socket])

  useEffect(() => {
    dispatch(resetListChat())
    setPage(DEFAULT_PAGE)
  }, [dispatch, searchDebounceValue])

  useEffect(() => {
    return () => {
      dispatch(resetListChat())
    }
  }, [dispatch, socket])

  return (
    <div className="p-5 h-full min-w-[400px] max-w-[400px]">
      <div className="mb-5">
        <Input
          prefix={<SearchOutlined className="pl-2" />}
          placeholder="Enter a name to start chatting"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(String(e?.target?.value) || '')
          }}
          suffix={isLoading && searchValue ? <Loading /> : undefined}
        />
      </div>

      <ListConversation
        listConversation={listChatData}
        setPage={setPage}
        isLoadingMore={isLoading}
        hasMoreData={listChatData?.length < totalRecord}
      />
    </div>
  )
}
