import {
  getTeacherRequestDetail,
  updateTeacherRequestStatus,
  useAppDispatch,
} from '@redux'
import { Typography } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button } from 'src/common'
import { AppModal } from 'src/components/AppModal'
import { XCloseIcon } from 'src/components/Icon'
import { ERequestStatus } from 'src/interfaces/teacher-request-management'

interface IAddNewRoleProps {
  open: boolean
  onClose: () => void
}

export default function AcceptTeacherRequestDialog({
  open,
  onClose,
}: IAddNewRoleProps) {
  const dispatch = useAppDispatch()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { teacherId } = useParams()

  const handleAcceptTeacherRequest = async () => {
    try {
      setIsSubmitting(true)
      await dispatch(
        updateTeacherRequestStatus({
          teacherRequestId: String(teacherId),
          payload: {
            response: ERequestStatus.ACCEPTED,
          },
        })
      ).unwrap()

      setIsSubmitting(false)
      dispatch(getTeacherRequestDetail(String(teacherId)))
      toast.success('Accept teacher creation request successfully!')
      onClose()
    } catch (error: any) {
      setIsSubmitting(false)
      toast.error(
        error?.message ||
          error?.data?.message ||
          'Something went wrong. Please try again'
      )
    }
  }

  return (
    <AppModal open={open} onClose={onClose} contentClassName="!px-8 !py-6">
      <div className="h-auto">
        <div className="flex items-center justify-center gap-4">
          <div>
            <h1 className="m-0 text-[24px]">
              Want to accept this account creation request?
            </h1>
          </div>
          <div className="hover:opacity-75 cursor-pointer">
            <XCloseIcon width={16} height={16} onClick={onClose} />
          </div>
        </div>
      </div>

      <div className="flex flex-col max-w-[400px] mt-4 mb-8 mx-auto">
        <Typography
          style={{ color: '#424955FF', textAlign: 'center', fontSize: 16 }}
        >
          Are you sure you want to accept this request?
        </Typography>

        <Typography
          style={{
            color: '#424955FF',
            textAlign: 'center',
            fontSize: 16,
          }}
        >
          Once you have accepted the request, an email will be sent directly to
          the user.
        </Typography>
      </div>

      <div className="flex justify-center items-center my-2 mt-4 gap-4">
        <Button type="ghost" size="small" onClick={onClose}>
          Check Again
        </Button>

        <Button
          type="primary"
          size="small"
          loading={isSubmitting}
          onClick={handleAcceptTeacherRequest}
        >
          Accept Request
        </Button>
      </div>
    </AppModal>
  )
}
