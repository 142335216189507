import { INITIAL_PAGINATION_SiZE } from '@configs'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { teacherManagementAPI } from '@api'
import {
  ICreateTeacherAuthorizedRequest,
  ICreateTeacherLLCRequest,
  IGetTeacherParams,
  ITeacherOverviewForm,
} from 'src/interfaces/teacher'
import { ETeacherStatus } from '@pages'

export const getAllTeachersAction = createAsyncThunk(
  'teacher/getAllTeachersAction',
  async (params: IGetTeacherParams | undefined) => {
    try {
      const localParams = params
        ? params
        : {
            page: 1,
            limit: INITIAL_PAGINATION_SiZE,
          }
      const res = await teacherManagementAPI.getListTeacher(localParams)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const createTeacherLLCAction = createAsyncThunk(
  'teacher/createTeacherLLCAction',
  async (params: ICreateTeacherLLCRequest) => {
    try {
      const res = await teacherManagementAPI.createTeacherLLC(params)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const createTeacherAuthorizedAction = createAsyncThunk(
  'teacher/createTeacherAuthorizedAction',
  async (params: ICreateTeacherAuthorizedRequest) => {
    try {
      const res = await teacherManagementAPI.createTeacherAuthorized(params)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const deleteTeacherByIdAction = createAsyncThunk(
  'teacher/deleteTeacherByIdAction',
  async (id: number) => {
    try {
      const res = await teacherManagementAPI.deleteTeacherById(id)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const getTeacherByIdAction = createAsyncThunk(
  'teacher/getTeacherByIdAction',
  async (id: number) => {
    try {
      const res = await teacherManagementAPI.getTeacherById(id)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const updateTeacherByIdAction = createAsyncThunk(
  'teacher/updateTeacherByIdAction',
  async (params: {
    id: number
    data: {
      phone: string
      name: string
      profilePhotoId?: number
      description: string
      status: string
    }
  }) => {
    try {
      const res = await teacherManagementAPI.updateTeacherById(
        params.id,
        params.data
      )
      return res.data
    } catch (error) {
      throw error
    }
  }
)
