import { ApiClient } from 'src/api/axiosClient'
import {
  ICreateRoleSuccessData,
  IGetAdmisSuccessData,
  IGetRolesParams,
  IGetRolesSuccessData,
  IPermissionModule,
  IPostRolePayload,
  IRole,
  IStaffPayload,
  IStaffSuccessData,
  IUserDetail,
  TUpdateUserData,
} from 'src/interfaces'
import {
  IAddNewRolePayload,
  ICreateNewRoleResponse,
  IStaffDeleteParams,
} from 'src/interfaces/role-management'
import { API_LIST_PERMISSION_ROLES, API_ROLES } from 'src/routes/api'
import { objectToQueryString } from 'src/utils'

export const roleManagementAPI = {
  getPermissions: async () => {
    return await ApiClient.get<IPermissionModule[]>('/permissions')
  },
  getRoles: async (params: IGetRolesParams) => {
    return await ApiClient.get<IGetRolesSuccessData>(`/roles`, {
      params: {
        ...params,
        sort: objectToQueryString(params.sort || {}) || undefined,
      },
    })
  },
  getRoleByID: async (id: number) => {
    return await ApiClient.get<IRole>(`/roles/${id}`)
  },
  createRole: async (payload: IPostRolePayload) => {
    return await ApiClient.post<ICreateRoleSuccessData, IPostRolePayload>(
      '/roles',
      payload
    )
  },
  updateRoleByID: async ({
    roleId,
    payload,
  }: {
    roleId: number
    payload: IAddNewRolePayload
  }) => {
    return await ApiClient.put<ICreateNewRoleResponse, IAddNewRolePayload>(
      `${API_ROLES}/${roleId}`,
      payload
    )
  },
  deleteRoleByID: async (id: number) => {
    return await ApiClient.delete<{ message: string }>(`${API_ROLES}/${id}`)
  },
  getAdminList: async (params: IGetRolesParams) => {
    return await ApiClient.get<IGetAdmisSuccessData>('/users/admins', {
      params,
    })
  },

  createAdmin: async (payload: IStaffPayload) => {
    return await ApiClient.post<IStaffSuccessData, IStaffPayload>(
      '/users/admins',
      payload
    )
  },

  deleteAdmin: async (params: IStaffDeleteParams) => {
    return await ApiClient.delete<any>(`/users/admins/${params.id}`)
  },
  getAdminById: async (id: string) => {
    return await ApiClient.get<IUserDetail>(`/users/admins/${id}`)
  },
  updateAdminById: async (payload: Partial<TUpdateUserData>) => {
    const { id, ...passPayload } = payload
    return await ApiClient.put<{ data: IUserDetail; message: string }, {}>(
      `/users/admins/${id}`,
      passPayload
    )
  },
  getListPermissionRoles: async () => {
    const response = await ApiClient.get<any>(API_LIST_PERMISSION_ROLES)

    return response || {}
  },
  createNewRole: async (payload: IAddNewRolePayload) => {
    const response = await ApiClient.post<
      ICreateNewRoleResponse,
      IAddNewRolePayload
    >(API_ROLES, payload)

    return response || {}
  },
  duplicateRole: async (roleId: number) => {
    const response = await ApiClient.post<
      ICreateNewRoleResponse,
      IAddNewRolePayload
    >(`${API_ROLES}/${roleId}`)

    return response || {}
  },
}
