import { Input, DatePicker } from 'antd'
import { Controller, useFormContext } from 'react-hook-form'
import { ISelectOption, ShareSelectInput } from '@components'
import { SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import {
  REQUEST_STATUS_OPTIONS,
  SEARCH_FORM_FIELDS,
} from 'src/pages/teacher-request-management/config'

const { RangePicker } = DatePicker

export default function TeacherRequestTableToolbar() {
  const { t } = useTranslation(['common', 'teacherRequest'])
  const { control, setValue } = useFormContext()

  return (
    <div className="flex flex-wrap item-centers justify-between gap-4">
      <Controller
        name={SEARCH_FORM_FIELDS.SEARCH}
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Input
              prefix={<SearchOutlined className="pr-2 pl-0" />}
              placeholder={t('teacherRequest:searchTextPlaceholder')}
              name={SEARCH_FORM_FIELDS.SEARCH}
              onChange={onChange}
              value={value}
              style={{
                maxWidth: 380,
                width: '100%',
                borderRadius: 6,
                borderColor: '#e2e8f0',
              }}
            />
          )
        }}
      />

      <div className="flex item-centers justify-between gap-4">
        <Controller
          name={SEARCH_FORM_FIELDS.DATE_RANGE}
          control={control}
          render={({ field: { value } }) => {
            return (
              <RangePicker
                value={value}
                format={'DD-MM-YYYY'}
                allowEmpty={[true, true]}
                onChange={(values) => {
                  setValue(SEARCH_FORM_FIELDS.DATE_RANGE, values)
                }}
                style={{
                  borderRadius: 6,
                  borderColor: '#e2e8f0',
                }}
              />
            )
          }}
        />

        <Controller
          name={SEARCH_FORM_FIELDS.REQUEST_STATUS}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <ShareSelectInput
                data={REQUEST_STATUS_OPTIONS as ISelectOption[]}
                onChange={(value: string) => {
                  onChange(value)
                }}
                errors={error?.message}
                value={value}
                style={{ minWidth: 250, marginRight: 16 }}
                placeholder="Request status"
                mode="multiple"
                showArrow
                filterOption={(input: string, option: ISelectOption) => {
                  return String(option?.value ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }}
              />
            )
          }}
        />
      </div>
    </div>
  )
}
