import { Tabs } from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { useState } from 'react'

import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { Button, Text } from 'src/common'
import { useAppSelector } from '@redux'
import { COURSE_DETAIL_TABS } from './config'
import CourseOverview from './overview/CourseOverview'
import { CourseContent } from './content/CourseContent'
import CourseSeoConfig from './seo-config/CourseSeoConfig'
import {
  ECourseRequestModalType,
  ECourseRequestStatus,
} from 'src/interfaces/course-request-management'
import { PATH_COURSE_REQUEST_MANAGEMENT } from '@configs'
import AcceptCourseRequestDialog from './AcceptCourseRequestDialog'
import RejectCourseRequestDialog from './RejectCourseRequestDialog'

export default function CourseRequestDetailPage() {
  const [searchParams] = useSearchParams()
  const [size, setSize] = useState<SizeType>('small')
  const [activeTab, setActiveTab] = useState<string>(
    searchParams.get('tab') || COURSE_DETAIL_TABS.COURSE_OVERVIEW
  )
  const [modalType, setModalType] = useState<ECourseRequestModalType | ''>('')

  const navigate = useNavigate()

  const { data: courseRequestDetailData, isLoading } = useAppSelector(
    (state) => state.courseRequestManagement.courseRequestDetail
  )

  const items = [
    {
      label: <Text>Overview</Text>,
      key: COURSE_DETAIL_TABS.COURSE_OVERVIEW,
      children: <CourseOverview />,
    },

    {
      label: <Text>Content</Text>,
      key: COURSE_DETAIL_TABS.COURSE_CONTENT,
      children: <CourseContent activeTab={activeTab} />,
    },
    {
      label: <Text>Seo config</Text>,
      key: COURSE_DETAIL_TABS.COURSE_SEO_CONFIG,
      children: <CourseSeoConfig activeTab={activeTab} />,
    },
  ]

  return (
    <>
      <HomePageStyled>
        <Tabs
          destroyInactiveTabPane={true}
          defaultActiveKey="1"
          type="card"
          size={size}
          items={items}
          defaultChecked={true}
          activeKey={activeTab}
          onChange={(e) => {
            setActiveTab(e)
          }}
        />

        {!isLoading ? (
          <div className="flex w-full justify-center items-center gap-4 pb-6">
            {[ECourseRequestStatus.PENDING].includes(
              courseRequestDetailData?.status as ECourseRequestStatus
            ) ? (
              <>
                <Button
                  type="ghost"
                  danger
                  size="small"
                  style={{
                    backgroundColor: '#ffffff',
                    color: '#ff0000',
                    borderColor: '#ff0000',
                  }}
                  onClick={() => setModalType(ECourseRequestModalType.REJECTED)}
                >
                  Reject Profile
                </Button>

                <Button
                  type="primary"
                  size="small"
                  style={{
                    backgroundColor: '#26B7B9FF',
                    color: '#ffffff',
                    borderColor: '#26B7B9FF',
                  }}
                  onClick={() => setModalType(ECourseRequestModalType.ACCEPTED)}
                >
                  Accept Profile
                </Button>
              </>
            ) : (
              <Button
                type="primary"
                size="small"
                onClick={() => navigate(PATH_COURSE_REQUEST_MANAGEMENT)}
              >
                Back
              </Button>
            )}
          </div>
        ) : null}
      </HomePageStyled>

      {/* need to render a modal in dom to prevent scroll to top of page when open modal */}
      <AcceptCourseRequestDialog
        open={modalType === ECourseRequestModalType.ACCEPTED}
        onClose={() => setModalType('')}
      />

      {modalType === ECourseRequestModalType.REJECTED ? (
        <RejectCourseRequestDialog open onClose={() => setModalType('')} />
      ) : null}
    </>
  )
}

const HomePageStyled = styled('div')(
  ({ isDisabled }: { isDisabled?: boolean }) => {
    return {
      '.ant-tabs-nav': {
        marginBottom: 0,
        paddingLeft: '1.25rem',

        '& .ant-tabs-tab': {
          borderRadius: '0.75rem 0.75rem 0 0 !important',

          '&.ant-tabs-tab-active': {
            span: {
              color: '#009BB7',
              fontWeight: '500',
            },
          },

          ...(isDisabled && {
            '&.ant-tabs-tab-disabled': {
              '& .ant-tabs-tab-btn': {
                '& .ant-typography': {
                  color: '#b8b8b8',
                },
              },
            },
          }),
        },
      },

      '.ant-tabs-content': {
        paddingBottom: 24,
      },
    }
  }
)
