import { AppModal } from '@components'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Button } from 'src/common'
import styled from 'styled-components'

interface ISubmitConfirmModalProps {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  isSubmitting: boolean
}

export default function SubmitConfirmModal({
  open,
  onClose,
  onSubmit,
  isSubmitting,
}: ISubmitConfirmModalProps) {
  const { t } = useTranslation(['testManagement'])
  return (
    <ConfirmModalContainer>
      <AppModal
        open={open}
        onClose={onClose}
        title={t('testManagement:submitConfirmModalTitle')}
        haveCloseIcon
      >
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 400,
            marginBottom: 16,
          }}
        >
          {t('testManagement:submitConfirmModalContent')}
        </Typography>
        <div className="flex justify-center items-center gap-4">
          <Button type="ghost" onClick={onClose}>
            {t('testManagement:checkAgain')}
          </Button>

          <Button type="primary" onClick={onSubmit} loading={isSubmitting}>
            {t('testManagement:confirmGrading')}
          </Button>
        </div>
      </AppModal>
    </ConfirmModalContainer>
  )
}

const ConfirmModalContainer = styled.div`
  & .content {
    width: 420px;
  }
  & .modal__title {
    font-size: 20px !important;
    margin-bottom: 12px !important;
  }
`
