import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { t } from 'i18next'

import { Button } from 'src/common'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'

import { IFinalTestSection } from 'src/interfaces/final-test'
import { ETestSectionType } from '@configs'
import { useFieldArray, useForm } from 'react-hook-form'
import { IQuestion } from '@interfaces'
import { zodResolver } from '@hookform/resolvers/zod'
import { sectionSchema } from 'src/pages/course-management/form/final-test/schema'
import ModalCreateQuestion from 'src/pages/course-management/form/assignment/FormCreateQuestion'
import Question from 'src/pages/course-management/components/Question'

const defaultValues = {
  type: ETestSectionType.MULTIPLE_CHOICE,
  title: '',
  titleInDutch: '',
  restrictTimer: 0,
  questions: [],
}

function FinalTestSection({
  item,
  index,
  onRemove,
  onEdit,
  onUpdate,
  disabled,
}: {
  item: IFinalTestSection
  index: number
  onRemove: () => void
  onEdit: () => void
  onUpdate: (data: any) => void
  disabled?: boolean
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [openAddQuestion, setOpenAddQuestion] = useState<boolean>(false)
  const [currentQuestionItem, setCurrentQuestionItem] = useState<
    IQuestion & { index: number }
  >()

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm<IFinalTestSection>({
    defaultValues: defaultValues,
    resolver: zodResolver(sectionSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'questions',
  })

  const closeModalQuestion = () => {
    setCurrentQuestionItem(undefined)
    setOpenAddQuestion(false)
  }

  const openModalQuestion = () => {
    setOpenAddQuestion(true)
  }

  const [openConfirmDeleteSectionModal, setOpenConfirmDeleteSectionModal] =
    useState(false)

  const onCloseConfirmModal = () => {
    setOpenConfirmDeleteSectionModal(false)
  }

  const handleOnRemoveQuestion = (index: number) => {
    remove(index)
    onUpdate(getValues())
  }

  const onDelete = () => {
    onRemove()
    onCloseConfirmModal()
  }

  useEffect(() => {
    if (item) {
      reset({
        ...item,
      })
      return
    }
    reset({
      ...defaultValues,
    })
  }, [item])

  return (
    <>
      <ConfirmDeleteModal
        isLoading={isLoading}
        title={t('section:delete_this_section')}
        content={t('section:delete_this_section_confirm')}
        open={openConfirmDeleteSectionModal}
        onClose={onCloseConfirmModal}
        onDelete={onDelete}
      />
      <div className="w-full flex flex-col items-center">
        <SectionContainer>
          <div className="flex items-center gap-8">
            <LabelWrapper>
              {index + 1}. {item.title}
            </LabelWrapper>
            <TypeTag>
              {item.type === ETestSectionType.MULTIPLE_CHOICE
                ? t('section:multiple_choice')
                : t('section:practice')}
            </TypeTag>
          </div>

          <ActionWrapper>
            {disabled ? (
              <EyeOutlined className="text-[18px]" onClick={onEdit} />
            ) : (
              <>
                <EditOutlined className="text-[18px]" onClick={onEdit} />
                <DeleteOutlined
                  className="text-[18px] !text-red-500"
                  onClick={() => setOpenConfirmDeleteSectionModal(true)}
                />
              </>
            )}
          </ActionWrapper>
        </SectionContainer>
        <div className="w-full grid grid-cols-4 gap-3">
          {fields.map((field: any, index) => {
            return (
              <div className="my-5 self-start w-full" key={field.id}>
                <Question
                  item={field}
                  index={index}
                  disabled={disabled}
                  isMultipleChoiceSection={
                    item.type === ETestSectionType.MULTIPLE_CHOICE
                  }
                  onRemove={() => handleOnRemoveQuestion(index)}
                  onEdit={() => {
                    setCurrentQuestionItem({
                      ...field,
                      index,
                    })
                    openModalQuestion()
                  }}
                />
              </div>
            )
          })}
        </div>

        {fields.length < 20 && !disabled && (
          <Button
            htmlType="button"
            type="primary"
            size="middle"
            // disabled={!isActive}
            className="submit__btn login-btn"
            onClick={openModalQuestion}
          >
            {t('section:add_question_btn')}
          </Button>
        )}
      </div>

      <ModalCreateQuestion
        question={currentQuestionItem}
        open={openAddQuestion}
        isMultipleChoiceSection={item.type === ETestSectionType.MULTIPLE_CHOICE}
        onClose={closeModalQuestion}
        disabled={disabled}
        onEdit={({
          isMultipleChoice,
          content,
          contentInDutch,
          answers,
          type,
        }: IQuestion) => {
          if (!currentQuestionItem) return

          update(currentQuestionItem.index, {
            isMultipleChoice,
            content,
            contentInDutch,
            answers,
            type,
          })
          onUpdate(getValues())
        }}
        onAppendNewQuestion={({
          isMultipleChoice,
          content,
          contentInDutch,
          answers,
          type,
        }: IQuestion) => {
          append({
            isMultipleChoice,
            content,
            contentInDutch,
            answers,
            type,
          })
          onUpdate(getValues())
        }}
      />
    </>
  )
}

const SectionContainer = styled.div`
  width: 100%;
  padding: 0 20vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
`
const LabelWrapper = styled.div`
  font-size: 24px;
  font-weight: 700;
  max-width: 350px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const TypeTag = styled.span`
  width: max-content;
  padding: 5px 7px;
  margin-left: 32px;
  background: #d3d2d2;
  color: black;
  font-size: 14px;
  border-radius: 12px;
`

const ActionWrapper = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 12px;
`

export default FinalTestSection
