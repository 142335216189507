/* eslint-disable react-hooks/exhaustive-deps */
import { EStepChart } from '@configs'
import {
  getStatisticChartIncome,
  getStatisticChartUSer,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import {
  formatDate,
  getPreviousDateStepOneYear,
  getPreviousMonthDate,
} from '@utils'
import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js'
import 'chartjs-adapter-moment'
import React, { useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import reactotron from 'reactotron-react-js'

import SliderComponent from './SliderComponent'
import { IChartData } from '@interfaces'
import { get, keyBy, map, sortBy, unionBy } from 'lodash'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
  Legend
)

type Props = {
  step: EStepChart
}

const factoryArray = ({
  statisticChartUSerIncome,
  statisticChartUSer,
}: {
  statisticChartUSerIncome: IChartData[]
  statisticChartUSer: IChartData[]
}): any => {
  const incomeMap = keyBy(statisticChartUSerIncome, 'day')

  // Create the combined array using _.map
  const combinedArray = map(statisticChartUSer, (entry) => ({
    day: entry.day,
    total: get(incomeMap, [entry.day, 'total'], 0), // Get total from incomeMap or default to 0
  }))
  return combinedArray
}
const factoryLabel = ({
  statisticChartUSerIncome,
  statisticChartUSer,
}: {
  statisticChartUSerIncome: IChartData[]
  statisticChartUSer: IChartData[]
}): any => {
  // get different day in 2 array
  const mergedArray = unionBy(
    statisticChartUSerIncome,
    statisticChartUSer,
    'day'
  )

  // Sort merged array by date
  const sortedArray = sortBy(mergedArray, (entry) => new Date(entry.day))

  return sortedArray
}
const date = getPreviousMonthDate()
const dateStepYear = getPreviousDateStepOneYear()
const dateStep2Year = getPreviousDateStepOneYear(2)

const LineChartComponent = (props: Props) => {
  const { step } = props

  const dispatch = useAppDispatch()

  const { statisticChartUSer, statisticChartUSerIncome } = useAppSelector(
    (state) => state.dashboard
  )

  const [min, setMin] = React.useState(0)
  const [max, setMax] = React.useState(statisticChartUSer.length - 1)

  useEffect(() => {
    setMax(statisticChartUSer.length - 1)
  }, [statisticChartUSer])

  useEffect(() => {
    dispatch(
      getStatisticChartUSer({
        params: {
          step: step,
          from:
            step === EStepChart.ONE_MONTh
              ? dateStepYear.first
              : step === EStepChart.THREE_MONTH
              ? dateStep2Year.first
              : date.first,
          to:
            step === EStepChart.ONE_MONTh
              ? dateStepYear.last
              : step === EStepChart.THREE_MONTH
              ? dateStep2Year.last
              : date.last,
        },
      })
    )
    dispatch(
      getStatisticChartIncome({
        params: {
          step: step,
          from: date.first,
          to: date.last,
        },
      })
    )
  }, [step])

  const config: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        align: 'end',
        labels: {
          boxWidth: 16,
          boxHeight: 16,
          padding: 32,
        },
        title: {
          text: `${formatDate(
            statisticChartUSer[min]?.day ?? new Date(),
            'MMM DD, YYYY'
          )} - ${formatDate(
            statisticChartUSer[max]?.day ?? new Date(),
            'MMM DD, YYYY'
          )}`,
          position: 'end',
          display: true,
        },
      },
      tooltip: {
        backgroundColor: '#fff',
        titleColor: '#000',
        titleMarginBottom: 8,
        titleSpacing: 3,
        bodyColor: '#475059',
        borderColor: '#E3E3E3',
        borderWidth: 1,
        padding: 10,
        bodySpacing: 8,
        usePointStyle: true,
        filter: (e) => {
          return !!e.label
        },
        callbacks: {
          labelPointStyle: function (context: any) {
            return {
              pointStyle: 'circle',
              rotation: 0,
            }
          },
          label: function (context: any) {
            var label = '  '
            if (context !== null && context !== undefined && context !== '') {
              label = label + context.dataset.label
              if (context.parsed.y !== null && context.parsed.y !== undefined) {
                label += ': ' + context.parsed.y
              }
            }
            return label
          },
        },
      },
    },
    scales: {
      x: {
        min: min,
        max: max,
        grid: {
          drawOnChartArea: true,
          tickWidth: 1,
          tickLength: 10,
          offset: true,
        },
        display: true,
        ticks: {
          align: 'center',
        },
      },
      y: {
        display: false,
      },
    },
  }

  const data: ChartData<'line'> = {
    labels: factoryLabel({
      statisticChartUSer,
      statisticChartUSerIncome,
    })?.map((i: { day: string }) => {
      return formatDate(
        new Date(i?.day),
        step === EStepChart.ONE_DAY || step === EStepChart.ONE_WEEK
          ? 'MMM DD'
          : 'MMM DD YYYY'
      )
    }),
    datasets: [
      {
        label: 'User',
        data: statisticChartUSer.map((i) => i?.total.toFixed(0)),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'y',
      },
      {
        label: 'Income',
        data: factoryArray({
          statisticChartUSer,
          statisticChartUSerIncome,
        })?.map((i: { total: number }) => i?.total.toFixed(2)),
        borderColor: 'rgba(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        yAxisID: 'y',
      },
    ],
  }

  return (
    <div>
      <div style={{ height: '500px', width: '100%' }}>
        <Line data={data} options={config} />
      </div>
    </div>
  )
}

export default LineChartComponent
