import { ECourseDifficulty, ECourseType, ENationalLanguage } from '@configs'

export const FORM_FIELDS = {
  TITLE: 'title',
  TITLE_IN_DUTCH: 'titleInDutch',
  SLUG: 'slug',
  DESCRIPTION: 'description',
  DESCRIPTION_IN_DUTCH: 'descriptionInDutch',
  CATEGORY_IDS: 'categoriesIds',
  TYPE: 'type',
  SUB_CATEGORY_IDS: 'subCategoriesIds',
  DIFFICULTY: 'difficulty',
  EURO_PRICE: 'eurPrice',
  USD_PRICE: 'usdPrice',
  USER_ID: 'userId',
  NATIONAL_LANGUAGE: 'nationalLanguage',
  HAS_FINAL_TEST: 'hasFinalTest',
  TARGET_USER: 'targetUser',
  THUMBNAIL_ID: 'thumbnailId',
  DEMO_VIDEO_ID: 'demoVideoId',
  SUB_TITLE: 'subTitle',
  IS_ACTIVED: 'isActived',
  IS_DRAFTED: 'isDrafted',
  IS_BEST_SELLER: 'isBestSeller',
  PACKAGE_IDS: 'packageIds',
  USER_CREATION_FROM: 'userCreationFrom',
  USER_CREATION_TO: 'userCreationTo',
}

export const DEFAULT_FORM_FIELD_VALUES = {
  [FORM_FIELDS.TITLE]: '',
  [FORM_FIELDS.TITLE_IN_DUTCH]: '',
  [FORM_FIELDS.SUB_TITLE]: '',
  [FORM_FIELDS.SLUG]: '',
  [FORM_FIELDS.DESCRIPTION]: '',
  [FORM_FIELDS.DESCRIPTION_IN_DUTCH]: '',
  [FORM_FIELDS.CATEGORY_IDS]: [],
  [FORM_FIELDS.TYPE]: ECourseType.FREE,
  [FORM_FIELDS.SUB_CATEGORY_IDS]: [],
  [FORM_FIELDS.DIFFICULTY]: ECourseDifficulty.BEGINNER,
  [FORM_FIELDS.EURO_PRICE]: 0,
  [FORM_FIELDS.USD_PRICE]: 0,
  [FORM_FIELDS.USER_ID]: '',
  [FORM_FIELDS.NATIONAL_LANGUAGE]: ENationalLanguage.ENGLISH,
  [FORM_FIELDS.HAS_FINAL_TEST]: false,
  [FORM_FIELDS.TARGET_USER]: false,
  [FORM_FIELDS.IS_ACTIVED]: false,
  [FORM_FIELDS.THUMBNAIL_ID]: '',
  [FORM_FIELDS.DEMO_VIDEO_ID]: '',
  [FORM_FIELDS.IS_DRAFTED]: false,
  [FORM_FIELDS.IS_BEST_SELLER]: false,
  [FORM_FIELDS.PACKAGE_IDS]: [],
  [FORM_FIELDS.USER_CREATION_FROM]: '',
  [FORM_FIELDS.USER_CREATION_TO]: '',
}
