import { TestProvider } from 'src/pages/test-management/provider'
import ListTest from './list'

export default function TestManagementPage() {
  return (
    <TestProvider>
      <ListTest />
    </TestProvider>
  )
}
