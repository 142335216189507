import { SetStateAction, createContext, useContext, useState } from 'react'
import { Socket } from 'socket.io-client'

interface ISelectedConversation {
  name: string
  profileUrl: string
  userId: string
  enrollCourseId: string
}

interface IChatContextValue {
  socket: Socket | null
  selectedConversation: ISelectedConversation | null
  setSocket: (value: SetStateAction<Socket | null>) => void
  setSelectedConversation: (
    value: SetStateAction<ISelectedConversation | null>
  ) => void
}

const initialState = {
  socket: null,
  selectedConversation: null,
  setSocket: (value: SetStateAction<Socket | null>) => {},
  setSelectedConversation: (
    value: SetStateAction<ISelectedConversation | null>
  ) => {},
}

const ChatContext = createContext<IChatContextValue>(initialState)

export default function ChatProvider({
  children,
}: {
  children: React.ReactElement
}) {
  const [socket, setSocket] = useState<Socket | null>(null)

  const [selectedConversation, setSelectedConversation] =
    useState<ISelectedConversation | null>(null)

  return (
    <ChatContext.Provider
      value={{
        socket,
        selectedConversation,
        setSocket,
        setSelectedConversation,
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}

export const useChatContext = () => {
  const context = useContext(ChatContext)

  if (!context) throw new Error('ChatContext must be use inside ChatProvider')

  return context
}
