import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'

import { ETeacherRole } from '@configs'
import { ICreateTeacher } from 'src/interfaces/teacher'
import {
  CreateTeacherAuthorizedSchema,
  CreateTeacherLLCSchema,
} from 'src/pages/teacher-management/config'
import { INPUT_CLASSNAME } from 'src/pages/teacher-management/styled'
import { Button, Input, TextArea } from 'src/common'
import styled from 'styled-components'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import { EMediaType } from 'src/interfaces/gallery'
import { useEffect, useState } from 'react'
import { useTeacherContext } from 'src/pages/teacher-management/provider/TeacherProvider'

interface IProps {
  teacherType: ETeacherRole
  onSubmit: (data: ICreateTeacher) => void
  onCancel?: () => void
}

const defaultValues = {
  email: '',
  phone: '',
  name: '',
  password: '',
  description: '',
}

const CreateTeacherAuthorizedAndLLCForm = ({
  onSubmit,
  teacherType,
  onCancel,
}: IProps) => {
  const { createTeacherModalContent, setCreateTeacherModalContent } =
    useTeacherContext()
  const [isLoading, setIsLoading] = useState(false)

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<ICreateTeacher>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
    resolver:
      teacherType === ETeacherRole.LLC
        ? zodResolver(CreateTeacherLLCSchema)
        : zodResolver(CreateTeacherAuthorizedSchema),
  })

  useEffect(() => {
    if (!createTeacherModalContent.open) {
      reset({ ...defaultValues })
      setCreateTeacherModalContent((prev) => ({
        ...prev,
        resetDragger: !prev.resetDragger,
        isSubmitFinished: false,
      }))
    }
  }, [createTeacherModalContent?.open])

  return (
    <FormStyled onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="name"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Input
              placeholder="Fullname"
              value={value}
              onChange={onChange}
              label="Fullname"
              alignment="col"
              errors={error?.message}
              className={`${INPUT_CLASSNAME}`}
              required
            />
          )
        }}
      />
      {[ETeacherRole.LLC as string].includes(teacherType) ? (
        <Controller
          name="email"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                placeholder="Email"
                onChange={onChange}
                value={value}
                label="Email"
                alignment="col"
                errors={error?.message}
                className={`${INPUT_CLASSNAME}`}
                required
              />
            )
          }}
        />
      ) : null}

      {[ETeacherRole.LLC as string].includes(teacherType) ? (
        <Controller
          name="password"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                label="Password"
                placeholder="Password"
                type="password"
                value={value}
                onChange={onChange}
                errors={error?.message}
                className={`${INPUT_CLASSNAME}`}
                alignment="col"
                haveShowPassIcon
                required
              />
            )
          }}
        />
      ) : null}

      <Controller
        name="phone"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Input
              placeholder="Phone Number"
              value={value}
              onChange={onChange}
              label="Phone Number"
              alignment="col"
              errors={error?.message}
              className={`${INPUT_CLASSNAME}`}
              required
            />
          )
        }}
      />

      {[ETeacherRole.AUTHORIZED as string].includes(teacherType) ? (
        <Controller
          name="description"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextArea
                label="Bio"
                name="description"
                onChange={onChange}
                value={value}
                errors={error?.message}
                containerClassName=""
              />
            )
          }}
        />
      ) : null}

      <Controller
        name={'profilePhotoId'}
        control={control}
        render={({ field: { onChange }, fieldState: { error } }) => {
          return (
            <CustomDragger
              name="profilePhotoId"
              id="profilePhotoId"
              label="Photo profile"
              containerClassName="w-full !flex flex-col gap-2 flex-start"
              onLoadEnd={(data) => {
                onChange(data?.id)
              }}
              changeLoading={(loading) => setIsLoading(loading)}
              errors={error?.message}
              multiple={false}
              allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
              uploadType={EMediaType.IMAGE as any}
              reset={createTeacherModalContent.resetDragger}
              //   disabled={disabled}
              //   initResource={}
            />
          )
        }}
      />
      <ButtonWrapper className="blog-content-top item-right-wrapper">
        <Button type="default" size="small" id="blog-form" onClick={onCancel}>
          {t('common:cancel')}
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          size="small"
          id="blog-form"
          loading={createTeacherModalContent.isLoading || isLoading}
          //   disabled={disabled}
        >
          {t('common:save')}
        </Button>
      </ButtonWrapper>
    </FormStyled>
  )
}

export default CreateTeacherAuthorizedAndLLCForm

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  min-width: 38dvw;
  width: 100%;

  textarea.ant-input {
    min-height: 8rem;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-top: 32px;
  padding-bottom: 12px;
`
