import {
  DeleteOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { AppModal, SharedCheckbox, ShareSelectInput } from '@components'
import { EAnswerType } from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import { IQuestion } from '@interfaces'
import { Divider, message, Tooltip } from 'antd'
import { t } from 'i18next'
import { Fragment, useEffect } from 'react'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { Button, Input, TextArea } from 'src/common'
import {
  multipleChoiceQuestionSchema,
  practiceQuestionSchema,
} from 'src/pages/course-management/form/final-test/schema'

type Props = {
  open: boolean
  isLoading?: boolean
  onClose: () => void
  question?: IQuestion
  isMultipleChoiceSection?: boolean
  disabled?: boolean
  onAppendNewQuestion: ({
    isMultipleChoice,
    content,
    contentInDutch,
    answers,
    type,
  }: IQuestion) => void
  onEdit: ({
    isMultipleChoice,
    content,
    contentInDutch,
    answers,
    type,
  }: IQuestion) => void
}

const ModalCreateQuestion = ({
  open,
  onClose,
  onAppendNewQuestion,
  isMultipleChoiceSection,
  question,
  disabled,
  onEdit,
}: Props) => {
  const { state } = useLocation()

  const defaultValues = {
    content: question?.content || '',
    contentInDutch: question?.contentInDutch || '',
    answers: question?.answers || [],
    isMultipleChoice: question?.isMultipleChoice || false,
    type: question?.type || '',
  }

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
    reset,
    formState: { isDirty, errors },
  } = useForm<any>({
    defaultValues: defaultValues,
    resolver: isMultipleChoiceSection
      ? zodResolver(multipleChoiceQuestionSchema)
      : zodResolver(practiceQuestionSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control: control,
      name: 'answers',
    }
  )

  const watchAnswers = watch('answers')

  const onSave = async (data: any) => {
    if (fields.length < 2 && isMultipleChoiceSection) {
      message.error(t('error:min_answer_2'))
      return
    }

    if (
      !watchAnswers?.find(
        (answer: {
          content: string
          contentInDutch: string
          isCorrected: boolean
        }) => answer.isCorrected
      ) &&
      isMultipleChoiceSection
    ) {
      message.error(t('error:min_correct_answer'))
      return
    }

    if (question) {
      onEdit({
        isMultipleChoice: data.isMultipleChoice,
        content: data.content,
        contentInDutch: data.contentInDutch,
        answers: data.answers,
        type: data.type,
      })
      onClose()
      return
    }

    onAppendNewQuestion({
      isMultipleChoice: data.isMultipleChoice,
      content: data.content,
      contentInDutch: data.contentInDutch,
      answers: data.answers,
      type: data.type,
    })
    onClose()
  }

  const onChangeMultipleChoice = (isMultipleChoice: boolean) => {
    if (!isMultipleChoice) {
      for (let i = 0; i < fields.length; i++) {
        setValue(`answers.${i}.isCorrected`, false)
      }
      setValue(`answers.0.isCorrected`, true)
    } else {
      if (question && question?.answers && question?.answers?.length > 0) {
        setValue('answers', question?.answers)
      } else {
        setValue(`answers.0.isCorrected`, true)
      }
    }
  }

  useEffect(() => {
    if (open && question) {
      reset({
        ...question,
        isMultipleChoice:
          question.type === EAnswerType.MULTIPLE_CHOICE ||
          question.isMultipleChoice,
      })
      return
    }
    reset({
      ...defaultValues,
    })
  }, [open, question])

  useEffect(() => {
    if (fields?.length <= 0) {
      append({
        content: '',
        contentInDutch: '',
        isCorrected: true,
      })
    }
  }, [fields, fields?.length])

  return (
    <AppModal open={open} onClose={onClose}>
      <div className="flex items-center justify-center flex-col w-[700px] px-10">
        <h1 className="mb-5 mt-5 text-[24px] text-center ">
          {question
            ? t('section:question_detail')
            : t('section:question_create_new')}
        </h1>

        <form>
          <div className="w-full flex flex-col question-wrapper gap-4 mb-4">
            <Controller
              name={'content'}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label={t('section:question')}
                    disabled={disabled}
                    name="content"
                    required
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                  />
                )
              }}
            />
            <Controller
              name={'contentInDutch'}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label={t('section:question_in_nl')}
                    name="contentInDutch"
                    disabled={disabled}
                    required
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                  />
                )
              }}
            />
          </div>
          {isMultipleChoiceSection && (
            <Controller
              name="isMultipleChoice"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <div className="wrapper-multiple-choice w-full mt-3 grid grid-cols-7 gap-3 ">
                    <label className="label-multiple-choice col-span-2">
                      {t('section:multiple_choice')}
                    </label>

                    <div className="wrap-checkbox-group col-span-5">
                      <div className="flex gap-10">
                        <SharedCheckbox
                          type="radio"
                          size="1rem"
                          text="Yes"
                          checked={value}
                          onChange={() => {
                            onChange(true)
                            onChangeMultipleChoice(true)
                          }}
                          disabled={disabled}
                        />
                        <SharedCheckbox
                          type="radio"
                          size="1rem"
                          text="No"
                          checked={!value}
                          onChange={() => {
                            onChange(false)
                            onChangeMultipleChoice(false)
                          }}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                  </div>
                )
              }}
            />
          )}

          <Divider orientation="left" orientationMargin={0}>
            {t('section:answers')}
          </Divider>
          {isMultipleChoiceSection && (
            <div className="wrap-checkbox-group col-span-5">
              {fields?.map((item, index) => {
                return (
                  <Fragment key={item.id}>
                    <div className="answer-item">
                      <div className="relative answer-item answer mb-5 row gap-4 flex item-center">
                        <Controller
                          name={`answers.${index}.isCorrected`}
                          control={control}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => {
                            return (
                              <SharedCheckbox
                                value={value}
                                type={
                                  getValues('isMultipleChoice')
                                    ? 'checkbox'
                                    : 'radio'
                                }
                                checked={value}
                                name={`answers.${index}.isCorrected`}
                                size="1.2rem"
                                disabled={disabled}
                                onChange={(e: any) => {
                                  if (!getValues('isMultipleChoice')) {
                                    for (let i = 0; i < fields.length; i++) {
                                      setValue(
                                        `answers.${i}.isCorrected`,
                                        false
                                      )
                                    }
                                  }
                                  onChange(e?.target?.checked)
                                }}
                              />
                            )
                          }}
                        />
                        <div className="w-full flex flex-col justify-start gap-4">
                          <Controller
                            name={`answers.${index}.content`}
                            control={control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => {
                              return (
                                <Fragment>
                                  <TextArea
                                    containerClassName="w-full items-center"
                                    label={t('section:answers')}
                                    name={`answers.${index}.content`}
                                    required
                                    onChange={onChange}
                                    value={value}
                                    errors={error?.message}
                                    disabled={disabled}
                                    isGrid
                                  />
                                </Fragment>
                              )
                            }}
                          />
                          <Controller
                            name={`answers.${index}.contentInDutch`}
                            control={control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => {
                              return (
                                <Fragment>
                                  <TextArea
                                    containerClassName="w-full items-center"
                                    label={t('section:answers_in_nl')}
                                    name={`answers.${index}.contentInDutch`}
                                    required
                                    onChange={onChange}
                                    value={value}
                                    errors={error?.message}
                                    disabled={disabled}
                                    isGrid
                                  />
                                </Fragment>
                              )
                            }}
                          />

                          {!disabled && (
                            <>
                              <div className="absolute top-1/2 -translate-y-[25%] right-[-60px] w-10 h-10 rounded-full bg-[#e2e8f0] cursor-pointer">
                                {index === 0 && fields.length === 1 ? (
                                  <PlusCircleOutlined
                                    className={`w-full h-full flex justify-center items-center text-lg font-light hover:opacity-80 !text-[#184e62]`}
                                    onClick={() => {
                                      append({
                                        content: '',
                                        contentInDutch: '',
                                        isCorrected: false,
                                      })
                                    }}
                                  />
                                ) : (
                                  <DeleteOutlined
                                    className={`w-full h-full flex justify-center items-center text-lg font-light hover:opacity-80 !text-red-600`}
                                    onClick={() => {
                                      fields.length > 1 && remove(index)
                                    }}
                                  />
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )
              })}
              {disabled ? (
                <Divider />
              ) : fields?.length > 1 && fields?.length < 5 ? (
                <Divider
                  orientation="center"
                  className="text-lg items-center text-center justify-center"
                >
                  <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                      append({
                        content: '',
                        contentInDutch: '',
                        isCorrected: false,
                      })
                    }}
                  >
                    {t('common:add_more_answer')}
                  </Button>
                </Divider>
              ) : (
                <Divider />
              )}
            </div>
          )}

          {!isMultipleChoiceSection && (
            <div className="flex flex-col w-full justify-center gap-4">
              <label>
                {t('section:type')}
                <span className="required text-[#B91C1C] font-bold">*</span>
              </label>
              <Controller
                name="type"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <ShareSelectInput
                      name="type"
                      defaultValue={EAnswerType.WRITTING}
                      data={[
                        {
                          value: EAnswerType.UPLOAD,
                          label: t('section:uploading'),
                        },
                        {
                          value: EAnswerType.WRITTING,
                          label: t('section:writting'),
                        },
                      ]}
                      containerClassName="!w-full flex flex-col"
                      onChange={onChange}
                      value={value}
                      errors={error?.message}
                      disabled={disabled}
                      showArrow
                      required
                    />
                  )
                }}
              />
            </div>
          )}
          <div className="mt-20">
            <div className="flex items-center mt-5 mb-5 justify-center gap-3">
              <Button
                type="default"
                size="middle"
                className="submit__btn login-btn"
                loading={false}
                onClick={onClose}
              >
                {t('common:cancel')}
              </Button>
              {!disabled && (
                <Button
                  htmlType="submit"
                  type="primary"
                  size="middle"
                  className="submit__btn login-btn"
                  loading={false}
                  onClick={handleSubmit(onSave)}
                >
                  {t('common:save')}
                </Button>
              )}
            </div>
          </div>
        </form>
      </div>
    </AppModal>
  )
}

export default ModalCreateQuestion
