import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { ShareSelectInput } from '@components'
import {
  EUserPostStatus,
  PATH_POST_CREATE,
  PATH_POST_MANAGEMENT,
  POST_DISPLAY_NAME,
  enumTableTitlePost,
  thunkActionLoading,
} from '@configs'
import { ICategory } from '@interfaces'
import {
  RootState,
  getAllCategoriesAction,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { Card, Skeleton, Tag, Tooltip, message } from 'antd'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Input, Loading, SharedTable, Text } from 'src/common'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import { useDebounce } from 'src/hooks/useDebounce'
import {
  TGetPostParams,
  TPostDetail,
  TTag,
} from 'src/interfaces/post-management'
import {
  deletePostAction,
  getPostsAction,
} from 'src/redux/actions/post-management'

type Props = {}

const PostManagementScreen = (props: Props) => {
  const [isModalLoading, setIsModalLoading] = useState(false)

  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)

  const { t } = useTranslation(['error', 'common'])

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [deletedPost, setDeletedPost] = useState<TPostDetail>()

  const [selectedPost, setSelectedPost] = useState<TPostDetail>()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [status, setStatus] = useState<EUserPostStatus | undefined>(undefined)
  const [tag, setTag] = useState<number | undefined>(undefined)

  const debouncedValue = useDebounce<string | null>(searchText, 500)
  const [isSearching, setIsSearching] = useState(false)

  const [categories, setCategories] = useState<ICategory[]>([])

  const { post } = useAppSelector((state: RootState) => state.post)

  const getPostsLoading = useAppSelector(
    (state: RootState) =>
      state.post.loadings[thunkActionLoading.GET_POSTS_LOADING]
  )

  const getPosts = async (params?: TGetPostParams) => {
    dispatch(
      getPostsAction({
        page: page,
        limit: 10,
        search:
          debouncedValue !== null && debouncedValue.trim()
            ? debouncedValue.trim()
            : undefined,
        status: status,
        tagId: tag,
        ...(params || {}),
      })
    )
  }

  const getPostsBySearchValue = async () => {
    try {
      setIsSearching(true)
      await dispatch(
        getPostsAction({
          page: 1,
          search:
            debouncedValue !== null && debouncedValue.trim()
              ? debouncedValue.trim()
              : undefined,
          status: status,
          tagId: tag,
        })
      ).unwrap()
    } catch (error: any) {
      if (error.message) {
        message.error(error.message)
      }
    } finally {
      setIsSearching(false)
    }
  }

  const onDeletePost = async () => {
    if (!deletedPost || !deletedPost?.id) return
    setIsModalLoading(true)
    try {
      const response = await dispatch(
        deletePostAction({ id: deletedPost?.id as number })
      ).unwrap()
      if (response?.success) message.success(response.message)
      getPosts()
    } catch (error: any) {
      message.error(error.message)
    } finally {
      setDeletedPost(undefined)
      setOpenConfirmDeleteModal(false)
      setIsModalLoading(false)
    }
  }

  const onCloseDeleteModal = () => {
    setOpenConfirmDeleteModal(false)
    setDeletedPost(undefined)
  }

  const statusOptions = [
    {
      label: 'All',
      value: 'All',
    },
    {
      label: POST_DISPLAY_NAME[EUserPostStatus.PUBLIC],
      value: 'true',
    },
    {
      label: POST_DISPLAY_NAME[EUserPostStatus.FRIEND],
      value: 'false',
    },
  ]

  const columns = [
    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitlePost.NO}</p>
          </div>
        )
      },
      dataIndex: 'no',
      align: 'center',
      key: 'no',
      width: '5%',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: false,
    },
    {
      title: enumTableTitlePost.CONTENT_EN,
      dataIndex: 'content',
      key: 'content',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      // width: '35%',
    },
    {
      title: enumTableTitlePost.CONTENT_LN,
      dataIndex: 'contentInDutch',
      key: 'contentInDutch',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      // width: '35%',
    },
    {
      title: enumTableTitlePost.TAG,
      dataIndex: 'tags',
      key: 'tags',
      showSorterTooltip: false,
      ellipsis: true,
      align: 'center',
      width: '20%',
      render: (record: TTag[]) => {
        return (
          <div className="wrapperTagsInTable flex justify-start items-center flex-wrap gap-1 ">
            {record && record?.length > 0 ? (
              record?.map((item, index) => {
                return (
                  <Tooltip title={item?.name} key={item?.id}>
                    <Tag
                      style={{
                        maxWidth: '100px',
                      }}
                    >
                      <Text
                        key={index}
                        className="tagInTable max-w-[25px]"
                        ellipsis
                      >
                        {item?.name}
                      </Text>
                    </Tag>
                  </Tooltip>
                )
              })
            ) : (
              <div className="text-center">--</div>
            )}
          </div>
        )
      },
    },
    {
      title: enumTableTitlePost.CREATED_AT,
      dataIndex: 'createdAt',
      key: 'createdAt',
      showSorterTooltip: false,
      ellipsis: true,
      align: 'center',
      width: '120px',
      render: (record: string) => {
        return (
          <div className="flex justify-center items-center">
            <p className="m-0">{moment(record).format('DD/MM/YYYY h:mm')}</p>
          </div>
        )
      },
    },
    {
      title: enumTableTitlePost.STATUS,
      dataIndex: 'published',
      key: 'published',
      showSorterTooltip: false,
      ellipsis: true,
      align: 'center',
      width: '10%',
      render: (record: boolean) => {
        return <div>{record ? 'Published' : 'Draft'}</div>
      },
    },
    {
      title: enumTableTitlePost.ACTION,
      key: 'action',
      dataIndex: 'action',
      width: '15%',
      align: 'center',
      render: (record: TPostDetail) => (
        <div className="flex space-x-4 items-center justify-center w-full">
          <Tooltip title={t('common:view_detail')}>
            <EyeOutlined
              className="text-lg font-light cursor-pointer !text-[#184f64] hover:opacity-80"
              onClick={() => {
                navigate(`${PATH_POST_MANAGEMENT}/post-detail/${record?.id}`)
              }}
            />
          </Tooltip>

          <Tooltip title={t('common:edit')}>
            <EditOutlined
              className="text-lg font-light mr-2.5 cursor-pointer !text-[#184f64] hover:opacity-80"
              onClick={() => {
                // Edit action
                navigate(`${PATH_POST_CREATE}/${record?.id}`)
              }}
            />
          </Tooltip>

          <Tooltip title={t('common:delete')}>
            <DeleteOutlined
              className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 !text-red-600`}
              onClick={() => {
                setDeletedPost(record)
                setOpenConfirmDeleteModal(true)
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ]

  const allTagsOption = useMemo(() => {
    let result: { label: React.ReactElement; value: number }[] = []

    categories?.forEach((item, index) => {
      const options = item?.subCategories?.map((item, index) => {
        return {
          label: (
            <Text ellipsis className="max-w-[50px]">
              {item?.name}
            </Text>
          ),
          value: item?.id,
        }
      })
      result = [...result, ...options]
    })
    result.unshift({
      label: (
        <Text ellipsis className="max-w-[50px]">
          All
        </Text>
      ),
      value: NaN,
    })
    return result
  }, [categories, categories?.length])

  useEffect(() => {
    getPosts({ page: 1, status: status, tagId: tag })
  }, [])

  useEffect(() => {
    getPostsBySearchValue()
  }, [debouncedValue])

  useEffect(() => {
    const getAllCategories = async () => {
      try {
        const response = await dispatch(getAllCategoriesAction()).unwrap()

        if (response) setCategories(response)
      } catch (error: any) {
        console.log(error?.message)
      }
    }
    getAllCategories()
  }, [])

  return (
    <Card>
      <div className="flex items-center justify-between py-[1.25rem] gap-5 w-full">
        <div className="max-w-[800px] flex-1 flex gap-8">
          <Input
            prefix={<SearchOutlined className="pl-2" />}
            placeholder="Search post"
            value={searchText === null ? '' : searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
            suffix={debouncedValue && isSearching ? <Loading /> : undefined}
          />

          {/* Start filter */}

          <div className="filter-status flex justify-center items-center gap-1 relative">
            <div className="status-label">Status</div>
            <ShareSelectInput
              placeholder="Status"
              onChange={(value) => {
                if (value === 'All') {
                  setStatus(undefined)
                  getPosts({ page: 1, status: undefined, tagId: tag })
                } else {
                  setStatus(value as EUserPostStatus)
                  getPosts({
                    page: 1,
                    published: value,
                    tagId: tag,
                  })
                }
              }}
              data={statusOptions}
              style={{
                width: 105,
              }}
            />
          </div>

          <div className="filter-tags flex justify-center items-center gap-1 relative">
            <div className="tags-label">Tag</div>
            <ShareSelectInput
              placeholder="Tag"
              onChange={(value) => {
                if (isNaN(+value)) {
                  setTag(undefined)
                  getPosts({ page: 1, status: status, tagId: undefined })
                } else {
                  setTag(+value)
                  getPosts({
                    page: 1,
                    status: status,
                    tagId: +value,
                  })
                }
              }}
              data={allTagsOption as any}
              style={{
                width: 300,
              }}
            />
          </div>
        </div>

        {/* ----Filter---- */}
        <div>
          <Button
            type="primary"
            onClick={() => {
              // Create new post action
              navigate(PATH_POST_CREATE)
            }}
          >
            New post
          </Button>
        </div>
      </div>
      {getPostsLoading ? (
        <Skeleton
          paragraph={{ rows: 4 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <SharedTable
          columns={columns as any}
          dataSource={
            post.posts &&
            post?.posts?.map((item, index) => {
              return {
                ...item,
                key: item?.id,
                no: index + 1,
                content: item?.content ? item?.content : `--`,
                contentInDutch: item?.contentInDutch
                  ? item?.contentInDutch
                  : `--`,
                tags: item?.tags ?? [],
                status:
                  POST_DISPLAY_NAME[
                    item?.status ? item?.status : EUserPostStatus.PUBLIC
                  ],
                record: item,
                action: item,
              }
            })
          }
          sticky
          paginationProps={{
            total: post.total ?? 0,
            pageSize: 10,
            showSizeChanger: false,
            current: post.page ?? 1,
            onChange(page) {
              getPosts({ page: page, status: status, tagId: tag })
            },
          }}
        />
      )}

      {openConfirmDeleteModal && (
        <ConfirmDeleteModal
          title="Delete this post"
          content="Are you sure to delete this post?"
          open={openConfirmDeleteModal}
          isLoading={!!isModalLoading}
          onDelete={onDeletePost}
          onClose={onCloseDeleteModal}
        />
      )}
    </Card>
  )
}

export default PostManagementScreen
