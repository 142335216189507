import { ApiClient } from '@api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  ERequestStatus,
  IListTeacherRequestData,
  IMiniDashboardData,
  ITeacherRequestDetailData,
  ITeacherRequestDetailError,
  ITeacherRequestFilterValue,
} from 'src/interfaces/teacher-request-management'
import { API_TEACHER_REQUESTS } from 'src/routes/api'

interface IGetTeacherRequestParams {
  page: number
  limit: number
  search?: string | null
  requestStatus?: ERequestStatus
  startDate?: string | null
  endDate?: string | null
}
interface ITeacherRequest {
  listTeacherRequest: {
    data: IListTeacherRequestData
    isLoading: boolean
  }
  miniDashboard: {
    data: IMiniDashboardData
    isLoading: boolean
  }
  teacherRequestDetail: {
    data: ITeacherRequestDetailData
    isLoading: boolean
    error: ITeacherRequestDetailError
  }
  teacherRequestFilterValue: ITeacherRequestFilterValue
}

export const getListTeacherRequest = createAsyncThunk(
  'teacherRequestManagement/getListTeacherRequest',
  async (params: IGetTeacherRequestParams) => {
    const response = await ApiClient.get(API_TEACHER_REQUESTS, {
      params,
    })

    return response?.data || []
  }
)

export const getMiniDashboardTeacherRequest = createAsyncThunk(
  'teacherRequestManagement/getMiniDashboard',
  async (params: { startDate?: string; endDate?: string }) => {
    const response = await ApiClient.get(
      `${API_TEACHER_REQUESTS}/mini-dashboard`,
      {
        params,
      }
    )

    return response?.data || {}
  }
)

export const getTeacherRequestDetail = createAsyncThunk(
  'teacherRequestManagement/getTeacherRequestDetail',
  async (teacherRequestId: number | string, { rejectWithValue }) => {
    try {
      const response = await ApiClient.get(
        `${API_TEACHER_REQUESTS}/${teacherRequestId}`
      )

      return response?.data || {}
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateTeacherRequestStatus = createAsyncThunk(
  'teacherRequestManagement/updateTeacherRequestStatus',
  async ({
    teacherRequestId,
    payload,
  }: {
    teacherRequestId: number | string
    payload: {
      response: ERequestStatus
      reason?: string
    }
  }) => {
    const response = await ApiClient.put(
      `${API_TEACHER_REQUESTS}/${teacherRequestId}`,
      payload
    )

    return response?.data || {}
  }
)

const initialState: ITeacherRequest = {
  listTeacherRequest: {
    data: {} as IListTeacherRequestData,
    isLoading: false,
  },
  miniDashboard: {
    data: {} as IMiniDashboardData,
    isLoading: false,
  },
  teacherRequestDetail: {
    data: {} as ITeacherRequestDetailData,
    isLoading: false,
    error: {} as ITeacherRequestDetailError,
  },
  teacherRequestFilterValue: {} as ITeacherRequestFilterValue,
}

const teacherRequestManagementSlice = createSlice({
  name: 'teacherRequestManagement',
  initialState,
  reducers: {
    updateFilterValue: (state, action) => {
      state.teacherRequestFilterValue = action.payload || {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListTeacherRequest.pending, (state) => {
        state.listTeacherRequest.data = {} as IListTeacherRequestData
        state.listTeacherRequest.isLoading = true
      })
      .addCase(getListTeacherRequest.fulfilled, (state, action) => {
        state.listTeacherRequest.data =
          action.payload as IListTeacherRequestData
        state.listTeacherRequest.isLoading = false
      })
      .addCase(getListTeacherRequest.rejected, (state) => {
        state.listTeacherRequest.isLoading = false
      })
      .addCase(getMiniDashboardTeacherRequest.pending, (state) => {
        state.miniDashboard.data = {} as IMiniDashboardData
        state.miniDashboard.isLoading = true
      })
      .addCase(getMiniDashboardTeacherRequest.fulfilled, (state, action) => {
        state.miniDashboard.data = action.payload as IMiniDashboardData
        state.miniDashboard.isLoading = false
      })
      .addCase(getMiniDashboardTeacherRequest.rejected, (state) => {
        state.miniDashboard.isLoading = false
      })
      .addCase(getTeacherRequestDetail.pending, (state) => {
        state.teacherRequestDetail.data = {} as ITeacherRequestDetailData
        state.teacherRequestDetail.isLoading = true
        state.teacherRequestDetail.error = {} as ITeacherRequestDetailError
      })
      .addCase(getTeacherRequestDetail.fulfilled, (state, action) => {
        state.teacherRequestDetail.data =
          action.payload as ITeacherRequestDetailData
        state.teacherRequestDetail.isLoading = false
      })
      .addCase(getTeacherRequestDetail.rejected, (state, action) => {
        state.teacherRequestDetail.isLoading = false
        state.teacherRequestDetail.error =
          action?.payload as ITeacherRequestDetailError
      })
  },
})

export const { updateFilterValue } = teacherRequestManagementSlice.actions

export default teacherRequestManagementSlice.reducer
