import { useCallback, useEffect, useMemo, useState } from 'react'
import { Checkbox, Skeleton } from 'antd'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import styled from 'styled-components'

import { Button, SharedTable } from 'src/common'
import { useDebounce } from 'src/hooks/useDebounce'
import {
  DEFAULT_FORM_FIELD_VALUES,
  FORM_FIELDS,
  checkSelectAll,
} from './config'
import {
  getListPermissionRoles,
  updateRole,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import {
  EPermissionAction,
  IChosenRole,
  IPermissionRoleItem,
  IRoleItem,
} from '@interfaces'
import RoleManagementButtonAction from './RoleManagementButtonAction'
import HeaderCellCustomized from './HeaderCellCustomized'
import AddNewRoleModal from './AddNewRoleDialog'
import EditRoleNameModal from './EditRoleNameDialog'
import DeleteRoleDialog from './DeleteRoleDialog'

export default function RoleManagementSection() {
  const { t } = useTranslation(['auth', 'staffAndRole', 'common'])

  const dispatch = useAppDispatch()
  const { data, isLoading } = useAppSelector(
    (state) => state.role.listPermissionRoles
  )
  const { permissionWithRoles: listPermissionRoles, listRoles } = data || {}

  const [chosenRole, setChosenRole] = useState<IChosenRole | null>(null)
  const [filterByRole, setFilterByRole] = useState<string[]>([])
  const [isOpenCreateRoleDialog, setIsOpenCreateRoleDialog] = useState(false)
  const [isOpenEditRoleNameDialog, setIsOpenEditRoleNameDialog] =
    useState(false)
  const [isOpenDeleteRoleDialog, setIsOpenDeleteRoleDialog] = useState(false)

  const methods = useForm<any>({
    defaultValues: DEFAULT_FORM_FIELD_VALUES,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })
  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const permissionNameValue = useDebounce(
    watch(FORM_FIELDS.PERMISSION_NAME),
    100
  )
  const watchPermissionValues = watch([`${chosenRole?.name}`])
  delete watchPermissionValues?.[0]?.selectAll

  const permissionValues = Object.values(watchPermissionValues?.[0] || {})

  const handleToggleAllPermission = useCallback(
    ({ roleName, isSelectedAll }) => {
      const checkedValue = isSelectedAll ? false : true
      listPermissionRoles?.forEach((it: any) =>
        setValue(`${roleName}.${it?.permission}`, checkedValue)
      )
    },
    [listPermissionRoles, setValue]
  )

  const onEditRolePermissions = useCallback(
    (role: IRoleItem) => {
      setChosenRole({
        id: role?.id,
        name: role?.name,
        actionType: EPermissionAction.EDIT_PERMISSION,
      })

      listPermissionRoles?.forEach((it: any) =>
        setValue(`${role?.name}.${it?.permission}`, it?.roles?.[role?.name])
      )
    },
    [listPermissionRoles, setValue]
  )

  const onOpenEditRoleNameDialog = useCallback((role: IRoleItem) => {
    setIsOpenEditRoleNameDialog(true)
    setChosenRole({
      id: role?.id,
      name: role?.name,
      actionType: EPermissionAction.EDIT_NAME,
    })
  }, [])

  const onOpenDeleteRoleDialog = useCallback((role: IRoleItem) => {
    setIsOpenDeleteRoleDialog(true)
    setChosenRole({
      id: role?.id,
      name: role?.name,
      actionType: EPermissionAction.DELETE_ROLE,
    })
  }, [])

  const isSelectAll = useMemo(() => {
    const listSelected = permissionValues?.filter((it) => !!it)

    return checkSelectAll(listSelected, listPermissionRoles)
  }, [listPermissionRoles, permissionValues])

  const columns = useMemo(
    () =>
      [
        {
          width: '10%',
          title: 'Name',
          dataIndex: 'permissionName',
          key: 'permissionName',
          editable: true,
          fixed: true,
          onHeaderCell: (column: any) => {
            return {
              ...column,
              editing: true,
              fixed: true,
              key: 'permissionName',
            }
          },
        },
      ].concat(
        listRoles?.map((role) => ({
          width: '10%',
          title: role?.name,
          dataIndex: role?.name,
          key: role?.id,
          align: 'center',
          onHeaderCell: (column: any) => {
            return {
              ...column,
              title: role?.name,
              canEdit: !role?.isAdmin,
              roleId: role?.id,
              onEditRolePermissions: () => onEditRolePermissions(role),
              onOpenEditRoleNameDialog: () => onOpenEditRoleNameDialog(role),
              onOpenDeleteRoleDialog: () => onOpenDeleteRoleDialog(role),
            }
          },
          render: (_: any, record: any) => {
            return (
              <div>
                {role?.id === chosenRole?.id &&
                [EPermissionAction.EDIT_PERMISSION].includes(
                  chosenRole?.actionType
                ) ? (
                  <Controller
                    name={`${role?.name}.${record?.key}`}
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <DefaultInputCheckbox>
                          <input
                            id={`${role?.name}.${record?.key}`}
                            checked={value}
                            type="checkbox"
                            className="!relative !left-0"
                            onChange={(e) => {
                              if (isSubmitting) return
                              if (record?.key === 'selectAll') {
                                const listSelected = permissionValues?.filter(
                                  (it) => !!it
                                )
                                const isSelectedAll = checkSelectAll(
                                  listSelected,
                                  listPermissionRoles
                                )
                                handleToggleAllPermission({
                                  roleName: role?.name,
                                  isSelectedAll,
                                })
                                onChange(!isSelectedAll)
                                return
                              }
                              onChange(e.target.checked)
                            }}
                          />
                        </DefaultInputCheckbox>
                      )
                    }}
                  />
                ) : (
                  <Checkbox
                    checked={
                      record?.key === 'selectAll'
                        ? false
                        : Boolean(record?.[role?.name])
                    }
                    disabled={
                      role?.id !== chosenRole?.id ||
                      ![EPermissionAction.EDIT_PERMISSION].includes(
                        chosenRole?.actionType as EPermissionAction
                      )
                    }
                  />
                )}
              </div>
            )
          },
        })) as any
      ),
    [
      chosenRole?.actionType,
      chosenRole?.id,
      control,
      handleToggleAllPermission,
      isSubmitting,
      listPermissionRoles,
      listRoles,
      onEditRolePermissions,
      onOpenDeleteRoleDialog,
      onOpenEditRoleNameDialog,
      permissionValues,
    ]
  )

  const listPermissions = useMemo(() => {
    return listPermissionRoles.map((it: IPermissionRoleItem, idx: number) => {
      return {
        key: it?.permission,
        permissionName: t(`auth:${it?.permission}` as any),
        ...it?.roles,
      }
    })
  }, [listPermissionRoles, t])

  const roleData = useMemo(() => {
    if (!Array.isArray(listPermissions) || !listPermissions?.length) return []

    return listPermissions.filter((it: any) =>
      String(it?.permissionName)
        ?.toLowerCase()
        .includes(String(permissionNameValue)?.trim()?.toLowerCase())
    )
  }, [listPermissions, permissionNameValue])

  const columnsData = useMemo(() => {
    if (!Array.isArray(filterByRole) || !filterByRole?.length) return columns

    return columns.filter((it) => {
      if (it?.key === 'permissionName') return true

      return filterByRole?.some((role) => role === it?.title)
    })
  }, [columns, filterByRole])

  const onUpdateRolePermissions = async (data: any) => {
    try {
      if (!chosenRole) return

      delete data?.[chosenRole?.name]?.selectAll

      const payload = {
        name: chosenRole?.name,
        permissions: data?.[chosenRole?.name],
      }

      await dispatch(
        updateRole({
          roleId: Number(chosenRole?.id),
          payload,
        })
      ).unwrap()
      toast.success(t('staffAndRole:update_role_permissions_successfully'))
      dispatch(getListPermissionRoles())
      setChosenRole(null)
    } catch (error: any) {
      toast.error(
        error?.message ||
          error?.data?.message ||
          t('staffAndRole:something_went_wrong')
      )
    }
  }

  const dataSource = useMemo(() => {
    if (
      chosenRole?.actionType === EPermissionAction.EDIT_PERMISSION &&
      !permissionNameValue
    )
      return []
        .concat({
          key: 'selectAll',
          permissionName: 'Select All / Clear All',
          ...listPermissionRoles?.[0]?.roles,
        } as any)
        .concat(roleData as any)

    return roleData
  }, [chosenRole, listPermissionRoles, permissionNameValue, roleData])

  useEffect(() => {
    setValue(`${chosenRole?.name}.selectAll`, isSelectAll)
  }, [chosenRole?.name, isSelectAll, setValue])

  useEffect(() => {
    dispatch(getListPermissionRoles())
  }, [dispatch])

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onUpdateRolePermissions)}>
          <RoleManagementButtonAction
            setFilterByRole={setFilterByRole}
            onOpenCreateRoleDialog={() => setIsOpenCreateRoleDialog(true)}
          />

          {(() => {
            if (isLoading)
              return (
                <>
                  {Array(10)
                    .fill({})
                    ?.map((_, idx) => {
                      return (
                        <SkeletonContainer
                          className="flex p-4 w-full overflow-x-hidden gap-3"
                          key={idx}
                        >
                          {Array(10)
                            .fill({})
                            .map((_, idx) => {
                              return (
                                <Skeleton.Button
                                  key={idx}
                                  active
                                  shape="default"
                                  size="small"
                                  style={{ width: '100%' }}
                                />
                              )
                            })}
                        </SkeletonContainer>
                      )
                    })}
                </>
              )

            return (
              <SharedTableContainer>
                <SharedTable
                  columns={columnsData as any}
                  dataSource={dataSource}
                  tableProps={{
                    components: {
                      header: {
                        cell: HeaderCellCustomized,
                      },
                    },
                  }}
                  className="!max-h-[70vh] !ml-0"
                  hasIndexColumn={false}
                />
              </SharedTableContainer>
            )
          })()}

          {chosenRole?.actionType === EPermissionAction.EDIT_PERMISSION ? (
            <div className="flex justify-center items-center gap-4 p-2 pb-4">
              <Button
                type="ghost"
                size="small"
                onClick={() => setChosenRole(null)}
              >
                {t('common:cancel')}
              </Button>

              <Button
                type="primary"
                size="small"
                htmlType="submit"
                loading={isSubmitting}
              >
                {t('common:save')}
              </Button>
            </div>
          ) : null}
        </form>
      </FormProvider>

      {isOpenCreateRoleDialog ? (
        <AddNewRoleModal
          open
          onClose={() => setIsOpenCreateRoleDialog(false)}
        />
      ) : null}

      {isOpenEditRoleNameDialog ? (
        <EditRoleNameModal
          open
          onClose={() => setIsOpenEditRoleNameDialog(false)}
          chosenRole={chosenRole}
        />
      ) : null}

      {isOpenDeleteRoleDialog ? (
        <DeleteRoleDialog
          open
          onClose={() => setIsOpenDeleteRoleDialog(false)}
          chosenRole={chosenRole}
        />
      ) : null}
    </>
  )
}

const SharedTableContainer = styled('div')(() => ({
  '& > div': {
    padding: '0 12px',
  },
}))

const SkeletonContainer = styled('div')(() => ({
  '& .ant-skeleton': {
    flex: 1,
  },
}))

const DefaultInputCheckbox = styled.div`
  [type='checkbox']:checked,
  [type='checkbox']:not(:checked) {
    width: 16px;
    height: 16px;
    border-radius: 4px;
  }
`
