import {
  CalendarOutlined,
  DeleteTwoTone,
  EditOutlined,
} from '@ant-design/icons'
import { Card, Typography } from 'antd'
import moment from 'moment'
import styled from 'styled-components'
import { TExperienceView } from 'src/pages/teacher-request-detail/fill-in-teacher-information/types'

const monthYearFormat = 'MMM YYYY'

export const ExperienceView = ({
  data,
  index,
  onRemove,
  onEdit,
}: TExperienceView) => {
  const { isPresent: isCurrentJob } = data || {}

  const lifeTime = `${moment(data?.startDate).format(monthYearFormat)} - ${
    isCurrentJob ? 'Present' : moment(data?.endDate).format(monthYearFormat)
  }`

  return (
    <StyledExperienceView>
      <div className="flex flex-row justify-between">
        <Typography.Text className="text-base font-semibold capitalize">
          {data?.jobTitle} -
          <Typography.Text className="text-base font-semibold !text-purple-600">
            {` ${data?.companyName}`}
          </Typography.Text>
        </Typography.Text>
        <div className="ml-5 min-w-14  items-center">
          {!!onEdit && (
            <EditOutlined
              className="self-end mr-3 text-base text-gray-500"
              onClick={() => onEdit?.(index)}
            />
          )}
          {!!onRemove && (
            <DeleteTwoTone
              onClick={() => onRemove?.(index)}
              className="self-end text-base"
            />
          )}
        </div>
      </div>
      <div className="flex flex-row items-center">
        <CalendarOutlined className="text-base text-gray-500" />
        <Typography.Text className="text-base font-semibold ml-3  !text-gray-500">
          {lifeTime}
        </Typography.Text>
      </div>
      <Typography.Text>{data?.description}</Typography.Text>
    </StyledExperienceView>
  )
}

const StyledExperienceView = styled(Card)`
  .ant-card-body {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`
