import { ECourseType, PATH_COURSE_MANAGEMENT } from '@configs'
import { useAppDispatch, useAppSelector } from '@redux'
import { Empty, message, Skeleton } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ELessonType,
  ICourseSections,
  ILesson,
  ITrailLesson,
} from 'src/interfaces/course'
import { t } from 'i18next'
import {
  getCourseSectionDetailAction,
  updateSectionTrialLessonsAction,
} from 'src/redux/actions/course'
import ModalAddSection from './ModalAddSection'

import { Button } from 'src/common'
import { SectionProvider } from 'src/pages/course-request-detail/provider/section/SectionProvider'
import Section from '../components/sections/Section'
import ModalMedia from './media/MediaModal'
import ModalFinalTest from './final-test/ModalFinalTest'

interface ICourseContentProps {
  activeTab?: string
}

export function CourseContent({ activeTab }: ICourseContentProps) {
  const { courseId } = useParams()
  const dispatch = useAppDispatch()

  const [listSections, setListSections] = useState<ICourseSections[]>([])

  const [reset, setReset] = useState(false)
  const [sectionId, setSectionId] = useState<number | undefined>(undefined)
  const [isActive, setIsActive] = useState(true)
  const [section, setSection] = useState<ICourseSections | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [openEditSection, setOpenEditSectionModal] = useState<boolean>(false)
  const [courseType, setCourseType] = useState<ECourseType>(ECourseType.FREE)
  const [trialLessonList, setTrialLessonList] = useState<ITrailLesson[]>([])

  const onCloseEditSection = () => {
    setOpenEditSectionModal(false)
    setSection(undefined)
    // onReset()
  }

  const [openEditLesson, setOpenEditLessonModal] = useState<boolean>(false)
  const [lesson, setLesson] = useState<any>(undefined)
  const onCloseEditLesson = () => {
    setOpenEditLessonModal(false)
    setSection(undefined)
    // onReset()
  }

  const onReset = () => {
    setReset(!reset)
    setLesson(undefined)
  }
  const getSections = useCallback(
    async (id: number) => {
      try {
        setIsLoading(true)
        const response = await dispatch(
          getCourseSectionDetailAction(id)
        ).unwrap()
        setListSections(response.data)
      } catch (error: any) {
      } finally {
        setIsLoading(false)
      }
    },
    [dispatch]
  )

  const listLessonRender = useMemo(() => {
    return listSections
  }, [listSections])

  const handleChangeTrial = async () => {
    if (!courseId) return
    const data = {
      body: trialLessonList,
      sectionId: listSections[0].id,
    }
    try {
      const response = await dispatch(
        updateSectionTrialLessonsAction(data)
      ).unwrap()
      if (response.data) {
        message.success(t('common:set_trail_success'))
      }
    } catch (error: any) {
      message.error(error.message)
    }
  }

  useEffect(() => {
    if (!courseId) return

    getSections(Number(courseId))
  }, [courseId, getSections])

  return (
    <>
      <div
        className={`w-full min-h-[82vh] justify-start flex  border-1 flex-col  rounded-lg bg-white ${
          !listLessonRender.length && 'bg-white'
        }`}
      >
        {isLoading ? (
          <Skeleton paragraph={{ rows: 4 }} className="p-5" />
        ) : (
          <>
            {!Array.isArray(listLessonRender) || !listLessonRender.length ? (
              <div className="flex justify-center m-auto">
                <Empty />
              </div>
            ) : null}

            <div className="">
              {listLessonRender.map((item, index) => (
                <SectionProvider
                  key={item.id}
                  listSectionsLength={listLessonRender.length}
                  sectionIndex={index}
                  sectionId={item.id}
                  onReset={onReset}
                >
                  <Section
                    item={item}
                    index={index}
                    courseType={courseType}
                    onSwitchTrial={setTrialLessonList}
                    onCloseEditSection={onCloseEditSection}
                    onOpenEditSection={(item: ICourseSections) => {
                      setSection(item)
                      setOpenEditSectionModal(true)
                    }}
                    isActive={isActive}
                    onReset={onReset}
                    onEditLesson={(item: ILesson, sectionId: number) => {
                      setSectionId(sectionId)
                      setOpenEditLessonModal(true)
                      setLesson({
                        type: item.type,
                        id: item.id,
                      })
                    }}
                    isViewMode
                  />
                </SectionProvider>
              ))}
            </div>
          </>
        )}
      </div>

      {isActive && courseType !== ECourseType.FREE ? (
        <div className="w-full flex items-center mt-5 mb-5 justify-center gap-3">
          <Button
            type="default"
            size="middle"
            className="submit__btn login-btn"
            loading={false}
            onClick={() => navigate(PATH_COURSE_MANAGEMENT)}
          >
            {t('common:cancel')}
          </Button>

          <Button
            htmlType="submit"
            type="primary"
            size="middle"
            className="submit__btn login-btn"
            loading={false}
            onClick={handleChangeTrial}
          >
            {t('common:save')}
          </Button>
        </div>
      ) : null}

      <ModalAddSection
        open={openEditSection}
        onClose={onCloseEditSection}
        isLoading={true}
        section={section}
        onSave={onReset}
        isActive={isActive}
        isViewMode
      />

      {lesson ? (
        <>
          {lesson?.type === ELessonType.VIDEO && (
            <ModalMedia
              open={openEditLesson}
              onClose={onCloseEditLesson}
              assignmentId={lesson.id}
              selectSection={sectionId}
              onSave={onReset}
              isActive={isActive}
              isViewMode
            />
          )}

          {lesson?.type === ELessonType.FINAL_TEST && (
            <ModalFinalTest
              open={openEditLesson}
              onClose={() => {
                onCloseEditLesson()
                onReset()
              }}
              finalTestId={lesson.id}
              selectSection={sectionId}
              onSave={onReset}
              isActive={isActive}
              isViewMode
            />
          )}
        </>
      ) : null}
    </>
  )
}
