import { Tabs } from 'antd'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { useEffect, useState } from 'react'

import {
  HOME_PAGE_CHAT,
  HOME_PAGE_CONTENT,
  HOME_PAGE_VIDEO,
  HOME_SEO,
  SEO_CONFIG,
} from '@configs'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { Text } from 'src/common'
import CourseCreateScreen from './CourseCreateScreen'
import CourseSeoConfigScreen from './CourseSeoConfigScreen'
import { LessonManagement } from './form/lesson/SectionManagement'
import ChatTab from './chat'
import { useAppDispatch, useAppSelector } from '@redux'
import { checkDeletedCourse } from 'src/redux/actions/course'

interface Props {}

// eslint-disable-next-line no-empty-pattern
export const CourseDetail = ({}: Props) => {
  const { id: courseId } = useParams()
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const [size, setSize] = useState<SizeType>('small')
  const [activeTab, setActiveTab] = useState<string>(
    searchParams.get('tab') || HOME_PAGE_VIDEO
  )

  const dispatch = useAppDispatch()
  const isCourseDeleted = useAppSelector(
    (state) => state.course.isCourseDeleted
  )

  const items = [
    {
      label: <Text>Overview</Text>,
      key: HOME_PAGE_VIDEO,
      children: <CourseCreateScreen />,
      disabled: isCourseDeleted,
    },

    {
      label: <Text>Content</Text>,
      key: HOME_PAGE_CONTENT,
      children: <LessonManagement activeTab={activeTab} />,
      disabled: isCourseDeleted,
    },
    {
      label: <Text>{SEO_CONFIG}</Text>,
      key: HOME_SEO,
      children: <CourseSeoConfigScreen activeTab={activeTab} />,
      disabled: isCourseDeleted,
    },
    {
      label: <Text>Chat</Text>,
      key: HOME_PAGE_CHAT,
      children: <ChatTab />,
      disabled: false,
    },
  ]

  useEffect(() => {
    if (location.state && location.state.isCreateNew) {
      setActiveTab(HOME_PAGE_CONTENT)
    }
  }, [location.state])

  useEffect(() => {
    dispatch(
      checkDeletedCourse({
        courseId: Number(courseId),
        params: { checkDeleted: true },
      })
    )
  }, [courseId, dispatch])

  return (
    <HomePageStyled isDisabled={isCourseDeleted || false}>
      <Tabs
        destroyInactiveTabPane={true}
        defaultActiveKey="1"
        type="card"
        size={size}
        items={items}
        defaultChecked={true}
        activeKey={activeTab}
        onChange={(e) => {
          setActiveTab(e)
        }}
      />
    </HomePageStyled>
  )
}

const HomePageStyled = styled('div')(
  ({ isDisabled }: { isDisabled?: boolean }) => {
    return {
      '.ant-tabs-nav': {
        marginBottom: 0,
        paddingLeft: '1.25rem',

        '& .ant-tabs-tab': {
          borderRadius: '0.75rem 0.75rem 0 0 !important',

          '&.ant-tabs-tab-active': {
            span: {
              color: '#009BB7',
              fontWeight: '500',
            },
          },

          ...(isDisabled && {
            '&.ant-tabs-tab-disabled': {
              '& .ant-tabs-tab-btn': {
                '& .ant-typography': {
                  color: '#b8b8b8',
                },
              },
            },
          }),
        },
      },

      '.ant-tabs-content': {
        paddingBottom: 24,
      },
    }
  }
)
