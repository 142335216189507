import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import moment from 'moment'

import { RootState, useAppDispatch } from '@redux'

import {
  BookOutlined,
  CalendarOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons'
import { Typography } from 'antd'
import {
  ERequestStatus,
  IMiniDashboardData,
} from 'src/interfaces/teacher-request-management'
import { useTranslation } from 'react-i18next'
import { getMiniDashboardTeacherRequest } from 'src/redux/Slices/teacher-request-management'
import {
  EMiniDashBoardColor,
  SEARCH_FORM_FIELD_DEFAULT_VALUES,
  SEARCH_FORM_FIELDS,
} from 'src/pages/teacher-request-management/config'
import Skeleton from 'src/components/skeleton/Skeleton'

const MiniDashboard = () => {
  const { reset, setValue } = useFormContext()

  const { t } = useTranslation(['teacherRequest'])

  const dispatch = useAppDispatch()
  const { data: miniDashboardData, isLoading = false } = useSelector(
    (state: RootState) => state.teacherRequestManagement.miniDashboard
  )
  const {
    totalRequests,
    totalPendingRequests,
    totalNewRequestsThisWeek,
    totalRequestsUpdated,
  } = (miniDashboardData as IMiniDashboardData) || {}

  const thisWeek = useMemo(() => {
    const currentDate = moment()
    const startOfWeek = currentDate.clone().startOf('isoWeek').startOf('day')
    const endOfWeek = currentDate.clone().endOf('isoWeek').endOf('day')

    return { startOfWeek, endOfWeek }
  }, [])

  const queryParams = useMemo(() => {
    const startDate = thisWeek?.startOfWeek?.toISOString()
    const endDate = thisWeek?.endOfWeek?.toISOString()

    return { startDate, endDate }
  }, [thisWeek])

  const renderData = useMemo(() => {
    if (!Object?.keys(miniDashboardData)?.length) return null

    return [
      {
        title: t('teacherRequest:totalRequest'),
        value: totalRequests,
        bgColor: EMiniDashBoardColor.LIGHT_PINK,
        logo: <UserDeleteOutlined className="text-[28px] text-black" />,
        onClick: () => {
          reset(SEARCH_FORM_FIELD_DEFAULT_VALUES)
        },
      },
      {
        title: t('teacherRequest:totalPendingRequest'),
        value: totalPendingRequests,
        bgColor: EMiniDashBoardColor.LIGHT_PURPLE,
        logo: <CalendarOutlined className="text-[28px] text-black" />,
        onClick: () => {
          reset(SEARCH_FORM_FIELD_DEFAULT_VALUES)
          setValue(SEARCH_FORM_FIELDS.REQUEST_STATUS, [ERequestStatus.PENDING])
        },
      },
      {
        title: t('teacherRequest:totalNewRequestThisWeek'),
        value: totalNewRequestsThisWeek,
        bgColor: EMiniDashBoardColor.LIGHT_BLUE,
        logo: <BookOutlined className="text-[28px] text-black" />,
        onClick: () => {
          reset(SEARCH_FORM_FIELD_DEFAULT_VALUES)
          setValue(SEARCH_FORM_FIELDS.DATE_RANGE, [
            thisWeek.startOfWeek,
            thisWeek.endOfWeek,
          ])
        },
      },
      {
        title: t('teacherRequest:totalRequestUpdate'),
        value: totalRequestsUpdated,
        bgColor: EMiniDashBoardColor.LIGHT_BLUE,
        logo: <BookOutlined className="text-[28px] text-black" />,
        onClick: () => {
          reset(SEARCH_FORM_FIELD_DEFAULT_VALUES)
          setValue(SEARCH_FORM_FIELDS.REQUEST_STATUS, [ERequestStatus.UPDATED])
        },
      },
    ]
  }, [
    miniDashboardData,
    t,
    totalRequests,
    totalPendingRequests,
    totalNewRequestsThisWeek,
    totalRequestsUpdated,
    reset,
    setValue,
    thisWeek.startOfWeek,
    thisWeek.endOfWeek,
  ])

  useEffect(() => {
    dispatch(getMiniDashboardTeacherRequest(queryParams))
  }, [dispatch, queryParams])

  return (
    <MiniDashboardContainer>
      {(() => {
        if (isLoading)
          return <Skeleton row={1} column={4} style={{ height: 120 }} />

        return renderData?.map((item, index) => (
          <DashboardTag key={`${item.title}.${index}`} tag={item} />
        ))
      })()}
    </MiniDashboardContainer>
  )
}

export default MiniDashboard

const MiniDashboardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  gap: 32px;
`

const DashboardTag = ({
  tag,
}: {
  tag: {
    bgColor?: string
    logo: React.ReactNode
    title: string
    value: number
    onClick?: () => void
  }
}) => {
  const { bgColor, logo, title, value, onClick } = tag || {}
  return (
    <DashboardTagContainer
      $bgColor={bgColor}
      $clickAble={!!onClick}
      onClick={onClick}
    >
      <TitleWrapper>
        {logo}
        <Typography.Text className="text-[16px] text-black font-semibold">
          {title}
        </Typography.Text>
      </TitleWrapper>
      <Typography.Text className="text-[32px] text-black font-semibold mt-4">
        {value}
      </Typography.Text>
    </DashboardTagContainer>
  )
}

const DashboardTagContainer = styled.div<{
  $bgColor?: string
  $clickAble?: boolean
}>`
  width: max-content;
  min-width: 320px;
  min-height: 110px;
  padding: 16px;
  border-radius: 6px;
  background-color: ${(props) => (!!props.$bgColor ? props.$bgColor : '#fff')};
  cursor: ${(props) => (props.$clickAble ? 'pointer' : 'default')};
`

const TitleWrapper = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;
`
