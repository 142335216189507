import { EAboutUsPageTabs } from '@configs'
import { Tabs } from 'antd'
import { useState } from 'react'
import { Text } from 'src/common'
import AboutUsPageSeo from 'src/components/about-us-page-management/about-us-page-seo/AboutUsPageSeo'
import CEOquotesTab from 'src/components/about-us-page-management/ceo-quote/CEOquotesTab'
import CoreMembersTab from 'src/components/about-us-page-management/CoreMembersTab'
import CoreValueTab from 'src/components/about-us-page-management/CoreValueTab'
import LandingTab from 'src/components/about-us-page-management/LandingTab'
import OurStoryTab from 'src/components/about-us-page-management/OurStoryTab'
import OurVisionTab from 'src/components/about-us-page-management/OurVisionTab'
import ProvidingValueTab from 'src/components/about-us-page-management/providing-value/ProvidingValueTab'
import SimpleQuoteTab from 'src/components/about-us-page-management/simpleQuotes/SimpleQuoteTab'
import styled from 'styled-components'

interface IAboutUsPageManagementProps {}

const AboutUsPageManagement = ({}: IAboutUsPageManagementProps) => {
  const [activeTab, setActiveTab] = useState<string>(EAboutUsPageTabs.LANDING)

  const tabItems = [
    {
      label: <Text>{EAboutUsPageTabs.LANDING}</Text>,
      key: EAboutUsPageTabs.LANDING,
      children: <LandingTab activeTab={activeTab} />,
    },
    {
      label: <Text>{EAboutUsPageTabs.OUR_STORY}</Text>,
      key: EAboutUsPageTabs.OUR_STORY,
      children: <OurStoryTab activeTab={activeTab} />,
    },
    {
      label: <Text>{EAboutUsPageTabs.PROVIDING_VALUE}</Text>,
      key: EAboutUsPageTabs.PROVIDING_VALUE,
      children: <ProvidingValueTab activeTab={activeTab} />,
    },
    {
      label: <Text>{EAboutUsPageTabs.CORE_VALUES}</Text>,
      key: EAboutUsPageTabs.CORE_VALUES,
      children: <CoreValueTab activeTab={activeTab} />,
    },
    {
      label: <Text>Our Vision/Mission</Text>,
      key: EAboutUsPageTabs.OUR_VISION,
      children: <OurVisionTab activeTab={activeTab} />,
    },
    {
      label: <Text>{EAboutUsPageTabs.CEO_QUOTES}</Text>,
      key: EAboutUsPageTabs.CEO_QUOTES,
      children: <CEOquotesTab />,
    },

    {
      label: <Text>{EAboutUsPageTabs.ABOUT_US_PAGE_SEO}</Text>,
      key: EAboutUsPageTabs.ABOUT_US_PAGE_SEO,
      children: <AboutUsPageSeo activeTab={activeTab} />,
    },
  ]

  return (
    <AboutUsPageStyled>
      <Tabs
        destroyInactiveTabPane={true}
        defaultActiveKey={EAboutUsPageTabs.LANDING}
        type="card"
        size={'small'}
        items={tabItems}
        defaultChecked={true}
        activeKey={activeTab}
        onChange={(e) => {
          setActiveTab(e)
        }}
      />
    </AboutUsPageStyled>
  )
}

export default AboutUsPageManagement

const AboutUsPageStyled = styled('div')(() => {
  return {
    '.ant-tabs-nav': {
      marginBottom: 0,
      paddingLeft: '1.25rem',

      '& .ant-tabs-tab': {
        borderRadius: '0.75rem 0.75rem 0 0 !important',

        '&.ant-tabs-tab-active': {
          span: {
            color: '#009BB7',
            fontWeight: '500',
          },
        },
      },
    },
  }
})
