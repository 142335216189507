import {
  EPermissions,
  LLC_ACCESS_TOKEN,
  MAIN_THEME_DATA,
  PATH_DASHBOARD,
  PATH_LOGIN,
} from '@configs'
import { authActions, useAppDispatch, useAppSelector } from '@redux'
import { themes } from '@theme'
import { getRedirectPathAfterLoginSuccess } from '@utils'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import errorUrl from 'src/assets/images/403.svg'
import styled from 'styled-components'

export const PageError403 = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['common'])

  const dispatch = useAppDispatch()
  const accountInfo = useAppSelector((state) => state.auth.accountInfo)

  const redirectPath = getRedirectPathAfterLoginSuccess({
    defaultPath: PATH_DASHBOARD,
    permissions: accountInfo?.role?.permissions as Record<EPermissions, string>,
  })

  const handleLogOut = () => {
    Cookies.remove(LLC_ACCESS_TOKEN)
    sessionStorage.clear()
    dispatch(authActions.logout())
    navigate(PATH_LOGIN)
  }

  return (
    <ContainerStyled $bg={MAIN_THEME_DATA.mainColor}>
      <div className="container">
        <div className="error-page flex flex-col lg:flex-row items-center justify-center h-screen text-center lg:text-left">
          <div
            data-aos-duration="1000"
            data-aos="fade-right"
            className="-intro-x"
          >
            <img className="h-80 lg:h-auto" alt="" src={errorUrl} />
          </div>
          <div className="text-white mt-10 lg:mt-0 lg:ml-20">
            <div
              data-aos-duration="1000"
              data-aos="fade-left"
              className="intro-x text-8xl font-medium"
            >
              403
            </div>
            <div
              data-aos-duration="1000"
              data-aos="fade-left"
              data-aos-delay="50"
              className="intro-x text-xl lg:text-3xl font-medium mt-5"
            >
              {t('common:restricted_access')}
            </div>
            <div
              data-aos-duration="1000"
              data-aos="fade-left"
              data-aos-delay="100"
              className="intro-x text-lg mt-3"
            >
              {t('common:dont_have_permission')}
            </div>
            <button
              data-aos="fade-left"
              data-aos-delay="150"
              data-aos-duration="1000"
              onClick={() => {
                navigate(redirectPath)
              }}
              className="intro-x btn py-3 px-4 text-white border-white dark:border-darkmode-400 dark:text-slate-200 mt-10"
            >
              {!redirectPath ? (
                <div
                  className="logout flex items-center justify-start w-full"
                  onClick={handleLogOut}
                >
                  <span>{t('common:logout')}</span>
                </div>
              ) : (
                <>{t('common:back_to_home')}</>
              )}
            </button>
          </div>
        </div>
      </div>
    </ContainerStyled>
  )
}
const ContainerStyled = styled.div<{ $bg: string }>`
  /* background-color: ${(p) => themes.theme.light.colors.bgSection} ; */
  background-color: #184f64;
  .error-page img {
    width: 450px;
  }
  .btn {
    cursor: pointer;
    border-radius: 0.375rem;
    border-width: 1px;
  }
`
