import { ENavBarRole } from '@configs'
import { RootState } from '@redux'
import { createContext } from 'react'
import { useSelector } from 'react-redux'

interface RoleContextProps {
  currentUserRole: ENavBarRole | string
  isAdminRole: boolean
  isTeacherRole: boolean
}

const initialState = {
  currentUserRole: '',
  isAdminRole: false,
  isTeacherRole: false,
}

export const RoleContext = createContext<RoleContextProps>(initialState)

interface RoleProviderProps extends React.PropsWithChildren<any> {}

export default function RoleProvider(props: RoleProviderProps) {
  const { accountInfo } = useSelector((state: RootState) => state.auth)
  const currentUserRole = accountInfo?.client ?? ''

  const isAdminRole = ENavBarRole.ADMIN === currentUserRole
  const isTeacherRole = [
    ENavBarRole.TEACHER,
    ENavBarRole.TEACHER_LLC,
    ENavBarRole.TEACHER_OTHER,
  ].includes(currentUserRole as ENavBarRole)

  return (
    <RoleContext.Provider
      value={{ currentUserRole, isAdminRole, isTeacherRole }}
    >
      {props.children}
    </RoleContext.Provider>
  )
}
