import { useEffect, useMemo, useState } from 'react'
import { message } from 'antd'
import { t } from 'i18next'

import { AppModal, ShareSelectInput } from '@components'
import { ETeacherRole } from '@configs'
import { ICreateTeacher } from 'src/interfaces/teacher'
import CreateTeacherAuthorizedAndLLCForm from 'src/pages/teacher-management/components/CreateAuthorizeAndLLCForm'
import { INPUT_CLASSNAME } from 'src/pages/teacher-management/styled'
import { TEACHER_TYPE_OPTIONS } from 'src/pages/teacher-management/config'
import {
  createTeacherAuthorizedAction,
  createTeacherLLCAction,
  useAppDispatch,
} from '@redux'
import { useTeacherContext } from 'src/pages/teacher-management/provider/TeacherProvider'

interface IProps {
  open: boolean
  defaultType?: ETeacherRole
  onClose?: () => void
}

const CreateTeacherModal = ({ onClose, open, defaultType }: IProps) => {
  const dispatch = useAppDispatch()
  const { setCreateTeacherModalContent } = useTeacherContext()
  const [type, setType] = useState(ETeacherRole.LLC)

  const handleSubmitForm = async (data: ICreateTeacher) => {
    let response
    try {
      setCreateTeacherModalContent((prev) => ({
        ...prev,
        isLoading: true,
        isSubmitFinished: false,
      }))
      if (type === ETeacherRole.LLC) {
        response = await dispatch(createTeacherLLCAction(data)).unwrap()
      } else {
        response = await dispatch(createTeacherAuthorizedAction(data)).unwrap()
      }

      if (response?.teacher) {
        message.success(response.message)
        setCreateTeacherModalContent((prev) => ({
          ...prev,
          resetDragger: !prev.resetDragger,
          isSubmitFinished: true,
        }))
        onClose && onClose()
      }
    } catch (error: any) {
      if (error?.message) {
        message.error(error.message)
      }
    } finally {
      setCreateTeacherModalContent((prev) => ({
        ...prev,
        isLoading: false,
      }))
    }
  }

  const formRendering = useMemo(
    () => (
      <CreateTeacherAuthorizedAndLLCForm
        key={type}
        teacherType={type}
        onSubmit={handleSubmitForm}
        onCancel={onClose}
      />
    ),
    [onClose, type]
  )

  useEffect(() => {
    if (!defaultType) return
    setType(defaultType)
  }, [defaultType])

  return (
    <AppModal
      open={open}
      onClose={onClose}
      haveCloseIcon
      title={t('teacher:add_new_teacher')}
    >
      {/* temporarily hide teacher others */}
      <ShareSelectInput
        data={TEACHER_TYPE_OPTIONS.filter(
          (item) => item.value !== ETeacherRole.OTHER
        )}
        label="Type"
        placeholder="Type"
        onChange={(value) => setType(value as ETeacherRole)}
        value={type}
        className={`${INPUT_CLASSNAME}`}
        containerClassName="w-full mb-4"
        showArrow
      />
      {formRendering}
    </AppModal>
  )
}

export default CreateTeacherModal
