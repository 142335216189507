import { Tabs } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'

import { EUserManagementTabs } from '@configs'
import { Text } from 'src/common'
import TransactionHistoryTab from 'src/components/user-management/TransactionHistoryTab'
import { UserInfoTab } from 'src/components/user-management/UserInfoTab'
import UserLogTab from 'src/components/user-management/UserLogTab'

export const UserDetailPage = () => {
  const [activeTab, setActiveTab] = useState<string>(
    EUserManagementTabs.USER_INFO
  )
  const tabItems = [
    {
      label: <Text>{EUserManagementTabs.USER_INFO}</Text>,
      key: EUserManagementTabs.USER_INFO,
      children: <UserInfoTab activeTab={activeTab} />,
    },
    {
      label: <Text>{EUserManagementTabs.USER_LOG}</Text>,
      key: EUserManagementTabs.USER_LOG,
      children: <UserLogTab activeTab={activeTab} />,
    },
    {
      label: <Text>{EUserManagementTabs.TRANSACTION_HISTORY}</Text>,
      key: EUserManagementTabs.TRANSACTION_HISTORY,
      children: <TransactionHistoryTab activeTab={activeTab} />,
    },
  ]

  return (
    <UserManagementPageStyled>
      <Tabs
        destroyInactiveTabPane={true}
        defaultActiveKey={EUserManagementTabs.USER_INFO}
        type="card"
        size={'small'}
        items={tabItems}
        defaultChecked={true}
        activeKey={activeTab}
        onChange={(e) => {
          setActiveTab(e)
        }}
      />
    </UserManagementPageStyled>
  )
}

const UserManagementPageStyled = styled('div')(() => {
  return {
    '.ant-tabs-nav': {
      marginBottom: 0,
      paddingLeft: '1.25rem',

      '& .ant-tabs-tab': {
        borderRadius: '0.75rem 0.75rem 0 0 !important',

        '&.ant-tabs-tab-active': {
          span: {
            color: '#009BB7',
            fontWeight: '500',
          },
        },
      },
    },
  }
})
