import { Card } from 'antd'
import styled from 'styled-components'
import { TExperienceSection } from 'src/pages/teacher-request-detail/fill-in-teacher-information/types'
import { ReferenceForm } from './ReferenceForm'
import { ReferenceView } from './ReferenceView'

export const ReferenceSection = (props: TExperienceSection) => {
  return (
    <ExperienceSectionStyled className="!border-none flex items-center justify-center w-full">
      {!!props?.data?.editable ? (
        <ReferenceForm {...props} />
      ) : (
        <ReferenceView {...props} />
      )}
    </ExperienceSectionStyled>
  )
}

const ExperienceSectionStyled = styled(Card)`
  .ant-card-body {
  }

  .reset-form {
    > div:last-child {
      justify-content: center;
    }
  }
`
