import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Empty, message, Skeleton } from 'antd'
import { t } from 'i18next'

import {
  getTeacherByIdAction,
  RootState,
  selectTeachersLoading,
  updateTeacherByIdAction,
  useAppDispatch,
} from '@redux'
import TeacherOverviewForm from 'src/pages/teacher-management/components/TeacherOverviewForm'
import { TeacherContainer } from 'src/pages/teacher-management/styled'
import { ITeacherOverviewForm } from 'src/interfaces/teacher'
import { ETeacherStatus } from 'src/pages/teacher-management/config'
import {
  TeacherProvider,
  useTeacherContext,
} from 'src/pages/teacher-management/provider/TeacherProvider'
import { ETeacherRole, PATH_TEACHER_MANAGEMENT } from '@configs'
import TeacherOtherForm from 'src/pages/teacher-detail/TeacherOtherForm'

const TeacherOverview = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { setDeleteModalContent } = useTeacherContext()
  const params = useParams()
  const { id: teacherId } = params

  const [isLoading, setIsLoading] = useState(false)

  const { selectedTeacher } = useSelector(
    (state: RootState) => state.teacherManagement
  )

  const isTeacherOther = selectedTeacher?.client === ETeacherRole.OTHER

  const getTeacherByIdActionLoading = useSelector((state: RootState) =>
    selectTeachersLoading(state, 'getTeacherById')
  )

  const handleGetTeacherById = async (id: number) => {
    dispatch(getTeacherByIdAction(id))
  }

  const handleEditTeacherById = async (data: ITeacherOverviewForm) => {
    if (selectedTeacher?.isAssigned && !data.isActive) {
      setDeleteModalContent((prev) => ({
        ...prev,
        open: true,
        title: t('teacher:inactive_this_teacher'),
        content: t('teacher:inactive_this_teacher_content'),
        highlight: true,
        onConfirmDelete: () => editTeacher(data),
      }))
      return
    }
    await editTeacher(data)
  }

  const editTeacher = async (data: ITeacherOverviewForm) => {
    const params = {
      id: Number(teacherId),
      data: {
        phone: data.phone,
        name: data.name,
        status: data.isActive ? ETeacherStatus.ACTIVE : ETeacherStatus.INACTIVE,
        ...(!!data.profilePhotoId && { profilePhotoId: data.profilePhotoId }),
        description: !!data.description ? data.description : '',
      },
    }
    try {
      setIsLoading(true)
      const res = await dispatch(updateTeacherByIdAction(params)).unwrap()
      if (res) {
        message.success(res.message)
        navigate(PATH_TEACHER_MANAGEMENT)
      }
    } catch (error: any) {
      if (error?.message) {
        message.error(error?.message)
      }
    } finally {
      setIsLoading(false)
      setDeleteModalContent({
        open: false,
      })
    }
  }

  useEffect(() => {
    if (!teacherId) return
    handleGetTeacherById(Number(teacherId))
  }, [teacherId])

  return (
    <TeacherContainer>
      {(() => {
        if (getTeacherByIdActionLoading)
          return <Skeleton paragraph={{ rows: 4 }} />

        if (!selectedTeacher) return <Empty />

        if (isTeacherOther)
          return (
            <TeacherOtherForm teacher={selectedTeacher} isLoading={isLoading} />
          )

        return (
          <TeacherOverviewForm
            isLoading={isLoading}
            teacher={selectedTeacher}
            onSubmit={handleEditTeacherById}
            onUploading={(loading) => {
              setIsLoading(loading)
            }}
          />
        )
      })()}
    </TeacherContainer>
  )
}

const TeacherOverviewWithContext = () => {
  return (
    <TeacherProvider>
      <TeacherOverview />
    </TeacherProvider>
  )
}

export default TeacherOverviewWithContext
