import { AppModal, ShareSelectInput, XCloseIcon } from '@components'
import { t } from 'i18next'
import {
  ECourseDifficulty,
  ECourseType,
  ENationalLanguage,
  ENavBarRole,
  ERequest,
  EStatus,
  ETeacherRole,
  PATH_COURSE_MANAGEMENT,
} from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import { ICategory, User } from '@interfaces'
import {
  getAllCategoriesAction,
  RootState,
  selectCourseLoading,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { Checkbox, message, Skeleton, Typography } from 'antd'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Input, SwitchButton, TextArea } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import DeleteBlogModal from 'src/components/blog/DeleteBlogModal'
import PreviewPhotoModal from 'src/components/chatChannel/PreviewPhotoModal'
import SelectMedia from 'src/components/gallery/SelectMedia'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'
import { IAddNewCourse, IRequest } from 'src/interfaces/course'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import {
  addNewCourseAction,
  deleteCourseByIdAction,
  getCourseDetailAction,
  updateCourseByIdAction,
} from 'src/redux/actions/course'
import { getUserChat } from 'src/redux/actions/chat'
import { customSlugify } from 'src/utils/customSlugify'
import styled from 'styled-components'
import { getAllAuthorsAction } from 'src/redux/actions/blog-news'
import { getListPackageAction } from 'src/redux/actions/package-management'
import { ETeacherStatus } from 'src/pages/teacher-management'
import {
  COURSE_TYPE_OPTIONS,
  courseSchema,
  DEFAULT_FORM_FIELD_VALUES,
  RESPONSE_STATUS_CONTENT,
} from 'src/pages/course-management/configs'
import { deleteObjectFields } from '@utils'
import useDeepCompareEffect from 'use-deep-compare-effect'

const CourseCreateScreen = () => {
  const { id } = useParams()
  const courseId = id

  const { selectedCourse: courseDetailData } = useSelector(
    (state: RootState) => state.course
  )

  const [dataSubmit, setDataSubmit] = useState<IAddNewCourse>(
    {} as IAddNewCourse
  )
  const [previewOpen, setPreviewOpen] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [detailImg, setDetailImg] = useState('')
  const [selectedSubCategories, setSelectedSubCategories] = useState<
    ICategory[]
  >([])
  const [isUploading, setIsUploading] = useState(false)

  const [showModalSelectImageMedia, setShowModalSelectImageMedia] =
    useState(false)
  const [showModalSelectVideoMedia, setShowModalSelectVideoMedia] =
    useState(false)
  const [selectedField, setSelectedField] = useState<
    'desktop' | 'mobile' | null
  >(null)
  const onChangeDesktopRef = useRef<any>(null)
  const onChangeMobileRef = useRef<any>(null)

  const [reset, setReset] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [imageSrc, setImageSrc] = useState('')
  const [videoSrc, setVideoSrc] = useState('')
  const [type, setType] = useState(ECourseType.FREE)
  const [isActive, setIsActive] = useState(true)
  //confirm publish
  const [confirm, setConfirm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [defaultIsDraft, setDefaultIsDraft] = useState(false)
  const [defaultFormState, setDefaultFormState] = useState<{
    targetUser: boolean
    packageIds?: number[]
    userCreationFrom?: string
    userCreationTo?: string
    teacherCourse?: User
    requests?: IRequest[]
  }>({
    targetUser: false,
    requests: [],
  })
  const [openConfirmRemoveTargetUser, setOpenConfirmRemoveTargetUser] =
    useState(false)

  const handleTypeChange = (selectedType: any) => {
    setType(selectedType)

    const packages = getValues('packageIds')
    if (selectedType !== ECourseType.FREE && packages && packages.includes(1)) {
      setValue(
        'packageIds',
        packages.filter((item: any) => item !== 1)
      )
    }
  }

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const authors = useSelector((state: RootState) => state.blog)?.authors ?? []
  const { categories } = useAppSelector((state: RootState) => state.categories)

  const isTeacherOtherCourse = useMemo(
    () => defaultFormState?.teacherCourse?.client === ENavBarRole.TEACHER_OTHER,
    [defaultFormState?.teacherCourse?.client]
  )

  function getUniqueAuthors(array: { id: number; [key: string]: any }[]) {
    const activeAuthorizedTeachers = array.filter(
      (item) =>
        item.client === ETeacherRole.AUTHORIZED &&
        item.status === ETeacherStatus.ACTIVE
    )
    const uniqueItems: { [key: number]: any } = {}
    activeAuthorizedTeachers.forEach((item) => {
      uniqueItems[item.id] = item
    })
    return Object.values(uniqueItems)
  }

  const packages = useSelector((state: RootState) => state.package)?.packages

  const uploadThumbnailActionLoading = useSelector((state: RootState) =>
    selectCourseLoading(state, 'uploadThumbnailAction')
  )

  const getCourseDetailActionLoading = useSelector((state: RootState) =>
    selectCourseLoading(state, 'getCourseDetailActionLoading')
  )
  const onCloseConfirm = () => {
    setOpenConfirm(false)
  }

  const onCloseConfirmRemoveTargetUser = () => {
    setOpenConfirmRemoveTargetUser(false)
  }

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    watch,
    getValues,
    formState: { errors },
  } = useForm<IAddNewCourse & { authorName: string }>({
    defaultValues: DEFAULT_FORM_FIELD_VALUES,
    resolver: zodResolver(courseSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const responseStatusTitle = useMemo(() => {
    const teacherRequestType = defaultFormState?.requests?.[0]?.type

    if (!teacherRequestType) return ''

    return RESPONSE_STATUS_CONTENT?.[teacherRequestType]?.responseTitle || ''
  }, [defaultFormState?.requests])

  const renderResponseStatus = useCallback(
    (teacherRequestType) => {
      if (!teacherRequestType || teacherRequestType === ERequest.PUBLISH)
        return null

      return (
        <div className="pb-2 mb-4 border-b border-dashed">
          <Typography
            style={{
              fontSize: 24,
              fontWeight: 'bold',
              color:
                RESPONSE_STATUS_CONTENT?.[teacherRequestType as ERequest]
                  ?.color,
            }}
          >
            {responseStatusTitle}
          </Typography>
          <Typography.Paragraph className="!text-[20px] !font-semibold !m-0">
            {`Reason: ${defaultFormState?.requests?.[0]?.responseData?.reason}`}
          </Typography.Paragraph>
        </div>
      )
    },
    [defaultFormState?.requests, responseStatusTitle]
  )

  const watchTargetUser = watch('targetUser')
  const watchSubCategoriesIds = watch('subCategoriesIds')

  useEffect(() => {
    if (!defaultIsDraft && !!defaultFormState.targetUser) {
      return
    }

    if (!watchTargetUser) {
      setValue('packageIds', [])
      setValue('userCreationFrom', '')
      setValue('userCreationTo', '')
    }
  }, [watchTargetUser])

  const onClose = () => {
    setPreviewOpen(false)
  }
  const onCloseConfirmModal = () => {
    setOpenConfirmModal(false)
  }
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  // const handleClearThumbnail = () => {
  //   setPreviewImage('')
  //   setDetailImg('')
  // }

  const filterSubCategoriesIds = (
    categories: ICategory[],
    subCategoriesIds: number[]
  ): number[] => {
    // Create a Set of existing subCategory ids for quick lookup
    const existingSubCategoryIds = new Set<number>()

    for (const category of categories) {
      for (const subCategory of category.subCategories) {
        existingSubCategoryIds.add(subCategory.id)
      }
    }

    // Filter out ids that do not exist in any subCategories
    return subCategoriesIds.filter((id) => existingSubCategoryIds.has(id))
  }

  const handleChangeCategories = (value: any) => {
    if (value.length !== 0) {
      clearErrors(['categoriesIds'])
    }

    const selectedSubCategories = categories.filter((category) =>
      value.includes(category.id)
    )
    setSelectedSubCategories(selectedSubCategories)

    const filterSubCatIds = filterSubCategoriesIds(
      selectedSubCategories,
      watchSubCategoriesIds
    )

    setValue('subCategoriesIds', filterSubCatIds)
  }

  const handleChangePackage = (value: any) => {
    if (!!value.length) {
      clearErrors(['packageIds'])
    }

    setValue('packageIds', value)
  }

  // const onSubmit = (data: IAddNewCourse) => {
  //   setDataSubmit(() => data)
  //   setOpenConfirmModal(true)
  // }

  const packageOptions = useMemo(() => {
    if (!packages) return []
    return packages.map((item) => ({
      label: item.name,
      value: item.id,
    }))
  }, [packages])

  const handleConfirmSubmit = async (data: IAddNewCourse) => {
    setIsModalLoading(true)
    if (courseId) {
      try {
        const response = await dispatch(
          updateCourseByIdAction({
            courseId: courseId,
            params: data,
          })
        ).unwrap()
        if (response?.success) {
          message.success(response?.message)
        }
      } catch (error: any) {
        message.error(error?.message)
      } finally {
        setIsModalLoading(false)
        onCloseConfirm()
        getCourseDetail(String(courseId))
      }
    } else {
      try {
        const response = await dispatch(addNewCourseAction(data)).unwrap()
        if (response?.success) {
          message.success(response.message)
          navigate(`${PATH_COURSE_MANAGEMENT}/${response.data.newCourse.id}`, {
            state: {
              isCreateNew: true,
            },
          })
        }
      } catch (error: any) {
        message.error(error?.message)
      } finally {
        setIsModalLoading(false)
        onCloseConfirm()
      }
    }
  }
  const watchIsDrafted = useWatch({
    control,
    name: 'isDrafted',
  })
  const handleConfirmDelete = async () => {
    try {
      const response = await dispatch(
        deleteCourseByIdAction(courseId as string)
      ).unwrap()

      if (response?.success) {
        message.success(response?.message)

        navigate(PATH_COURSE_MANAGEMENT)
      }
    } catch (error: any) {
      message.error(error?.message)
    }
  }

  const onCloseModalSelectImageMedia = () => {
    setShowModalSelectImageMedia(false)
  }

  const onCloseModalSelectVideoMedia = () => {
    setShowModalSelectVideoMedia(false)
  }

  const onSelectImageMedia = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      setImageSrc(data?.baseUrl ?? '')
      setValue('thumbnailId', data?.id ?? 0)

      onChangeDesktopRef.current(data?.id ?? 0)
    } else if (selectedField === 'mobile') {
      // setImageMobileSrc(data?.baseUrl ?? '')
      // setValue('mediaMobileId', data?.id ?? 0)
      // onChangeMobileRef.current(data?.id ?? 0)
    }

    setShowModalSelectImageMedia(false)
    // setIsChange(true)
  }

  const onSelectVideoMedia = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      setVideoSrc(data?.baseUrl ?? '')
      setValue('demoVideoId', data?.id ?? 0)

      onChangeDesktopRef.current(data?.id ?? 0)
    } else if (selectedField === 'mobile') {
      // setImageMobileSrc(data?.baseUrl ?? '')
      // setValue('mediaMobileId', data?.id ?? 0)
      // onChangeMobileRef.current(data?.id ?? 0)
    }

    setShowModalSelectVideoMedia(false)
    // setIsChange(true)
  }

  useEffect(() => {
    const getDataRightSide = async () => {
      try {
        await dispatch(getAllCategoriesAction())
        await dispatch(
          getAllAuthorsAction({
            limit: '1000',
            search: '',
            status: EStatus.ACTIVE,
            types: ETeacherRole.AUTHORIZED,
          })
        )
        await dispatch(getUserChat({ page: '1', limit: '1000', search: '' }))
      } catch (error: any) {}
    }
    getDataRightSide()
  }, [dispatch])

  const getCourseDetail = useCallback(
    async (courseId: string) => {
      if (!courseId) return
      setIsLoading(true)
      try {
        const response = await dispatch(
          getCourseDetailAction(courseId)
        ).unwrap()

        if (response?.success) {
          const courseDetail = response?.data
          const {
            title,
            description,
            titleInDutch,
            descriptionInDutch,
            userId,
            type,
            eurPrice,
            usdPrice,
            difficulty,
            isActived,
            isDrafted,
            thumbnailId,
            demoVideoId,
            categories: categoriesAPI,
            subCategories,
            nationalLanguage,
            seoConfig: { slug },
            targetUser,
            hasFinalTest,
            packages: packagesAPI,
            userCreationFrom,
            userCreationTo,
            subTitle,
            isBestSeller,
            user,
            requests,
          } = courseDetail || {}

          const selectedCategories = categoriesAPI.map(
            (category) => category.id
          )

          const selectedPackages = packagesAPI.map((item) => item.id)

          setValue('title', title || '')
          setValue('titleInDutch', titleInDutch || '')
          setValue('slug', slug || '')
          setValue('description', description || '')
          setValue('descriptionInDutch', descriptionInDutch || '')
          setValue('userId', userId)
          setValue('type', type)
          setValue('eurPrice', eurPrice ?? 0)
          setValue('usdPrice', usdPrice ?? 0)
          setValue('difficulty', difficulty)
          setValue('isActived', isActived)
          setValue('isDrafted', isDrafted)
          setIsActive(isDrafted)
          setConfirm(!isDrafted)
          setValue('thumbnailId', thumbnailId)
          setValue('demoVideoId', demoVideoId)
          setValue('categoriesIds', selectedCategories)
          setValue(
            'subCategoriesIds',
            subCategories?.map((it) => it?.id)
          )
          setValue('nationalLanguage', nationalLanguage)
          setValue('hasFinalTest', hasFinalTest)
          setValue('targetUser', targetUser)
          setValue('packageIds', selectedPackages)
          setValue('userCreationFrom', userCreationFrom)
          setValue('userCreationTo', userCreationTo)
          setValue('subTitle', subTitle)
          setValue('isBestSeller', isBestSeller)
          setValue('authorName', user?.name || '')
          setType(
            type === ECourseType.FREE ? ECourseType.FREE : ECourseType.PREMIUM
          )
          setDetailImg(courseDetail?.thumbnail?.url)
          setDefaultIsDraft(isDrafted)
          setDefaultFormState({
            targetUser: targetUser,
            packageIds: selectedPackages,
            userCreationFrom: userCreationFrom,
            userCreationTo: userCreationTo,
            teacherCourse: user,
            requests: requests,
          })
          setImageSrc(courseDetail?.thumbnail?.url)
          setVideoSrc(courseDetail?.demoVideo?.url)
        }
      } catch (error: any) {
        message.error(error.message)
        navigate('/course-management')
      } finally {
        setIsLoading(false)
      }
    },
    [dispatch, navigate, setValue]
  )

  useEffect(() => {
    dispatch(
      getListPackageAction({
        page: 1,
        limit: 99999,
        isActive: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    if (!courseId) return
    setIsLoading(true)

    getCourseDetail(String(courseId))
  }, [courseId, dispatch, getCourseDetail])

  useDeepCompareEffect(() => {
    const { categories: categoriesAPI } = courseDetailData || {}

    const categoriesIds = categoriesAPI?.map((category: any) => category.id)

    const selectedSubCategories = categories?.filter((category) =>
      categoriesIds?.includes(category.id)
    )
    setSelectedSubCategories(selectedSubCategories)
  }, [categories, courseDetailData, setValue])

  return (
    <>
      {isLoading ? (
        <Skeleton paragraph={{ rows: 4 }} className="p-5" />
      ) : (
        <CreateCourseStyled>
          <div className="blog-content-m-right col-span-12 lg:col-span-4 rounded-lg">
            <div className="intro-y box p-5 bg-white rounded-lg">
              {renderResponseStatus(defaultFormState?.requests?.[0]?.type)}
              <div className="blog-content-top intro-y mt-2 flex flex-col items-center sm:flex-row">
                <h2 className="blog-content-top mr-auto text-lg font-medium flex items-center justify-between">
                  {Boolean(courseId) ? 'Edit course' : 'Create new course'}
                </h2>
              </div>

              <div className="blog-content-main intro-y mt-5">
                <div className="grid grid-cols-12 gap-4">
                  <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
                    <div className="col-span-6">
                      <Controller
                        name="title"
                        defaultValue=""
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              placeholder="Title"
                              value={value}
                              onChange={(event) => {
                                onChange(event?.target?.value)
                                setValue(
                                  'slug',
                                  customSlugify(event?.target?.value?.trim())
                                )
                              }}
                              required
                              label="Title"
                              alignment="col"
                              errors={error?.message}
                              className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                            />
                          )
                        }}
                      />
                    </div>

                    <div className="col-span-6">
                      <Controller
                        name="titleInDutch"
                        defaultValue=""
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              placeholder="Title in Netherlands"
                              value={value}
                              onChange={onChange}
                              required
                              label="Title in Netherlands"
                              alignment="col"
                              errors={error?.message}
                              className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                            />
                          )
                        }}
                      />
                    </div>
                  </div>

                  <div className="blog-content-m-left intro-y col-span-12">
                    <Controller
                      name="slug"
                      defaultValue=""
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <Input
                            placeholder="Slug"
                            value={value}
                            onChange={onChange}
                            label="Slug"
                            errors={error?.message}
                            required
                            alignment="col"
                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                          />
                        )
                      }}
                    />
                  </div>
                </div>

                <div className="blog-content-main intro-y mt-5">
                  <label>
                    Description{' '}
                    <span className="required text-[#B91C1C] font-bold">*</span>
                  </label>
                  <div className="blog-content-m-left intro-y col-span-12 lg:col-span-8 mb-4">
                    <Controller
                      name="description"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <TextArea
                            name="description"
                            required
                            onChange={onChange}
                            value={value}
                            style={{ marginTop: 10 }}
                            errors={error?.message}
                            isGrid
                            containerClassName="mt-3"
                            rows={10}
                          />
                        )
                      }}
                    />
                  </div>

                  <label>
                    Description in Netherlands{' '}
                    <span className="required text-[#B91C1C] font-bold">*</span>
                  </label>
                  <div className="blog-content-m-left intro-y col-span-12 lg:col-span-8">
                    <Controller
                      name="descriptionInDutch"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <TextArea
                            name="description in Netherlands"
                            required
                            onChange={onChange}
                            value={value}
                            style={{ marginTop: 10 }}
                            errors={error?.message}
                            isGrid
                            containerClassName="mt-3"
                            rows={10}
                          />
                        )
                      }}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-5">
                  <div className="blog-written-by">
                    {isTeacherOtherCourse ? (
                      <Controller
                        name="authorName"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              placeholder="Author"
                              value={value}
                              onChange={onChange}
                              label="Author"
                              errors={error?.message}
                              required
                              alignment="col"
                              className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                              disabled={isTeacherOtherCourse}
                            />
                          )
                        }}
                      />
                    ) : (
                      <Controller
                        name="userId"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <ShareSelectInput
                              data={getUniqueAuthors(authors).map(
                                (item, index) => {
                                  return {
                                    label: item.name,
                                    value: item.id,
                                  }
                                }
                              )}
                              value={value}
                              label="Author"
                              placeholder="Select an author"
                              onChange={onChange}
                              errors={error?.message}
                              required
                            />
                          )
                        }}
                      />
                    )}
                  </div>
                  <div className="blog-categories">
                    <Controller
                      name="categoriesIds"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <ShareSelectInput
                            data={
                              !getCourseDetailActionLoading
                                ? categories &&
                                  categories?.map((item, index) => {
                                    return {
                                      label: item.name,
                                      value: item.id,
                                    }
                                  })
                                : []
                            }
                            label="Categories"
                            mode="multiple"
                            placeholder="Select categories"
                            value={value}
                            onChange={(value) => {
                              onChange(value)
                              handleChangeCategories(value)
                            }}
                            errors={error?.message}
                            showArrow
                            required
                          />
                        )
                      }}
                    />
                  </div>
                  <div className="blog-tags">
                    <Controller
                      name="subCategoriesIds"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <ShareSelectInput
                            options={
                              getCourseDetailActionLoading ||
                              !selectedSubCategories?.length
                                ? []
                                : (selectedSubCategories?.map((item) => {
                                    return {
                                      label: item.name,
                                      options: item.subCategories?.map(
                                        (it) => ({
                                          label: it.name,
                                          value: it.id,
                                        })
                                      ),
                                    }
                                  }) as any)
                            }
                            label="Sub Category"
                            mode="multiple"
                            placeholder="Select Sub categories"
                            onChange={(e) => {
                              onChange(e)
                            }}
                            value={value}
                            errors={error?.message}
                            showArrow
                            onFocus={() => {
                              if (selectedSubCategories.length === 0) {
                                message.warning(
                                  'Please select categories first!'
                                )
                              }
                            }}
                          />
                        )
                      }}
                    />
                  </div>
                  <div className="blog-type">
                    <Controller
                      name="type"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <ShareSelectInput
                            defaultValue={'Blog value'}
                            data={COURSE_TYPE_OPTIONS}
                            label="Type"
                            onChange={(selectedType) => {
                              onChange(selectedType)
                              handleTypeChange(selectedType)
                            }}
                            value={value}
                            required
                          />
                        )
                      }}
                    />
                  </div>

                  {[ECourseType.PREMIUM, ECourseType.MEMBER_COURSE].includes(
                    type
                  ) && (
                    <div className="blog-published">
                      <label className="blog-published-label mb-2">
                        Price in EUR
                      </label>
                      <span className="required text-[#B91C1C] font-bold">
                        {' '}
                        *
                      </span>
                      <Controller
                        name="eurPrice"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              name="eurPrice"
                              type="number"
                              onChange={(e) => {
                                e.target.value
                                  ? onChange(parseFloat(e.target.value))
                                  : onChange(0)
                              }}
                              value={value}
                              errors={error?.message}
                              min={0}
                              required
                            />
                          )
                        }}
                      />
                    </div>
                  )}
                  {[ECourseType.PREMIUM, ECourseType.MEMBER_COURSE].includes(
                    type
                  ) && (
                    <div className="blog-published">
                      <label className="blog-published-label mb-2">
                        Price in USD
                      </label>
                      <span className="required text-[#B91C1C] font-bold">
                        {' '}
                        *
                      </span>
                      <Controller
                        name="usdPrice"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              name="usdPrice"
                              type="number"
                              onChange={(e) => {
                                e.target.value
                                  ? onChange(parseFloat(e.target.value))
                                  : onChange(0)
                              }}
                              value={value}
                              errors={error?.message}
                              min={0}
                              required
                            />
                          )
                        }}
                      />
                    </div>
                  )}

                  <div className="blog-type">
                    <Controller
                      name="difficulty"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <ShareSelectInput
                            defaultValue={'Blog value'}
                            data={[
                              {
                                value: ECourseDifficulty.BEGINNER,
                                label: ECourseDifficulty.BEGINNER,
                              },
                              {
                                value: ECourseDifficulty.MODERATE,
                                label: ECourseDifficulty.MODERATE,
                              },
                              {
                                value: ECourseDifficulty.INTERMEDIATE,
                                label: ECourseDifficulty.INTERMEDIATE,
                              },
                              {
                                value: ECourseDifficulty.ADVANCED,
                                label: ECourseDifficulty.ADVANCED,
                              },
                            ]}
                            label="Difficulty"
                            onChange={onChange}
                            value={value}
                            required
                          />
                        )
                      }}
                    />
                  </div>
                  <div className="blog-type">
                    <Controller
                      name="nationalLanguage"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <ShareSelectInput
                            defaultValue={'English'}
                            data={[
                              {
                                value: ENationalLanguage.ENGLISH,
                                label: t('common:english'),
                              },
                              {
                                value: ENationalLanguage.NETHERLAND,
                                label: t('common:netherland'),
                              },
                            ]}
                            label="National language"
                            onChange={onChange}
                            value={value}
                            required
                          />
                        )
                      }}
                    />
                  </div>

                  <div className="blog-type">
                    <Controller
                      name="subTitle"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <ShareSelectInput
                            name="subTitle"
                            defaultValue={''}
                            data={[
                              {
                                value: '',
                                label: '',
                              },
                              {
                                value: ENationalLanguage.ENGLISH,
                                label: t('common:english'),
                              },
                              {
                                value: ENationalLanguage.NETHERLAND,
                                label: t('common:netherland'),
                              },
                            ]}
                            showArrow
                            label="Subtitle"
                            onChange={(value) =>
                              !!value ? onChange(value) : onChange(null)
                            }
                            value={value ?? ''}
                          />
                        )
                      }}
                    />
                  </div>

                  <div className="blog-type"></div>

                  {courseId && (
                    <div className="blog-published">
                      <div>
                        <label className="blog-show-author-label mb-2">
                          Inactive / Active
                        </label>
                      </div>
                      <Controller
                        name="isActived"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <SwitchButton
                              size="default"
                              onChange={onChange}
                              checked={value}
                            />
                          )
                        }}
                      />
                    </div>
                  )}

                  {courseId ? <div className="blog-type"></div> : null}

                  <div className="blog-published">
                    <span className="flex items-center gap-4">
                      <Controller
                        name="hasFinalTest"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <Checkbox
                              name="hasFinalTest"
                              onChange={(e) => {
                                onChange(e.target.checked)
                              }}
                              checked={value}
                              className="!w-fit !h-fit !font-normal"
                              disabled={!isActive}
                            >
                              {t('section:have_final_test')}
                            </Checkbox>
                          )
                        }}
                      />
                      {/* <label className="blog-published-label mb-2">
                  </label> */}
                    </span>
                    <div>
                      <span className="flex items-center gap-4">
                        <Controller
                          name="targetUser"
                          control={control}
                          render={({ field: { value, onChange } }) => {
                            return (
                              <Checkbox
                                name="targetUser"
                                onChange={(e) => {
                                  if (
                                    !defaultIsDraft &&
                                    defaultFormState.targetUser &&
                                    !!courseId &&
                                    !e.target.checked
                                  ) {
                                    setOpenConfirmRemoveTargetUser(true)
                                    return
                                  }
                                  onChange(e.target.checked)
                                }}
                                checked={value}
                                className="!w-fit !h-fit !font-normal"
                                disabled={
                                  !defaultIsDraft &&
                                  !defaultFormState.targetUser &&
                                  !!courseId
                                }
                              >
                                Target user
                              </Checkbox>
                            )
                          }}
                        />
                      </span>
                    </div>
                    <div>
                      <span className="flex items-center gap-4">
                        <Controller
                          name="isBestSeller"
                          control={control}
                          render={({ field: { value, onChange } }) => {
                            return (
                              <Checkbox
                                name="isBestSeller"
                                onChange={(e) => {
                                  onChange(e.target.checked)
                                }}
                                checked={value}
                                className="!w-fit !h-fit !font-normal"
                              >
                                Best seller
                              </Checkbox>
                            )
                          }}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="blog-published"></div>

                  {watchTargetUser && (
                    <div className="blog-published col-span-2 grid grid-cols-2 gap-4 mb-4">
                      <div className="blog-type">
                        <Controller
                          name="packageIds"
                          control={control}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => {
                            return (
                              <ShareSelectInput
                                data={packageOptions}
                                label="Packages"
                                mode="multiple"
                                placeholder="Select packages"
                                value={value}
                                onChange={handleChangePackage}
                                errors={error?.message}
                                showArrow
                                disabled={
                                  !defaultIsDraft &&
                                  !!defaultFormState.targetUser
                                }
                                required={watchTargetUser}
                              />
                            )
                          }}
                        />
                      </div>
                      <div className="flex items-start justify-between gap-4">
                        <div className="w-full">
                          <Controller
                            name="userCreationFrom"
                            control={control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => {
                              return (
                                <Input
                                  containerClassName="w-full !flex !flex-col items-start !gap-[0px]"
                                  type="date"
                                  label="User creation from"
                                  alignment="col"
                                  errors={error?.message}
                                  onChangeDate={onChange}
                                  disabled={
                                    !defaultIsDraft &&
                                    !!defaultFormState.targetUser
                                  }
                                  value={value}
                                />
                              )
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <Controller
                            name="userCreationTo"
                            control={control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => {
                              return (
                                <Input
                                  containerClassName="w-full !flex !flex-col items-start !gap-[0px]"
                                  type="date"
                                  label="User creation to"
                                  alignment="col"
                                  errors={error?.message}
                                  onChangeDate={onChange}
                                  disabled={
                                    !defaultIsDraft &&
                                    !!defaultFormState.targetUser
                                  }
                                  value={value}
                                />
                              )
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="blog-published">
                    <div className="wrapper-thumbnail-section mb-2 flex justify-between items-center">
                      <label className="blog-add-thumbnail-label">
                        Thumbnail
                        <span className="required text-[#B91C1C] font-bold">
                          {' '}
                          *
                        </span>
                      </label>
                    </div>
                    {previewImage || detailImg ? (
                      // <img
                      //   src={previewImage || detailImg}
                      //   alt="blog-thumbnail"
                      //   style={{ width: '100%', objectFit: 'contain' }}
                      //   onClick={handlePreviewThumbnail}
                      // />
                      <>
                        <Controller
                          name={'thumbnailId'}
                          control={control}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => {
                            onChangeDesktopRef.current = onChange
                            return (
                              <>
                                <CustomDragger
                                  // label="Image"
                                  containerClassName="mt-[10px]"
                                  onLoadEnd={(data) => {
                                    setValue('thumbnailId', data?.id ?? 0)
                                    onChange(data?.id ?? 0)
                                    // setIsChange(true)
                                  }}
                                  name="thumbnailId"
                                  id="thumbnailId"
                                  required
                                  errors={error?.message}
                                  multiple={false}
                                  initResource={imageSrc}
                                  reset={reset}
                                  allowFileTypes={[
                                    'image/png',
                                    'image/jpeg',
                                    'image/jpg',
                                  ]}
                                  limitFileSize={5}
                                  changeLoading={setIsModalLoading}
                                  uploadType={EMediaType.IMAGE as any}
                                  {...(!isTeacherOtherCourse && {
                                    uploadCategory: EMediaCategory.COURSE,
                                  })}
                                />
                              </>
                            )
                          }}
                        />
                        {!isTeacherOtherCourse ? (
                          <SelectMedia
                            onClickSelectMedia={() => {
                              setSelectedField((pre) => 'desktop')
                              setShowModalSelectImageMedia(true)
                            }}
                            isNotGrid={true}
                          />
                        ) : null}
                      </>
                    ) : (
                      <>
                        <Controller
                          name={'thumbnailId'}
                          control={control}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => {
                            onChangeDesktopRef.current = onChange
                            return (
                              <>
                                <CustomDragger
                                  // label="Image"
                                  containerClassName="mt-[10px]"
                                  onLoadEnd={(data) => {
                                    setValue('thumbnailId', data?.id ?? 0)
                                    onChange(data?.id ?? 0)
                                    // setIsChange(true)
                                  }}
                                  name="thumbnailId"
                                  id="thumbnailId"
                                  required
                                  errors={error?.message}
                                  multiple={false}
                                  initResource={imageSrc}
                                  reset={reset}
                                  allowFileTypes={[
                                    'image/png',
                                    'image/jpeg',
                                    'image/jpg',
                                  ]}
                                  limitFileSize={5}
                                  changeLoading={setIsModalLoading}
                                  uploadType={EMediaType.IMAGE as any}
                                  {...(!isTeacherOtherCourse && {
                                    uploadCategory: EMediaCategory.COURSE,
                                  })}
                                />
                              </>
                            )
                          }}
                        />
                        {!isTeacherOtherCourse ? (
                          <SelectMedia
                            onClickSelectMedia={() => {
                              setSelectedField((pre) => 'desktop')
                              setShowModalSelectImageMedia(true)
                            }}
                            isNotGrid={true}
                          />
                        ) : null}
                      </>
                    )}
                  </div>
                  <div className="blog-published">
                    <div className="wrapper-thumbnail-section mb-2 flex justify-between items-center">
                      <label className="blog-add-thumbnail-label">
                        Demo Video
                        <span className="required text-[#B91C1C] font-bold">
                          {' '}
                          *
                        </span>
                      </label>
                    </div>
                    {previewImage || detailImg ? (
                      // <img
                      //   src={previewImage || detailImg}
                      //   alt="blog-thumbnail"
                      //   style={{ width: '100%', objectFit: 'contain' }}
                      //   onClick={handlePreviewThumbnail}
                      // />
                      <>
                        <Controller
                          name={'demoVideoId'}
                          control={control}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => {
                            onChangeDesktopRef.current = onChange
                            return (
                              <>
                                <CustomDragger
                                  // label="Image"
                                  containerClassName="mt-[10px]"
                                  onLoadEnd={(data) => {
                                    setValue(
                                      'demoVideoId',
                                      data?.dataValues.id ?? 0
                                    )
                                    onChange(data?.dataValues.id ?? 0)
                                    // setIsChange(true)
                                  }}
                                  name="demoVideoId"
                                  id="demoVideoId"
                                  required
                                  errors={error?.message}
                                  multiple={false}
                                  initResource={videoSrc}
                                  reset={reset}
                                  allowFileTypes={[
                                    'video/mp4',
                                    'video/webm',
                                    'video/m4v',
                                    'video/mov',
                                    'video/qt',
                                  ]}
                                  limitFileSize={0}
                                  changeLoading={setIsModalLoading}
                                  uploadType={EMediaType.VIDEO as any}
                                  {...(!isTeacherOtherCourse && {
                                    uploadCategory: EMediaCategory.COURSE,
                                  })}
                                />
                              </>
                            )
                          }}
                        />
                        {!isTeacherOtherCourse ? (
                          <SelectMedia
                            onClickSelectMedia={() => {
                              setSelectedField((pre) => 'desktop')
                              setShowModalSelectVideoMedia(true)
                            }}
                            isNotGrid={true}
                          />
                        ) : null}
                      </>
                    ) : (
                      <>
                        <Controller
                          name={'demoVideoId'}
                          control={control}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => {
                            onChangeDesktopRef.current = onChange
                            return (
                              <>
                                <CustomDragger
                                  // label="Image"
                                  containerClassName="mt-[10px]"
                                  onLoadEnd={(data) => {
                                    setValue(
                                      'demoVideoId',
                                      data?.dataValues?.id ?? 0
                                    )
                                    onChange(data?.dataValues?.id ?? 0)
                                    // setIsChange(true)
                                  }}
                                  name="demoVideoId"
                                  id="demoVideoId"
                                  required
                                  errors={error?.message}
                                  multiple={false}
                                  initResource={videoSrc}
                                  reset={reset}
                                  allowFileTypes={[
                                    'video/mp4',
                                    'video/webm',
                                    'video/m4v',
                                    'video/mov',
                                    'video/qt',
                                  ]}
                                  limitFileSize={0}
                                  changeLoading={setIsModalLoading}
                                  uploadType={EMediaType.VIDEO as any}
                                  {...(!isTeacherOtherCourse && {
                                    uploadCategory: EMediaCategory.COURSE,
                                  })}
                                />
                              </>
                            )
                          }}
                        />
                        {!isTeacherOtherCourse ? (
                          <SelectMedia
                            onClickSelectMedia={() => {
                              setSelectedField((pre) => 'desktop')
                              setShowModalSelectVideoMedia(true)
                            }}
                            isNotGrid={true}
                          />
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="blog-content-top item-right-wrapper flex items-center justify-center gap-2 mt-5 pb-8">
              {/* {!!courseId ? (
            <Button
              type="default"
              size="small"
              icon={<DeleteOutlined />}
              className="flex items-center justify-start"
              onClick={() => {
                setOpenDeleteModal(true)
              }}
            >
              Delete
            </Button>
          ) : ( */}
              <Button
                type="default"
                size="small"
                id="blog-form"
                onClick={() => navigate(PATH_COURSE_MANAGEMENT)}
              >
                {t('common:cancel')}
              </Button>
              {/* )} */}
              <Button
                type="primary"
                size="small"
                id="blog-form"
                loading={isModalLoading}
                disabled={isModalLoading}
                onClick={() => {
                  handleSubmit(handleConfirmSubmit)()
                }}
              >
                {t('common:save')}
              </Button>
              {!!courseDetailData?.isDrafted && !!courseId && (
                <Button
                  type="primary"
                  size="small"
                  id="blog-form"
                  onClick={() => {
                    setValue('isDrafted', false)
                    setValue('isActived', true)
                    setOpenConfirm(true)
                  }}
                  className="!bg-green-500 !border-green-500"
                >
                  {t('common:publish')}
                </Button>
              )}
            </div>
          </div>
          <PreviewPhotoModal
            open={previewOpen}
            onDelete={() => {}}
            onSave={() => {}}
            onClose={onClose}
            url={previewImage || detailImg}
          />
          {/* <AddNewBlogModal
        open={openConfirmModal}
        content={
          'This course will be saved and published on your setting time, make sure you have been checking all content.'
        }
        onSave={handleConfirmSubmit}
        onClose={onCloseConfirmModal}
      /> */}
          <DeleteBlogModal
            open={openDeleteModal}
            content={'Are you sure to delete this course?'}
            onSave={handleConfirmDelete}
            onClose={onCloseDeleteModal}
          />
          {showModalSelectImageMedia ? (
            <>
              <SelectMediaModal
                isOpen={showModalSelectImageMedia}
                onClose={onCloseModalSelectImageMedia}
                mediaType={EMediaType.IMAGE}
                category={EMediaCategory.COURSE}
                onSelectMedia={onSelectImageMedia}
              />
            </>
          ) : (
            <></>
          )}
          {showModalSelectVideoMedia ? (
            <>
              <SelectMediaModal
                isOpen={showModalSelectVideoMedia}
                onClose={onCloseModalSelectVideoMedia}
                mediaType={EMediaType.VIDEO}
                category={EMediaCategory.COURSE}
                onSelectMedia={onSelectVideoMedia}
              />
            </>
          ) : (
            <></>
          )}
          <AppModal open={openConfirm} onClose={onCloseConfirm}>
            <div className="flex items-center justify-between ">
              <div>
                <h1 className="m-0 text-[20px]">
                  Are you sure to publish this course?
                </h1>
              </div>
              <div className="hover:opacity-75 cursor-pointer">
                <XCloseIcon width={16} height={16} onClick={onCloseConfirm} />
              </div>
            </div>
            <div className="mt-6">
              <span className="text-[16px] text-danger">
                You cannot updated the related lesson and cannot back this
                course to draft.
              </span>
            </div>
            <div className="mt-6">
              <div className="flex items-center mt-5 justify-end">
                <Button
                  type="ghost"
                  size="middle"
                  className="submit__btn login-btn mr-10"
                  onClick={onCloseConfirm}
                >
                  {'No'}
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  size="middle"
                  className="submit__btn login-btn"
                  // onClick={() => onSave(cacheData.current)}
                  onClick={() => handleSubmit(handleConfirmSubmit)()}
                >
                  {'Yes'}
                </Button>
              </div>
            </div>
          </AppModal>
          <AppModal
            open={openConfirmRemoveTargetUser}
            onClose={onCloseConfirmRemoveTargetUser}
          >
            <div className="flex items-center justify-between gap-4">
              <div>
                <h1 className="m-0 text-[20px]">
                  Are you sure to remove target user for this course ?
                </h1>
              </div>
              <div className="hover:opacity-75 cursor-pointer">
                <XCloseIcon
                  width={16}
                  height={16}
                  onClick={onCloseConfirmRemoveTargetUser}
                />
              </div>
            </div>
            <div className="mt-6">
              <span className="text-[16px] text-danger">
                You CAN NOT edit this after you Save it !.
              </span>
            </div>
            <div className="mt-6">
              <div className="flex items-center mt-5 justify-end">
                <Button
                  type="ghost"
                  size="middle"
                  className="submit__btn login-btn mr-10"
                  onClick={onCloseConfirmRemoveTargetUser}
                >
                  {'No'}
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  size="middle"
                  className="submit__btn login-btn"
                  onClick={() => {
                    setValue('targetUser', false)
                    setOpenConfirmRemoveTargetUser(false)
                  }}
                >
                  {'Yes'}
                </Button>
              </div>
            </div>
          </AppModal>
        </CreateCourseStyled>
      )}
    </>
  )
}

export default CourseCreateScreen
const CreateCourseStyled = styled('div')(() => {
  return {
    label: {
      fontWeight: 'bold',
    },
    '.label': {
      fontWeight: 'bold',
    },
    '.ant-picker-disabled': {
      background: '#fafafa !important',
    },
    '.ant-tabs-nav': {
      marginBottom: 0,
      paddingLeft: '1.25rem',

      '& .ant-tabs-tab': {
        borderRadius: '0.75rem 0.75rem 0 0 !important',

        '&.ant-tabs-tab-active': {
          span: {
            color: '#009BB7',
            fontWeight: '500',
          },
        },
      },
    },

    '.ant-upload.ant-upload-select-picture-card': {
      width: '100%',
      height: 'auto',
    },
  }
})
