import { DownOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { PATH_GROUP_MANAGEMENT, thunkActionLoading } from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import { RouterParams } from '@interfaces'
import { RootState, useAppDispatch, useAppSelector } from '@redux'
import { Card, List, Skeleton, Typography, message } from 'antd'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Input, SwitchButton, TextArea } from 'src/common'
import {
  IGroupUserForm,
  ISubscribedEmail,
} from 'src/interfaces/group-user-management'
import {
  createGroupUserAction,
  editGroupUserAction,
  getGroupUserByIdAction,
  getListSubscribedEmailAction,
} from 'src/redux/actions/group-user-management'
import { isInListSubscribedEmail } from 'src/utils/checkInListSubcribedEmail'
import { z } from 'zod'
import SelectUserModal from './SelectUserModal'
import { t } from 'i18next'
type Props = {}

const groupUserSchema = z.object({
  name: z
    .string()
    .trim()
    .nonempty(t('error:field_required'))
    .max(50, t('error:group_user_name_title_length_error')),
  description: z
    .string()
    .trim()
    .nonempty(t('error:field_required'))
    .max(255, t('error:group_user_name_description_length_error')),
  emailSubscribers: z.string().array().nonempty(t('error:field_required')),
  status: z.boolean(),
})

const defaultValue: IGroupUserForm = {
  name: '',
  description: '',
  emailSubscribers: [],
  status: false,
}

const CreateEditGroupPage = (props: Props) => {
  const { groupId } = useParams<RouterParams['GroupUserDetail']>()
  const isEdit = Boolean(groupId)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [openSelectUserModal, setOpenSelectUserModal] = useState<boolean>(false)
  const [isSelectAllUsers, setIsSelectAllUsers] = useState<boolean>(false)

  const [selectedUserEmail, setSelectedUserEmail] = useState<
    ISubscribedEmail[]
  >([])

  const {
    groupUser: { groupUsersByGroupId },
  } = useAppSelector((state: RootState) => state?.groupUser)

  const [filtered, setFiltered] = useState<{
    search?: string
    categoryId?: number
    packageId?: number
  }>({
    search: '',
    categoryId: undefined,
    packageId: undefined,
  })

  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false)

  const onSelectedAll = (value?: boolean) => {
    setIsSelectedAll((prev) => value ?? !prev)
  }

  const onChangeFilter = (data: {
    search?: string
    categoryId?: number
    packageId?: number
  }) => {
    setFiltered((prev) => ({
      ...prev,
      ...data,
    }))
  }

  const isCreateGroupUserLoading = useAppSelector(
    (state) =>
      state.groupUser?.loadings?.[thunkActionLoading.CREATE_GROUP_USER_LOADING]
  )

  const isGetGroupUserByIdLoading = useAppSelector(
    (state) =>
      state.groupUser?.loadings?.[
        thunkActionLoading.GET_GROUP_USER_BY_ID_LOADING
      ]
  )

  const isGetListEmailLoading = useAppSelector(
    (state) =>
      state.groupUser?.loadings?.[
        thunkActionLoading.GET_LIST_SUBSCRIBED_EMAIL_LOADING
      ]
  )

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors, isValid, isDirty },
  } = useForm<IGroupUserForm>({
    defaultValues: defaultValue,
    resolver: zodResolver(groupUserSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onSave = async (data: IGroupUserForm) => {
    if (!isEdit) {
      try {
        const response = await dispatch(createGroupUserAction(data)).unwrap()
        if (response?.success) {
          message.success(response?.message)
          navigate(PATH_GROUP_MANAGEMENT)
        }
      } catch (error: any) {
        message.error(error && error?.message)
      }
    } else {
      if (!groupId) return
      try {
        const response = await dispatch(
          editGroupUserAction({
            id: +groupId,
            ...data,
          })
        ).unwrap()
        if (response?.success) {
          message.success(response?.message)
          navigate(PATH_GROUP_MANAGEMENT)
        }
      } catch (error: any) {
        message.error(error && error?.message)
      }
    }
  }

  const onCloseSelectUserModal = () => {
    setOpenSelectUserModal(false)
  }

  const onSelectAllUser = async (e: boolean) => {
    if (e) {
      setIsSelectAllUsers(true)
      try {
        const res = await dispatch(
          getListSubscribedEmailAction({
            isAll: true,
          })
        ).unwrap()
        setSelectedUserEmail(res.data.items)
        setValue(
          'emailSubscribers',
          res.data.items?.map((item: any) => item?.email),
          { shouldDirty: true }
        )
      } catch (error) {
        setIsSelectAllUsers(false)
      }
      return
    }

    setIsSelectAllUsers(false)
    if (groupId) {
      const response = await dispatch(getGroupUserByIdAction(+groupId)).unwrap()

      if (response?.success) {
        const data = response?.data
        setSelectedUserEmail(data?.subscribers)
        setValue('name', data?.name)
        setValue('description', data?.description)
        setValue('status', data?.status)
        setValue(
          'emailSubscribers',
          data?.subscribers?.map((item) => item?.email)
          // { shouldDirty: true }
        )
      }

      return
    }

    setSelectedUserEmail([])
  }

  useEffect(() => {
    const getDetailGroup = async () => {
      if (!groupId) return
      try {
        const response = await dispatch(
          getGroupUserByIdAction(+groupId)
        ).unwrap()
        if (response?.success) {
          const data = response?.data
          setSelectedUserEmail(data?.subscribers)
          setIsSelectAllUsers(!!data.isAll)
          setValue('name', data?.name)
          setValue('description', data?.description)
          setValue('status', data?.status)
          setValue(
            'emailSubscribers',
            data?.subscribers?.map((item) => item?.email)
            // { shouldDirty: true }
          )
        }
      } catch (error: any) {
        message.error(error && error?.message)
      }
    }
    if (isEdit) {
      getDetailGroup()
    }
  }, [groupId])

  return (
    <Card
      style={{
        padding: '0 64px',
        minHeight: '600px',
      }}
    >
      <Typography className="text-[24px] font-semibold w-full text-center text-black">
        {isEdit ? 'Edit group' : 'Create new group'}
      </Typography>

      {isGetGroupUserByIdLoading ? (
        <Skeleton
          paragraph={{ rows: 10 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <form onSubmit={handleSubmit(onSave)}>
          <Controller
            name="name"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  label="Group name"
                  required
                  onChange={onChange}
                  value={value}
                  errors={error?.message}
                  alignment="col"
                  selectMode="multiple"
                  containerClassName="mt-[24px]"
                />
              )
            }}
          />
          <Controller
            name={'description'}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <TextArea
                  label="Description"
                  onChange={onChange}
                  required
                  value={value}
                  style={{ marginTop: 10, width: '100%' }}
                  errors={error?.message}
                  containerClassName="mt-[24px]"
                  labelClassName="mb-[8px] inline-block"
                  autoSize={{ minRows: 4, maxRows: 8 }}
                />
              )
            }}
          />

          <Controller
            name="status"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <div className="flex mt-5  pb-5">
                  <p className="mb-0 mr-4">Status</p>
                  <SwitchButton
                    size="default"
                    disabled={false}
                    checked={value}
                    onChange={(e) => {
                      onChange(e)
                    }}
                  />
                </div>
              )
            }}
          />

          <div className="flex mt-5  pb-5">
            <p className="mb-0 mr-4">Choose all users</p>
            <SwitchButton
              size="default"
              disabled={false}
              checked={isSelectAllUsers}
              onChange={(e) => {
                // setIsSelectAllUsers(e)
                !isGetListEmailLoading && onSelectAllUser(e)
              }}
            />
          </div>
          {!isSelectAllUsers && (
            <>
              <div className="flex mb-2">
                <div>Choose users</div>
                <span className="required text-[#B91C1C] font-bold"> *</span>
              </div>
              <div
                className={`w-full cursor-pointer border rounded-md border-slate-200 px-3 py-2 shadow-slate-50 ${
                  errors?.emailSubscribers ? 'border-danger' : ''
                }`}
                onClick={() => {
                  setOpenSelectUserModal(true)
                }}
              >
                <span>
                  {!(selectedUserEmail?.length > 0)
                    ? 'Choose more users'
                    : `${selectedUserEmail?.length} users selected`}
                </span>
                <div
                  className="focus:rotate-180"
                  style={{
                    float: 'right',
                    marginTop: '3px',
                  }}
                >
                  <DownOutlined
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  />
                </div>
              </div>
              {errors?.emailSubscribers && (
                <div className="grid grid-cols-7 w-full ">
                  <div className="Input__text-error mt-2 text-sm col-span-7 sm:col-span-5 !text-danger">
                    {errors?.emailSubscribers?.message}
                  </div>
                </div>
              )}

              <List
                className="!mt-6"
                pagination={
                  selectedUserEmail?.length > 10 && {
                    pageSize: 10,
                  }
                }
                grid={{ gutter: 16, column: 2 }}
                dataSource={selectedUserEmail}
                renderItem={(item) => {
                  return (
                    <List.Item>
                      <div className="flex items-center justify-between w-full cursor-pointer hover:bg-slate-100 max-h-[300px]">
                        <div
                          className={`ml-3`}
                        >{`${item.name} - ${item.email}`}</div>

                        <MinusCircleOutlined
                          style={{
                            color: 'red',
                            fontSize: '20px',
                          }}
                          onClick={() => {
                            if (
                              isInListSubscribedEmail(selectedUserEmail, item)
                            ) {
                              setSelectedUserEmail((pre) => {
                                return pre.filter(
                                  (user) => user.email !== item?.email
                                )
                              })

                              setValue(
                                'emailSubscribers',
                                selectedUserEmail
                                  .filter(
                                    (itemInList) =>
                                      itemInList?.email !== item?.email
                                  )
                                  ?.map((item) => item?.email),
                                { shouldDirty: true }
                              )
                            } else {
                              setSelectedUserEmail((pre) => {
                                return [...pre, item]
                              })

                              setValue(
                                'emailSubscribers',
                                [...selectedUserEmail, item]?.map(
                                  (item) => item?.email
                                ),
                                { shouldDirty: true }
                              )
                            }
                          }}
                        />
                      </div>
                    </List.Item>
                  )
                }}
              />
            </>
          )}

          <div className="flex mt-10 pb-10 items-center gap-[40px] justify-center">
            <Button
              htmlType="submit"
              type="primary"
              size="middle"
              className="submit__btn login-btn"
              loading={isCreateGroupUserLoading}
              disabled={!isDirty || isCreateGroupUserLoading}
            >
              {'Save'}
            </Button>
          </div>
        </form>
      )}
      {openSelectUserModal && (
        <SelectUserModal
          onSelectedAll={onSelectedAll}
          isSelectedAll={isSelectedAll}
          onChangeFilter={onChangeFilter}
          filtered={filtered}
          open={openSelectUserModal}
          onClose={onCloseSelectUserModal}
          data={selectedUserEmail}
          onSave={(data) => {
            setSelectedUserEmail(data)
            setValue(
              'emailSubscribers',
              data.map((item) => item?.email),
              { shouldDirty: true }
            )
          }}
          isEdit={isEdit}
        />
      )}
    </Card>
  )
}

export default CreateEditGroupPage
