import { lessonAPI } from '@api'
import { zodResolver } from '@hookform/resolvers/zod'
import { IAssignment } from '@interfaces'
import { message, Skeleton } from 'antd'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { Button, Input, TextArea } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import { AppModal } from 'src/components/AppModal'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'
import { XCloseIcon } from 'src/components/Icon'
import { ELessonType } from 'src/interfaces/course'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

type Props = {
  open: boolean
  isLoading?: boolean
  onClose: () => void
  onSave: () => void
  assignmentId?: number
  selectSection?: number
  isActive?: boolean
  isViewMode?: boolean
  type?: ELessonType.AUDIO | ELessonType.VIDEO
}

const schema = z.object({
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })

    .max(150, {
      message: 'Title must be less than 150 characters long',
    })
    .min(2, {
      message: 'Title must be more than 2 characters long',
    }),
  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })

    .max(150, {
      message: 'Title must be less than 150 characters long',
    })
    .min(2, {
      message: 'Title must be more than 2 characters long',
    }),
  content: z
    .string()
    .trim()
    // .nonempty({
    //   message: t('error:field_required') as string,
    // })
    .max(1000, {
      message: 'Content must be less than 1000 characters long',
    }),
  // .min(2, {
  //   message: 'Content must be more than 2 characters long',
  // })
  contentInDutch: z
    .string()
    .trim()
    // .nonempty({
    //   message: t('error:field_required') as string,
    // })
    .max(1000, {
      message: 'Content must be less than 1000 characters long',
    }),
  // .min(2, {
  //   message: 'Content must be more than 2 characters long',
  // })
  mediaId: z
    .number({
      required_error: t('error:field_required') as string,
    })
    .min(1, {
      message: t('error:field_required') as string,
    }),

  attachmentIds: z.array(z.number()).optional(),
  restrictTimer: z.number().nullable(),
  thumbnailId: z
    .number({
      required_error: t('error:field_required') as string,
    })
    .min(1, {
      message: t('error:field_required') as string,
    }),
})
const ModalMedia = ({
  open,
  onClose,
  assignmentId,
  selectSection,
  onSave,
  type,
  isActive = true,
  isViewMode,
}: Props) => {
  const DEFAULT_VALUE = {
    title: '',
    content: '',
    titleInDutch: '',
    mediaId: 0,
    thumbnailId: 0,
    restrictTimer: 0,
    contentInDutch: '',
    attachmentIds: [],
  }
  const { control, setValue, reset } = useForm<any>({
    defaultValues: DEFAULT_VALUE,
    resolver: zodResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(false)

  const [videoSrc, setVideoSrc] = useState('')

  const [imageSrc, setImageSrc] = useState('')
  const [showModalSelectVideoMedia, setShowModalSelectVideoMedia] =
    useState(false)
  const [showModalSelectImageMedia, setShowModalSelectImageMedia] =
    useState(false)
  const onCloseModalSelectImageMedia = () => {
    setShowModalSelectImageMedia(false)
  }
  const [aborted, setAborted] = useState(false)
  const onSelectImageMedia = (data: IMediaItem) => {
    setImageSrc(data?.baseUrl ?? '')
    setValue('thumbnailId', data?.id ?? 0)
    setShowModalSelectImageMedia(false)
  }
  const onCloseModalSelectVideoMedia = () => {
    setShowModalSelectVideoMedia(false)
  }
  const fetchLesson = async () => {
    if (!assignmentId) {
      return
    }
    setIsFetching(true)
    try {
      const response = await lessonAPI.getAssignment(assignmentId)
      if (response?.success) {
        const { data } = response
        setValue('id', data.id || '')
        setValue('title', data.title || '')
        setValue('content', data.content || '')
        setValue('titleInDutch', data.titleInDutch || '')
        setValue('thumbnailId', data.thumbnailId || '')
        setImageSrc(data.thumbnail?.url || '')
        setVideoSrc(data.media?.url || '')
        setValue('mediaId', data.mediaId || '')
        setValue('contentInDutch', data.contentInDutch || '')
        setValue(
          'attachments',
          data.lessonAttachment.map((data) => data.attachment) || []
        )
        setValue('restrictTimer', data.restrictTimer)
      }
    } catch (error) {
      console.log('error', error)
      message.error(t('error:error_connect_server'))
    } finally {
      setIsFetching(false)
    }
  }
  const onSubmit = async (payload: IAssignment) => {
    setIsLoading(true)
    if (!selectSection) {
      setIsLoading(false)
      return
    }
    if (payload.type === ELessonType.ASSIGNMENT_EXERCISE) {
      delete payload.questions
      delete payload.restrictTimer
    }
    try {
      if (!assignmentId) {
        payload.sectionId = +selectSection
        const response = await lessonAPI.createMediaLesson({
          ...payload,
          type: type === ELessonType.AUDIO ? 'AUDIO' : 'VIDEO',
        })

        message.success(response.message)
      } else {
        const response = await lessonAPI.updateMediaLesson(assignmentId, {
          ...payload,
          type: type === ELessonType.AUDIO ? 'AUDIO' : 'VIDEO',
        })
        message.success(response.message)
      }
    } catch (error) {
      message.error(t('error:error_connect_server'))
    } finally {
      setIsLoading(false)
      onSave()
      reset()
      onClose()
    }
  }
  const onSelectVideoMedia = (data: IMediaItem) => {
    setVideoSrc(data?.baseUrl ?? '')
    setValue('mediaId', data?.id ?? 0)
    setShowModalSelectVideoMedia(false)
  }
  useEffect(() => {
    if (assignmentId) {
      return
    }
    setValue('attachmentIds', [])
    return () => {
      reset()
    }
  }, [open, setValue, reset])

  useEffect(() => {
    if (open) {
      fetchLesson()
    }
  }, [assignmentId, open])

  return (
    <>
      <TextAreaStyled>
        <AppModal
          open={open}
          onClose={() => {
            if (isLoading) return
            onClose()
          }}
          title={
            <div className="flex items-center justify-between  w-full">
              <div>
                <h1 className="m-0 text-[20px]">Lesson detail</h1>
              </div>
              <div className="hover:opacity-75 cursor-pointer">
                {!isLoading && (
                  <XCloseIcon width={16} height={16} onClick={onClose} />
                )}
              </div>
            </div>
          }
        >
          {isFetching ? (
            <Skeleton paragraph={{ rows: 10 }} />
          ) : (
            <div className="mt-6 w-[1200px]">
              <form>
                <div className="flex flex-row  gap-5">
                  <Controller
                    name="title"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <Input
                          alignment="col"
                          label={'Title'}
                          name="title"
                          required
                          className="input"
                          value={value}
                          type="text"
                          onChange={onChange}
                          errors={error?.message}
                          disabled={isViewMode}
                        />
                      )
                    }}
                  />
                  <Controller
                    name="titleInDutch"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <Input
                          alignment="col"
                          label={'Title in Netherlands'}
                          name="titleInDutch"
                          required
                          className="input"
                          value={value}
                          type="text"
                          onChange={onChange}
                          disabled={isViewMode}
                          errors={error?.message}
                        />
                      )
                    }}
                  />
                </div>

                <div className="flex flex-row  gap-5">
                  <Controller
                    name="content"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextArea
                          label="Content"
                          onChange={onChange}
                          value={value}
                          name="content"
                          style={{ marginTop: 10, width: '100%' }}
                          errors={error?.message}
                          containerClassName="mt-[24px]"
                          labelClassName="mb-[16px] inline-block"
                          disabled={isViewMode}
                          autoSize={{ minRows: 4, maxRows: 16 }}
                        />
                      )
                    }}
                  />
                  <Controller
                    name="contentInDutch"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextArea
                          label="Content in Netherlands"
                          onChange={onChange}
                          value={value}
                          name="contentInDutch"
                          style={{ marginTop: 10, width: '100%' }}
                          errors={error?.message}
                          containerClassName="mt-[24px]"
                          labelClassName="mb-[16px] inline-block"
                          disabled={isViewMode}
                          autoSize={{ minRows: 4, maxRows: 16 }}
                        />
                      )
                    }}
                  />
                </div>

                <div className=" mt-5 flex flex-row  gap-5">
                  <div className="w-full">
                    <label className="input__label  mb-[16px] inline-block">
                      Thumbnail
                      <span className="required text-[#B91C1C] font-bold">
                        *
                      </span>
                    </label>
                    <Controller
                      name={'thumbnailId'}
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <>
                            <CustomDragger
                              containerClassName="mt-[10px]"
                              onLoadEnd={(data) => {
                                setValue('thumbnailId', data?.id ?? 0)
                                onChange(data?.id ?? 0)
                              }}
                              name="thumbnailId"
                              id="thumbnailId"
                              required
                              errors={error?.message}
                              multiple={false}
                              initResource={imageSrc}
                              changeLoading={(loading) => setIsLoading(loading)}
                              allowFileTypes={[
                                'image/png',
                                'image/jpeg',
                                'image/jpg',
                                'image/*',
                              ]}
                              uploadType={EMediaType.IMAGE as any}
                              uploadCategory={EMediaCategory.COURSE}
                              disabled={isViewMode}
                            />
                          </>
                        )
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <label className="input__label  mb-[16px] inline-block">
                      {type === ELessonType.AUDIO ? 'Audio' : 'Video'}
                      <span className="required text-[#B91C1C] font-bold">
                        *
                      </span>
                    </label>
                    <Controller
                      name={'mediaId'}
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <CustomDragger
                            containerClassName="mt-[10px]"
                            onLoadEnd={(data) => {
                              setValue('mediaId', data?.dataValues.id)
                              onChange(data?.dataValues.id)
                              // setIsChange(true)
                            }}
                            aborted={aborted}
                            onSetDuration={(duration) => {
                              setValue('restrictTimer', duration)
                            }}
                            id="mediaId"
                            name="mediaId"
                            required
                            errors={error?.message}
                            multiple={false}
                            initResource={videoSrc}
                            changeLoading={(loading) => setIsLoading(loading)}
                            allowFileTypes={
                              type === ELessonType.AUDIO
                                ? [
                                    'audio/mp3,audio/ogg,audio/WAV,audio/wav',
                                    'audio/mpeg3',
                                  ]
                                : [
                                    'video/mp4',
                                    'video/webm',
                                    'video/m4v',
                                    'video/mov',
                                    'video/qt',
                                  ]
                            }
                            uploadType={
                              type === ELessonType.AUDIO
                                ? EMediaType.AUDIO
                                : (EMediaType.VIDEO as any)
                            }
                            disabled={isViewMode}
                          />
                        )
                      }}
                    />
                  </div>
                </div>

                <div className="mt-10">
                  <div className="flex items-center mt-5 justify-center">
                    <Button
                      type="ghost"
                      size="middle"
                      className="submit__btn login-btn mr-10"
                      onClick={() => {
                        if (isLoading) {
                          setAborted(true)
                        }
                        onClose()
                        reset()
                      }}
                    >
                      {'Cancel'}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </AppModal>
      </TextAreaStyled>
      <>
        <SelectMediaModal
          isOpen={showModalSelectImageMedia}
          onClose={onCloseModalSelectImageMedia}
          mediaType={EMediaType.IMAGE}
          category={EMediaCategory.COURSE}
          onSelectMedia={onSelectImageMedia}
        />
      </>
    </>
  )
}

const TextAreaStyled = styled.div`
  .display-block {
    display: block !important;
  }
  .upload-video {
    display: none !important;
  }
`
export default ModalMedia
