import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Tabs } from 'antd'
import styled from 'styled-components'

import { TEACHER_PACKAGE_MANAGEMENT, USER_PACKAGE_MANAGEMENT } from '@configs'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { Text } from 'src/common'
import UserPackageManagementPage from 'src/pages/package-management/user-package-management/UserPackageManagementPage'
import TeacherPackageManagementPage from 'src/pages/package-management/teacher-package-management/TeacherPackageManagementPage'

export const PackageManagementPage = () => {
  const location = useLocation()
  const [size, setSize] = useState<SizeType>('small')
  const [activeTab, setActiveTab] = useState<string>(USER_PACKAGE_MANAGEMENT)

  const items = [
    {
      label: <Text>User</Text>,
      key: USER_PACKAGE_MANAGEMENT,
      children: <UserPackageManagementPage />,
    },

    {
      label: <Text>Teacher</Text>,
      key: TEACHER_PACKAGE_MANAGEMENT,
      children: <TeacherPackageManagementPage />,
    },
  ]

  return (
    <HomePageStyled>
      <Tabs
        destroyInactiveTabPane={true}
        defaultActiveKey="1"
        type="card"
        size={size}
        items={items}
        defaultChecked={true}
        activeKey={activeTab}
        onChange={(e) => {
          setActiveTab(e)
        }}
      />
    </HomePageStyled>
  )
}

const HomePageStyled = styled('div')(() => {
  return {
    '.ant-tabs-nav': {
      marginBottom: 0,
      paddingLeft: '1.25rem',

      '& .ant-tabs-tab': {
        borderRadius: '0.75rem 0.75rem 0 0 !important',

        '&.ant-tabs-tab-active': {
          span: {
            color: '#009BB7',
            fontWeight: '500',
          },
        },
      },
    },
  }
})
