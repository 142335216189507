import { useAppSelector } from '@redux'
import { SetStateAction, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FORM_FIELDS } from './config'
import { PopoverPopup } from 'src/components/AppPopover'
import styled from 'styled-components'
import { Checkbox, Typography } from 'antd'
import { Button } from 'src/common'
import { CaretDownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

interface IRoleManagementActionProps {
  setFilterByRole: (value: SetStateAction<string[]>) => void
  onOpenCreateRoleDialog: () => void
}

interface IFormData {
  filterByRole: Record<string, boolean>
}

export default function RoleManagementButtonAction({
  setFilterByRole,
  onOpenCreateRoleDialog,
}: IRoleManagementActionProps) {
  const { t } = useTranslation(['common', 'staffAndRole'])
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const { data } = useAppSelector((state) => state.role.listPermissionRoles)
  const { listRoles } = data || {}

  const listRoleFromData = listRoles.map((role) => role.name)

  const methods = useForm<IFormData>({
    defaultValues: {
      [FORM_FIELDS.FILTER_BY_ROLE]: null,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const { control, reset, handleSubmit } = methods

  const onSubmit = handleSubmit((data: any) => {
    if (!Object.keys(data?.[FORM_FIELDS.FILTER_BY_ROLE])?.length) return
    const listSelectedRole = Object.entries(data?.[FORM_FIELDS.FILTER_BY_ROLE])
      .reduce((acc, cur) => {
        if (!cur?.[1]) return acc

        return [].concat(acc).concat(cur?.[0] as any)
      }, [])
      .filter((roleName) => listRoleFromData.includes(roleName))

    setFilterByRole(listSelectedRole)
    setIsOpenFilter(false)
  })

  return (
    <div className="flex justify-end items-center my-2 gap-2 absolute top-[-72px] right-0">
      <PopoverPopup
        open={isOpenFilter}
        onOpenChange={(open: boolean) => setIsOpenFilter(open)}
        content={
          <form
            onSubmit={(e) => {
              e.preventDefault()
              e.stopPropagation()
              onSubmit()
            }}
          >
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-2 max-h-[200px] overflow-y-auto">
                {listRoles?.map((role, idx) => {
                  return (
                    <RoleFilterItemStyled key={role?.name ?? idx}>
                      <Controller
                        name={`filterByRole.${role?.name}`}
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <Checkbox
                              checked={value}
                              onChange={(e) => {
                                onChange(e.target.checked)
                              }}
                            >
                              <Typography>{role?.name}</Typography>
                            </Checkbox>
                          )
                        }}
                      />
                    </RoleFilterItemStyled>
                  )
                })}
              </div>

              <div className="flex justify-between items-center my-2 gap-2">
                <Button
                  type="primary"
                  size="small"
                  style={{
                    backgroundColor: '#ff0000',
                    borderColor: '#ff0000',
                  }}
                  onClick={() => reset()}
                >
                  {t('common:clear_filter')}
                </Button>

                <Button type="primary" size="small" htmlType="submit">
                  {t('common:apply')}
                </Button>
              </div>
            </div>
          </form>
        }
        placement="bottomRight"
      >
        <Button
          icon={<CaretDownOutlined />}
          iconPosition="start"
          type="primary"
        >
          {t('staffAndRole:filter_by_role')}
        </Button>
      </PopoverPopup>

      <Button type="primary" onClick={onOpenCreateRoleDialog}>
        {t('staffAndRole:new_role')}
      </Button>
    </div>
  )
}

const RoleFilterItemStyled = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
  cursor: 'pointer',
  borderRadius: 6,
  padding: '0',
  minWidth: 150,
  maxWidth: 250,

  '& > span': {
    fontSize: 16,
  },
}))
