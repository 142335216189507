import { Typography } from 'antd'
import { format } from 'date-fns'
import { Trans } from 'react-i18next'
import {
  ECourseRequestStatus,
  ICourseRequestStatus,
} from 'src/interfaces/course-request-management'

export const SEARCH_FORM_FIELDS = {
  SEARCH: 'search',
  DATE_RANGE: 'dateRange',
  REQUEST_STATUS: 'requestStatus',
}

export const SEARCH_FORM_FIELD_DEFAULT_VALUES = {
  [SEARCH_FORM_FIELDS.SEARCH]: '',
  [SEARCH_FORM_FIELDS.DATE_RANGE]: null,
  [SEARCH_FORM_FIELDS.REQUEST_STATUS]: [],
}

export const EMPTY_VALUE = 'N/A'

export const RequestStatusObj: Record<
  ECourseRequestStatus,
  ICourseRequestStatus
> = {
  [ECourseRequestStatus.ACCEPTED]: {
    color: '#1DD75BFF',
    label: <Trans i18nKey={'courseRequest:accepted' as any} />,
  },
  [ECourseRequestStatus.PENDING]: {
    color: '#FE763EFF',
    label: <Trans i18nKey={'courseRequest:pending' as any} />,
  },
  [ECourseRequestStatus.REJECTED]: {
    color: '#DE3B40FF',
    label: <Trans i18nKey={'courseRequest:rejected' as any} />,
  },
}

export const REQUEST_STATUS_OPTIONS = Object.entries(ECourseRequestStatus).map(
  (it) => {
    return {
      label: RequestStatusObj?.[it[0] as ECourseRequestStatus]
        ?.label as React.ReactElement,
      value: it[1],
    }
  }
)

export const REASON_CHARACTER_LIMITATION = {
  MAX_LENGTH: 500,
  MIN_LENGTH: 2,
}

export enum EMiniDashBoardColor {
  LIGHT_BLUE = '#C7F9EE',
  LIGHT_PINK = '#FCEAE6',
  LIGHT_YELLOW = '#F7F4BF',
  LIGHT_PURPLE = '#F1F2FDFF',
  BLUE = '#6DFOO2',
  PINK = '#F0A58F',
  YELLOW = '#E7E34E',
}

export const TABLE_COLUMNS = {
  NO: {
    title: 'courseRequest:no',
    dataIndex: 'index',
    key: 'index',
    align: 'center',
    width: '10%',
  },
  COURSE_NAME: {
    title: 'courseRequest:courseName',
    dataIndex: 'courseName',
    key: 'index',
    align: 'center',
    width: '20%',
    render: (_: any, record: any) => {
      const { courseName } = record || {}
      return (
        <Typography
          style={{
            maxWidth: 280,
            textWrap: 'wrap',
          }}
        >
          {courseName || EMPTY_VALUE}
        </Typography>
      )
    },
  },
  TEACHER_NAME: {
    title: 'courseRequest:teacherName',
    dataIndex: 'teacherName',
    key: 'index',
    align: 'center',
    width: '15%',
    render: (_: any, record: any) => {
      const { teacherName } = record || {}
      return (
        <Typography
          style={{
            maxWidth: 280,
            textWrap: 'wrap',
          }}
        >
          {teacherName || EMPTY_VALUE}
        </Typography>
      )
    },
  },
  REQUESTED_DATE: {
    title: 'courseRequest:requestedDate',
    dataIndex: 'requestedDate',
    key: 'requestedDate',
    align: 'center',
    width: '20%',
    render: (_: any, record: any) => {
      const { requestedDate } = record || {}
      return (
        <div className="flex items-center justify-center h-full w-full gap-2">
          <Typography>
            {requestedDate
              ? format(new Date(requestedDate), 'dd/MM/yyyy')
              : EMPTY_VALUE}
          </Typography>
        </div>
      )
    },
  },
  RESPONSE_DATE: {
    title: 'courseRequest:responseDate',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    align: 'center',
    width: '20%',
    render: (_: any, record: any) => {
      const { updatedAt } = record || {}
      return (
        <div className="flex items-center justify-center h-full w-full gap-2">
          <Typography>
            {updatedAt
              ? format(new Date(updatedAt), 'dd/MM/yyyy')
              : EMPTY_VALUE}
          </Typography>
        </div>
      )
    },
  },
  RESPONSER_NAME: {
    title: 'courseRequest:responserName',
    dataIndex: 'responserName',
    key: 'responserName',
    align: 'center',
    width: '20%',
    render: (_: any, record: any) => {
      const { responseData } = record || {}
      return (
        <div className="flex items-center justify-center h-full w-full gap-2">
          <Typography
            style={{
              maxWidth: 280,
              textWrap: 'wrap',
            }}
          >
            {responseData?.updatedBy?.name || EMPTY_VALUE}
          </Typography>
        </div>
      )
    },
  },
  REQUEST_STATUS: {
    title: 'courseRequest:status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    width: '10%',
    render: (_: any, record: any) => {
      const { status } = record || {}
      return (
        <div className="flex items-center justify-center h-full w-full gap-2">
          <Typography
            style={{
              color:
                RequestStatusObj?.[status as ECourseRequestStatus]?.color ||
                '#000000',
            }}
          >
            {RequestStatusObj?.[status as ECourseRequestStatus]?.label ||
              EMPTY_VALUE}
          </Typography>
        </div>
      )
    },
  },
  COURSE_REQUEST_ACTION: {
    title: '',
    dataIndex: 'courseRequestAction',
    key: 'courseRequestAction',
    align: 'center',
    width: '5%',
  },
}
