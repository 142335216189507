import { Typography } from 'antd'
import styled from 'styled-components'
import { t } from 'i18next'
import { z } from 'zod'

import { FORMAT_DATE_TABLE, FORMAT_TIME_LONG } from '@configs'
import { formatDate } from '@utils'
import { format } from 'currency-formatter'

export enum EPackageStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ALL = 'all',
}

export enum EPackageStatusLabel {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  ALL = 'All status',
}

export enum ETeacherPackageAction {
  VIEW = 'view',
  EDIT = 'edit',
  DELETE = 'delete',
}

export const TEACHER_PACKAGE_STATUS_OPTIONS = [
  {
    label: EPackageStatusLabel.ALL,
    value: EPackageStatus.ALL,
  },
  {
    label: EPackageStatusLabel.ACTIVE,
    value: EPackageStatus.ACTIVE,
  },
  {
    label: EPackageStatusLabel.INACTIVE,
    value: EPackageStatus.INACTIVE,
  },
]

export enum enumTableTeacherPackage {
  NO = 'No',
  NAME = 'Name',
  PRICE_EUR = 'Price(EUR)',
  PRICE_USD = 'Price(USD)',
  CREATE_TIME = 'Creation time',
  MODIFICATION_TIME = 'Modification time',
  STATUS = 'Status',
  ACTION = 'Action',
}

export const TEACHER_PACKAGE_COLUMN = [
  {
    width: '5%',
    title: enumTableTeacherPackage.NO,
    dataIndex: 'index',
    key: 'index',
    ellipsis: true,
    align: 'center',
  },
  {
    width: '15%',
    key: 'id',
    dataIndex: 'name',
    ellipsis: true,
    title: () => {
      return (
        <div className="flex items-center justify-start w-full">
          <p className="m-0">{enumTableTeacherPackage.NAME}</p>
        </div>
      )
    },
    render: (e: string) => {
      return (
        <div className="flex items-center max-w-[460px]">
          <Typography.Text
            style={{
              width: 400,
              whiteSpace: 'nowrap',
            }}
            ellipsis={true}
          >
            {e}
          </Typography.Text>
        </div>
      )
    },
  },
  {
    width: '5%',
    key: 'priceEur',
    dataIndex: 'priceEur',
    align: 'center',
    ellipsis: true,
    title: () => {
      return (
        <span className="flex flex-wrap items-center justify-center max-w-[100px] ">
          {enumTableTeacherPackage.PRICE_EUR}
        </span>
      )
    },
    render: (e: number) => {
      return (
        <div className="flex flex-col items-center justify-center">
          {format(e, { code: '' }).replace('.00', '')}
        </div>
      )
    },
  },
  {
    width: '5%',
    key: 'priceUsd',
    dataIndex: 'priceUsd',
    align: 'center',
    ellipsis: true,
    title: () => {
      return (
        <span className="flex flex-wrap items-center justify-center max-w-[100px] ">
          {enumTableTeacherPackage.PRICE_USD}
        </span>
      )
    },
    render: (e: number) => {
      return (
        <div className="flex flex-col items-center justify-center">
          {format(e, { code: '' }).replace('.00', '')}
        </div>
      )
    },
  },
  {
    width: '10%',
    dataIndex: 'createdAt',
    key: 'createdAt',
    ellipsis: true,
    title: () => {
      return (
        <div className="flex items-center justify-center w-full">
          <p className="m-0">{enumTableTeacherPackage.CREATE_TIME}</p>
        </div>
      )
    },
    render: (e: string) => {
      return (
        <div className="flex flex-col items-center justify-center">
          <p className="!mb-0 !pb-0">
            {e ? formatDate(e, FORMAT_TIME_LONG) : null}
          </p>
          <p className="!mb-0 !pb-0">
            {e ? formatDate(e, FORMAT_DATE_TABLE) : null}
          </p>
        </div>
      )
    },
  },
  {
    width: '10%',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    ellipsis: true,
    title: () => {
      return (
        <div className="flex items-center justify-center w-full">
          <p className="m-0">{enumTableTeacherPackage.MODIFICATION_TIME}</p>
        </div>
      )
    },
    render: (e: string) => {
      return (
        <div className="flex flex-col items-center justify-center">
          {!!e ? (
            <>
              <p className="!mb-0 !pb-0">{formatDate(e, FORMAT_TIME_LONG)}</p>
              <p className="!mb-0 !pb-0">{formatDate(e, FORMAT_DATE_TABLE)}</p>
            </>
          ) : (
            'N/A'
          )}
        </div>
      )
    },
  },
  {
    width: '5%',
    dataIndex: 'isActive',
    key: 'isActive',
    ellipsis: true,
    title: () => {
      return (
        <div className="flex items-center justify-center w-full">
          <p className="m-0">{enumTableTeacherPackage.STATUS}</p>
        </div>
      )
    },
    render: (e: boolean) => (
      <StatusText $isActive={e}>{e ? 'Active' : 'Inactive'}</StatusText>
    ),
  },
  {
    width: '5%',
    key: ETeacherPackageAction.EDIT,
    dataIndex: 'action',
    ellipsis: true,
    align: 'center',
  },
  {
    width: '5%',
    key: ETeacherPackageAction.DELETE,
    dataIndex: 'action',
    ellipsis: true,
    align: 'center',
  },
]

const StatusText = styled.span<{ $isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${(props) => (props.$isActive ? '#19ba8c' : '#DE3B40')};
`

export const deleteTeacherPackageSchema = z.object({
  selectPackageId: z.number().min(1, {
    message: t('error:field_required') as string,
  }),
})

export const InputClassName =
  'disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]'

const courseSchema = z.object({
  isPublicPremiumLimit: z.boolean(),
  premiumLimit: z
    .number()
    .int({
      message: t('teacher:must_be_integer'),
    })
    .min(1, { message: t('teacher:range_1_99') })
    .max(99, {
      message: t('teacher:range_1_99'),
    })
    .nullable()
    .optional(),
  isPublicMemberLimit: z.boolean(),
  memberLimit: z
    .number()
    .int({
      message: t('teacher:must_be_integer'),
    })
    .min(1, { message: t('teacher:range_1_99') })
    .max(99, {
      message: t('teacher:range_1_99'),
    })
    .nullable()
    .optional(),
})

const revenueSchema = z.object({
  defaultRate: z
    .number()
    .int({
      message: t('teacher:must_be_integer'),
    })
    .min(0, { message: t('teacher:must_be_postive_number') })
    .max(99, {
      message: t('teacher:range_0_99'),
    })
    .nullable(),
  memberCourse: z.array(
    z.object({
      rate: z
        .number()
        .int({
          message: t('teacher:must_be_integer'),
        })
        .min(0, { message: t('teacher:must_be_postive_number') })
        .max(99, {
          message: t('teacher:range_0_99'),
        })
        .nullable(),
      packageId: z.number(),
    })
  ),
  premiumRate: z
    .number()
    .int({
      message: t('teacher:must_be_integer'),
    })
    .min(0, { message: t('teacher:must_be_postive_number') })
    .max(99, {
      message: t('teacher:range_0_99'),
    })
    .nullable(),
})

export const teacherPackageSchema = z.object({
  name: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .min(2, {
      message: t('teacher:min_name_2') as string,
    })
    .max(100, {
      message: t('teacher:max_name_100') as string,
    }),
  nameInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .min(2, {
      message: t('teacher:min_name_2') as string,
    })
    .max(100, {
      message: t('teacher:max_name_100') as string,
    }),
  priceEur: z
    .number({
      required_error: t('error:field_required') as string,
    })
    .min(0.5, {
      message: t('teacher:min_price_is_0.5') as string,
    }),
  priceUsd: z
    .number({
      required_error: t('error:field_required') as string,
    })
    .min(0.5, {
      message: t('teacher:min_price_is_0.5') as string,
    }),
  mediaId: z
    .number({
      required_error: t('error:field_required') as string,
    })
    .nullable()
    .refine((value) => !!value, {
      message: t('error:field_required') as string,
    }),
  textColor: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    }),
  accessModule: z.object({
    course: courseSchema
      .refine(
        (data) => {
          return !(
            data.isPublicPremiumLimit &&
            (!data.premiumLimit || data?.premiumLimit <= 0)
          )
        },
        {
          message: t('error:field_required') as string,
          path: ['premiumLimit'],
        }
      )
      .refine(
        (data) => {
          return !(
            data.isPublicMemberLimit &&
            (!data.memberLimit || data?.memberLimit <= 0)
          )
        },
        {
          message: t('error:field_required') as string,
          path: ['memberLimit'],
        }
      )
      .refine((data) => !(!data.premiumLimit && !data.memberLimit), {
        message: t('teacher:require_at_least_1_field'),
        path: ['premiumLimit'],
      })
      .refine((data) => !(!data.premiumLimit && !data.memberLimit), {
        message: t('teacher:require_at_least_1_field'),
        path: ['memberLimit'],
      }),
    revenue: revenueSchema,
  }),
  isActive: z.boolean({
    required_error: t('teacher:field_required') as string,
  }),
})
