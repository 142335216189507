export const ALLOW_FILE_DEGREE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'application/pdf',
]

export const DEGREE_MAX_FILE_SIZE = 5

export const DEGREE_FILE_TYPES = {
  IMAGE: 'image',
  PDF: 'pdf',
}
