import { zodResolver } from '@hookform/resolvers/zod'
import { useAppDispatch, useAppSelector } from '@redux'
import { Skeleton, message } from 'antd'
import { ReactNode, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button, Input, SwitchButton, TextArea } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import {
  EUploadFileType,
  PATH_DAILY_ROUTINE_JOBS_DETAIL,
  thunkActionLoading,
} from 'src/configs'
import { IChallengeItem, TJobForm } from 'src/interfaces/habit-management'
import {
  getJobDetailDailyRoutineAction,
  postJobDetailDailyRoutineAction,
  putJobDetailDailyRoutineAction,
} from 'src/redux/actions/habit-management'
import styled from 'styled-components'
import { z } from 'zod'

type ChallengeHabitModalProps = {
  isLoading: boolean
  title?: string
  content?: ReactNode
  item?: IChallengeItem | null
  changeLoading?: (value: boolean) => void
  noDefault: number
}

const jobSchema = z.object({
  title: z
    .string()
    .trim()
    .nonempty('This is a required field')
    .max(35, 'Title should not excess 35 characters'),
  titleInDutch: z
    .string()
    .trim()
    .nonempty('This is a required field')
    .max(50, 'Title in Netherland should not excess 50 characters'),
  mediaId: z.number().nonnegative('This is a required field'),
  description: z
    .string()
    .trim()
    .nonempty('This is a required field')
    .max(50, 'Description should not excess 50 characters'),
  descriptionInDutch: z
    .string()
    .trim()
    .nonempty('This is a required field')
    .max(50, 'Description in Netherland should not excess 50 characters'),
  dailyRoutineId: z.number().optional(),
  status: z.boolean().optional(),
})
const JobDetail = ({ changeLoading }: ChallengeHabitModalProps) => {
  const params = useParams<any>()

  const { jobAction, routineId } = params

  const { state } = useLocation()

  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [defaultStatus, setDefaultStatus] = useState(false)
  const [total, setTotal] = useState(0)
  const [imageSource, setImageSource] = useState<string | null>(null)

  const isPutDetailJobRoutineLoading = useAppSelector(
    (state) =>
      state.habit?.loadings[
        thunkActionLoading.PUT_JOB_DETAIL_DAILY_ROUTINE_LOADING
      ]
  )

  const defaultValue: any = {
    title: '',
    titleInDutch: '',
    mediaId: -1,
    description: '',
    descriptionInDutch: '',
    status: false,
    position: total || 1,
  }
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isValid, isDirty },
  } = useForm<TJobForm>({
    defaultValues: defaultValue,
    resolver: zodResolver(jobSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onSave = async (data: TJobForm) => {
    if (!routineId) {
      return
    }
    try {
      let response: any
      setIsLoading(true)
      if (jobAction && jobAction !== 'create') {
        response = await dispatch(
          putJobDetailDailyRoutineAction({
            ...data,
            id: jobAction,
          })
        ).unwrap()
      } else {
        response = await dispatch(
          postJobDetailDailyRoutineAction({
            ...data,
            dailyRoutineId: +(routineId ?? 0),
          })
        ).unwrap()
      }
      if (response?.success) message.success(response.message)
      navigate(
        PATH_DAILY_ROUTINE_JOBS_DETAIL.replace(':routineId', routineId).replace(
          '/:jobAction',
          ''
        )
      )
    } catch (error: any) {
      message.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const getJobDetail = async () => {
    if (!jobAction || jobAction === 'create') {
      return
    }
    setIsLoading(true)
    try {
      const response = await dispatch(
        getJobDetailDailyRoutineAction(jobAction)
      ).unwrap()

      setValue('title', response.data?.title ?? '')
      setValue('titleInDutch', response.data?.titleInDutch ?? '')
      setValue('mediaId', response.data?.mediaId ?? 0)
      setValue('description', response.data?.description ?? '')
      setValue('descriptionInDutch', response.data?.descriptionInDutch ?? '')
      setValue('status', response.data?.status ?? false)
      setDefaultStatus(response.data?.status ?? false)
      setImageSource(response.data.media.url)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getJobDetail()
  }, [routineId, jobAction])

  return (
    <SingleBlogStyled className="px-20 bg-white">
      <div className="flex items-center justify-center ">
        <div>
          <h1 className="mb-5 mt-5 text-[32px] text-center ">
            {jobAction && jobAction !== 'create'
              ? 'Job detail'
              : 'Create new job'}
          </h1>
        </div>
      </div>
      <div className="mt-6">
        {isLoading ? (
          <Skeleton
            paragraph={{ rows: 4 }}
            style={{
              marginTop: 10,
            }}
          />
        ) : (
          <form className="gap-5 flex flex-col">
            <Controller
              name="title"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label={`Title EN`}
                    name="title"
                    required
                    onChange={onChange}
                    value={value ? value.toString() : ''}
                    errors={error?.message}
                    alignment="col"
                    disabled={state?.isDisabled}
                    isViewMode={state?.viewOnly}
                  />
                )
              }}
            />
            <Controller
              name="titleInDutch"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label={`Title NL`}
                    name="titleInDutch"
                    required
                    onChange={onChange}
                    value={value ? value.toString() : ''}
                    errors={error?.message}
                    alignment="col"
                    disabled={state?.isDisabled}
                    isViewMode={state?.viewOnly}
                  />
                )
              }}
            />
            <Controller
              name="description"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <TextArea
                    label="Description EN"
                    name="description"
                    required
                    onChange={onChange}
                    value={value ?? ''}
                    errors={error?.message}
                    disabled={state?.isDisabled}
                    isViewMode={state?.viewOnly}
                  />
                )
              }}
            />
            <Controller
              name="descriptionInDutch"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <TextArea
                    label="Description NL"
                    name="description"
                    required
                    onChange={onChange}
                    value={value ?? ''}
                    errors={error?.message}
                    disabled={state?.isDisabled}
                    isViewMode={state?.viewOnly}
                  />
                )
              }}
            />
            <Controller
              name="mediaId"
              control={control}
              render={({ field: { onChange }, fieldState: { error } }) => {
                return (
                  <CustomDragger
                    label={'Update photo'}
                    name="mediaId"
                    id="mediaId"
                    required
                    containerClassName="w-full"
                    onLoadEnd={(data) => {
                      setValue('mediaId', data?.id ?? 0, {
                        shouldDirty: true,
                      })
                      clearErrors('mediaId')
                      setImageSource(data?.url ?? null)
                    }}
                    errors={error?.message}
                    initResource={imageSource as string}
                    changeLoading={(loading) => {
                      //
                    }}
                    // alignment="row"
                    allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                    limitFileSize={5}
                    labelClassName="w-full"
                    uploadType={EUploadFileType.IMAGE}
                    disabled={state?.viewOnly}
                  />
                )
              }}
            />
            {!state?.viewOnly && (
              <Controller
                name="status"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <div className="grid  gap-3 mt-4">
                      <div className="col-span-2 flex items-center">
                        <p className="mb-0 mr-4">Status</p>
                      </div>
                      <div className="col-span-5">
                        <SwitchButton
                          disabled={state?.viewOnly}
                          size="default"
                          checked={value}
                          onChange={(e) => {
                            onChange(e)
                          }}
                        />
                      </div>
                    </div>
                  )
                }}
              />
            )}
            <div className="mt-[80px] pb-10">
              {!state?.viewOnly && (
                <div className="flex items-center mt-5 justify-center">
                  <Button
                    htmlType="submit"
                    type="primary"
                    size="middle"
                    disabled={total >= 30 || state?.isDisabled}
                    className="submit__btn login-btn"
                    loading={isLoading}
                    onClick={handleSubmit(onSave, (e) => {})}
                  >
                    {'Save'}
                  </Button>
                </div>
              )}
            </div>
          </form>
        )}
      </div>
    </SingleBlogStyled>
  )
}

export default JobDetail

const SingleBlogStyled = styled('div')(() => {
  // return {
  //   '.ant-tabs-nav': {
  //     marginBottom: 0,
  //     paddingLeft: '1.25rem',

  //     '& .ant-tabs-tab': {
  //       borderRadius: '0.75rem 0.75rem 0 0 !important',

  //       '&.ant-tabs-tab-active': {
  //         span: {
  //           color: '#009BB7',
  //           fontWeight: '500',
  //         },
  //       },
  //     },
  //   },

  //   '.ant-upload.ant-upload-select-picture-card': {
  //     width: '100%',
  //     height: 'auto',
  //   },
  // }
  return {}
})
