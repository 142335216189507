import { Typography } from 'antd'
import { ReactNode } from 'react'
import styled from 'styled-components'

interface IProps {
  bgColor?: string
  logo: ReactNode
  title: string
  analysticNumber: number
  onClick?: () => void
}

const DashboardTag = ({
  analysticNumber,
  logo,
  title,
  bgColor,
  onClick,
}: IProps) => {
  return (
    <DashboardTagContainer
      $bgColor={bgColor}
      $clickAble={!!onClick}
      onClick={onClick}
    >
      <TitleWrapper>
        {logo}
        <Typography.Text className="text-[16px] text-black font-semibold">
          {title}
        </Typography.Text>
      </TitleWrapper>
      <Typography.Text className="text-[32px] text-black font-semibold mt-4">
        {analysticNumber}
      </Typography.Text>
    </DashboardTagContainer>
  )
}

export default DashboardTag

const DashboardTagContainer = styled.div<{
  $bgColor?: string
  $clickAble?: boolean
}>`
  width: max-content;
  min-width: 320px;
  min-height: 110px;
  padding: 16px;
  border-radius: 6px;
  background-color: ${(props) => (!!props.$bgColor ? props.$bgColor : '#fff')};
  cursor: ${(props) => (props.$clickAble ? 'pointer' : 'default')};
`

const TitleWrapper = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;
`
