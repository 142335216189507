import styled from 'styled-components'
import { Skeleton as AntdSkeleton } from 'antd'

interface ISkeletonProps {
  row?: number
  column?: number
  style?: any
  containerStyle?: any
}

export default function Skeleton({
  row = 10,
  column = 10,
  style,
  containerStyle,
  ...rest
}: ISkeletonProps) {
  return (
    <>
      {Array(row)
        .fill({})
        ?.map((_, idx) => {
          return (
            <SkeletonContainer
              className="flex p-4 w-full overflow-x-hidden gap-3"
              key={idx}
              style={containerStyle}
            >
              {Array(column)
                .fill({})
                .map((_, idx) => {
                  return (
                    <AntdSkeleton.Button
                      key={idx}
                      active
                      shape="default"
                      size="small"
                      style={{ width: '100%', ...style }}
                      {...rest}
                    />
                  )
                })}
            </SkeletonContainer>
          )
        })}
    </>
  )
}

const SkeletonContainer = styled('div')(() => ({
  '& .ant-skeleton': {
    flex: 1,
  },
}))
