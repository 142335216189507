import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { Controller, useForm } from 'react-hook-form'

import { useEffect, useMemo } from 'react'
import { Button, Input } from 'src/common'
import { AppModal } from 'src/components/AppModal'
import { XCloseIcon } from 'src/components/Icon'
import { ShareSelectInput } from '@components'
import { ETestSectionType } from '@configs'
import styled from 'styled-components'
import { IFinalTestSection } from 'src/interfaces/final-test'
import { sectionSchema } from 'src/pages/course-management/form/final-test/schema'

const defaultValues = {
  type: ETestSectionType.MULTIPLE_CHOICE,
  title: '',
  titleInDutch: '',
  restrictTimer: 0,
  questions: [],
}

type Props = {
  open: boolean
  isLoading?: boolean
  onClose: () => void
  section?: IFinalTestSection
  isActive?: boolean
  haveMultipleChoices?: boolean
  havePractice?: boolean
  isAddNew?: boolean
  onAppendNewSection: ({
    title,
    titleInDutch,
    restrictTimer,
    type,
    questions,
  }: IFinalTestSection) => void
  onEdit: ({
    title,
    titleInDutch,
    restrictTimer,
    type,
    questions,
  }: IFinalTestSection) => void
}

const ModalAddFinalTestSection = ({
  open,
  onClose,
  section,
  onAppendNewSection,
  onEdit,
  haveMultipleChoices = false,
  havePractice = false,
  isAddNew = true,
  isActive = true,
}: Props) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm<IFinalTestSection>({
    defaultValues: defaultValues,
    resolver: zodResolver(sectionSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const watchType = watch('type')
  const INIT_TYPE = section?.type || undefined

  const onSave = async (data: any) => {
    let questions = data.questions
    if (INIT_TYPE && INIT_TYPE !== data.type) {
      questions = []
    }

    if (section) {
      onEdit({
        title: data.title,
        titleInDutch: data.titleInDutch,
        restrictTimer: data.restrictTimer,
        type: data.type,
        questions: questions,
      })
      onClose()
      return
    }

    onAppendNewSection({
      title: data.title,
      titleInDutch: data.titleInDutch,
      restrictTimer: data.restrictTimer,
      type: data.type,
      questions: questions,
    })
    onClose()
  }
  const disabled = useMemo(() => {
    if (haveMultipleChoices && isAddNew) return true
    if (havePractice && haveMultipleChoices && !isAddNew) return true
    return false
  }, [haveMultipleChoices, isAddNew, havePractice])

  useEffect(() => {
    if (open && section) {
      reset({
        ...section,
      })
      return
    }

    if (haveMultipleChoices && isAddNew) {
      reset({
        ...defaultValues,
        type: ETestSectionType.PRACTICE,
      })
      return
    }
    reset({
      ...defaultValues,
    })
  }, [open, section, haveMultipleChoices, isAddNew])

  useEffect(() => {
    if (watchType === ETestSectionType.PRACTICE) {
      setValue('restrictTimer', 0)
    }
  }, [watchType])

  return (
    <AppModal
      open={open}
      onClose={onClose}
      title={
        <div className="flex items-center justify-between ">
          <div>
            <h1 className="m-0 text-[20px]">
              {section ? t('section:edit_section') : t('section:add_section')}
            </h1>
          </div>
          <div className="hover:opacity-75 cursor-pointer">
            <XCloseIcon width={16} height={16} onClick={onClose} />
          </div>
        </div>
      }
    >
      <div className="mt-6 w-[500px]">
        <form>
          <InputWrapper>
            <InputLabelWrapper>
              {t('section:type')}
              <span className="required text-[#B91C1C] font-bold"> *</span>
            </InputLabelWrapper>
            <Controller
              name="type"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <ShareSelectInput
                    name="type"
                    data={[
                      {
                        value: ETestSectionType.MULTIPLE_CHOICE,
                        label: t('section:multiple_choice'),
                      },
                      {
                        value: ETestSectionType.PRACTICE,
                        label: t('section:practice'),
                      },
                    ]}
                    containerClassName="!w-[40%]"
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                    showArrow
                    required
                  />
                )
              }}
            />
          </InputWrapper>

          <InputWrapper $align="start" className="mt-5">
            <InputLabelWrapper>
              {t('common:name')}
              <span className="required text-[#B91C1C] font-bold"> *</span>
            </InputLabelWrapper>
            <div className="flex flex-col gap-4 items-start w-[80%]">
              <Controller
                name="title"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      alignment="col"
                      label={t('common:english')}
                      name="title"
                      className="input"
                      value={value}
                      type="text"
                      onChange={onChange}
                      errors={error?.message}
                      required
                      disabled={!isActive}
                    />
                  )
                }}
              />
              <Controller
                name="titleInDutch"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      alignment="col"
                      label={t('common:netherland')}
                      name="titleInDutch"
                      className="input"
                      value={value}
                      type="text"
                      onChange={onChange}
                      disabled={!isActive}
                      required
                      errors={error?.message}
                    />
                  )
                }}
              />
            </div>
          </InputWrapper>

          {watchType === ETestSectionType.MULTIPLE_CHOICE && (
            <InputWrapper className="mt-3">
              <InputLabelWrapper className="mt-2 !w-[25%]">
                {t('section:set_timer')}
                <span className="required text-[#B91C1C] font-bold"> *</span>
              </InputLabelWrapper>
              <Controller
                name="restrictTimer"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      className="input"
                      containerClassName="!w-[50%]"
                      alignment="col"
                      name="restrictTimer"
                      type="time"
                      showNow={false}
                      onChangeTime={onChange}
                      timeFormat="HH:mm:ss"
                      errors={error?.message}
                      value={value}
                      allowClear
                      required
                      disabled={!isActive}
                    />
                  )
                }}
              />
            </InputWrapper>
          )}

          <div className="mt-6">
            <div className="flex items-center mt-5 justify-end">
              <Button
                type="ghost"
                size="middle"
                className=" login-btn mr-10"
                onClick={onClose}
              >
                {t('common:cancel')}
              </Button>
              {isActive && (
                <Button
                  htmlType="button"
                  type="primary"
                  size="middle"
                  disabled={!isActive}
                  className="submit__btn login-btn"
                  onClick={handleSubmit(onSave)}
                >
                  {t('common:save')}
                </Button>
              )}
            </div>
          </div>
        </form>
      </div>
    </AppModal>
  )
}

const InputWrapper = styled.div<{ $align?: string }>`
  display: flex;
  align-items: ${(props) => props.$align ?? 'center'};
`

const InputLabelWrapper = styled.label`
  width: 20%;
`

export default ModalAddFinalTestSection
