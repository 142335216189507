import { Tabs } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { EManageRoleTabs, EPermissions } from '@configs'
import { Text } from 'src/common'
import StaffManagementTab from 'src/components/staff-and-role-management/staff-management/StaffManagementTab'
import usePermissions from 'src/hooks/usePermissions'
import RoleManagementSection from 'src/components/staff-and-role-management/role-management/RoleManagementSection'

export const StaffAndRoleManagementPage = () => {
  const [activeTab, setActiveTab] = useState<string>(
    EManageRoleTabs.STAFF_MANAGEMENT
  )
  const { getPermissions } = usePermissions({ moduleName: '' })
  const rolePermission = getPermissions(EPermissions?.ROLE_MANAGEMENT)
  const staffPermission = getPermissions(EPermissions?.STAFF_MANAGEMENT)

  const tabItems = useMemo(
    () => [
      {
        label: <Text>{EManageRoleTabs.STAFF_MANAGEMENT}</Text>,
        key: EManageRoleTabs.STAFF_MANAGEMENT,
        children: <StaffManagementTab activeTab={activeTab} />,
        isShow: staffPermission,
      },
      {
        label: <Text>{EManageRoleTabs.ROLE_MANAGEMENT}</Text>,
        key: EManageRoleTabs.ROLE_MANAGEMENT,
        children: <RoleManagementSection />,
        isShow: rolePermission,
      },
    ],
    [activeTab, rolePermission, staffPermission]
  )

  useEffect(() => {
    if (staffPermission) return
    const nearestActiveTab = tabItems?.find((it) => it?.isShow)

    if (!!nearestActiveTab?.key) {
      setActiveTab(nearestActiveTab?.key as EManageRoleTabs)
    }
  }, [staffPermission, tabItems])

  return (
    <StaffAndRoleManagementPageStyled>
      <Tabs
        destroyInactiveTabPane={true}
        defaultActiveKey={
          staffPermission
            ? EManageRoleTabs.STAFF_MANAGEMENT
            : EManageRoleTabs.ROLE_MANAGEMENT
        }
        type="card"
        size={'small'}
        items={tabItems?.filter((it) => !!it?.isShow)}
        defaultChecked={true}
        activeKey={activeTab}
        onChange={(e) => {
          setActiveTab(e)
        }}
      />
    </StaffAndRoleManagementPageStyled>
  )
}

const StaffAndRoleManagementPageStyled = styled('div')(() => {
  return {
    '.ant-tabs-nav': {
      marginBottom: 0,
      paddingLeft: '1.25rem',

      '& .ant-tabs-tab': {
        borderRadius: '0.75rem 0.75rem 0 0 !important',

        '&.ant-tabs-tab-active': {
          span: {
            color: '#009BB7',
            fontWeight: '500',
          },
        },
      },
    },
    '& .ant-tabs-content-holder': {
      backgroundColor: '#ffffff',
    },
  }
})
