import { t } from 'i18next'

import { AppModal } from '@components'
import { ICreateEditTeacherPackage, TTeacherPackage } from '@interfaces'
import CreateEditPackageForm from 'src/pages/package-management/teacher-package-management/components/package-create-edit-modal/CreateEditPackageForm'

interface IProps {
  open: boolean
  onClose: () => void
  selectedPackageId?: number
  onSubmit: (data: ICreateEditTeacherPackage) => void
}

const PackageCreateEditModal = ({
  open,
  onClose,
  selectedPackageId,
  onSubmit,
}: IProps) => {
  return (
    <AppModal
      open={open}
      onClose={onClose}
      title={
        !!selectedPackageId
          ? t('teacher:edit_teacher_package')
          : t('teacher:add_new_teacher_package')
      }
      haveCloseIcon
    >
      <CreateEditPackageForm
        onSubmit={onSubmit}
        onClose={onClose}
        selectedPackageId={selectedPackageId}
      />
    </AppModal>
  )
}

export default PackageCreateEditModal
