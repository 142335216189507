import { ApiClient } from 'src/api/axiosClient'
import {
  ICreateEditPackageInput,
  ICreatePackageSuccessData,
  IGetPackageSuccessData,
  IPackage,
  IPackageBenefitsModule,
} from 'src/interfaces/package-management'
import { ICommonGetParams } from 'src/interfaces/app'
import { objectToQueryString } from 'src/utils'

export const packageManagementAPI = {
  getPackageBenefits: async () => {
    return await ApiClient.get<IPackageBenefitsModule[]>('/package-benefits')
  },
  getListPackage: async (params: ICommonGetParams) => {
    return await ApiClient.get<IGetPackageSuccessData>('/packages', {
      params: {
        ...params,
        sort: objectToQueryString(params.sort || {}) || undefined,
      },
    })
  },

  getListPublicPackageForUser: async () => {
    return await ApiClient.get<IPackage[]>('/packages/public')
  },
  createPackage: async (payload: any) => {
    return await ApiClient.post<ICreatePackageSuccessData>('/packages', payload)
  },

  getPackageById: async (id: string | number) => {
    return await ApiClient.get<IPackage>(`/packages/${id}`)
  },
  updatePackage: async (
    id: number | string,
    payload: ICreateEditPackageInput
  ) => {
    return await ApiClient.put<ICreatePackageSuccessData>(
      `/packages/${id}`,
      payload
    )
  },
  deletePackage: async (id: string | number, selectedPackageId: number) => {
    return await ApiClient.delete<any>(`/packages/${id}`, {
      packageId: selectedPackageId,
    })
  },
}
