import { EStatusCode, PATH_403, PATH_404 } from '@configs'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const ERROR_PATHS = {
  [EStatusCode.NOT_FOUND]: PATH_404,
  [EStatusCode.RESTRICTED_ACCESS]: PATH_403,
}

export default function useNavigateToErrorPage({
  statusCode,
}: {
  statusCode: EStatusCode
}) {
  const navigate = useNavigate()
  const errorPath = ERROR_PATHS?.[statusCode as EStatusCode] || ''

  useEffect(() => {
    if (!errorPath) return

    navigate(errorPath)
  }, [errorPath, navigate])
}
