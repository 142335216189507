import { DeleteTwoTone, EditOutlined } from '@ant-design/icons'
import { Card, Typography } from 'antd'
import styled from 'styled-components'
import { TReferenceView } from 'src/pages/teacher-request-detail/fill-in-teacher-information/types'

export const ReferenceView = ({
  data,
  index,
  onRemove,
  onEdit,
}: TReferenceView) => {
  return (
    <StyledReferenceView>
      <div className="flex flex-row justify-between">
        <Typography.Text className="text-base font-semibold capitalize">
          {data?.name}
        </Typography.Text>
        <div className="ml-5 items-center min-w-14">
          {!!onEdit && (
            <EditOutlined
              className="self-end mr-3 text-base text-gray-500"
              onClick={() => onEdit?.(index)}
            />
          )}
          {!!onRemove && (
            <DeleteTwoTone
              onClick={() => onRemove?.(index)}
              className="self-end text-base"
            />
          )}
        </div>
      </div>
      <Typography.Link
        className="text-base underline"
        href={data?.url}
        target="_blank"
      >
        {data?.url}
      </Typography.Link>
    </StyledReferenceView>
  )
}

const StyledReferenceView = styled(Card)`
  .ant-card-body {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`
