import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'src/common'
import { AppModal } from 'src/components/AppModal'
import { XCloseIcon } from 'src/components/Icon'

interface IRecoveryModalProps {
  open: boolean
  isLoading: boolean
  onClose: () => void
  title: string
  content: ReactNode
  onClick: () => void
}

const RecoveryModal = ({
  isLoading,
  onClose,
  open,
  title,
  content,
  onClick,
}: IRecoveryModalProps) => {
  const { t } = useTranslation(['common', 'user', 'error'])

  return (
    <AppModal open={open} onClose={onClose}>
      <div className="flex items-center justify-between ">
        <div>
          <h1 className="m-0 text-[20px]">{title}</h1>
        </div>
        <div className="hover:opacity-75 cursor-pointer">
          <XCloseIcon width={16} height={16} onClick={onClose} />
        </div>
      </div>
      <div className="mt-6">
        <div>{content}</div>
        <div className="flex items-center mt-5 justify-end">
          <Button
            type="ghost"
            size="middle"
            className="submit__btn login-btn mr-2"
            onClick={onClose}
          >
            {t('common:cancel')}
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            size="middle"
            className="submit__btn login-btn"
            loading={isLoading}
            onClick={onClick}
          >
            {t('common:submit')}
          </Button>
        </div>
      </div>
    </AppModal>
  )
}

export default RecoveryModal
