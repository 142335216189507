export const deleteObjectFields = (
  object?: { [key: string]: any },
  keys?: string[]
) => {
  if (!object || !keys || !keys?.length) return

  keys.forEach((key) => {
    delete object[key]
  })
}

export const retainObjectFields = (
  object?: { [key: string]: any },
  keys?: string[]
) => {
  if (!object || !keys || !keys.length) return

  Object.keys(object).forEach((key) => {
    if (!keys.includes(key)) {
      delete object[key]
    }
  })
}
