import { RootState, selectContentLoading, useAppDispatch } from '@redux'
import {
  addNewBenefitPackageAction,
  deleteBenefitPackageAction,
  getBenefitAction,
  getMotivePackageAction,
  updateBenefitPackageAction,
} from 'src/redux/actions/package-management'
import { IAboutUsDetail } from 'src/interfaces/content-management'
import { IDataMotiveTab } from 'src/interfaces/package-page-management'
import { useEffect, useState } from 'react'
import { Tooltip, message } from 'antd'
import { useSelector } from 'react-redux'
import {
  EPackagePageTabs,
  enumTableTitleMotiveTab,
  thunkActionLoading,
  PLACEHOLDER_IMAGE_AVATAR,
} from '@configs'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import { IPhotoMedia } from '@interfaces'
import ReactPlayer from 'react-player'
export const useMotiveViewModel = (activeTab: string) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation(['error', 'common'])

  const [selectedMotive, setSelectedMotive] = useState<
    IAboutUsDetail | undefined | null
  >()
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [selectedAboutUsId, setSelectedAboutUsId] = useState<number | null>(
    null
  )

  const [reset, setReset] = useState(false)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [no, setNoDefault] = useState(0)

  const apiLoading = useSelector((state: RootState) =>
    selectContentLoading(
      state,
      thunkActionLoading.CREATE_PACKAGE_BENEFIT_LOADING
    )
  )
  const { benefit } = useSelector(
    (state: RootState) => state.content.packagePage
  )

  const onDelete = async () => {
    //on delete about us
    try {
      if (selectedAboutUsId) {
        setIsModalLoading(true)
        const response = await dispatch(
          deleteBenefitPackageAction(selectedAboutUsId)
        ).unwrap()
        setShowModal(false)
        setReset((r) => !r)
        if (response?.success) {
          message.success(response.data.message)
          setIsModalLoading(false)
          setOpenConfirmDeleteModal(false)
          dispatch(getMotivePackageAction())
        }
      } else {
        setShowModal(false)
      }
    } catch (error: any) {
      message.error(error.message)
    }
  }

  const onSubmit = async (data: IDataMotiveTab) => {
    try {
      if (!!selectedMotive) {
        const response = await dispatch(
          updateBenefitPackageAction({ ...data, id: selectedMotive.id })
        ).unwrap()
        setShowModal(false)
        setReset((r) => !r)
        if (response?.aboutUs) message.success(response.message)
      } else {
        const response = await dispatch(
          addNewBenefitPackageAction(data)
        ).unwrap()
        setShowModal(false)
        setReset((r) => !r)
        if (response?.aboutUs) message.success(response.message)
      }
      dispatch(getMotivePackageAction())
    } catch (error: any) {
      message.error(error.message)
    }
  }

  const onHide = () => {
    setShowModal(false)
  }

  const onOpen = () => {
    setSelectedMotive(null)
    setShowModal(true)
  }
  const onSelectMotive = (motive: IAboutUsDetail) => {
    setSelectedMotive(motive)
    setShowModal(true)
  }

  const onOpenDeleteModal = (id: number) => {
    setOpenConfirmDeleteModal(true)
    setSelectedAboutUsId(id)
  }
  const onCloseConfirmModal = () => {
    setOpenConfirmDeleteModal(false)
  }

  useEffect(() => {
    setNoDefault(benefit?.length)
  }, [benefit])

  useEffect(() => {
    if (activeTab === EPackagePageTabs.BENEFIT) {
      dispatch(getBenefitAction())
    }
  }, [reset, activeTab])

  const columns = [
    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleMotiveTab.NO}</p>
          </div>
        )
      },
      dataIndex: 'no',
      align: 'center',
      key: 'no',
      width: '5%',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
    },

    {
      title: enumTableTitleMotiveTab.CONTENT,
      dataIndex: 'content',
      key: 'content',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      width: '10%',
    },
    {
      title: 'Image',
      align: 'center',
      dataIndex: 'media',
      key: 'media',
      width: '10%',

      render: (record: IPhotoMedia) => {
        if (record) {
          if (['image/png', 'image/jpeg', 'image/jpg'].includes(record.type)) {
            return (
              <div className="flex items-center justify-center w-full">
                <img
                  src={record?.url ?? PLACEHOLDER_IMAGE_AVATAR}
                  alt={record?.url}
                  className="object-cover w-14 h-14"
                />
              </div>
            )
          }

          return (
            <div className="flex items-center justify-center w-full">
              <div className="w-14 h-14">
                <ReactPlayer
                  url={record?.url}
                  controls={true}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              </div>
            </div>
          )
        } else {
          return (
            <div className="flex items-center justify-center w-full">
              <img
                src={PLACEHOLDER_IMAGE_AVATAR}
                alt={'image'}
                className="object-cover w-14 h-14"
              />
            </div>
          )
        }
      },
    },
    {
      title: 'Image mobile',
      align: 'center',
      dataIndex: 'mediaMobile',
      key: 'mediaMobile',
      width: '10%',

      render: (record: IPhotoMedia) => {
        if (record) {
          if (['image/png', 'image/jpeg', 'image/jpg'].includes(record.type)) {
            return (
              <div className="flex items-center justify-center w-full">
                <img
                  src={record?.url ?? PLACEHOLDER_IMAGE_AVATAR}
                  alt={record?.url}
                  className="object-cover w-14 h-14"
                />
              </div>
            )
          }
          return (
            <div className="flex items-center justify-center w-full">
              <div className="w-14 h-14">
                <ReactPlayer
                  url={record?.url}
                  controls={true}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              </div>
            </div>
          )
        } else {
          return (
            <div className="flex items-center justify-center w-full">
              <img
                src={PLACEHOLDER_IMAGE_AVATAR}
                alt={'image'}
                className="object-cover w-14 h-14"
              />
            </div>
          )
        }
      },
    },
    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleMotiveTab.ACTION}</p>
          </div>
        )
      },
      key: 'action',
      dataIndex: 'action',
      width: '15%',
      render: (record: any) => (
        <div className="flex space-x-4 items-center justify-center w-full">
          <Tooltip title={t('common:view_detail')}>
            <EyeOutlined
              className="text-lg font-light mr-2.5 cursor-pointer text-[#184f64]"
              onClick={() => {
                onSelectMotive(record)
              }}
            />
          </Tooltip>
          <DeleteOutlined
            className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 !text-red-600`}
            onClick={() => {
              onOpenDeleteModal(record.id)
            }}
          />
        </div>
      ),
      ellipsis: true,
    },
  ]

  return {
    onDelete,
    benefit,
    apiLoading,
    no,
    onHide,
    onOpen,
    isModalOpen: showModal,
    onSelectMotive,
    onOpenDeleteModal,
    onSubmit,
    showDeleteModal: openConfirmDeleteModal,

    onCloseConfirmModal,
    isModalLoading,
    setIsModalLoading,
    selectedMotive,
    t,
    columns,
  }
}
