import {
  DEFAULT_PAGE,
  DEFAULT_ROWS_PER_PAGE,
  ENavBarRole,
  PATH_GRADE_TEST,
  PATH_GRADED_TEST,
  PATH_TEST_MANAGEMENT,
  PLACEHOLDER_IMAGE_AVATAR,
} from '@configs'
import { Skeleton, Typography } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, SharedTable } from 'src/common'
import {
  SEARCH_FORM_FIELDS,
  SEARCH_FORM_FIELD_DEFAULT_VALUES,
  TABLE_COLUMNS,
} from 'src/pages/test-management/config'
import TestTableToolbar from './TestTableToolbar'
import { useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { toast } from 'react-toastify'
import {
  RootState,
  acceptTestAssignment,
  getListTest,
  useAppDispatch,
} from '@redux'
import { useSelector } from 'react-redux'
import { useDebounce } from 'src/hooks/useDebounce'
import { useNavigate } from 'react-router-dom'
import {
  ITestItem,
  EAssignStatus,
  EGradingStatus,
} from 'src/pages/test-management/type'
import ModalFinalTest from 'src/pages/course-request-detail/content/final-test/ModalFinalTest'
import AssignTeacherModal from './AssignTeacherModal'
import MiniDashboard from 'src/pages/test-management/mini-dashboard'
import { useTestContext } from 'src/pages/test-management/provider'
import useRole from 'src/hooks/useRole'
import RejectAssignmentModal from './RejectAssignmentModal'
import moment from 'moment'
import styled from 'styled-components'
import useDeepCompareEffect from 'use-deep-compare-effect'

export default function ListTest() {
  const { t } = useTranslation(['testManagement'])
  const navigate = useNavigate()
  const { isTeacherRole } = useRole()

  const dispatch = useAppDispatch()
  const { data: testManagementData, isLoading: isLoadingDataTest } =
    useSelector((state: RootState) => state.testManagement?.listTest)
  const { currentUserRole } = useTestContext()
  const userRole = currentUserRole as ENavBarRole

  const [page, setPage] = useState(1)
  const [isShowAssignTeacherModal, setIsShowAssignTeacherModal] =
    useState(false)
  const [isShowRejectAssignmentModal, setIsShowRejectAssignmentModal] =
    useState(false)

  const [finalTestHistoryId, setFinalTestHistoryId] = useState<number | null>(
    null
  )
  const [selectedTestItem, setSelectedTestItem] = useState<ITestItem | null>(
    null
  )

  const { control, watch, reset, setValue } = useForm({
    defaultValues: SEARCH_FORM_FIELD_DEFAULT_VALUES,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })
  const watchSearchValue = watch(SEARCH_FORM_FIELDS.SEARCH) ?? ''

  const searchDebounceValue = useDebounce(String(watchSearchValue).trim(), 200)
  const assignToValue = useDebounce(watch(SEARCH_FORM_FIELDS.ASSIGNED_TO), 200)
  const assignStatusValue = useDebounce(
    watch(SEARCH_FORM_FIELDS.ASSIGN_STATUS),
    200
  )
  const gradingStatusValue = useDebounce(
    watch(SEARCH_FORM_FIELDS.GRADING_STATUS) as string[],
    200
  )
  const dateRangeValue = useDebounce(watch(SEARCH_FORM_FIELDS.DATE_RANGE), 200)

  const dateRangeParams = useMemo(() => {
    if (!dateRangeValue) return
    const deadlineFrom = moment(dateRangeValue?.[0]).startOf('day')
    const deadlineTo = moment(dateRangeValue?.[1]).endOf('day')

    return {
      // From 00:00:00 of start day
      deadlineFrom: deadlineFrom.toISOString(),
      // To 23:59:59 of end day
      deadlineTo: deadlineTo.toISOString(),
    }
  }, [dateRangeValue])

  const queryParams = useMemo(() => {
    return {
      ...(!!searchDebounceValue && { search: searchDebounceValue }),
      ...(Array.isArray(assignToValue) &&
        !!assignToValue.length && { assignTo: assignToValue.join(',') }),
      ...(!!assignStatusValue &&
        assignStatusValue !== EAssignStatus.ALL && {
          assignStatus: assignStatusValue,
        }),
      ...(Array.isArray(gradingStatusValue) &&
        !!gradingStatusValue.length && {
          gradingStatus: gradingStatusValue.join(','),
        }),
      ...(dateRangeParams?.deadlineFrom && {
        deadlineFrom: dateRangeParams?.deadlineFrom,
      }),
      ...(dateRangeParams?.deadlineTo &&
        ({
          deadlineTo: dateRangeParams?.deadlineTo,
        } as any)),
    }
  }, [
    assignStatusValue,
    assignToValue,
    dateRangeParams?.deadlineFrom,
    dateRangeParams?.deadlineTo,
    gradingStatusValue,
    searchDebounceValue,
  ])

  const handleReloadData = useCallback(() => {
    if (page === DEFAULT_PAGE) {
      dispatch(
        getListTest({
          page: DEFAULT_PAGE,
          limit: DEFAULT_ROWS_PER_PAGE,
          ...queryParams,
        })
      )
      return
    }

    setPage(DEFAULT_PAGE)
  }, [dispatch, page, queryParams])

  const checkDisableGradeTestButton = useCallback(
    (gradingStatus) => {
      if ([ENavBarRole.ADMIN].includes(userRole)) {
        return gradingStatus === EGradingStatus.GRADED
      }

      if (
        [
          ENavBarRole.TEACHER,
          ENavBarRole.TEACHER_LLC,
          ENavBarRole.TEACHER_OTHER,
        ].includes(userRole)
      ) {
        return gradingStatus !== EGradingStatus.IN_PROGRESS
      }

      return true
    },
    [userRole]
  )

  const handleAcceptTestAssignment = useCallback(
    async (finalTestHistoryId: number) => {
      try {
        await dispatch(acceptTestAssignment(finalTestHistoryId)).unwrap()

        toast.success(t('testManagement:acceptAssignmentSuccess'))
        handleReloadData()
      } catch (error: any) {
        toast.error(error?.message)
      } finally {
        setSelectedTestItem(null)
      }
    },
    [dispatch, handleReloadData, t]
  )

  const columns = useMemo(() => {
    return Object.values(TABLE_COLUMNS)
      ?.map((column) => {
        if (
          isTeacherRole &&
          [
            TABLE_COLUMNS.ASSIGNEE.key,
            TABLE_COLUMNS.ASSIGN_STATUS.key,
          ].includes(column.key)
        )
          return null

        return {
          ...column,
          width: '10%',
          ellipsis: false,
          title: column.title ? t(String(column.title) as any) : '',
          ...(TABLE_COLUMNS.GRADE_ACTION.key === column.key && {
            render: (_: any, record: ITestItem) => {
              const { gradingStatus, finalTestId, studentId, assignStatus } =
                record || {}

              if (
                isTeacherRole &&
                gradingStatus !== EGradingStatus.GRADED &&
                assignStatus === EAssignStatus.PENDING
              )
                return (
                  <div className="flex items-center justify-center h-full w-full gap-2">
                    <Button
                      type="primary"
                      onClick={() =>
                        handleAcceptTestAssignment(Number(finalTestId))
                      }
                      style={{
                        background: '#19ba8c',
                        border: 'none',
                      }}
                    >
                      <Trans i18nKey={'testManagement:accept' as any} />
                    </Button>

                    <Button
                      type="primary"
                      onClick={() => {
                        setIsShowRejectAssignmentModal(true)
                        setFinalTestHistoryId(Number(finalTestId))
                      }}
                      style={{
                        background: '#ff0000',
                        border: 'none',
                      }}
                    >
                      <Trans i18nKey={'testManagement:reject' as any} />
                    </Button>
                  </div>
                )

              return (
                <div className="flex items-center justify-center h-full w-full gap-2">
                  <Button
                    type="primary"
                    disabled={checkDisableGradeTestButton(gradingStatus)}
                    onClick={() => {
                      navigate(
                        `${PATH_TEST_MANAGEMENT}${PATH_GRADE_TEST}/${finalTestId}?studentId=${studentId}`
                      )
                    }}
                  >
                    <Trans i18nKey={'testManagement:grade' as any} />
                  </Button>
                </div>
              )
            },
          }),
          ...(TABLE_COLUMNS.ASSIGNEE.key === column.key && {
            width: '15%',
            render: (_: any, record: ITestItem) => {
              const { assignee, assignStatus, finalTestId, gradingStatus } =
                record || {}
              return (
                <div className="flex items-center justify-center h-full w-full gap-2">
                  {(() => {
                    if (!!assignee)
                      return (
                        <Typography
                          style={{
                            fontWeight: 700,
                            fontSize: 16,
                            maxWidth: 280,
                            minWidth: 200,
                            textWrap: 'wrap',
                          }}
                        >
                          {assignee}
                        </Typography>
                      )

                    if (assignStatus === EAssignStatus.REJECTED)
                      return (
                        <Button
                          type="primary"
                          onClick={() => {
                            setIsShowAssignTeacherModal(true)
                            setFinalTestHistoryId(Number(finalTestId))
                          }}
                          disabled={gradingStatus === EGradingStatus.GRADED}
                        >
                          <Trans i18nKey={'testManagement:reAssign' as any} />
                        </Button>
                      )

                    return (
                      <Button
                        type="primary"
                        onClick={() => {
                          setIsShowAssignTeacherModal(true)
                          setFinalTestHistoryId(Number(finalTestId))
                        }}
                        disabled={gradingStatus === EGradingStatus.GRADED}
                      >
                        <Trans i18nKey={'testManagement:assign' as any} />
                      </Button>
                    )
                  })()}
                </div>
              )
            },
          }),
          ...(TABLE_COLUMNS.VIEW_TEST_ACTION.key === column.key && {
            render: (_: any, record: ITestItem) => {
              const { finalTestId } = record || {}

              return (
                <div className="flex items-center justify-center h-full w-full gap-2">
                  <Button
                    type="primary"
                    onClick={() => {
                      setSelectedTestItem(record)
                      setFinalTestHistoryId(Number(finalTestId))
                    }}
                  >
                    <Trans i18nKey={'testManagement:viewTest' as any} />
                  </Button>
                </div>
              )
            },
          }),
          ...(TABLE_COLUMNS.VIEW_GRADED_ACTION.key === column.key && {
            render: (_: any, record: ITestItem) => {
              const { gradingStatus, finalTestId, studentId } = record || {}

              return (
                <div className="flex items-center justify-center h-full w-full gap-2">
                  <Button
                    type="primary"
                    disabled={gradingStatus !== EGradingStatus.GRADED}
                    onClick={() => {
                      navigate(
                        `${PATH_TEST_MANAGEMENT}${PATH_GRADED_TEST}/${finalTestId}?studentId=${studentId}`
                      )
                    }}
                  >
                    <Trans i18nKey={'testManagement:viewGrade' as any} />
                  </Button>
                </div>
              )
            },
          }),
        }
      })
      .filter(Boolean)
  }, [
    checkDisableGradeTestButton,
    handleAcceptTestAssignment,
    isTeacherRole,
    navigate,
    t,
  ])

  const listTestFmt = useMemo(() => {
    if (
      !Array.isArray(testManagementData?.items) ||
      !testManagementData?.items.length
    )
      return []

    return testManagementData.items.map((it: any, idx: number) => {
      const {
        id,
        finalTestSection,
        course,
        user,
        teacher,
        assignStatus,
        gradingStatus,
        submitDate,
        gradedDate,
        deadline,
        userId,
      } = it || {}
      return {
        index: idx + 1,
        key: id,
        testName: finalTestSection?.title || '',
        courseName: course?.title || '',
        studentName: user?.name || t('testManagement:defaultUsername'),
        assignee: teacher?.name || '',
        assignStatus,
        gradingStatus,
        studentSubmissionDate: submitDate || null,
        gradedDate,
        gradingDeadline: deadline || null,
        avatar: user?.profilePhoto?.url || PLACEHOLDER_IMAGE_AVATAR,
        finalTestId: id || '',
        studentId: userId,
        finalTestSection,
      }
    })
  }, [t, testManagementData.items])

  useDeepCompareEffect(() => {
    dispatch(
      getListTest({
        page,
        limit: DEFAULT_ROWS_PER_PAGE,
        ...queryParams,
      })
    )
  }, [dispatch, page, queryParams])

  useDeepCompareEffect(() => {
    setPage(DEFAULT_PAGE)
  }, [
    assignStatusValue,
    assignToValue,
    dateRangeParams?.deadlineFrom,
    dateRangeParams?.deadlineTo,
    gradingStatusValue,
    searchDebounceValue,
  ])

  return (
    <>
      <div className="bg-white rounded-md p-4">
        <div className="w-full">
          <MiniDashboard
            reset={reset}
            setValue={setValue}
            reload={isShowAssignTeacherModal}
          />
          <form className="mt-16">
            <TestTableToolbar
              control={control}
              reset={reset}
              setValue={setValue}
            />
          </form>
        </div>

        {isLoadingDataTest ? (
          <Skeleton
            paragraph={{ rows: 10 }}
            style={{
              marginTop: 10,
            }}
          />
        ) : (
          <SharedTableStyled>
            <SharedTable
              columns={columns as any}
              dataSource={listTestFmt}
              totalResults={Number(testManagementData?.total)}
              paginationProps={{
                total: !!testManagementData?.total
                  ? Number(testManagementData?.total)
                  : undefined,
                pageSize: 10,
                showSizeChanger: false,
                current: page,
                onChange(page) {
                  setPage(page)
                },
              }}
            />
          </SharedTableStyled>
        )}
      </div>

      {!!selectedTestItem && !!selectedTestItem?.finalTestId ? (
        <ModalFinalTest
          open={!!selectedTestItem}
          finalTestId={Number(selectedTestItem?.finalTestSection?.lessonId)}
          onClose={() => setSelectedTestItem(null)}
          isActive={false}
          onSave={() => {}}
          isTeacherView={
            [ENavBarRole.TEACHER_LLC, ENavBarRole.TEACHER_OTHER].includes(
              userRole
            ) &&
            [EAssignStatus.PENDING].includes(selectedTestItem?.assignStatus)
          }
          deadline={selectedTestItem?.gradingDeadline ?? ''}
          onAcceptTest={() =>
            handleAcceptTestAssignment(Number(selectedTestItem?.finalTestId))
          }
          onRejectTest={() => setIsShowRejectAssignmentModal(true)}
        />
      ) : null}

      {isShowAssignTeacherModal ? (
        <AssignTeacherModal
          finalTestHistoryId={Number(finalTestHistoryId)}
          open={isShowAssignTeacherModal}
          onClose={() => setIsShowAssignTeacherModal(false)}
          handleReloadData={handleReloadData}
        />
      ) : null}

      {isShowRejectAssignmentModal ? (
        <RejectAssignmentModal
          open={isShowRejectAssignmentModal}
          onClose={() => {
            setSelectedTestItem(null)
            setIsShowRejectAssignmentModal(false)
          }}
          finalTestHistoryId={Number(finalTestHistoryId)}
          handleReloadData={handleReloadData}
        />
      ) : null}
    </>
  )
}

const SharedTableStyled = styled.div`
  .ant-table-wrapper {
    margin: 0 !important;
    margin-top: 16px !important;
  }

  .ant-table-content {
    margin-right: 0 !important;
  }

  // sticky header
  .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 50;
  }
`
