import { Tabs } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'

import { EPackagePageTabs } from '@configs'
import { Text } from 'src/common'
import BannerTab from 'src/components/membership-page-management/BannerTab'
import CommunityTab from 'src/components/membership-page-management/CommunityTab'
import MotiveTab from 'src/components/membership-page-management/MotiveTab/MotiveTab'
import MemberTab from 'src/components/membership-page-management/MemberTab/MemberTab'
import PackageSeoTab from 'src/components/package-page-management/PackageSeoTab'
import BenefitTab from 'src/components/membership-page-management/Benifit/BenefitTab'

type Props = {}

const MembershipPage = (props: Props) => {
  const [activeTab, setActiveTab] = useState<string>(EPackagePageTabs.BANNER)

  const tabItems = [
    {
      label: <Text>{EPackagePageTabs.BANNER}</Text>,
      key: EPackagePageTabs.BANNER,
      children: <BannerTab activeTab={activeTab} />,
    },
    {
      label: <Text>{EPackagePageTabs.COMMUNITY}</Text>,
      key: EPackagePageTabs.COMMUNITY,
      children: <CommunityTab activeTab={activeTab} />,
    },

    {
      label: <Text>{EPackagePageTabs.MOTIVE}</Text>,
      key: EPackagePageTabs.MOTIVE,
      children: <MotiveTab activeTab={activeTab} />,
    },
    {
      label: <Text>{EPackagePageTabs.BENEFIT}</Text>,
      key: EPackagePageTabs.BENEFIT,
      children: <BenefitTab activeTab={activeTab} />,
    },
    {
      label: <Text>{EPackagePageTabs.MEMBER}</Text>,
      key: EPackagePageTabs.MEMBER,
      children: <MemberTab activeTab={activeTab} />,
    },
    {
      label: <Text>{EPackagePageTabs.SEO_CONFIG}</Text>,
      key: EPackagePageTabs.SEO_CONFIG,
      children: <PackageSeoTab activeTab={activeTab} />,
    },
  ]
  return (
    <MembershipPageStyled>
      <Tabs
        destroyInactiveTabPane={true}
        defaultActiveKey={EPackagePageTabs.BANNER}
        type="card"
        size={'small'}
        items={tabItems}
        defaultChecked={true}
        activeKey={activeTab}
        onChange={(e) => {
          setActiveTab(e)
        }}
      />
    </MembershipPageStyled>
  )
}

export default MembershipPage

const MembershipPageStyled = styled('div')(() => {
  return {
    '.ant-tabs-nav': {
      marginBottom: 0,
      paddingLeft: '1.25rem',

      '& .ant-tabs-tab': {
        borderRadius: '0.75rem 0.75rem 0 0 !important',

        '&.ant-tabs-tab-active': {
          span: {
            color: '#009BB7',
            fontWeight: '500',
          },
        },
      },
    },
  }
})
