import { UploadOutlined } from '@ant-design/icons'
import { EUploadFileType, VIDEO_FILTER, POST_MEDIA_FILTER } from '@configs'
import { UploadProps, message } from 'antd'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { galleryAPI } from 'src/api/gallery'
import { EMediaCategory } from 'src/interfaces/gallery'
import { IUploadMediaResponse } from 'src/interfaces/media'
import { Uploader } from 'src/utils/multipartUpload'

type Props = {}

const CustomUploadButton = (
  props: UploadProps & {
    containerClassName?: string
    label?: string
    onLoadEnd?: (data: IUploadMediaResponse) => void
    errors?: string
    initResource?: string
    reset?: boolean
    changeLoading?: (loading: boolean) => void
    allowFileTypes?: string[]
    limitFileSize?: number
    uploadType?: EUploadFileType
    uploadCategory?: EMediaCategory
  }
) => {
  const {
    uploadType = EUploadFileType.IMAGE,
    allowFileTypes = [
      'video/mp4',
      'video/webm',
      'video/m4v',
      'video/mov',
      'video/qt',
    ],
    limitFileSize = 500,
    reset,
    changeLoading,
    initResource,
    onLoadEnd,
    errors,
    label,
    uploadCategory,
    name = 'btn-upload',
    id = 'btn-upload',
    ...rest
  } = props

  const [loading, setLoading] = useState(false)
  const [resource, setResource] = useState(initResource)
  const [multipartFile, setMultipartFile] = useState<File>()
  const [uploader, setUploader] = useState<Uploader>()

  const handleChangeMedia = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    changeLoading?.(true)
    if (!e.target.files || e.target.files.length === 0) {
      setLoading(false)
      changeLoading?.(false)
      return
    }

    const file = e.target.files[0]
    const isMatchMediaType =
      uploadType === EUploadFileType.IMAGE
        ? POST_MEDIA_FILTER.test(file.type)
        : VIDEO_FILTER.test(file.type)

    const allowedInputType = allowFileTypes
      ?.map((item, index) => item.split('/')[1])
      ?.join('/')
      ?.toUpperCase()

    if (!isMatchMediaType) {
      message.error(`You can only upload ${allowedInputType}file!`)
      setLoading(false)
      changeLoading?.(false)
      return
    }

    // const isMatchMediaSize = Number(file.size) / 1024 / 1024 < limitFileSize
    // if (!isMatchMediaSize) {
    //   message.error(`Media file must smaller than ${limitFileSize}MB!`)
    //   setLoading(false)
    //   changeLoading?.(false)
    //   return
    // }

    try {
      if (uploadType === EUploadFileType.IMAGE) {
        const response = await galleryAPI.uploadImage(file, uploadCategory)
        if (response.success) {
          message.success(response?.message ?? 'Upload image successfully!')
          setResource(response?.data?.original)
          if (onLoadEnd) {
            onLoadEnd(response?.data)
          }
          setLoading(false)
          changeLoading?.(false)
        }
      } else {
        setMultipartFile(file)

        const videoUploaderOptions = {
          fileName: file.name,
          fileType: file.type,
          file: file,
        }

        const uploader = new Uploader(videoUploaderOptions)
        setUploader(uploader)
      }
    } catch (error: any) {
      setLoading(false)
      console.log(error)
      message.error(error?.message ?? 'Upload file failed!')
    }
  }

  const hanldeStartUpload = async () => {
    let percentage: number

    if (!uploader || !multipartFile) return
    await uploader.start()
    uploader.onProgressFn((data: any) => {
      if (data.percentage !== percentage) {
        percentage = data.percentage
      }
    })

    uploader.onErrorFn((error: any) => {
      setMultipartFile(undefined)
      console.error(error)
    })

    uploader.setUploadCompletionCallback(async () => {
      try {
        changeLoading?.(true)
        const result = await uploader.sendCompleteRequest()
        if (result.Location) {
          const formData = new FormData()
          // formData.append('file', multipartFile)
          formData.append('bucket', result.Bucket)
          formData.append('key', result.Key)
          formData.append('contentType', multipartFile.type)
          formData.append('originalname', multipartFile.name)
          if (uploadCategory) {
            formData.append('category', uploadCategory)
          }

          createMedia(formData)
        }
      } catch (error) {
        console.log(error)
        changeLoading?.(false)
        message.error(error ?? 'Upload file failed!')
      } finally {
        changeLoading?.(true)
        setLoading(false)
      }
    })
  }

  const createMedia = async (data: any) => {
    try {
      const result = await galleryAPI.createMedia(data)
      if (result) {
        console.log(result)
        if (onLoadEnd) {
          onLoadEnd(result?.data)
        }
        setResource(result.data.url)
        message.success('Upload video successfully!')
      }
    } catch (error) {
      console.log(error)
      message.error(error ?? 'Upload file failed!')
    } finally {
      changeLoading?.(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    hanldeStartUpload()
  }, [multipartFile, uploader])

  return (
    <div
      style={{
        display: 'flex',
        marginTop: 24,
      }}
    >
      <div
        style={{
          flex: 1,
        }}
      />
      {/* <UploadButton
          allowFileTypes={['video/mp4', 'video/webm', 'video/m4v', 'video/qt']}
          limitFileSize={500}
          changeLoading={setLoading}
          uploadType={EUploadFileType.VIDEO}
          onLoadEnd={(data) => {
            setRource(data?.url ?? '')
            mediaId.current = data?.dataValues?.id
            setIsChange(true)
          }}
          multiple={false}
          label={'Upload'}
        /> */}
      <label
        className="upload-media-btn flex items-center justify-center px-3 py-2 border shadow rounded-lg font-bold cursor-pointer"
        htmlFor={id || name}
      >
        <div className="upload-icon flex items-center justify-center">
          <UploadOutlined />
        </div>
        <div className="upload-text text-center ml-2">Upload</div>
      </label>
      <input
        type="file"
        id={id}
        name={name}
        className="hidden"
        accept={allowFileTypes.join(',')}
        onChange={handleChangeMedia}
      />
    </div>
  )
}

export default CustomUploadButton
