import { AppModal, ShareSelectInput, XCloseIcon } from '@components'
import {
  LIMIT_ITEM_PER_PAGE,
  MAX_LIMIT_ITEM_PER_PAGE,
  thunkActionLoading,
} from '@configs'
import {
  RootState,
  getAllCategoriesAction,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { Card, Checkbox, List, Skeleton, message } from 'antd'
import { t } from 'i18next'
import { delay, every, includes, uniq, uniqBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { Button, Input } from 'src/common'
import { StyledPagination } from 'src/components/pagination/StyledPagination'
import { useDebounce } from 'src/hooks/useDebounce'
import {
  ISubscribedEmail,
  TSubscribedEmailParams,
} from 'src/interfaces/group-user-management'
import { getListSubscribedEmailAction } from 'src/redux/actions/group-user-management'
import { getListPackageAction } from 'src/redux/actions/package-management'
import { isInListSubscribedEmail } from 'src/utils/checkInListSubcribedEmail'

type Props = {
  open: boolean
  onClose: () => void
  data: ISubscribedEmail[]
  onSave: (data: ISubscribedEmail[]) => void
  isEdit: boolean
  filtered: {
    search?: string
    categoryId?: number
    packageId?: number
  }
  onChangeFilter: (data: {
    search?: string
    categoryId?: number
    packageId?: number
  }) => void
  isSelectedAll?: boolean
  onSelectedAll?: (value?: boolean) => void
}

const ItemInList = ({
  isCheck,
  text,
  onClick,
  className,
}: {
  isCheck: boolean
  text: string
  onClick: () => void
  className?: string
}) => {
  return (
    <div
      className={`flex items-center justify-start w-full cursor-pointer relative ${className}`}
      onClick={onClick}
    >
      <Checkbox checked={isCheck} />
      <div className="absolute w-5 h-5 left-0 top-0"></div>
      <div className={`ml-3 ${isCheck ? 'font-semibold' : ''}`}>{text}</div>
    </div>
  )
}

const SelectUserModal = ({
  open,
  onClose,
  data,
  onSave,
  isEdit,
  filtered,
  onChangeFilter,
  isSelectedAll,
  onSelectedAll,
}: Props) => {
  const debouncedValue = useDebounce<string | null>(filtered.search ?? '', 500)

  const [allUserEmail, setAllUserEmail] = useState<ISubscribedEmail[]>([])

  const [page, setPage] = useState(1)
  const [isSearching, setIsSearching] = useState(false)

  const [selectedUserEmail, setSelectedUserEmail] =
    useState<ISubscribedEmail[]>(data)

  const dispatch = useAppDispatch()

  const { categories } = useAppSelector((state: RootState) => state.categories)

  const { packages } = useAppSelector((state: RootState) => state.package)

  const { emailSubscriber } = useAppSelector(
    (state: RootState) => state?.groupUser
  )

  const getEmailSubscribersLoading = useAppSelector(
    (state: RootState) =>
      state.groupUser.loadings[
        thunkActionLoading.GET_LIST_SUBSCRIBED_EMAIL_LOADING
      ]
  )

  const categoriesOptions = categories?.map((category) => {
    return {
      label: category?.name,
      value: category?.id?.toString(),
    }
  })

  categoriesOptions?.unshift({
    label: 'All',
    value: 'All',
  })

  const packagesOptions = packages?.map((packageItem) => {
    return {
      label: packageItem?.name,
      value: packageItem?.id?.toString(),
    }
  })

  packagesOptions.unshift({
    label: 'All',
    value: 'All',
  })

  const selectedAllInPage = useMemo(() => {
    return (
      !!emailSubscriber?.emailSubscribers?.length &&
      every(emailSubscriber?.emailSubscribers ?? [], (item) =>
        selectedUserEmail.find((email) => email.email === item.email)
      )
    )
  }, [emailSubscriber?.emailSubscribers, selectedUserEmail])

  const getListSubscribedEmail = async (params?: TSubscribedEmailParams) => {
    dispatch(
      getListSubscribedEmailAction({
        page: page,
        limit: LIMIT_ITEM_PER_PAGE,
        search:
          debouncedValue !== null && debouncedValue.trim()
            ? debouncedValue.trim()
            : undefined,
        // categoryId: categoryId,
        // packageId: packageId,
        ...(params || {}),
      })
    )
  }

  const getListSubscribedEmailBySearchValue = async () => {
    try {
      setIsSearching(true)
      await dispatch(
        getListSubscribedEmailAction({
          page: 1,
          limit: LIMIT_ITEM_PER_PAGE,
          search:
            debouncedValue !== null && debouncedValue.trim()
              ? debouncedValue.trim()
              : undefined,
          categoryId: filtered.categoryId,
          packageId: filtered.packageId,
        })
      ).unwrap()
    } catch (error: any) {
      if (error.message) {
        message.error(error.message)
      }
    } finally {
      setIsSearching(false)
    }
  }

  const onClickSelectedAll = () => {
    if (selectedAllInPage) {
      setSelectedUserEmail((prev) => {
        return prev.filter((item) => {
          return !emailSubscriber?.emailSubscribers?.find(
            (email) => email.email === item.email
          )
        })
      })
      return
    }
    setSelectedUserEmail((prev) => {
      return uniqBy(
        [...(prev ?? []), ...(emailSubscriber?.emailSubscribers ?? [])],
        'email'
      ) as ISubscribedEmail[]
    })
  }

  useEffect(() => {
    setSelectedUserEmail(data)
  }, [data])

  useEffect(() => {
    dispatch(getAllCategoriesAction())
  }, [dispatch])

  useEffect(() => {
    dispatch(getListPackageAction({ page: 1, limit: 10000 }))
  }, [dispatch])

  useEffect(() => {
    getListSubscribedEmail({ page: 1 })
  }, [])

  // useEffect(() => {
  //   const getAllSubscribedEmail = async () => {
  //     const response = await dispatch(
  //       getListSubscribedEmailAction({
  //         page: page,
  //         limit: LIMIT_ITEM_PER_PAGE,
  //       })
  //     ).unwrap()

  //     if (response?.success) {
  //       setAllUserEmail(response?.data?.items || [])
  //     }
  //   }
  //   getAllSubscribedEmail()
  // }, [])

  // useEffect(() => {
  //   if (data?.length > 0 && data?.length === emailSubscriber?.total && isEdit) {
  //     onSelectedAll?.(true)
  //   }
  // }, [data, emailSubscriber?.total])

  // useEffect(() => {
  //   if (
  //     selectedUserEmail?.length > 0 &&
  //     selectedUserEmail?.length === emailSubscriber?.total
  //   ) {
  //     onSelectedAll?.(true)
  //   }
  // }, [selectedUserEmail, emailSubscriber?.total])

  useEffect(() => {
    getListSubscribedEmailBySearchValue()
  }, [debouncedValue])

  // useEffect(() => {
  //   if (isSelectedAll) {
  //     setSelectedUserEmail((prev) => {
  //       return uniqBy(
  //         [...(prev ?? []), ...(emailSubscriber.emailSubscribers ?? [])],
  //         'email'
  //       )
  //     })
  //     return
  //   }
  // }, [emailSubscriber])

  return (
    <AppModal open={open} onClose={onClose}>
      <Card
        style={{
          minHeight: '300px',
          width: '800px',
          borderWidth: '0px',
        }}
        bodyStyle={{
          padding: '0',
        }}
      >
        <div className="flex items-center justify-between ">
          <div>
            <h1 className="m-0 text-[20px]">Add users</h1>
          </div>
          <div className="hover:opacity-75 cursor-pointer">
            <XCloseIcon width={16} height={16} onClick={onClose} />
          </div>
        </div>
        <div className="mt-6">
          <Input
            label="Search for user"
            placeholder="Search for user by name or email"
            onChange={(e) => {
              onChangeFilter({
                search: e.target.value,
              })
            }}
            alignment="col"
            value={filtered?.search ?? ''}
            containerClassName="mt-[24px]"
          />
          <div className="flex gap-4 items-center justify-between mt-5">
            <ShareSelectInput
              data={categoriesOptions}
              label="Category"
              placeholder="Choose category"
              containerClassName="w-1/2"
              onChange={(data) => {
                if (data === 'All') {
                  onChangeFilter({
                    categoryId: undefined,
                  })

                  getListSubscribedEmail({
                    page: 1,
                    ...filtered,
                    categoryId: undefined,
                  })
                } else {
                  onChangeFilter({
                    categoryId: +data,
                  })
                  getListSubscribedEmail({
                    page: 1,
                    ...filtered,
                    categoryId: +data,
                  })
                }
              }}
            />
            <ShareSelectInput
              data={packagesOptions as any}
              label="Package"
              placeholder="Choose package"
              containerClassName="w-1/2"
              onChange={(data) => {
                if (data === 'All') {
                  onChangeFilter({
                    packageId: undefined,
                  })

                  getListSubscribedEmail({
                    page: 1,
                    ...filtered,
                    packageId: undefined,
                  })
                } else {
                  onChangeFilter({
                    packageId: +data,
                  })
                  getListSubscribedEmail({
                    page: 1,
                    ...filtered,
                    packageId: +data,
                  })
                }
              }}
            />
          </div>

          <div className="wrapper-action my-6 flex items-center justify-between">
            <ItemInList
              isCheck={selectedAllInPage}
              text={t('common:select_all')}
              onClick={() => {
                onClickSelectedAll()
              }}
            />
          </div>

          {getEmailSubscribersLoading ? (
            <div className="h-[200px]">
              <Skeleton paragraph={{ rows: 5 }} />
            </div>
          ) : (
            <>
              <List
                className=""
                grid={{ gutter: 16, column: 2 }}
                dataSource={emailSubscriber?.emailSubscribers}
                renderItem={(item) => {
                  const isCheck = isInListSubscribedEmail(
                    selectedUserEmail,
                    item
                  )
                  return (
                    <List.Item>
                      <ItemInList
                        isCheck={isCheck}
                        text={`${item?.name} - ${item?.email}`}
                        onClick={() => {
                          if (isCheck) {
                            setSelectedUserEmail((pre) => {
                              return pre.filter(
                                (itemPre) => itemPre?.email !== item?.email
                              )
                            })
                            onSelectedAll?.(false)
                          } else {
                            const newSelectedUserEmail = [
                              ...(selectedUserEmail ?? []),
                              item,
                            ]
                            setSelectedUserEmail(newSelectedUserEmail)

                            if (
                              newSelectedUserEmail.length ===
                              (emailSubscriber?.emailSubscribers?.length ?? 0)
                            ) {
                              onSelectedAll?.(true)
                            }
                          }
                        }}
                      />
                    </List.Item>
                  )
                }}
              />
            </>
          )}

          <div className="w-full flex flex-col gap-4 justify-center items-center mt-5 relative bottom-0">
            <StyledPagination
              current={emailSubscriber?.page || 1}
              onChange={(page: number) => {
                setPage(page)
                getListSubscribedEmail({
                  page: page,
                  ...filtered,
                })
              }}
              total={emailSubscriber?.total || 0}
              showSizeChanger={false}
              className="flex justify-center items-center"
            />
            <Button
              htmlType="submit"
              type="primary"
              size="middle"
              loading={getEmailSubscribersLoading}
              disabled={
                (getEmailSubscribersLoading ||
                  selectedUserEmail.length === 0) &&
                !isSelectedAll
              }
              onClick={() => {
                onSave && onSave(selectedUserEmail)
                delay(() => {
                  onClose()
                }, 300)
              }}
            >
              {'Save'}
            </Button>
          </div>
        </div>
      </Card>
    </AppModal>
  )
}

export default SelectUserModal
