import MessageItem from './MessageItem'
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import PreviewPhotoModal from 'src/pages/course-management/chat/content/PreviewPhotoModal'
import {
  resetListMessageWithUser,
  setPage,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { getListChatWithUser } from 'src/redux/actions/course'
import { Spin } from 'antd'
import { PLACEHOLDER_IMAGE_AVATAR } from '@configs'
import { useChatContext } from '../ChatContext'
import { useParams } from 'react-router-dom'
import { IMessageContent } from 'src/interfaces/course'
import { toast } from 'react-toastify'
import { groupBy, sortBy } from 'lodash'
import moment from 'moment'
import useCheckConnection from 'src/hooks/useCheckConnection'
import styled from 'styled-components'
import { themes } from '@theme'

interface IMessageListHeight {
  previous: number
  current: number
}

export default function ChatMainContent({
  messageListRef,
}: {
  messageListRef: React.RefObject<HTMLDivElement>
}) {
  const { selectedConversation } = useChatContext()
  const { isOffline } = useCheckConnection()
  const { id: courseId } = useParams()

  const [isShowPreviewImageModal, setIsShowPreviewImageModal] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')
  const [messageListHeight, setMessageListHeight] =
    useState<IMessageListHeight>({ previous: 0, current: 0 })

  const messageContentRef = useRef<HTMLDivElement>(null)
  const promiseRef = useRef<any>(null)
  const abortControllerRef = useRef<AbortController | null>(null)

  const dispatch = useAppDispatch()
  const {
    data: listMessages,
    isLoading,
    total: totalRecord,
  } = useAppSelector((state) => state.course.listMessageWithUser)
  const page = useAppSelector((state) => state.course.page)
  const sendingMessageIds = useAppSelector(
    (state) => state.course.sendingMessageIds
  )

  const listMessagesGroupByDate = useMemo(() => {
    const listSortedMessages = sortBy(listMessages, 'createdAt')

    return groupBy(listSortedMessages, (it) => {
      return moment(it?.createdAt).format('MM/DD/YYYY')
    })
  }, [listMessages])

  const getListMessage = async () => {
    abortControllerRef.current = new AbortController()
    const signal = abortControllerRef.current.signal

    if (!selectedConversation || isOffline) return

    try {
      promiseRef.current = dispatch(
        getListChatWithUser({
          userId: selectedConversation?.userId,
          courseId: String(courseId),
          params: {
            limit: 10,
            page,
          },
          config: {
            signal,
          },
        })
      )
      await promiseRef.current?.unwrap()

      setIsLoadingMore(false)
    } catch (error: any) {
      if (error?.name === 'AbortError') return
      toast.error(error?.data?.message || error?.message)
      setIsLoadingMore(false)
    }
  }

  useEffect(() => {
    getListMessage()

    return () => {
      promiseRef?.current?.abort()
    }
  }, [courseId, dispatch, messageListRef, page, selectedConversation])

  useEffect(() => {
    if (isOffline) {
      dispatch(setPage({ page: 1 }))
    }
    if (!isOffline) {
      dispatch(resetListMessageWithUser())
      getListMessage()
    }

    return () => {
      promiseRef?.current?.abort()
    }
  }, [dispatch, isOffline])

  useEffect(() => {
    if (!messageListRef?.current || isLoading || isLoadingMore || page <= 1)
      return

    const diff = messageListHeight?.current - messageListHeight?.previous

    if (!diff) return

    messageListRef?.current?.scrollTo(0, diff > 0 ? diff : 0)
  }, [isLoading, isLoadingMore, messageListHeight, messageListRef, page])

  useEffect(() => {
    if (!messageContentRef?.current || !messageListRef?.current || page > 1)
      return

    const resizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        for (let entry of entries) {
          messageListRef.current?.scrollBy(0, entry?.contentRect?.height)
        }
      }
    )

    try {
      resizeObserver?.observe(messageContentRef?.current as Element)
    } catch (error) {}

    return () => {
      try {
        resizeObserver?.unobserve(messageContentRef?.current as Element)
      } catch (error) {}
    }
  }, [page])

  useEffect(() => {
    dispatch(setPage({ page: 1 }))
  }, [dispatch, selectedConversation?.userId])

  useEffect(() => {
    return () => {
      dispatch(setPage({ page: 1 }))
      dispatch(resetListMessageWithUser())
      abortControllerRef?.current?.abort()
    }
  }, [dispatch, selectedConversation?.userId])

  useEffect(() => {
    const height = messageContentRef?.current?.clientHeight
    if (!height) return

    setMessageListHeight((prev) => {
      if (height >= prev?.current) {
        return {
          previous: prev?.current,
          current: height,
        }
      }

      return prev
    })
  }, [messageContentRef?.current?.clientHeight])

  return (
    <>
      <ChatMainContentStyled
        className={`${!selectedConversation?.userId ? 'h-full' : ''}`}
      >
        <div
          ref={messageListRef}
          className={`scroll-content max-h-[calc(75dvh-162px)] 2xl:max-h-[calc(80dvh-162px)] overflow-y-auto relative ${
            !selectedConversation?.userId ? 'max-h-[unset] h-full' : ''
          }`}
          onScroll={() => {
            if (
              Number(messageListRef.current?.scrollTop) !== 0 ||
              !Array.isArray(listMessages) ||
              !listMessages?.length ||
              isOffline
            )
              return

            if (isLoadingMore || listMessages?.length >= totalRecord) return

            setIsLoadingMore(true)
            dispatch(setPage({ page: page + 1 }))
          }}
        >
          <div
            className="flex-1 px-5 pt-5 h-full flex flex-col justify-end"
            ref={messageContentRef}
          >
            <div
              className={`loading-wrapper flex items-center justify-center relative left-0 h-[50vh] w-full z-[111] ${
                (!listMessages?.length || isLoading) &&
                !isLoadingMore &&
                !!selectedConversation?.userId
                  ? 'visible'
                  : 'hidden'
              }`}
            >
              <Spin size="large" />
            </div>

            {!selectedConversation?.userId ? (
              <ChatWelcome />
            ) : (
              <>
                {(() => {
                  const listMessagesData = Object.entries(
                    listMessagesGroupByDate
                  )

                  if (
                    !Array.isArray(listMessagesData) ||
                    !listMessagesData?.length
                  )
                    return null

                  return (
                    <Fragment>
                      {isLoadingMore ? (
                        <div className="loading-wrapper flex items-center justify-center h-auto p-2 mb-4">
                          <Spin size="default" />
                        </div>
                      ) : null}

                      {listMessagesData?.map((group) => {
                        return (
                          <Fragment key={group?.[0]}>
                            <div className="font-normal not-italic text-center text-[#8a8a8a] !text-[12px] w-full mb-4">
                              {moment(group?.[0]).format('MMM DD, YYYY')}
                            </div>

                            {(group?.[1] as any)?.map(
                              (item: IMessageContent, index: number) => {
                                return (
                                  <MessageItem
                                    key={index}
                                    message={item}
                                    handleClickImage={() => {
                                      setSelectedImage(
                                        item?.media?.cloudfrontUrl
                                      )
                                      setIsShowPreviewImageModal(true)
                                    }}
                                    receiverId={Number(
                                      selectedConversation?.userId
                                    )}
                                    isShowSentTime={
                                      index === group?.[1]?.length - 1
                                    }
                                  />
                                )
                              }
                            )}
                          </Fragment>
                        )
                      })}
                    </Fragment>
                  )
                })()}
              </>
            )}
          </div>
        </div>
      </ChatMainContentStyled>

      {isShowPreviewImageModal ? (
        <PreviewPhotoModal
          open
          onDelete={() => {}}
          onSave={() => {}}
          onClose={() => setIsShowPreviewImageModal(false)}
          url={selectedImage}
          modalClassName="[&>div]:!w-[50%]"
        />
      ) : null}
    </>
  )
}

const ChatMainContentStyled = styled.div`
  width: 100%;
  .scroll-content {
    &::-webkit-scrollbar {
      width: 12px;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      border: 3px solid transparent;
      background-clip: content-box;
      background-color: ${themes.theme.light.colors.lightBlue};
    }
  }
`

const ChatWelcome = ({ className }: { className?: string }) => {
  return (
    <div className={`flex h-full items-center justify-center ${className}`}>
      <div className="mx-auto text-center">
        <div className="image-fit mx-auto h-16 w-16 flex-none overflow-hidden rounded-full">
          <img
            src={PLACEHOLDER_IMAGE_AVATAR}
            alt="Avatar"
            className="w-full h-full rounded-full"
          />
        </div>
        <div className="mt-3">
          {/* <div className="font-medium">Hey, {}!</div> */}
          <div className="mt-1 text-slate-500">
            Please select a chat to start messaging.
          </div>
        </div>
      </div>
    </div>
  )
}
