import { useState, useEffect } from 'react'

function useCheckConnection() {
  const [isOffline, setIsOffline] = useState(!navigator.onLine)

  useEffect(() => {
    const updateStatus = () => {
      setIsOffline(!navigator.onLine)
    }

    window.addEventListener('online', updateStatus)
    window.addEventListener('offline', updateStatus)

    return () => {
      window.removeEventListener('online', updateStatus)
      window.removeEventListener('offline', updateStatus)
    }
  }, [])

  return { isOffline }
}

export default useCheckConnection
