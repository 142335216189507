import {
  ETeacherRequestStatus,
  TPersonalInformation,
  TResponseData,
  TSpecificInformation,
} from 'src/pages/teacher-request-detail/fill-in-teacher-information/types'

export enum ERequestStatus {
  ACCEPTED = 'ACCEPTED',
  REQUEST_UPDATE = 'REQUEST_UPDATE',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  UPDATED = 'UPDATED',
}

export interface ITeacherAvatar {
  id: string | number
  url: string
  name: string
  path: string
  type: string
  cloudfrontUrl: string
}

export interface ITeacherInformation {
  email: string
  phone: string
  gender: string
  fullName: string
  firstname: string
  lastname: string
  dateOfBirth: string | null
  description: string
  avatar: ITeacherAvatar
}

export interface IResponseData {
  reason: string | null
  updatedBy: {
    name: string
    client: string
  }
}

export interface ITeacherRequestItem {
  id: number | string
  userId: number | string
  updatedAt: string | null
  createdAt: string | null
  deletedAt: string | null
  status: ERequestStatus
  personalInformation: ITeacherInformation
  responseData: IResponseData
}

export interface IListTeacherRequestData {
  items: ITeacherRequestItem[]
  total: number
}

export interface IRequestStatus {
  color: string
  label: React.ReactElement
}

export interface ITeacherRequestRowData {
  index: number | string
  id: number | string
  requesterName: string
  requestedDate: string | null
  updatedAt: string | null
  status: ERequestStatus
  responseData: IResponseData
}

export interface IMiniDashboardData {
  totalRequests: number
  totalPendingRequests: number
  totalNewRequestsThisWeek: number
  totalRequestsUpdated: number
}

export interface ITeacherRequestDetailData {
  id: number
  userId: number
  createdAt: null | string
  updatedAt: null | string
  deletedAt: null | string
  personalInformation: TPersonalInformation & {
    avatar: { id: number | string }
  }
  specificInformation: TSpecificInformation
  responseData: TResponseData
  status: ETeacherRequestStatus
}

export interface ITeacherRequestDetailError {
  code: number
  success: boolean
  message: string
  error: string
}

export interface ITeacherRequestFilterValue {
  requestStatus: ERequestStatus[]
  dateRange: any
  search: string
}
