import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { t } from 'i18next'
import { FieldValues, UseFormReset, UseFormSetValue } from 'react-hook-form'
import moment from 'moment'

import { getMiniDashboard, RootState, useAppDispatch } from '@redux'
import {
  EMiniDashBoardColor,
  SEARCH_FORM_FIELDS,
} from 'src/pages/test-management/config'
import { EAssignStatus, EGradingStatus } from 'src/pages/test-management/type'
import DashboardTag from 'src/pages/test-management/mini-dashboard/DashboardTag'
import {
  BookOutlined,
  CalendarOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons'
import { useTestContext } from 'src/pages/test-management/provider'
import { ENavBarRole } from '@configs'

interface IProps {
  reset: UseFormReset<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  reload?: boolean
}

const MiniDashboard = ({ setValue, reset, reload }: IProps) => {
  const dispatch = useAppDispatch()
  const { currentUserRole } = useTestContext()

  const { miniDashboard } = useSelector(
    (state: RootState) => state.testManagement
  )

  const thisWeek = useMemo(() => {
    const currentDate = moment()
    const startOfWeek = currentDate.clone().startOf('isoWeek').startOf('day')
    const endOfWeek = currentDate.clone().endOf('isoWeek').endOf('day')

    return [startOfWeek, endOfWeek]
  }, [])

  const getDataParams = useMemo(() => {
    const deadlineFrom = thisWeek[0].toISOString()
    const deadlineTo = thisWeek[1].toISOString()

    return { deadlineFrom: deadlineFrom ?? '', deadlineTo: deadlineTo ?? '' }
  }, [thisWeek])

  const renderData = useMemo(() => {
    if (!miniDashboard) return
    return [
      {
        title: [ENavBarRole.ADMIN].includes(currentUserRole as ENavBarRole)
          ? t('teacher:total_unassigned_test')
          : t('teacher:total_pending_test'),
        value: miniDashboard.totalUnAssignedOrPending,
        bgColor: EMiniDashBoardColor.LIGHT_PINK,
        logo: <UserDeleteOutlined className="text-[28px] text-black" />,
        onClick: () => {
          reset()
          if ([ENavBarRole.ADMIN].includes(currentUserRole as ENavBarRole)) {
            setValue(SEARCH_FORM_FIELDS.ASSIGN_STATUS, EAssignStatus.NA)
            setValue(SEARCH_FORM_FIELDS.GRADING_STATUS, [EGradingStatus.NA])
            return
          }
          setValue(SEARCH_FORM_FIELDS.ASSIGN_STATUS, EAssignStatus.PENDING)
          setValue(SEARCH_FORM_FIELDS.GRADING_STATUS, [EGradingStatus.NA])
        },
      },
      {
        title: t('teacher:total_grade_test'),
        value: miniDashboard.totalGradedTest,
        bgColor: EMiniDashBoardColor.LIGHT_PURPLE,
        logo: <CalendarOutlined className="text-[28px] text-black" />,
        onClick: () => {
          reset()
          setValue(SEARCH_FORM_FIELDS.GRADING_STATUS, [EGradingStatus.GRADED])
        },
      },
      {
        title: t('teacher:total_deadline_in_weak'),
        value: miniDashboard.deadlineInWeek,
        bgColor: EMiniDashBoardColor.LIGHT_BLUE,
        logo: <BookOutlined className="text-[28px] text-black" />,
        onClick: () => {
          reset()
          setValue(SEARCH_FORM_FIELDS.DATE_RANGE, thisWeek)
          setValue(SEARCH_FORM_FIELDS.GRADING_STATUS, [
            EGradingStatus.IN_PROGRESS,
            EGradingStatus.NA,
          ])
        },
      },
    ]
  }, [currentUserRole, miniDashboard, reset, setValue, thisWeek])

  useEffect(() => {
    dispatch(getMiniDashboard(getDataParams))
  }, [dispatch, getDataParams])

  useEffect(() => {
    if (!reload) {
      dispatch(getMiniDashboard(getDataParams))
    }
  }, [reload])

  return (
    <MiniDashboardContainer>
      {renderData?.map((item, index) => (
        <DashboardTag
          key={`${item.title}.${index}`}
          analysticNumber={item.value}
          title={item.title}
          bgColor={item.bgColor}
          logo={item.logo}
          onClick={item.onClick}
        />
      ))}
    </MiniDashboardContainer>
  )
}

export default MiniDashboard

const MiniDashboardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  gap: 32px;
`
