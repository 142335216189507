import { useAppSelector } from '@redux'
import React from 'react'
import { Spin } from 'antd'
import { Button } from 'src/common'
import { AppModal, XCloseIcon } from '@components'
import { t } from 'i18next'

interface IAddNewBlogProps {
  open: boolean
  content: string
  onPublish: () => void
  onDraft: () => void
  onClose: () => void
  loading?: boolean
}

const SaveEmailContentModal = ({
  open,
  onClose,
  content,
  onPublish,
  onDraft,
  loading,
}: IAddNewBlogProps) => {
  const addNewBlogActionLoading = useAppSelector(
    (state) => state.blog?.loadings?.addNewBlogActionLoading
  )
  return (
    <AppModal open={open} onClose={onClose}>
      {addNewBlogActionLoading ? (
        <div className="loading-wrapper flex items-center justify-center">
          <Spin />
        </div>
      ) : (
        <div className="wrapper-sendfile w-[400px] h-auto">
          <div className="flex items-center justify-between ">
            <div />
            <div className="hover:opacity-75">
              <XCloseIcon width={16} height={16} onClick={onClose} />
            </div>
          </div>

          <div className="mt-6">
            <div>
              <p>{content}</p>
            </div>
            <div className="flex items-center mt-5 justify-end">
              <Button
                loading={loading}
                disabled={loading}
                type="ghost"
                size="large"
                className="submit__btn login-btn mr-2"
                onClick={onPublish}
              >
                {t('common:publish_now')}
              </Button>
              <Button
                loading={loading}
                disabled={loading}
                type="primary"
                size="large"
                className="submit__btn login-btn"
                onClick={onDraft}
              >
                {t('common:save_draft')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </AppModal>
  )
}

export default SaveEmailContentModal
