export const themes = {
  theme: {
    light: {
      id: 'T01',
      name: 'Light',
      colors: {
        body: '#FFFFFF',
        text: '#373C49',
        textLight: '#FFFFFF',
        authText: '#373C49',
        subText: '#8E96AF',
        bgSection: '#FFFFFF', //box
        bgPage: '#f1f5f9',
        bgWrapper: '#FFFFFF',
        primary: '#009BB7',
        secondary: '#6c757d',
        error: '#e57373',
        warning: '#ffb74d',
        info: '#4fc3f7',
        success: '#81c784',
        edit: '#28a745',
        delete: '#dc3545',
        note: '#1aab45',
        highlight: '#66cd00',
        active: '#0b60e0',
        lightBlue: '#8fc3f7',
        sidebar: {
          background: '#e2e8f0',
          text: '#f1f5f9',
          active: '#009BB7',
        },
        header: {
          background: '#009BB7',
          text: '#fff',
          subText: '#abc0c7',
        },
        button: {
          text: '#64748b',
          background: '#f1f5f9',
          border: '#e2e8f0',
          borderBackground: '#ffffff',
          shadowPrimary: '0 0 4px 4px rgba(22,78,69,0.25)',
        },
        link: {
          text: 'teal',
          opacity: 1,
        },
        input: {
          border: '#e2e8f0',
        },
        table: {
          rowLight: '#ffffff',
          rowDark: '#f1f5f9',
        },
      },
      font: 'Roboto',
    },
    dark: {
      id: 'T02',
      name: 'Dark',
      colors: {
        body: '#706f6d',
        text: '#f1f5f9',
        textLight: '#f1f5f9',
        authText: '#373C49',
        subText: '#abc0c7',
        bgSection: '#232d45',
        bgPage: '#232d45',
        bgWrapper: '#28334e',
        primary: '#42a5f5',
        secondary: '#bcbcbc',
        error: '#d32f2f',
        warning: '#f57c00',
        info: '#0288d1',
        success: '#388e3c',
        edit: '#28a745',
        delete: '#dc3545',
        note: '#1aab45',
        highlight: '#66cd00',
        active: '#0b60e0',
        header: {
          background: '#28334e',
          text: '#f1f5f9',
          subText: '#abc0c7',
        },
        sidebar: {
          background: '#1b253b',
          text: '#f1f5f9',
          active: '#f57c00',
        },
        button: {
          text: '#f1f5f9',
          background: '#1b253b',
          border: '#e2e8f0',
          borderBackground: '#1b253b',
          shadowPrimary: '0 0 4px 4px rgba(22,78,69,0.25)',
        },
        link: {
          text: '#0d47a1',
          opacity: 0.8,
        },
        input: {
          border: '#e2e8f0',
        },
        table: {
          rowLight: '#ffffff',
          rowDark: '#f1f5f9',
        },
      },
      font: 'Roboto',
    },
  },
}
