export const FORM_FIELDS = {
  PERMISSION_NAME: 'permissionName',
  FILTER_BY_ROLE: 'filterByRole',
}

export const DEFAULT_FORM_FIELD_VALUES = {
  [FORM_FIELDS.PERMISSION_NAME]: '',
  [FORM_FIELDS.FILTER_BY_ROLE]: null,
}

export const ROLE_NAME_CHARACTER_LIMITATION = {
  MAX_LENGTH: 255,
  MIN_LENGTH: 2,
}

export const checkSelectAll = (listSelected: any, listOrigin: any) => {
  if (!listSelected?.length) return false

  return listSelected?.length === listOrigin?.length
}
