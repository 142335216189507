import { objectToQueryString } from '@utils'
import { ApiClient } from 'src/api/axiosClient'
import {
  ICreateTeacherAuthorizedRequest,
  ICreateTeacherLLCRequest,
  IGetTeacherParams,
  IGetTeacherResponse,
  ITeacherItem,
} from 'src/interfaces/teacher'

export const teacherManagementAPI = {
  getListTeacher: async (params: IGetTeacherParams) => {
    return await ApiClient.get<IGetTeacherResponse>('/teachers', {
      params: {
        ...params,
        ...(!!params.types && {
          types: params.types.join(','),
        }),
        sort: objectToQueryString(params.sort || {}) || undefined,
      },
    })
  },

  createTeacherLLC: async (params: ICreateTeacherLLCRequest) => {
    return await ApiClient.post<{
      message: string
      teacher: ITeacherItem
    }>('/teachers/teacher-llc', params)
  },

  createTeacherAuthorized: async (params: ICreateTeacherAuthorizedRequest) => {
    return await ApiClient.post<{
      message: string
      teacher: ITeacherItem
    }>('/teachers/teacher-authorize', params)
  },

  deleteTeacherById: async (id: number) => {
    return await ApiClient.delete<{
      message: string
      status: boolean
    }>(`/teachers/${id}`)
  },

  getTeacherById: async (id: number) => {
    return await ApiClient.get<ITeacherItem>(`/teachers/${id}`)
  },

  updateTeacherById: async (
    id: number,
    data: {
      phone: string
      name: string
      profilePhotoId?: number
      description?: string
      status: string
    }
  ) => {
    return await ApiClient.put<{
      message: string
      teacher: ITeacherItem
    }>(`/teachers/${id}`, data)
  },
}
