import styled from 'styled-components'
import { Card, Empty } from 'antd'
import { TTeacherSignature } from 'src/pages/teacher-request-detail/fill-in-teacher-information/types'

export const SignatureView = ({ data }: { data: TTeacherSignature }) => {
  return (
    <StyledDegreeView>
      {!!data?.fileId ? (
        <div className="flex items-center justify-center gap-7 min-h-[300px] mt-4">
          <img
            src={data?.signaturePhoto?.cloudfrontUrl}
            className="max-h-[300px]"
            alt="teacher signature"
          />
        </div>
      ) : (
        <div className="flex items-center justify-center gap-7">
          <Empty />
        </div>
      )}
    </StyledDegreeView>
  )
}

const StyledDegreeView = styled(Card)`
  border: none !important;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`
