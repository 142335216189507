import React from 'react'
import Gallery from 'src/components/gallery/Gallery'

type Props = {}

const GalleryScreen = (props: Props) => {
  return <Gallery isModal={false} />
}

export default GalleryScreen
