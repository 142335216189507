import {
  ICreateEditTeacherPackage,
  IGetTeacherPackageParams,
  TGetTeacherPackageDetailsResponse,
  TGetTeacherPackagesResponse,
  TTeacherPackage,
} from '@interfaces'
import { objectToQueryString } from '@utils'
import { ApiClient } from 'src/api/axiosClient'

export const TeacherPackageManagementApi = {
  getListTeacherPackages: async (params: IGetTeacherPackageParams) => {
    return await ApiClient.get<TGetTeacherPackagesResponse>(
      '/teacher-package',
      {
        params: {
          ...params,
          sort: objectToQueryString(params.sort || {}) || undefined,
        },
      }
    )
  },

  getTeacherPackageById: async (id: number) => {
    return await ApiClient.get<TGetTeacherPackageDetailsResponse>(
      `/teacher-package/${id}`
    )
  },

  deleteTeacherPackageById: async (id: number, replacePackageId: number) => {
    return await ApiClient.delete<{ message: string; success: boolean }>(
      `/teacher-package/${id}`,
      {
        packageId: replacePackageId,
      }
    )
  },

  createTeacherPackage: async (data: ICreateEditTeacherPackage) => {
    return await ApiClient.post<{
      message: string
      teacherPackage: TTeacherPackage
    }>(`/teacher-package`, data)
  },

  updateTeacherPackageById: async (
    id: number,
    data: ICreateEditTeacherPackage
  ) => {
    return await ApiClient.put<{
      message: string
      teacherPackage: TTeacherPackage
    }>(`/teacher-package/${id}`, data)
  },
}
