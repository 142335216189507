import { TERM_POLICIES_PAGE } from '@configs'
import React from 'react'
import TermAndPolicyPage from 'src/components/declaration/term-and-policy-page/TermAndPolicyPage'

type Props = {}

const TermAndPolicyContent = (props: Props) => {
  return <TermAndPolicyPage activeTabParent={TERM_POLICIES_PAGE} />
}

export default TermAndPolicyContent
