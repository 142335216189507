import { EPermissions, IPermissions } from '@configs'
import { useAppSelector } from '@redux'
import { useCallback, useMemo } from 'react'

export default function usePermissions({
  moduleName,
}: {
  moduleName?: EPermissions | ''
}) {
  const accountInfo = useAppSelector((state) => state.auth.accountInfo)
  const { role } = accountInfo || {}
  const { permissions = {}, isAdmin } = role || {}

  const permissionObjAll = useMemo(() => {
    const permissionEntriesArray = Object.entries(permissions)

    if (
      !Array.isArray(permissionEntriesArray) ||
      !permissionEntriesArray.length
    )
      return null

    return permissionEntriesArray.reduce((acc, cur) => {
      if (typeof cur[1] === 'object') {
        return { ...acc, ...cur[1] }
      }

      return { ...acc, [cur[0]]: cur[1] }
    }, {})
  }, [permissions])

  const permissionByModule = useMemo(() => {
    if (!moduleName) return ''
    if (!(permissions as IPermissions)?.[moduleName as keyof IPermissions])
      return ''

    return (permissions as IPermissions)?.[moduleName as keyof IPermissions]
  }, [moduleName, permissions])

  const getPermissions = useCallback(
    (moduleName: EPermissions) => {
      if (!moduleName) return false

      if (!(permissions as IPermissions)?.[moduleName as keyof IPermissions])
        return false

      return (permissions as IPermissions)?.[moduleName as keyof IPermissions]
    },
    [permissions]
  )

  return {
    isAdmin,
    permissionByModule,
    permissionObjAll,
    permissions,
    getPermissions,
  }
}
