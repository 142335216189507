import { PLACEHOLDER_IMAGE_AVATAR } from '@configs'
import { Typography } from 'antd'
import moment from 'moment'
import { Fragment } from 'react'
import { useChatContext } from '../ChatContext'
import {
  resetListMessageWithUser,
  setPage,
  updateLastestMessage,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { EMessageType } from 'src/interfaces/course'
import { useTranslation } from 'react-i18next'

export default function ConversationItem({
  conversation,
}: {
  conversation: any
}) {
  const { t } = useTranslation(['courseManagement', 'common'])
  const { selectedConversation, setSelectedConversation } = useChatContext()
  const dispatch = useAppDispatch()
  const accountInfo = useAppSelector((state) => state.auth.accountInfo)

  const {
    content: lastestMessage,
    unreadMessages = 0,
    createdAt,
    enrollCourse,
    isRead,
    type,
    userId,
    enrollCourseId,
  } = conversation || {}
  const { user } = enrollCourse || {}
  const isMyMessage = accountInfo?.id === userId
  const isCurrentChatRoom =
    String(selectedConversation?.userId) === String(user?.id)

  const handleClickConversation = () => {
    if (isCurrentChatRoom) return

    setSelectedConversation({
      name: user?.name,
      profileUrl: user?.profilePhoto?.url,
      userId: user?.id,
      enrollCourseId,
    })
    dispatch(resetListMessageWithUser())
    dispatch(setPage({ page: 1 }))
    dispatch(
      updateLastestMessage({
        isRead: true,
        senderId: enrollCourseId,
        lastestMessage,
        createdAt,
        user,
        type,
        userId,
      })
    )
  }

  return (
    <div
      className={`intro-x cursor-pointer box relative flex items-center p-5 
    bg-[${isCurrentChatRoom ? '#e2e8f0' : '#f1f5f9'}] 
    rounded-md`}
      style={{
        backgroundColor: isCurrentChatRoom ? '#e2e8f0' : '#f1f5f5',
      }}
      onClick={handleClickConversation}
    >
      <div className="image-fit mr-1 h-12 w-12 flex-none">
        <img
          className="rounded-full w-full h-full object-cover"
          src={user?.profilePhoto?.url ?? PLACEHOLDER_IMAGE_AVATAR}
          alt="Avatar"
        />
      </div>
      <div className="ml-2 overflow-hidden w-full">
        <div className="flex items-center">
          <div className="font-medium max-w-[65%] truncate #009BB7 ">
            {user?.name}
          </div>
        </div>
        <div className="mt-0.5 w-[85%] truncate text-slate-500">
          {(() => {
            const senderName = isMyMessage ? t('common:you') : user?.name
            const previewMessage = [
              EMessageType.FILE,
              EMessageType.IMAGE,
            ].includes(type)
              ? ` ${t('courseManagement:just_sent_a_file')}`
              : `: ${lastestMessage}`

            return `${senderName}${previewMessage}`
          })()}
        </div>
      </div>

      <Typography
        style={{
          fontSize: 12,
          color: '#94A3B8',
          textWrap: 'nowrap',
          marginRight: 4,
        }}
      >
        {moment(createdAt).format('DD/MM/YYYY')}
      </Typography>

      {(() => {
        if (isRead || !unreadMessages) return null

        return (
          <Fragment>
            <div
              className={`flex h-5 w-5 p-2 items-center justify-center rounded-full bg-red-500 text-xs font-medium text-white ${
                unreadMessages > 9 ? '!w-auto' : ''
              }`}
            >
              {unreadMessages > 9 ? '9+' : unreadMessages}
            </div>
          </Fragment>
        )
      })()}
    </div>
  )
}
