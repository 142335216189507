import {
  EActivityLogActionType,
  EActivityLogType,
  LIST_USER_LOG_ACTION,
  thunkActionLoading,
} from '@configs'
import { RootState, useAppDispatch, useAppSelector } from '@redux'
import { Divider, Skeleton } from 'antd'
import { camelCase, startCase } from 'lodash'
import { useEffect } from 'react'
import { Input } from 'src/common'
import { AppModal } from 'src/components/AppModal'
import { getUserLogByIdAction } from 'src/redux/actions/user-log'
import { XCloseIcon } from '../Icon'
import moment from 'moment'

interface IDetailUserLogModalProps {
  open: boolean
  onClose: () => void
  logId: number | string
  title?: string
}

const DetailUserLogModal = (props: IDetailUserLogModalProps) => {
  const dispatch = useAppDispatch()
  const { open, onClose, logId, title } = props

  const { userLog } = useAppSelector((state: RootState) => state.userLog)

  const getDetailUserLog = async () => {
    await dispatch(getUserLogByIdAction(logId as string))
  }

  const getUserLogByIdActionLoading = useAppSelector(
    (state: RootState) =>
      state?.userLog?.loadings[
        thunkActionLoading.GET_USER_LOG_BY_ID_ACTION_LOADING
      ]
  )

  const renderTitle = () => {
    switch (userLog?.action) {
      case EActivityLogActionType.LOGIN_SUCCESS:
        return `${userLog?.adminTitle} ${userLog.metaData?.data?.deviceName}`
      case EActivityLogActionType.LOGOUT_SUCCESS:
        return `${userLog?.adminTitle} ${userLog.metaData?.data?.deviceName}`
      case EActivityLogActionType.PROFILE_UPDATE:
        const displayName = `${userLog?.adminTitle} (${Object.keys(
          userLog?.metaData?.data?.currentData ?? {}
        )})`
        return displayName
      case EActivityLogActionType.FRIEND_REQUEST:
        const displayName2 = `${userLog?.adminTitle} ${userLog.metaData?.data?.user?.name}`
        return displayName2
      case EActivityLogActionType.REACTION:
        const displayName3 = `${userLog?.adminTitle} with ID: ${userLog.metaData?.data?.post?.id}`
        return displayName3
      case EActivityLogActionType.POST:
        const displayName4 = `${userLog?.adminTitle} with ID: ${userLog.metaData?.data?.post?.id}`
        return displayName4
      case EActivityLogActionType.COMMENT:
        const displayName5 = `${userLog?.adminTitle} with ID: ${userLog.metaData?.data?.post?.id}`
        return displayName5
      default:
        return userLog?.adminTitle
    }
  }

  useEffect(() => {
    logId && getDetailUserLog()
  }, [logId])

  return (
    <AppModal open={open} onClose={onClose}>
      <div className="bg-white  w-[1000px]">
        {getUserLogByIdActionLoading ? (
          <Skeleton paragraph={{ rows: 4 }} />
        ) : (
          <div className="wrapper-modal">
            <div className="flex items-center justify-between ">
              {title && (
                <div>
                  <h1 className="m-0 text-[20px]">{title}</h1>
                </div>
              )}
              <div className="hover:opacity-75 cursor-pointer">
                <XCloseIcon width={16} height={16} onClick={onClose} />
              </div>
            </div>
            <div className="mt-6 flex flex-col gap-3 max-h-[50vh] overflow-auto">
              <Input
                label="Action"
                name="action"
                value={userLog?.action}
                style={{
                  boxShadow: 'none',
                }}
                isViewMode={true}
              />
              <Input
                label="Type"
                name="type"
                value={LIST_USER_LOG_ACTION[userLog?.type as EActivityLogType]}
                style={{
                  boxShadow: 'none',
                }}
                isViewMode={true}
              />
              <Input
                label="Title:"
                name="title"
                value={renderTitle()}
                // value={userLog?.adminTitle}
                style={{
                  boxShadow: 'none',
                }}
                isViewMode={true}
              />
              <Input
                label="Time:"
                name="time"
                value={moment(userLog?.createdAt).format(
                  'HH:mm:ss - DD MMM YYYY'
                )}
                style={{
                  boxShadow: 'none',
                }}
                isViewMode={true}
              />

              {(userLog?.action === EActivityLogActionType.LOGIN_SUCCESS ||
                userLog?.action === EActivityLogActionType.LOGOUT_SUCCESS) && (
                <Input
                  label="Authentication events:"
                  name="event"
                  value={`${userLog.adminTitle} ${
                    userLog?.metaData?.data?.deviceName &&
                    userLog?.metaData?.data?.deviceName
                  }`}
                  style={{
                    boxShadow: 'none',
                  }}
                  isViewMode={true}
                />
              )}
              {/* {userLog?.action === EActivityLogActionType.PROFILE_UPDATE && (
                <div className="profile-update">
                  <div className="previous-data">
                    <div className="previous-data-header flex items-center">
                      <div className="font-bold">Previous Data</div>
                      <div className="flex-1">
                        <Divider />
                      </div>
                    </div>
                    <div className="data-change-body flex flex-col gap-2">
                      {previousData &&
                        previousData?.map((item, index) => (
                          <Input
                            key={index}
                            label={startCase(camelCase(item?.key))}
                            name={item?.key}
                            value={item?.value ?? '--'}
                            style={{
                              boxShadow: 'none',
                            }}
                          />
                        ))}
                    </div>
                  </div>

                  <div className="current-data">
                    <div className="current-data-header flex items-center">
                      <div className="font-bold">Current Data</div>
                      <div className="flex-1">
                        <Divider />
                      </div>
                    </div>
                    <div className="data-change-body flex flex-col gap-2">
                      {currentData &&
                        currentData?.map((item, index) => (
                          <Input
                            key={index}
                            label={startCase(camelCase(item?.key))}
                            name={item?.key}
                            value={item?.value ?? '--'}
                            style={{
                              boxShadow: 'none',
                            }}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        )}
      </div>
    </AppModal>
  )
}

export default DetailUserLogModal
