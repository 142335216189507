import { deleteRoleByID, getListPermissionRoles, useAppDispatch } from '@redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Button } from 'src/common'
import { AppModal } from 'src/components/AppModal'
import { XCloseIcon } from 'src/components/Icon'
import { IChosenRole } from '@interfaces'
import { Typography } from 'antd'
import { useState } from 'react'
interface IDeleteRoleDialogProps {
  open: boolean
  chosenRole: IChosenRole | null
  onClose: () => void
}

export default function DeleteRoleDialog({
  open,
  chosenRole,
  onClose,
}: IDeleteRoleDialogProps) {
  const { t } = useTranslation(['common', 'staffAndRole'])
  const dispatch = useAppDispatch()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleDeleteRole = async () => {
    try {
      setIsSubmitting(true)
      await dispatch(deleteRoleByID(Number(chosenRole?.id))).unwrap()

      setIsSubmitting(false)
      toast.success(t('staffAndRole:delete_role_successfully'))
      dispatch(getListPermissionRoles())
      onClose()
    } catch (error: any) {
      setIsSubmitting(false)
      toast.error(
        error?.message ||
          error?.data?.message ||
          t('staffAndRole:something_went_wrong')
      )
    }
  }

  return (
    <AppModal open={open} onClose={onClose} contentClassName="!px-8 !py-6">
      <div className="w-[400px] h-auto">
        <div className="flex items-center justify-between ">
          <div>
            <h1 className="m-0 text-[24px]">{t('staffAndRole:delete_role')}</h1>
          </div>
          <div className="hover:opacity-75 cursor-pointer">
            <XCloseIcon width={16} height={16} onClick={onClose} />
          </div>
        </div>

        <Typography.Text style={{ fontSize: 16 }}>
          {t('staffAndRole:delete_role_message')}
        </Typography.Text>

        <div className="flex justify-end items-center my-2 mt-4 gap-2">
          <Button type="ghost" size="small" onClick={onClose}>
            {t('common:cancel')}
          </Button>

          <Button
            type="primary"
            size="small"
            loading={isSubmitting}
            onClick={handleDeleteRole}
          >
            {t('common:confirm')}
          </Button>
        </div>
      </div>
    </AppModal>
  )
}
