export function getKeyEnumFromValue(
  enumObj: any,
  value: number | string
): string | null {
  for (const key in enumObj) {
    if (enumObj[key] === value) {
      return key
    }
  }
  return null
}
