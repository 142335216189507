import {
  createContext,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'

import { ETeacherRole } from '@configs'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import CreateTeacherModal from 'src/pages/teacher-management/components/CreateTeacherModal'
import { ICreateTeacher } from 'src/interfaces/teacher'

interface IDeleteModalContent {
  teacherId?: number
  open: boolean
  title?: string
  content?: string
  isLoading?: boolean
  highlight?: boolean
  onConfirmDelete?: () => void
  onClose?: () => void
}

interface ICreateTeacherModalContent {
  type?: ETeacherRole
  open: boolean
  isLoading?: boolean
  resetDragger?: boolean
  isSubmitFinished?: boolean
  onClose?: () => void
}

interface TeacherContextProps {
  deleteModalContent: IDeleteModalContent
  setDeleteModalContent: (value: SetStateAction<IDeleteModalContent>) => void
  createTeacherModalContent: ICreateTeacherModalContent
  setCreateTeacherModalContent: (
    value: SetStateAction<ICreateTeacherModalContent>
  ) => void
}

const initTeacherContext = {
  deleteModalContent: {
    open: false,
    title: '',
    content: '',
    isLoading: false,
    highlight: false,
    onConfirmDelete: () => {},
    onClose: () => {},
  },
  setDeleteModalContent: (value: SetStateAction<IDeleteModalContent>) => {},
  createTeacherModalContent: {
    open: false,
    isLoading: false,
    resetDragger: false,
    isSubmitFinished: false,
    onClose: () => {},
  },
  setCreateTeacherModalContent: (
    value: SetStateAction<ICreateTeacherModalContent>
  ) => {},
}

const TeacherContext = createContext<TeacherContextProps>(initTeacherContext)

interface TeacherProviderProps extends React.PropsWithChildren<any> {}

function TeacherProvider(props: TeacherProviderProps) {
  const { children } = props

  const [deleteModalContent, setDeleteModalContent] =
    useState<IDeleteModalContent>(initTeacherContext.deleteModalContent)
  const [createTeacherModalContent, setCreateTeacherModalContent] =
    useState<ICreateTeacherModalContent>(
      initTeacherContext.createTeacherModalContent
    )

  useEffect(() => {
    if (!deleteModalContent?.open) {
      setDeleteModalContent(initTeacherContext.deleteModalContent)
    }
  }, [deleteModalContent?.open])

  useEffect(() => {
    if (!createTeacherModalContent?.open) {
      setCreateTeacherModalContent(initTeacherContext.createTeacherModalContent)
    }
  }, [createTeacherModalContent?.open])

  return (
    <TeacherContext.Provider
      value={{
        deleteModalContent,
        setDeleteModalContent,
        createTeacherModalContent,
        setCreateTeacherModalContent,
      }}
    >
      {children}
      <ConfirmDeleteModal
        title={deleteModalContent?.title ?? ''}
        content={deleteModalContent?.content ?? ''}
        open={deleteModalContent.open}
        isLoading={deleteModalContent?.isLoading ?? false}
        onDelete={() => {
          deleteModalContent?.onConfirmDelete &&
            deleteModalContent?.onConfirmDelete()
        }}
        onClose={() => {
          setDeleteModalContent(initTeacherContext.deleteModalContent)
          deleteModalContent.onClose && deleteModalContent.onClose()
        }}
        highlight={deleteModalContent.highlight}
      />
      <CreateTeacherModal
        open={createTeacherModalContent.open}
        onClose={() => {
          setCreateTeacherModalContent({
            ...initTeacherContext.createTeacherModalContent,
            resetDragger: !createTeacherModalContent.resetDragger,
          })
          createTeacherModalContent.onClose &&
            createTeacherModalContent.onClose()
        }}
        defaultType={createTeacherModalContent.type}
      />
    </TeacherContext.Provider>
  )
}

function useTeacherContext() {
  const context = useContext(TeacherContext)

  if (!context) {
    throw new Error('useTeacherContext must be used within a SectionsProvider')
  }

  return context
}

export { TeacherProvider, useTeacherContext }
