import styled from 'styled-components'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { t } from 'i18next'
import { message } from 'antd'
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons'

import { TeacherContainer } from 'src/pages/teacher-management'
import { ShareSelectInput } from '@components'
import { useDebounce } from 'src/hooks/useDebounce'
import { Button, Input, Loading } from 'src/common'

import {
  createTeacherPackageAction,
  deleteTeacherPackageByIdAction,
  getAllTeacherPackagesAction,
  getTeacherPackageDetailsByIdAction,
  RootState,
  setTeacherPackagePage,
  teacherPackagesActions,
  updateTeacherPackageByIdAction,
  useAppDispatch,
} from '@redux'
import {
  ICreateEditTeacherPackage,
  IGetTeacherPackageParams,
} from '@interfaces'
import { EPackagePrivacy, thunkActionLoading } from '@configs'
import TeacherPackageManagementTable from 'src/pages/package-management/teacher-package-management/components/TeacherPackageManagementTable'
import {
  EPackageStatus,
  ETeacherPackageAction,
  TEACHER_PACKAGE_COLUMN,
  TEACHER_PACKAGE_STATUS_OPTIONS,
} from 'src/pages/package-management/teacher-package-management/config'
import DeletePackageModal from 'src/pages/package-management/teacher-package-management/components/DeletePackageModal'
import PackageCreateEditModal from 'src/pages/package-management/teacher-package-management/components/package-create-edit-modal/PackageCreateEditModal'
import { getListPackageAction } from 'src/redux/actions/package-management'
import { deleteObjectFields } from '@utils'

const TeacherPackageManagementPage = () => {
  const dispatch = useAppDispatch()

  const [searchValue, setSearchValue] = useState('')
  const [deleteModal, setDeleteModal] = useState<{
    open: boolean
    currentPackageId?: number
  }>({ open: false })
  const [modalTeacherPackage, setModalTeacherPackage] = useState<{
    open: boolean
    selectedPackageId?: number
  }>({ open: false })
  const debouceSearch = useDebounce(searchValue.trim(), 500)
  const [status, setStatus] = useState<EPackageStatus>(EPackageStatus.ALL)

  const {
    teacherPackages,
    limitTeacherPackagesPerPage,
    teacherPackagesTotalItems,
    teacherPackagesCurrentPage,
    teacherPackagesTotalPage,
    loadings: teacherPackageLoading,
  } = useSelector((state: RootState) => state.teacherPackages)

  const isGetTeacherPackageLoading =
    teacherPackageLoading[thunkActionLoading.GET_ALL_TEACHER_PACKAGE_LOADING]

  const getTeacherPackageParams = useMemo(
    () => ({
      page: teacherPackagesCurrentPage,
      ...(debouceSearch && { search: debouceSearch }),
      ...(![EPackageStatus.ALL].includes(status) && {
        isActive: status === EPackageStatus.ACTIVE ? true : false,
      }),
    }),
    [debouceSearch, status, teacherPackagesCurrentPage]
  )

  const handleCloseModalTeacherPackage = () => {
    setModalTeacherPackage({ open: false })
    dispatch(teacherPackagesActions.clearSelectedPackage())
  }

  const getTeacherPackages = async (params?: IGetTeacherPackageParams) => {
    dispatch(
      getAllTeacherPackagesAction({
        ...params,
      })
    )
  }

  const getCurrentPage = ({
    totalPages,
    totalRecord,
    currentPage,
  }: {
    totalPages: number
    totalRecord: number
    currentPage: number
  }) => {
    if (currentPage < totalPages) return currentPage

    return Math.ceil((totalRecord - 1) / 10)
  }

  const deletePackage = async (selectPackageId: number) => {
    try {
      const res = await dispatch(
        deleteTeacherPackageByIdAction({
          id: deleteModal.currentPackageId ?? 0,
          replacePackageId: selectPackageId,
        })
      ).unwrap()

      if (!res?.success) return

      message.success(res?.message)

      const pageNumberAfterDeleted = getCurrentPage({
        totalPages: teacherPackagesTotalPage,
        totalRecord: teacherPackagesTotalItems,
        currentPage: teacherPackagesCurrentPage,
      })

      if (pageNumberAfterDeleted !== teacherPackagesCurrentPage) {
        dispatch(
          setTeacherPackagePage({
            page: pageNumberAfterDeleted,
          })
        )

        return
      }

      getTeacherPackages({
        ...getTeacherPackageParams,
        page: pageNumberAfterDeleted,
      })
    } catch (error: any) {
      message.error(error?.message ?? error.errors[0] ?? '')
    }
  }

  const handleCreateEditTeacherPackage = async (
    data: ICreateEditTeacherPackage
  ) => {
    if (
      !data?.accessModule?.course?.premiumLimit ||
      !data?.accessModule?.course?.isPublicPremiumLimit
    ) {
      deleteObjectFields(data?.accessModule?.course, ['isPublicPremiumLimit'])
    }

    if (
      !data?.accessModule?.course?.memberLimit ||
      !data?.accessModule?.course?.isPublicMemberLimit
    ) {
      deleteObjectFields(data?.accessModule?.course, ['memberLimit'])
    }

    if (!data?.accessModule?.revenue?.defaultRate) {
      deleteObjectFields(data?.accessModule?.revenue, ['defaultRate'])
    }

    deleteObjectFields(data?.accessModule?.course, [
      'isPublicMemberLimit',
      'isPublicPremiumLimit',
    ])

    try {
      let res
      if (modalTeacherPackage.selectedPackageId) {
        res = await dispatch(
          updateTeacherPackageByIdAction({
            id: modalTeacherPackage.selectedPackageId,
            payload: data,
          })
        ).unwrap()
      } else {
        res = await dispatch(createTeacherPackageAction(data)).unwrap()
      }

      if (res?.teacherPackage) {
        message.success(res?.message)
        handleCloseModalTeacherPackage()
        getTeacherPackages(getTeacherPackageParams)
      }
    } catch (error: any) {
      // message.error(error?.message)
    }
  }

  const columns = useMemo(() => {
    return Object.values(TEACHER_PACKAGE_COLUMN)?.map((column) => ({
      ...column,
      ...([ETeacherPackageAction.DELETE as string].includes(column.key) && {
        render: (_: any, record: { id: number; isActive: boolean }) => (
          <div className="flex space-x-4 justify-center items-center">
            <DeleteOutlined
              disabled={record.isActive}
              className={`text-lg font-light mr-2.5 ${
                record.isActive && 'opacity-40'
              } !text-[#DE3B40] cursor-pointer`}
              onClick={() => {
                if (record.isActive) return
                setDeleteModal({ open: true, currentPackageId: record.id })
              }}
            />
          </div>
        ),
      }),
      ...([ETeacherPackageAction.EDIT as string].includes(column.key) && {
        render: (_: any, record: { id: number }) => (
          <div className="flex space-x-4 justify-center items-center">
            <EditOutlined
              className={`text-lg font-light mr-2.5`}
              onClick={() =>
                setModalTeacherPackage({
                  open: true,
                  selectedPackageId: record.id,
                })
              }
            />
          </div>
        ),
      }),
    }))
  }, [])

  useEffect(() => {
    getTeacherPackages(getTeacherPackageParams)
  }, [getTeacherPackageParams])

  useEffect(() => {
    if (modalTeacherPackage.open) {
      dispatch(
        getListPackageAction({
          page: 1,
          limit: 10000,
          isActive: true,
          type: EPackagePrivacy.PUBLIC,
        })
      )
    }

    if (modalTeacherPackage.selectedPackageId) {
      dispatch(
        getTeacherPackageDetailsByIdAction(
          modalTeacherPackage.selectedPackageId
        )
      )
    }
  }, [
    dispatch,
    modalTeacherPackage.open,
    modalTeacherPackage.selectedPackageId,
  ])

  return (
    <>
      {modalTeacherPackage.open ? (
        <PackageCreateEditModal
          open={modalTeacherPackage.open}
          onClose={handleCloseModalTeacherPackage}
          selectedPackageId={modalTeacherPackage.selectedPackageId}
          onSubmit={handleCreateEditTeacherPackage}
        />
      ) : null}
      <DeletePackageModal
        open={deleteModal.open}
        currentPackageId={deleteModal.currentPackageId ?? -1}
        onClose={() => setDeleteModal({ open: false })}
        onConfirmDelete={deletePackage}
      />
      <TeacherContainer>
        <div className="flex items-center justify-between py-[1.25rem] w-full">
          <div className="max-w-[30dvw] flex-1 flex gap-8">
            <Input
              prefix={<SearchOutlined className="pl-2" />}
              placeholder={t('teacher:search_package_name_or_price')}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value)
              }}
              suffix={isGetTeacherPackageLoading && <Loading />}
            />
          </div>
          <FilterWrapper>
            <div className="wrapper-status-filter flex items-center justify-center gap-4">
              <ShareSelectInput
                data={TEACHER_PACKAGE_STATUS_OPTIONS}
                onChange={(value) => setStatus(value as EPackageStatus)}
                placeholder="Status"
                className="w-max min-w-[8rem] capitalize"
                showArrow
              />
              <Button
                type="primary"
                onClick={() => {
                  setModalTeacherPackage({ open: true })
                }}
              >
                Create new package
              </Button>
            </div>
          </FilterWrapper>
        </div>
        <TeacherPackageManagementTable
          columns={columns}
          teacherPackageData={teacherPackages}
          totalItem={teacherPackagesTotalItems}
          currentPage={teacherPackagesCurrentPage}
          limitItem={limitTeacherPackagesPerPage}
          isLoading={isGetTeacherPackageLoading}
          getTeacherPackagePage={(page) =>
            getTeacherPackages({ ...getTeacherPackageParams, page })
          }
        />
      </TeacherContainer>
    </>
  )
}

export default TeacherPackageManagementPage

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  gap: 32px;
`
