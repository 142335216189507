import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import { EAnswerType, ETestSectionType } from '@configs'
import { IQuestion } from '@interfaces'
import { Card, Divider, List, Row } from 'antd'
import { t } from 'i18next'
import { useMemo } from 'react'
import styled from 'styled-components'

const Question = ({
  item,
  index,
  onRemove,
  onEdit,
  isMultipleChoiceSection,
  disabled,
}: {
  item: IQuestion
  index: number
  onRemove: () => void
  onEdit: () => void
  isMultipleChoiceSection?: boolean
  disabled?: boolean
}) => {
  const getTag = useMemo(() => {
    if (isMultipleChoiceSection) {
      return item.type === EAnswerType.MULTIPLE_CHOICE ||
        item.isMultipleChoice ? (
        <BlueTag>{t('section:multiple_choices')}</BlueTag>
      ) : (
        <YellowTag>{t('section:single_choice')}</YellowTag>
      )
    } else {
      return item.type === EAnswerType.UPLOAD ? (
        <YellowTag>{t('section:uploading')}</YellowTag>
      ) : (
        <BlueTag>{t('section:writting')}</BlueTag>
      )
    }
  }, [isMultipleChoiceSection, item])

  return (
    <Card
      title={`Question ${+index + 1}: ${item.content}`}
      bordered={true}
      className="w-full"
    >
      <div className="w-full">
        {isMultipleChoiceSection && item.answers && (
          <>
            <span className="text-black text-[18px] text-bold">
              {t('section:number_of_answers', { num: item.answers.length })}
            </span>
            <Divider orientation="left" />
          </>
        )}
        <div className="flex items-center justify-between">
          {getTag}

          <ActionWrapper>
            {disabled ? (
              <EyeOutlined className="text-[18px]" onClick={onEdit} />
            ) : (
              <>
                <EditOutlined className="text-[18px]" onClick={onEdit} />
                <DeleteOutlined
                  className="text-[18px] !text-red-500"
                  onClick={() => onRemove()}
                />
              </>
            )}
          </ActionWrapper>
        </div>
      </div>
    </Card>
  )
}

const ActionWrapper = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 12px;
`
const BlueTag = styled.div`
  padding: 4px 8px;
  border-radius: 12px;
  background-color: #f1f8fd;
  color: #60b2f1;
  font-size: 16px;
`

const YellowTag = styled.div`
  padding: 4px 8px;
  border-radius: 12px;
  background-color: #fef9ee;
  color: #6e572a;
  font-size: 16px;
`

export default Question
