import DeclarationTabs from 'src/components/tab/declaration/Declaration'

interface Props {}

const DeclarationPage = ({}: Props) => {
  return (
    <div>
      {/* <Tabs>
        {ITEMS_IN_DECLARATION_MANAGEMENT.map((item, index) => {
          return (
            <Tabs.TabPane tab={item} key={item}>
              {item}
            </Tabs.TabPane>
          )
        })}
      </Tabs> */}
      <DeclarationTabs />
    </div>
  )
}

export default DeclarationPage
