import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { t } from 'i18next'

import {
  ETeacherRole,
  ETeacherRoleTitle,
  FORMAT_DATE_TABLE,
  PATH_COURSE_MANAGEMENT,
  PATH_TEACHER_MANAGEMENT,
  PATH_TEST_MANAGEMENT,
} from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Input, TextArea } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import { EMediaCategory, EMediaType } from 'src/interfaces/gallery'
import { ITeacherItem, ITeacherOverviewForm } from 'src/interfaces/teacher'
import {
  ETeacherStatus,
  TEACHER_TYPE_OPTIONS,
  TeacherOverviewSchema,
} from 'src/pages/teacher-management/config'
import { StyledHookFormSwitch } from 'src/components/editor/ui/StyledHookFormSwitch'
import { ShareSelectInput } from '@components'
import { INPUT_CLASSNAME } from 'src/pages/teacher-management/styled'
import { formatDate } from '@utils'
import { teachersActions, useAppDispatch } from '@redux'

const defaultValues = {
  type: '',
  name: '',
  email: '',
  description: '',
  isActive: true,
  phone: '',
  createdAt: '',
  lastActiveTime: '',
  profilePhotoId: null,
}

interface IProps {
  teacher: ITeacherItem
  isLoading?: boolean
  disabled?: boolean
  onSubmit: (data: ITeacherOverviewForm) => {}
  onUploading?: (loading: boolean) => void
}

const TeacherOverviewForm = ({
  disabled,
  teacher,
  onSubmit,
  isLoading,
  onUploading,
}: IProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const {
    control,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
  } = useForm<ITeacherOverviewForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
    resolver: zodResolver(TeacherOverviewSchema),
  })

  const handleClickButtonView = () => {
    if ([ETeacherRole.LLC as string].includes(teacher?.client)) {
      navigate(`${PATH_TEST_MANAGEMENT}?teacherId=${teacher?.id}`)
      return
    }
    navigate(`${PATH_COURSE_MANAGEMENT}?search=${teacher?.name}`)
  }

  useEffect(() => {
    reset({
      ...teacher,
      profilePhotoId: teacher?.profile_photo_id,
      isActive: [ETeacherStatus.ACTIVE as string].includes(teacher?.status),
      type: [ETeacherRole.LLC as string].includes(teacher?.client)
        ? ETeacherRoleTitle.LLC
        : ETeacherRoleTitle.AUTHORIZED,
    })
  }, [teacher])

  return (
    <FormStyled onSubmit={handleSubmit(onSubmit)}>
      <InputContainer>
        <InputSectionWrapper>
          <Controller
            name="type"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <ShareSelectInput
                  data={TEACHER_TYPE_OPTIONS}
                  label="Type"
                  placeholder="Type"
                  onChange={onChange}
                  value={value}
                  errors={error?.message}
                  className={`${INPUT_CLASSNAME}`}
                  containerClassName="w-full"
                  disabled
                  showArrow
                />
              )
            }}
          />
          <Controller
            name="name"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  placeholder="Fullname"
                  value={value}
                  onChange={onChange}
                  required
                  label="Fullname"
                  alignment="col"
                  errors={error?.message}
                  className={`${INPUT_CLASSNAME}`}
                />
              )
            }}
          />
          {[ETeacherRole.AUTHORIZED as string].includes(
            teacher?.client
          ) ? null : (
            <Controller
              name="email"
              control={control}
              render={({ field: { value }, fieldState: { error } }) => {
                return (
                  <Input
                    placeholder="Email"
                    value={value}
                    label="Email"
                    alignment="col"
                    errors={error?.message}
                    className={`${INPUT_CLASSNAME}`}
                    disabled
                  />
                )
              }}
            />
          )}

          {[ETeacherRole.LLC as string].includes(teacher?.client) ? null : (
            <Controller
              name="description"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <TextArea
                    label="Bio"
                    name="description"
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                    containerClassName="mt-3"
                  />
                )
              }}
            />
          )}

          <StyledHookFormSwitch
            control={control}
            controlName="isActive"
            label="Status"
            align="row"
            onChange={(checked) => {
              setValue('isActive', checked)
            }}
          />
          <Controller
            name={'profilePhotoId'}
            control={control}
            render={({ field: { onChange }, fieldState: { error } }) => {
              return (
                <CustomDragger
                  name="profilePhotoId"
                  id="profilePhotoId"
                  label="Photo profile"
                  containerClassName="w-full !flex flex-col gap-2 flex-start"
                  onLoadEnd={(data) => {
                    onChange(data?.id)
                  }}
                  changeLoading={onUploading}
                  initResource={teacher?.profilePhoto?.url}
                  errors={error?.message}
                  multiple={false}
                  allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                  disabled={disabled}
                  uploadType={EMediaType.IMAGE as any}
                />
              )
            }}
          />
        </InputSectionWrapper>

        <InputSectionWrapper>
          <Controller
            name="phone"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  placeholder="Phone Number"
                  value={value}
                  onChange={onChange}
                  label="Phone Number"
                  alignment="col"
                  errors={error?.message}
                  className={`${INPUT_CLASSNAME}`}
                  required
                />
              )
            }}
          />
          <Controller
            name="createdAt"
            control={control}
            render={({ field: { value }, fieldState: { error } }) => {
              return (
                <Input
                  placeholder="Creation time"
                  value={value ? formatDate(value, FORMAT_DATE_TABLE) : ''}
                  label="Creation time"
                  alignment="col"
                  errors={error?.message}
                  className={`${INPUT_CLASSNAME}`}
                  disabled
                />
              )
            }}
          />
          <Controller
            name="lastActiveTime"
            control={control}
            render={({ field: { value }, fieldState: { error } }) => {
              return (
                <Input
                  placeholder="Last time active"
                  value={value ? formatDate(value, FORMAT_DATE_TABLE) : ''}
                  label="Last time active"
                  alignment="col"
                  errors={error?.message}
                  className={`${INPUT_CLASSNAME}`}
                  disabled
                />
              )
            }}
          />
          {/* Button view */}
          {!(
            teacher?.status === ETeacherStatus.INACTIVE &&
            teacher?.client === ETeacherRole.LLC
          ) && (
            <ButtonWrapper className="!mt-0 !pt-0 !flex-row">
              <Button
                htmlType="submit"
                type="primary"
                size="small"
                id="blog-form"
                disabled={disabled}
                onClick={handleClickButtonView}
              >
                {[ETeacherRole.LLC as string].includes(teacher?.client)
                  ? t('teacher:view_test')
                  : t('teacher:view_course')}
              </Button>
            </ButtonWrapper>
          )}
        </InputSectionWrapper>
      </InputContainer>

      <ButtonWrapper className="blog-content-top item-right-wrapper">
        <Button
          type="default"
          size="small"
          id="blog-form"
          onClick={() => {
            dispatch(teachersActions.resetTeacher())
            navigate(PATH_TEACHER_MANAGEMENT)
          }}
        >
          {t('common:cancel')}
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          size="small"
          id="blog-form"
          loading={isLoading}
          disabled={disabled}
        >
          {t('common:save')}
        </Button>
      </ButtonWrapper>
    </FormStyled>
  )
}

export default TeacherOverviewForm

const FormStyled = styled('form')(() => {
  return {
    width: '100%',
    height: '100%',
  }
})

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 32px;
`

const InputSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 16px;
  flex: 1 1 0%;

  textarea.ant-input {
    min-height: 8rem;
  }

  .Input__field-container {
    width: 100%;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-top: 32px;
  padding-bottom: 12px;
`
