import { AppModal } from '../../../../components/AppModal'
import { XCloseIcon } from '../../../../components/Icon'

interface IPreviewPhotoModalProps {
  open: boolean
  onDelete: () => void
  onSave: () => void
  onClose: () => void
  url?: string
  modalClassName?: string
}

const PreviewPhotoModal = ({
  open,
  onClose,
  onSave,
  onDelete,
  url,
  modalClassName,
}: IPreviewPhotoModalProps) => {
  return (
    <AppModal open={open} onClose={onClose} modalClassName={modalClassName}>
      <div className="wrapper-sendfile w-full h-auto">
        <div className="flex items-center justify-between ">
          <div>
            <h1 className="m-0 text-[20px]">Photo preview</h1>
          </div>
          <div className="hover:opacity-75">
            <XCloseIcon width={16} height={16} onClick={onClose} />
          </div>
        </div>
        <div className="mt-6">
          <div className="preview-chat-photo w-full h-full object-contain">
            <img src={url} alt="preview chat content" className="w-full" />
          </div>
        </div>
      </div>
    </AppModal>
  )
}

export default PreviewPhotoModal
