import { Card } from 'antd'
import styled from 'styled-components'
import { TEducationSection } from '../types'
import { EducationForm } from './EducationForm'
import { EducationView } from './EducationView'

export const EducationSection = (props: TEducationSection) => {
  return (
    <ExperienceSectionStyled className="!border-none flex items-center justify-center w-full">
      {!!props?.data?.editable ? (
        <EducationForm {...props} />
      ) : (
        <EducationView {...props} />
      )}
    </ExperienceSectionStyled>
  )
}

const ExperienceSectionStyled = styled(Card)`
  .ant-card-body {
  }

  .reset-form {
    > div:last-child {
      justify-content: center;
    }
  }
`
