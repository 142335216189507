import { AppModal } from '@components'
import { zodResolver } from '@hookform/resolvers/zod'
import { rejectTestAssignment, useAppDispatch } from '@redux'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Button, TextArea } from 'src/common'
import styled from 'styled-components'
import * as z from 'zod'

interface IAssignTeacherModalProps {
  open: boolean
  finalTestHistoryId: number
  onClose: () => void
  handleReloadData: () => void
}

export default function RejectAssignmentModal({
  open,
  finalTestHistoryId,
  onClose,
  handleReloadData,
}: IAssignTeacherModalProps) {
  const { t } = useTranslation(['testManagement'])
  const dispatch = useAppDispatch()

  const AssignTeacherFormSchema = z.object({
    reason: z
      .string()
      .trim()
      .nonempty({
        message: t('testManagement:reasonRequired') as string,
      })
      .max(500, {
        message: t('testManagement:reasonLengthError') as string,
      }),
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<{
    reason: string
  }>({
    resolver: zodResolver(AssignTeacherFormSchema),
    defaultValues: {
      reason: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (data: { reason: string }) => {
    try {
      await dispatch(
        rejectTestAssignment({
          finalTestHistoryId,
          reason: data?.reason,
        })
      ).unwrap()

      handleReloadData()
      toast.success(t('testManagement:rejectAssignmentSuccess'))
      onClose()
    } catch (error: any) {
      toast.error(error?.message)
    }
  }

  return (
    <ModalContainer>
      <AppModal
        open={open}
        onClose={onClose}
        title={t('testManagement:rejectReason')}
        haveCloseIcon
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="reason"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <TextArea
                  label={t('testManagement:reason')}
                  placeholder={t('testManagement:reasonPlaceholder')}
                  name="reason"
                  required
                  onChange={onChange}
                  value={value}
                  errors={error?.message}
                  style={{ minHeight: 120 }}
                />
              )
            }}
          />

          <div className="flex justify-end gap-4 mt-4">
            <Button type="text" onClick={onClose}>
              {t('testManagement:back')}
            </Button>

            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              {t('testManagement:confirmReject')}
            </Button>
          </div>
        </form>
      </AppModal>
    </ModalContainer>
  )
}

const ModalContainer = styled.div`
  & .content {
    width: 600px;
  }
  & .modal__title {
    font-size: 24px !important;
    margin-bottom: 20px !important;
  }
`
