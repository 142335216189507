import styled from 'styled-components'
import { Skeleton } from 'antd'

import { TTeacherPackage } from '@interfaces'
import { SharedTable } from 'src/common'

interface IProps {
  columns: any
  isLoading?: boolean
  teacherPackageData: TTeacherPackage[]
  totalItem: number
  limitItem?: number
  currentPage?: number
  getTeacherPackagePage?: (page: number) => void
}

const TeacherPackageManagementTable = ({
  columns,
  isLoading,
  teacherPackageData,
  totalItem,
  limitItem = 10,
  currentPage = 1,
  getTeacherPackagePage,
}: IProps) => {
  return (
    <TeacherPackageManagementTableWrapper>
      {!columns || isLoading ? (
        <Skeleton paragraph={{ rows: 4 }} />
      ) : (
        <SharedTable
          columns={columns}
          dataSource={teacherPackageData.map((item, index) => {
            return {
              ...item,
              index: (currentPage - 1) * limitItem + index + 1,
              isActive: item?.isActive,
            }
          })}
          totalResults={totalItem}
          paginationProps={{
            total: !!totalItem ? +totalItem : undefined,
            pageSize: +limitItem || 10,
            current: +currentPage,
            onChange(page) {
              getTeacherPackagePage && getTeacherPackagePage(page)
            },
          }}
        />
      )}
    </TeacherPackageManagementTableWrapper>
  )
}

export default TeacherPackageManagementTable

const TeacherPackageManagementTableWrapper = styled.div`
  width: 100%;
  padding-bottom: 1.25rem;
`
