import { RootState } from '@redux'
import { Image, Typography } from 'antd'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'
import { useSelector } from 'react-redux'
import { Button } from 'src/common'
import {
  STUDENT_ANSWER_TYPES,
  getStudentAnswerType,
} from 'src/pages/test-management/config'
import {
  IGradingResultData,
  IGradeTestDetailData,
} from 'src/pages/test-management/type'

interface IStudentAnswerProps {
  gradeTestData: {
    data: IGradeTestDetailData | IGradingResultData
  }
}

export default function StudentAnswer({ gradeTestData }: IStudentAnswerProps) {
  const { t } = useTranslation(['testManagement'])

  const currentQuestion = useSelector(
    (state: RootState) => state.testManagement?.currentQuestion
  )

  const { data } = gradeTestData || {}

  const currentQuestionData =
    data?.finalTestSectionResults?.[currentQuestion - 1] || {}

  const { content } = currentQuestionData?.question || {}
  const { type: answerType, url } = currentQuestionData?.practiceFile || {}
  const studentWritingAnswer = currentQuestionData?.practiceWriting || ''
  const studentAnswerTypeFmt = getStudentAnswerType(String(answerType))

  const StudentAnswerType = useMemo(() => {
    if (studentAnswerTypeFmt === STUDENT_ANSWER_TYPES.VIDEO)
      return (
        <ReactPlayer
          url={url}
          controls={true}
          className="!w-full"
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
          }}
        />
      )

    if (studentAnswerTypeFmt === STUDENT_ANSWER_TYPES.IMAGE)
      return (
        <div className="flex justify-center pb-4">
          <Image
            preview={false}
            className="w-full"
            src={url}
            style={{
              maxHeight: 540,
            }}
          />
        </div>
      )

    if (studentAnswerTypeFmt === STUDENT_ANSWER_TYPES.PDF)
      return (
        <div className="flex justify-center">
          <Button type="primary">
            <a href={url} target="_blank" rel="noreferrer">
              {t('testManagement:viewFile')}
            </a>
          </Button>
        </div>
      )

    if (!!studentWritingAnswer)
      return (
        <div className="bg-[#e2e2e2] rounded-md px-8 py-4">
          <Typography
            style={{
              whiteSpace: 'break-spaces',
            }}
          >
            {studentWritingAnswer}
          </Typography>
        </div>
      )

    return null
  }, [studentAnswerTypeFmt, studentWritingAnswer, t, url])

  return (
    <>
      <Typography
        style={{
          fontSize: 18,
          fontWeight: 700,
          marginBottom: 8,
          textAlign: 'center',
          width: '100%',
          color: '#af5b5b',
        }}
      >
        {`${t('testManagement:question')} ${currentQuestion}: ${content || ''}`}
      </Typography>

      <div className="flex flex-col justify-center w-full h-auto max-h-[600px] px-0">
        <Typography
          style={{
            color: '#f3c514',
            fontSize: 24,
            fontWeight: 700,
            textAlign: 'start',
            marginBottom: 4,
          }}
        >
          {t('testManagement:submitAnswer')}
        </Typography>
        {StudentAnswerType}
      </div>
    </>
  )
}
