import { Card } from 'antd'
import { Statistic, StatisticChart } from '@components'
import { getProfileAdminAction, useAppDispatch } from '@redux'
import { useEffect } from 'react'

type Props = {}

export const Dashboard = (props: Props) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const getProfileInfo = async () => {
      try {
        await dispatch(getProfileAdminAction()).unwrap()
      } catch (error: any) {
        console.log(error.message)
      }
    }

    getProfileInfo()
  }, [])
  return (
    <Card>
      <Statistic />
      <StatisticChart />
      {/* <TopSales /> */}
    </Card>
  )
}
