import { DeleteTwoTone } from '@ant-design/icons'
import { themes } from '@theme'
import i18next from 'i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Button, HookFormTextArea, Input } from 'src/common'
import styled from 'styled-components'
import { enumBreakPoint } from '@configs'
import { TExperienceForm } from 'src/components/form/teacher-info/types'

export const ExperienceForm = ({
  data,
  index,
  onRemove,
  onSave,
}: TExperienceForm) => {
  const { control, trigger, setValue, getValues } = useFormContext()

  const handleSaveForm = async () => {
    const isValid = await trigger(
      `specificInformation.workExperience[${index}]`
    )
    if (isValid) {
      setValue(`specificInformation.workExperience[${index}].editable`, false)
      onSave?.(index)
    }
  }

  return (
    <StyledExperienceForm className="reset-password__section">
      {!!onRemove && (
        <DeleteTwoTone
          onClick={() => onRemove?.(index)}
          className="self-end text-base"
        />
      )}

      <Controller
        name={`specificInformation.workExperience[${index}].companyName`}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Input
              label={i18next.t('common:companyName')}
              alignment="col"
              placeholder={i18next.t('common:enterCompanyName')}
              name={`specificInformation.workExperience[${index}].companyName`}
              containerClassName={'mt-5'}
              errors={error?.message}
              value={value}
              onChange={onChange}
              required
            />
          )
        }}
      />
      <Controller
        name={`specificInformation.workExperience[${index}].jobTitle`}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Input
              label={i18next.t('common:jobTitle')}
              alignment="col"
              placeholder={i18next.t('common:enterJobTitle')}
              name={`specificInformation.workExperience[${index}].jobTitle`}
              containerClassName={'mt-5'}
              errors={error?.message}
              value={value}
              onChange={onChange}
              allowClear={false}
              required
            />
          )
        }}
      />
      <div className="col-span-8 mt-5">
        <HookFormTextArea
          control={control}
          controlName={`specificInformation.workExperience[${index}].description`}
          label={i18next.t('common:description')}
          labelClassName="mb-2"
          rows={5}
          placeholder={i18next.t('common:description')}
          required
        />
      </div>
      <div className="flex flex-row w-full mt-5 gap-10">
        <Controller
          name={`specificInformation.workExperience[${index}].startDate`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                label={i18next.t('common:startDate')}
                alignment="col"
                type="date"
                haveShowPassIcon
                name={`specificInformation.workExperience[${index}].startDate`}
                value={value}
                onChangeDate={(value) => {
                  onChange(value)
                  const currentEndDate = getValues(
                    `specificInformation.workExperience[${index}].endDate`
                  )
                  if (!!currentEndDate) {
                    trigger(
                      `specificInformation.workExperience[${index}].endDate`
                    )
                  }
                }}
                errors={error?.message}
                allowClear={false}
                required
              />
            )
          }}
        />
        <Controller
          name={`specificInformation.workExperience[${index}].endDate`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                label={i18next.t('common:endDate')}
                alignment="col"
                type="date"
                haveShowPassIcon
                name={`specificInformation.workExperience[${index}].endDate`}
                value={value}
                onChangeDate={(value) => {
                  onChange(value)
                  const currentStartDate = getValues(
                    `specificInformation.workExperience[${index}].startDate`
                  )
                  if (!!currentStartDate) {
                    trigger(
                      `specificInformation.workExperience[${index}].startDate`
                    )
                  }
                }}
                errors={error?.message}
                allowClear={false}
                required
              />
            )
          }}
        />
      </div>
      <div className="flex items-center max-[800px] justify-center my-5">
        <Button
          htmlType="submit"
          type="primary"
          size="large"
          className="min-[600px] max-[600px]:!w-full"
          onClick={handleSaveForm}
        >
          {i18next.t('common:save')}
        </Button>
      </div>
    </StyledExperienceForm>
  )
}

export const StyledExperienceForm = styled.section`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  .heading {
    margin-bottom: 3.6rem;
    .heading__title {
      text-align: center;
      font-size: 3.2rem;
      font-weight: 700;
      margin-bottom: 0.8rem;
      color: ${(p) => themes.theme.light.colors.text};
    }
    .desc {
      font-size: 1.6rem;
      font-weight: 400;
      color: ${(p) => themes.theme.light.colors.subText};
    }
  }

  .actions {
    @media (min-width: ${enumBreakPoint.DESKTOP}) {
      display: flex;
      align-items: center;
      .btn {
        width: 12.8rem;
      }
    }
  }

  .reset-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    .shared-input {
      margin-bottom: 2rem;
      .inner-input {
        height: 4.6rem;
      }
    }
    .submit__btn {
      @media (min-width: ${enumBreakPoint.DESKTOP}) {
        margin-right: 1.2rem;
        margin-bottom: 0;
      }
      margin-bottom: 1.2rem;
    }

    .inner-input {
      width: 100%;
      height: 4.6rem;
    }

    .agree-policy__cb {
      margin-bottom: 1.8rem;
    }
  }
`
