import { memo } from 'react'
import styled from 'styled-components'
import { Card, Image, Tooltip, Typography } from 'antd'
import { DeleteTwoTone, EditOutlined, ExportOutlined } from '@ant-design/icons'

import { TDegreeView } from 'src/pages/teacher-request-detail/fill-in-teacher-information/types'
import { DEGREE_FILE_TYPES } from './configs'
import { useTranslation } from 'react-i18next'

export const DegreeView = memo(
  ({ data, index, onRemove, onEdit }: TDegreeView) => {
    const { t } = useTranslation(['teacherRequest', 'testManagement'])

    return (
      <StyledDegreeView>
        <div className="flex flex-row justify-between mb-5">
          <Typography.Text className="text-base font-semibold capitalize max-w-[780px]">
            {data?.file?.name}
          </Typography.Text>
          <div className="ml-5 min-w-14 items-center">
            {!!onEdit && (
              <EditOutlined
                className="self-end mr-3 text-base text-gray-500"
                onClick={() => onEdit?.(index)}
              />
            )}
            {!!onRemove && (
              <DeleteTwoTone
                onClick={() => onRemove?.(index)}
                className="self-end text-base"
              />
            )}
          </div>
        </div>
        <div className="flex items-center gap-7">
          {(() => {
            if (data?.mediaFile?.type?.includes(DEGREE_FILE_TYPES.IMAGE))
              return (
                <Image
                  preview={false}
                  className="w-full"
                  src={data?.mediaFile?.cloudfrontUrl}
                  style={{
                    maxHeight: 300,
                  }}
                />
              )

            if (data?.mediaFile?.type?.includes(DEGREE_FILE_TYPES.PDF))
              return (
                <>
                  <a
                    href={`${process.env.REACT_APP_PUBLIC_PREFIX_MEDIA}/${data?.mediaFile?.path}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-3xl text-neutral-500"
                  >
                    <Tooltip title={t('testManagement:viewFile')}>
                      <ExportOutlined size={32} />
                    </Tooltip>
                  </a>

                  <Typography.Text className="text-base max-w-[600px]">
                    {data?.mediaFile?.name}
                  </Typography.Text>
                </>
              )

            return null
          })()}
        </div>
      </StyledDegreeView>
    )
  }
)

const StyledDegreeView = styled(Card)`
  border: none !important;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-top: 24px;
  }
`
