import { t } from 'i18next'
import { z } from 'zod'

export const addUserSchema = z.object({
  email: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .email({ message: t('error:email_format_error') as string }),
  phone: z
    .string()
    .trim()
    .regex(/^\s*\d*\s*$|^$/, {
      message: t('error:phone_format_error') as string,
    })
    .min(8, {
      message: t('error:phone_length_error') as string,
    })
    .max(13, {
      message: t('error:phone_length_error') as string,
    })
    .optional()
    .transform((e) => (e === '' ? undefined : e)),
  status: z.string(),
  name: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .regex(/^(?!.*[^a-zA-Z\s])\s*[a-zA-Z]+(?:\s+[a-zA-Z]+)*\s*$/, {
      message: t('error:name_format_error') as string,
    })
    .max(50, {
      message: t('error:name_staff_length_error'),
    })
    .min(2, {
      message: t('error:name_staff_length_error'),
    }),
  username: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .min(2, t('error:username_length_error') as string)
    .max(50, t('error:username_length_error') as string)
    .regex(/^\s*[^ ]+\s*$/, {
      message: t('error:username_format_error') as string,
    }),

  packageId: z.any(),
})
