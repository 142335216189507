import { SetStateAction, useRef } from 'react'
import ConversationItem from './ConversationItem'
import { Spin } from 'antd'

export default function ListConversation({
  listConversation,
  isLoadingMore,
  hasMoreData,
  setPage,
}: {
  listConversation: any
  isLoadingMore: boolean
  hasMoreData: boolean
  setPage: (value: SetStateAction<number>) => void
}) {
  const listChatRef = useRef<HTMLDivElement>(null)
  return (
    <>
      <div
        ref={listChatRef}
        onScroll={() => {
          const isBottomElement =
            Math.abs(
              Number(listChatRef?.current?.scrollHeight) -
                (Number(listChatRef?.current?.scrollTop) +
                  Number(listChatRef?.current?.clientHeight))
            ) <= 1

          if (!isBottomElement || !hasMoreData || isLoadingMore) return

          setPage((prev) => prev + 1)
        }}
        className="flex gap-4 flex-col overflow-y-auto no-scrollbar h-[calc(100%-60px)] max-h-full pt-2"
      >
        {listConversation?.map((it: any, idx: number) => {
          return <ConversationItem conversation={it} />
        })}

        {isLoadingMore ? (
          <div className="loading-wrapper flex items-center justify-center mt-2">
            <Spin size="small" />
          </div>
        ) : null}
      </div>
    </>
  )
}
