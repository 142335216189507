import {
  CaretDownOutlined,
  CaretUpOutlined,
  CopyOutlined,
  DeleteOutlined,
  EyeOutlined,
  MessageOutlined,
  SearchOutlined,
  StarFilled,
  StarOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { ShareSelectInput } from '@components'
import {
  PATH_COURSE_MANAGEMENT,
  PATH_COURSE_MANAGEMENT_NEW,
  enumTableTitleCourse,
  COURSE_STATUS,
  COURSE_TYPE,
  ESortColumnValue,
  DEFAULT_ROWS_PER_PAGE,
} from '@configs'
import { RootState, useAppDispatch, selectCourseLoading } from '@redux'
import { formatDate } from '@utils'
import { Skeleton, Tooltip, Typography, message } from 'antd'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Input, Loading, SharedTable } from 'src/common'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import { useDebounce } from 'src/hooks/useDebounce'
import { ICourseItem, ICourseParams } from 'src/interfaces/course'
import {
  deleteCourseByIdAction,
  duplicateCourseByIdAction,
  getCourseDetailAction,
  getListCourseAction,
  highLightByIdAction,
} from 'src/redux/actions/course'
import styled from 'styled-components'

const optionsFilterStatus: { value: string; label: string }[] = [
  {
    value: 'All status',
    label: 'All status',
  },
  {
    value: '1',
    label: 'Active',
  },
  {
    value: '0',
    label: 'Inactive',
  },
]

const optionsFilterType: { value: string; label: string }[] = [
  {
    value: 'All type',
    label: 'All type',
  },
  {
    value: 'free',
    label: 'Free',
  },
  {
    value: 'premium',
    label: 'Premium',
  },
  {
    value: 'member_course',
    label: 'Member Course',
  },
]

type Props = {}

const CoursePage = (props: Props) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [openConfirmHighLight, setOpenConfirmHighLight] = useState(false)
  const [searchValue, setSearchValue] = useState<string | null>(() =>
    !!searchParams.get('search') ? searchParams.get('search') : null
  )
  const debouncedValue = useDebounce<string | null>(searchValue, 800)
  const [isSearching, setIsSearching] = useState(false)
  const [sort, setSort] = useState<{ [key: string]: any }>({})
  const [status, setStatus] = useState(optionsFilterStatus[0].label)
  const [courseType, setCourseType] = useState(optionsFilterType[0].label)
  const [selectedCourseId, setSelectedCourseId] = useState<number | string>()
  const [isModalLoading, setIsModalLoading] = useState(false)

  const { courses, totalCourses, pageCourses, totalHighlight } = useSelector(
    (state: RootState) => state.course
  )

  const chosenCourse = courses.find((c) => c.id === selectedCourseId)

  const getSortOrder = (sort: string) => {
    if (sort === ESortColumnValue.ASC) {
      return ESortColumnValue.DESC
    }
    return ESortColumnValue.ASC
  }
  const getCourses = async (params?: ICourseParams) => {
    dispatch(
      getListCourseAction({
        page: +pageCourses,
        sort,
        search:
          debouncedValue !== null && debouncedValue.trim()
            ? debouncedValue.trim()
            : undefined,
        ...(params || {}),
      })
    )
  }

  const getCourseFilterParams = useMemo(() => {
    const params: ICourseParams = {
      page: 1,
      ...(!!debouncedValue &&
        !!debouncedValue.length && { search: debouncedValue.trim() }),
      ...(status !== optionsFilterStatus[0].value && { status }),
      ...(courseType !== optionsFilterType[0].value && { type: courseType }),
    }
    return params
  }, [courseType, debouncedValue, status])

  const getCoursesBySearchValue = async () => {
    try {
      setIsSearching(true)
      const options: {
        page: number
        status?: string
        type?: string
        search: string | undefined
      } = {
        page: 1,
        search:
          debouncedValue !== null && debouncedValue.trim()
            ? debouncedValue.trim()
            : undefined,
      }
      if (
        status === optionsFilterStatus[1].value ||
        status === optionsFilterStatus[2].value
      ) {
        options.status = status
      }

      if (
        courseType === optionsFilterType[1].value ||
        courseType === optionsFilterType[2].value
      ) {
        options.type = courseType
      }

      await dispatch(getListCourseAction(options)).unwrap()
    } catch (error: any) {
      if (error.message) {
        message.error({
          content: error.message,
        })
      }
    } finally {
      setIsSearching(false)
      // clear search params
      const emptySearchParams = new URLSearchParams(searchParams)
      emptySearchParams.set('search', '')
      setSearchParams(emptySearchParams)
    }
  }

  const getListCourseActionLoading = useSelector((state: RootState) =>
    selectCourseLoading(state, 'getListCourseAction')
  )

  const onCloseConfirmModal = () => {
    setOpenConfirmDeleteModal(false)
  }
  const onCloseHModal = () => {
    setOpenConfirmHighLight(false)
  }
  const handleConfirmDelete = async () => {
    try {
      if (selectedCourseId) {
        setIsModalLoading(true)

        const response = await dispatch(
          deleteCourseByIdAction(selectedCourseId as string)
        ).unwrap()

        if (response?.success) {
          message.success(response.message)
          setIsModalLoading(false)
          setOpenConfirmDeleteModal(false)
          getCourses({
            page: 1,
            type:
              courseType === optionsFilterType[1].value ||
              courseType === optionsFilterType[2].value
                ? courseType
                : undefined,
            status: status !== COURSE_STATUS.ALL ? status : undefined,
          })
        }
      } else {
        setIsModalLoading(false)
        setOpenConfirmDeleteModal(false)
      }
    } catch (error: any) {
      console.log(error?.message)
      message.error(error?.message)
    } finally {
      setIsModalLoading(false)
      setOpenConfirmDeleteModal(false)
    }
  }

  const onDuplicateCourse = async (courseId: number | string) => {
    try {
      setIsModalLoading(true)

      const response = await dispatch(
        duplicateCourseByIdAction(courseId)
      ).unwrap()

      if (response?.success) {
        message.success(response.message)
        setIsModalLoading(false)
        getCourses({
          page: 1,
          type:
            courseType === optionsFilterType[1].value ||
            courseType === optionsFilterType[2].value
              ? courseType
              : undefined,
          status: status !== COURSE_STATUS.ALL ? status : undefined,
        })
      }
    } catch (error: any) {
      message.error(error?.message)
    }
  }
  const onHighLightCourse = async (id?: number) => {
    if (!selectedCourseId && !id) {
      return
    }
    if (selectedCourseId) {
      setSelectedCourseId(id)
      try {
        const response = await dispatch(
          highLightByIdAction(selectedCourseId)
        ).unwrap()
        if (response?.success) {
          message.success(response.message)
        }
      } catch (error: any) {
        message.error(error?.message)
      } finally {
        setSelectedCourseId(undefined)
        setOpenConfirmHighLight(false)
      }
      return
    }

    if (id) {
      setSelectedCourseId(id)
      try {
        const response = await dispatch(highLightByIdAction(id)).unwrap()
        if (response?.success) {
          message.success(response.message)
        }
      } catch (error: any) {
        message.error(error?.message)
      } finally {
        setSelectedCourseId(undefined)
        setOpenConfirmHighLight(false)
      }
      return
    }
  }

  const columns = [
    {
      width: '5%',
      title: enumTableTitleCourse.NO,
      dataIndex: 'index',
      key: 'index',
      ellipsis: false,
      align: 'center',
    },
    {
      width: '20%',
      title: () => {
        return (
          <div className="flex items-center justify-start w-full">
            <p className="m-0">{enumTableTitleCourse.COURSE_NAME}</p>
          </div>
        )
      },
      dataIndex: 'title',
      key: 'id',
      render: (e: string, record: ICourseItem) => {
        const requestStatusText = record?.haveActiveRequest
          ? t('common:active')
          : record?.haveInactiveRequest
          ? t('common:inactive')
          : ''
        return (
          <div className="flex items-center max-w-[460px]">
            <Typography.Text
              style={{
                width: 400,
                whiteSpace: 'nowrap',
              }}
              ellipsis={true}
            >
              {e}
            </Typography.Text>
            {(record?.haveActiveRequest || record?.haveInactiveRequest) && (
              <Tooltip
                title={`The teacher from this course has requested to ${requestStatusText} the course`}
              >
                <WarningOutlined className="!ml-3 !text-red-500 !text-xl" />
              </Tooltip>
            )}
          </div>
        )
      },
      ellipsis: true,
    },
    {
      width: '12%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">Highlight</p>
            <div
              className="flex items-center ml-2 cursor-pointer"
              onClick={() => {
                setSort({ isHighlighted: getSortOrder(sort?.isHighlighted) })
                getCourses({
                  sort: { isHighlighted: getSortOrder(sort.isHighlighted) },
                })
              }}
            >
              {sort.isHighlighted === ESortColumnValue.ASC ? (
                <CaretDownOutlined />
              ) : (
                <CaretUpOutlined />
              )}
            </div>
          </div>
        )
      },
      key: 'highlight',
      dataIndex: 'action',
      render: (
        _: any,
        record: {
          isHighlighted: boolean
          id: number
          isDrafted: string
          deletedAt: string
        }
      ) => (
        <div
          className="flex space-x-4 justify-center items-center"
          onClick={() => {
            if (record?.deletedAt) {
              return
            }
            if (record?.isDrafted === 'Draft') {
              message.warning('Draft course can not be selected as highlight')
              return
            }
            if (totalHighlight === 5 && !record?.isHighlighted) {
              setOpenConfirmHighLight(true)
              setSelectedCourseId(record.id)
              return
            } else {
              setSelectedCourseId(record.id)
              onHighLightCourse(record.id)
            }
          }}
        >
          <Tooltip title="HighLight">
            {record?.isHighlighted && !record?.deletedAt ? (
              <StarFilled
                disabled
                className={`text-lg font-light !text-yellow-300 `}
                color="yellow"
              />
            ) : (
              <StarOutlined className={`text-lg font-light `} color="yellow" />
            )}
          </Tooltip>
        </div>
      ),
      ellipsis: true,
    },
    {
      width: '15%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleCourse.TYPE}</p>
          </div>
        )
      },
      dataIndex: 'type',
      key: 'type',
      render: (e: string) => {
        return (
          <div className="flex items-center justify-center capitalize rounded-[10px] bg-[#F3F4F6] px-3 min-w-[130px]">
            {e.replace('_', ' ')}
          </div>
        )
      },
      ellipsis: true,
    },
    {
      width: '15%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleCourse.USER}</p>
          </div>
        )
      },
      dataIndex: 'user',
      key: 'user',
      render: (e: any) => {
        return (
          <div className="flex items-center max-w-[460px]">
            <Typography.Text
              style={{
                width: 400,
                whiteSpace: 'nowrap',
              }}
              ellipsis={true}
            >
              {e}
            </Typography.Text>
          </div>
        )
      },
      ellipsis: true,
      align: 'center',
    },
    {
      width: '10%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleCourse.STATUS}</p>
          </div>
        )
      },
      dataIndex: 'isActived',
      key: 'isActived',
      render: (_: any, record: { deletedAt: any; isActived: any }) => (
        <div className="flex flex-col items-center justify-center h-full w-full">
          <p className={`m-0 bold ${!!record?.deletedAt && 'text-danger'}`}>
            {record?.deletedAt ? 'Deleted at' : record?.isActived}
          </p>
          {!!record?.deletedAt && (
            <p className="m-0 text-danger">
              {formatDate(record?.deletedAt, 'DD-MM-YYYY')}
            </p>
          )}
        </div>
      ),
      ellipsis: true,
    },
    {
      width: '10%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleCourse.PUBLISHED_DRAFT}</p>
          </div>
        )
      },
      dataIndex: 'isDrafted',
      key: 'isDrafted',
      render: (e: boolean) => {
        return <div className="flex items-center justify-center">{e}</div>
      },
      ellipsis: true,
    },
    {
      width: '20%',
      title: () => (
        <div className="flex items-center justify-center w-full">
          <p className="m-0">Action</p>
          <div
            className="flex items-center ml-2 cursor-pointer"
            onClick={() => {
              setSort({ unreadMessage: getSortOrder(sort?.unreadMessage) })
              getCourses({
                sort: { unreadMessage: getSortOrder(sort.unreadMessage) },
              })
            }}
          >
            {sort.unreadMessage === ESortColumnValue.ASC ? (
              <CaretDownOutlined />
            ) : (
              <CaretUpOutlined />
            )}
          </div>
        </div>
      ),
      key: 'action',
      dataIndex: 'action',
      render: (
        _: any,
        record: {
          id: any
          deletedAt: any
          isDrafted: any
          unreadMessage: number
        }
      ) => (
        <div className="flex space-x-4 justify-center items-center min-w-[120px]">
          <Tooltip title={t('common:chat')}>
            <div className="relative">
              <MessageOutlined
                className={`text-lg font-light mr-2.5 ${
                  !!record?.unreadMessage ? '!text-[#ff0000]' : ''
                }`}
                onClick={() => {
                  navigate(
                    `${PATH_COURSE_MANAGEMENT}/${record?.id}?tab=HOME_PAGE_CHAT`
                  )
                }}
              />

              {!!record?.unreadMessage ? (
                <div
                  className={`flex h-1 w-1 p-2 items-center justify-center rounded-full bg-red-500 text-[8px] text-white absolute top-0 right-0 -translate-y-1/2`}
                >
                  {record?.unreadMessage > 9 ? '9+' : record?.unreadMessage}
                </div>
              ) : null}
            </div>
          </Tooltip>

          <Tooltip title={t('common:view_detail')}>
            <EyeOutlined
              className={`text-lg font-light mr-2.5 ${
                !!record.deletedAt
                  ? '!text-gray-300 !cursor-not-allowed'
                  : 'cursor-pointer text-[#184f64]'
              }`}
              onClick={async () => {
                if (!!record.deletedAt) return

                navigate(`${PATH_COURSE_MANAGEMENT}/${record.id}`)
              }}
            />
          </Tooltip>
          <Tooltip title={t('common:duplicate')}>
            <CopyOutlined
              className={`text-lg font-light mr-2.5 ${
                !!record.deletedAt
                  ? '!text-gray-300 !cursor-not-allowed'
                  : 'cursor-pointer text-[#184f64]'
              }`}
              onClick={() => {
                if (!!record.deletedAt) return
                onDuplicateCourse(record.id)
              }}
            />
          </Tooltip>
          <DeleteOutlined
            className={`text-lg font-light mr-2.5 ${
              !!record.deletedAt
                ? '!text-gray-300 !cursor-not-allowed'
                : 'cursor-pointer hover:opacity-80 !text-red-600'
            }`}
            onClick={() => {
              if (!!record.deletedAt) return
              setOpenConfirmDeleteModal(true)
              setSelectedCourseId(record.id)
            }}
          />
        </div>
      ),
      ellipsis: true,
      align: 'center',
    },
  ]

  const handleChaneStatus = (value: string) => {
    if (value === COURSE_STATUS.ALL) {
      setStatus(COURSE_STATUS.ALL)
      getCourses({
        page: 1,
        type:
          courseType === optionsFilterType[1].value ||
          courseType === optionsFilterType[2].value
            ? courseType
            : undefined,
      })
    } else if (value === COURSE_STATUS.ACTIVE) {
      setStatus(COURSE_STATUS.ACTIVE)
      getCourses({
        page: 1,
        status: 1,
        type:
          courseType === optionsFilterType[1].value ||
          courseType === optionsFilterType[2].value
            ? courseType
            : undefined,
      })
    } else if (value === COURSE_STATUS.INACTIVE) {
      setStatus(COURSE_STATUS.INACTIVE)
      getCourses({
        page: 1,
        status: 0,
        type:
          courseType === optionsFilterType[1].value ||
          courseType === optionsFilterType[2].value
            ? courseType
            : undefined,
      })
    }
  }

  const handleChaneType = (value: string) => {
    const commonParams = {
      page: 1,
      status:
        status === optionsFilterStatus[1].value ||
        status === optionsFilterStatus[2].value
          ? status
          : undefined,
    }

    setCourseType(value)

    if (value === COURSE_TYPE.ALL) {
      getCourses(commonParams)
    } else {
      getCourses({
        ...commonParams,
        type: value,
      })
    }
  }

  useEffect(() => {
    getCourses({ page: 1 })
  }, [])

  useEffect(() => {
    getCourses({ ...getCourseFilterParams })
  }, [getCourseFilterParams])

  useEffect(() => {
    debouncedValue !== null && getCoursesBySearchValue()
  }, [debouncedValue])

  return (
    <CourseManagementStyled>
      <div className="flex items-center justify-between py-[1.25rem] w-full">
        <div className="max-w-[700px] flex-1 flex gap-8">
          <Input
            prefix={<SearchOutlined className="pl-2" />}
            placeholder="Enter course name or author name"
            value={searchValue === null ? '' : searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value)
            }}
            suffix={isSearching ? <Loading /> : undefined}
          />
          <div className="wrapper-status-filter flex items-center justify-center gap-1">
            <ShareSelectInput
              data={optionsFilterType}
              // defaultValue={optionsFilterStatus[0].label}
              value={courseType}
              style={{ width: '160px' }}
              onChange={handleChaneType}
            />
          </div>
          <div className="wrapper-status-filter flex items-center justify-center gap-1">
            <ShareSelectInput
              data={optionsFilterStatus}
              // defaultValue={optionsFilterStatus[0].label}
              value={status}
              style={{ width: '160px' }}
              onChange={handleChaneStatus}
            />
          </div>
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => {
              // setOpen(true)
              // navigate(PATH_ADD_COURSE)
              navigate(PATH_COURSE_MANAGEMENT_NEW)
            }}
          >
            Create new
          </Button>
        </div>
      </div>
      <div className="pb-[1.25rem]">
        {getListCourseActionLoading || isSearching ? (
          <Skeleton paragraph={{ rows: 4 }} />
        ) : (
          <SharedTable
            columns={columns as any}
            dataSource={courses.map((item, index) => {
              return {
                ...item,
                index: index + 1,
                key: item?.id,
                id: item?.id,
                deletedAt: item?.deletedAt,
                title: item?.title,
                user: item?.user?.name,
                type: item?.type,
                isActived: item?.isActived ? 'Active' : 'Inactive',
                isDrafted: item?.isDrafted ? 'Draft' : 'Published',
                category: item?.categories
                  ? item?.categories.map((category) => category.name).join(', ')
                  : [],
              }
            })}
            paginationProps={{
              total: !!totalCourses ? +totalCourses : undefined,
              pageSize: DEFAULT_ROWS_PER_PAGE,
              showSizeChanger: false,
              current: +pageCourses,
              onChange(page) {
                const params: ICourseParams = {
                  ...getCourseFilterParams,
                  page,
                }
                getCourses(params)
              },
            }}
            sticky
          />
        )}
      </div>
      {openConfirmDeleteModal && (
        <ConfirmDeleteModal
          title={`${
            chosenCourse?.isDrafted
              ? 'Delete course'
              : 'Do you want to delete this course?'
          }`}
          content={`${
            chosenCourse?.isDrafted
              ? 'Are you sure to delete this course?'
              : 'This course was published , this deletion will affect to others participating in this!'
          }`}
          open={openConfirmDeleteModal}
          isLoading={!!isModalLoading}
          onDelete={handleConfirmDelete}
          onClose={onCloseConfirmModal}
          highlight={!chosenCourse?.isDrafted}
        />
      )}
      {openConfirmHighLight && (
        <ConfirmDeleteModal
          title={`You want to highlight this course`}
          content={`You 've highlighted 5 courses , if confirm is clicked , this oldest highlight course will be removed`}
          open={openConfirmHighLight}
          isLoading={!!isModalLoading}
          onDelete={onHighLightCourse}
          onClose={onCloseHModal}
        />
      )}
    </CourseManagementStyled>
  )
}

export default CoursePage

const CourseManagementStyled = styled('div')(() => {
  return {
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: '#fff',

    '& .ant-table-wrapper': {
      margin: 0,
      '& .ant-table-content': {
        marginRight: 0,
      },
    },
  }
})
