import { ETeacherRequestStatus } from './types'

export const RESPONSE_STATUS_CONTENT: Record<
  ETeacherRequestStatus,
  { color: string; responseTitle: string }
> = {
  [ETeacherRequestStatus.ACCEPTED]: {
    color: '#1DD75BFF',
    responseTitle: 'Status: Accepted by',
  },
  [ETeacherRequestStatus.REJECTED]: {
    color: '#DE3B40FF',
    responseTitle: 'Status: Rejected by',
  },
  [ETeacherRequestStatus.REQUEST_UPDATE]: {
    color: '#DE3B40FF',
    responseTitle: 'Status: Request update by',
  },
  [ETeacherRequestStatus.UPDATED]: {
    color: '#FE763EFF',
    responseTitle: 'Updated by teacher',
  },
  [ETeacherRequestStatus.PENDING]: {
    color: '#FE763EFF',
    responseTitle: '',
  },
}
