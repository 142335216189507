import { URL_REGEX } from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, TextArea } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import { AppModal } from 'src/components/AppModal'
import { XCloseIcon } from 'src/components/Icon'
import SelectMedia from 'src/components/gallery/SelectMedia'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'
import {
  HomePageContent,
  IHomeContentRequest,
} from 'src/interfaces/aboutus-management'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import { z } from 'zod'
import queryString from 'query-string'
import styled from 'styled-components'

type ContentDetailModalProps = {
  open: boolean
  isLoading: boolean
  onClose: () => void
  title?: string
  content?: ReactNode
  onSave: (data: IHomeContentRequest) => void
  item?: HomePageContent | null
  changeLoading?: (value: boolean) => void
  noDefault: number
}

const addAboutUsSchema = z.object({
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(20, {
      message: 'Content title must be less than 20 characters long',
    }),
  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(20, {
      message: 'Content title must be less than 20 characters long',
    }),
  content: z
    .string()
    .trim()
    // .nonempty({
    //   message: t('error:field_required') as string,
    // })
    .max(150, {
      message: 'Content  must be less than 150 characters long',
    }),
  contentInDutch: z
    .string()
    .trim()
    // .nonempty({
    //   message: t('error:field_required') as string,
    // })
    .max(150, {
      message: 'Content  must be less than 150 characters long',
    }),
  button: z
    .string()
    .trim()
    // .nonempty({
    //   message: t('error:field_required') as string,
    // })
    .max(20, {
      message: 'Button title  must be less than 20 characters long',
    }),
  buttonLink: z.optional(
    z.union([
      z
        .string()
        .trim()
        .max(255, {
          message: 'Content  must be less than 255 characters long',
        })
        .regex(URL_REGEX, { message: t('error:required_url_error') as string }),
      z.string().trim().length(0),
    ])
  ),
  buttonInDutch: z
    .string()
    .trim()
    // .nonempty({
    //   message: t('error:field_required') as string,
    // })
    .max(20, {
      message: 'Button title  must be less than 20 characters long',
    }),
  button2: z
    .string()
    .trim()
    // .nonempty({
    //   message: t('error:field_required') as string,
    // })
    .max(20, {
      message: 'Button title  must be less than 20 characters long',
    }),
  buttonLink2: z.optional(
    z.union([
      z
        .string()
        .trim()
        .max(255, {
          message: 'Content  must be less than 255 characters long',
        })
        .regex(URL_REGEX, { message: t('error:required_url_error') as string }),
      z.string().trim().length(0),
    ])
  ),
  buttonInDutch2: z
    .string()
    .trim()
    // .nonempty({
    //   message: t('error:field_required') as string,
    // })
    .max(20, {
      message: 'Button title  must be less than 20 characters long',
    }),
  mediaId: z.number().min(1, { message: t('error:field_required') as string }),
  mediaMobileId: z
    .number()
    .min(1, { message: t('error:field_required') as string }),
})

export const ContentDetailModal = (props: ContentDetailModalProps) => {
  const {
    open,
    onClose,
    title,
    onSave,
    isLoading,
    item,
    changeLoading,
    noDefault,
  } = props

  const [imageSrc, setImageSrc] = useState(item?.media?.url ?? '')
  const [imageMobileSrc, setImageMobileSrc] = useState(
    item?.mediaMobile?.url ?? ''
  )
  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)
  const [selectedField, setSelectedField] = useState<
    'desktop' | 'mobile' | null
  >(null)
  const onChangeDesktopRef = useRef<any>(null)
  const onChangeMobileRef = useRef<any>(null)

  const defaultValue: any = {
    content: item?.content ?? '',
    contentInDutch: item?.contentInDutch ?? '',
    mediaId: item?.mediaId ?? 0,
    mediaMobileId: item?.mediaMobileId ?? 0,
    title: item?.title ?? '',
    titleInDutch: item?.titleInDutch ?? '',
    buttonInDutch: item?.metaData?.buttonInDutch ?? '',
    button: item?.metaData?.button ?? '',
    buttonLink: item?.metaData?.buttonLink ?? '',
    no: item?.no ?? noDefault,
  }
  const { control, handleSubmit, setValue, getValues } =
    useForm<IHomeContentRequest>({
      defaultValues: defaultValue,
      resolver: zodResolver(addAboutUsSchema),
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    })

  const onPreview = (data: IHomeContentRequest) => {
    const payload = {
      content: data.content,
      title: data.title,
      button: data.button,
      buttonLink: data.buttonLink,
      button2: data.button2,
      buttonLink2: data.buttonLink2,
      url: imageSrc,
    }
    const stringified = queryString.stringify(payload)

    const URL = `${process.env.REACT_APP_PUBLIC_WEB_DOMAIN}/preview-content?${stringified}`
    window.open(URL, '_blank')
  }

  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }

  const onSelectMedia = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      setImageSrc(data?.baseUrl ?? '')
      setValue('mediaId', data?.id ?? 0)
      onChangeDesktopRef.current(data?.id ?? 0)
    } else if (selectedField === 'mobile') {
      setImageMobileSrc(data?.baseUrl ?? '')
      setValue('mediaMobileId', data?.id ?? 0)
      onChangeMobileRef.current(data?.id ?? 0)
    }

    setShowModalSelectMedia(false)
  }
  useEffect(() => {
    setValue('content', item?.content ?? '')
    setValue('contentInDutch', item?.contentInDutch ?? '')
    setValue('mediaId', item?.mediaId ?? 0)
    setValue('mediaMobileId', item?.mediaMobileId ?? 0)
    setValue('title', item?.title ?? '')
    setValue('titleInDutch', item?.titleInDutch ?? '')
    setValue('buttonInDutch', item?.metaData?.buttonInDutch ?? '')
    setValue('button', item?.metaData?.button ?? '')
    setValue('buttonLink', item?.metaData?.buttonLink ?? '')
    setValue('buttonInDutch2', item?.metaData?.buttonInDutch2 ?? '')
    setValue('button2', item?.metaData?.button2 ?? '')
    setValue('buttonLink2', item?.metaData?.buttonLink2 ?? '')
    setValue('no', item?.no ?? noDefault)
  }, [open])

  return (
    <AppModal
      open={open}
      onClose={onClose}
      title={
        <div className="flex items-center justify-between ">
          <div>
            <h1 className="m-0 text-[20px]">
              {item ? 'Edit content section' : 'Add content section'}
            </h1>
          </div>
          <div className="hover:opacity-75 cursor-pointer">
            <XCloseIcon width={16} height={16} onClick={onClose} />
          </div>
        </div>
      }
    >
      <div className="mt-6">
        <form>
          <InputWrapperStyled>
            <Controller
              name={'title'}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="Title"
                    name="title"
                    alignment="col"
                    required
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                  />
                )
              }}
            />
            <Controller
              name={'titleInDutch'}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    alignment="col"
                    label="Title in Netherlands"
                    name="titleInDutch"
                    required
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                  />
                )
              }}
            />

            <Controller
              name="content"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <TextArea
                    alignment="col"
                    label="Content"
                    name="content"
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                    containerClassName="mt-3"
                  />
                )
              }}
            />

            <Controller
              name="contentInDutch"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <TextArea
                    alignment="col"
                    label="Content in Netherlands"
                    name="contentInDutch"
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                    containerClassName="mt-3"
                  />
                )
              }}
            />
            <Controller
              name={'button'}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    alignment="col"
                    label="First-button's title"
                    name="button"
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                  />
                )
              }}
            />
            <Controller
              name={'buttonInDutch'}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    alignment="col"
                    label="First-button's title in Netherlands"
                    name="buttonInDutch"
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                  />
                )
              }}
            />
            <Controller
              name={'buttonLink'}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    alignment="col"
                    label="First-button's link"
                    name="buttonLink"
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                  />
                )
              }}
            />

            <Controller
              name={'button2'}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    alignment="col"
                    label="Second-button's title"
                    name="button2"
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                  />
                )
              }}
            />
            <Controller
              name={'buttonInDutch2'}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    alignment="col"
                    label="Second-button's title in Netherlands"
                    name="buttonInDutch2"
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                  />
                )
              }}
            />
            <Controller
              name={'buttonLink2'}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    alignment="col"
                    label="Second-button's link"
                    name="buttonLink2"
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                  />
                )
              }}
            />
            <div className="flex flex-col">
              <Controller
                name="mediaId"
                control={control}
                render={({ field: { onChange }, fieldState: { error } }) => {
                  onChangeDesktopRef.current = onChange
                  return (
                    <CustomDragger
                      label="Image"
                      name="mediaId"
                      id="mediaId"
                      containerClassName="mt-3"
                      required
                      alignment="col"
                      onLoadEnd={(data) => {
                        setImageSrc((prev) => data.original ?? prev)
                        setValue('mediaId', data?.id ?? 0)
                        onChange(data?.id ?? 0)
                      }}
                      note={'1920 x 1080 resolution for better experience'}
                      errors={error?.message}
                      initResource={imageSrc}
                      reset={open}
                      allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                      limitFileSize={5}
                      changeLoading={changeLoading}
                      uploadType={EMediaType.IMAGE as any}
                      uploadCategory={EMediaCategory.HOME_PAGE}
                    />
                  )
                }}
              />
              <div className="flex self-end">
                <span className="required text-danger text-center ">
                  {'(1920 x 1080 resolution for better experience)'}
                </span>
              </div>
              <div className="flex self-end">
                <SelectMedia
                  isNotGrid
                  onClickSelectMedia={() => {
                    setSelectedField((pre) => 'desktop')
                    setShowModalSelectMedia(true)
                  }}
                />
              </div>
            </div>

            <div className="flex flex-col">
              <Controller
                name="mediaMobileId"
                control={control}
                render={({ field: { onChange }, fieldState: { error } }) => {
                  onChangeMobileRef.current = onChange
                  return (
                    <CustomDragger
                      label="Image mobile"
                      name="mediaMobileId"
                      id="mediaMobileId"
                      alignment="col"
                      containerClassName="mt-3"
                      required
                      onLoadEnd={(data) => {
                        setImageMobileSrc((prev) => data.original ?? prev)
                        setValue('mediaMobileId', data?.id ?? 0)
                        onChange(data?.id ?? 0)
                      }}
                      note={'1920 x 1080 resolution for better experience'}
                      errors={error?.message}
                      initResource={imageMobileSrc}
                      reset={open}
                      allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                      limitFileSize={5}
                      changeLoading={changeLoading}
                      uploadType={EMediaType.IMAGE as any}
                      uploadCategory={EMediaCategory.HOME_PAGE}
                    />
                  )
                }}
              />
              <div className="flex self-end">
                <span className="required text-danger  text-center ">
                  {'(720 x 480 resolution for better experience)'}
                </span>
              </div>
              <div className="flex self-end">
                <SelectMedia
                  isNotGrid
                  onClickSelectMedia={() => {
                    setSelectedField((pre) => 'mobile')
                    setShowModalSelectMedia(true)
                  }}
                />
              </div>
            </div>
          </InputWrapperStyled>

          <div className="mt-6">
            <div className="flex items-center mt-5 justify-between">
              <div>
                <Button
                  htmlType="submit"
                  type="primary"
                  size="middle"
                  className="submit__btn login-btn"
                  onClick={handleSubmit(onPreview)}
                >
                  {'Preview'}
                </Button>
              </div>
              <div className="flex items-center mt-5 justify-end">
                <Button
                  type="ghost"
                  size="middle"
                  className="submit__btn login-btn mr-10"
                  onClick={onClose}
                  disabled={isLoading}
                >
                  {'Cancel'}
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  size="middle"
                  className="submit__btn login-btn"
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={handleSubmit(onSave)}
                >
                  {'Save'}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
      {showModalSelectMedia ? (
        <>
          <SelectMediaModal
            isOpen={showModalSelectMedia}
            onClose={onCloseModalSelectMedia}
            mediaType={EMediaType.IMAGE}
            category={EMediaCategory.HOME_PAGE}
            onSelectMedia={onSelectMedia}
          />
        </>
      ) : (
        <></>
      )}
    </AppModal>
  )
}

const InputWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  gap: 16px;

  & > div {
    min-width: 500px;
    grid-column: span 4 / span 4;
  }

  .textarea {
    margin-top: 10px;
  }

  .Input__field-container {
    width: 100%;
  }
`
