import { DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import {
  EAboutUsPageTabs,
  ETableTitleAboutUsManagementProvidingValue,
  PLACEHOLDER_IMAGE_AVATAR,
  thunkActionLoading,
} from '@configs'
import { Card, Skeleton, Tooltip, message } from 'antd'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  RootState,
  addNewProviding,
  deleteProviding,
  getListProviding,
  selectAboutUsLoading,
  updateProviding,
  useAppDispatch,
} from '@redux'
import { useTranslation } from 'react-i18next'
import { Button, SharedTable } from 'src/common'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import {
  IAddProvidingRequest,
  ICommonContentDetail,
} from 'src/interfaces/aboutus-management'
import { IMediaTitle } from 'src/interfaces/media'
import { ProvidingValueDetailModal } from './ProvidingValueDetailModal'
import { IPhotoMedia } from 'src/interfaces'

type Props = { activeTab?: string }

const ProvidingValueTab = ({ activeTab }: Props) => {
  const [showModal, setShowModal] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [selectedProviding, setSelectedProviding] = useState<
    ICommonContentDetail | undefined | null
  >()
  const [reset, setReset] = useState(false)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [selectedAboutUsId, setSelectedProvidingId] = useState<number | null>(
    null
  )
  const [noDefault, setNoDefault] = useState(0)

  const { t } = useTranslation(['error', 'common'])

  const dispatch = useAppDispatch()
  const getListAboutUsLoading = useSelector((state: RootState) =>
    selectAboutUsLoading(state, thunkActionLoading.GET_LIST_ABOUT_US)
  )

  const isAddAboutUsLoading = useSelector((state: RootState) =>
    selectAboutUsLoading(state, thunkActionLoading.ADD_PROVIDING_LOADING)
  )

  const { providing } = useSelector(
    (state: RootState) => state.aboutUsManagement
  )

  // useEffect(() => {
  //   dispatch(getListProviding())
  // }, [reset])

  const onCloseConfirmModal = () => {
    setOpenConfirmDeleteModal(false)
  }

  useEffect(() => {
    setNoDefault(providing?.length)
  }, [providing])

  const onClose = () => {
    setShowModal(false)
  }

  const onOpen = () => {
    setSelectedProviding(null)
    setShowModal(true)
  }

  const columns = [
    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">
              {ETableTitleAboutUsManagementProvidingValue.NO}
            </p>
          </div>
        )
      },
      dataIndex: 'no',
      align: 'center',
      key: 'no',
      width: '7%',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
    },
    {
      title: ETableTitleAboutUsManagementProvidingValue.TITLE,
      dataIndex: 'title',
      key: 'title',
      sortDirections: ['ascend', 'descend'],
      render: (title: string | IMediaTitle[]) => {
        const isArray = Array.isArray(title)
        if (isArray) {
          return (
            <div className="flex flex-row items-center gap-1">
              {title.map((img) => (
                <img
                  key={img?.id}
                  src={img?.url}
                  alt={img?.url}
                  width={24}
                  height={24}
                  className="object-contain"
                />
              ))}
            </div>
          )
        } else {
          return (
            <div className="h-auto items-center flex">
              <p className="my-auto">{title}</p>
            </div>
          )
        }
      },
      showSorterTooltip: false,
      ellipsis: true,
    },
    {
      title: ETableTitleAboutUsManagementProvidingValue.CONTENT,
      dataIndex: 'content',
      key: 'content',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
    },
    {
      title: ETableTitleAboutUsManagementProvidingValue.IMAGE,
      align: 'center',
      dataIndex: 'media',
      key: 'media',
      render: (record: IPhotoMedia) => (
        <div className="flex items-center justify-center w-full">
          <img
            src={record?.url ?? PLACEHOLDER_IMAGE_AVATAR}
            alt={record?.url}
            className="object-cover w-14 h-14"
          />
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">
              {ETableTitleAboutUsManagementProvidingValue.ACTION}
            </p>
          </div>
        )
      },
      key: 'action',
      dataIndex: 'action',
      width: '15%',
      render: (record: ICommonContentDetail) => (
        <div className="flex space-x-4 items-center justify-center w-full">
          <Tooltip title={t('common:view_detail')}>
            <EyeOutlined
              className="text-lg font-light mr-2.5 cursor-pointer text-[#184f64]"
              onClick={() => {
                setSelectedProviding(record)
                setShowModal(true)
              }}
            />
          </Tooltip>
          <DeleteOutlined
            className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 !text-red-600`}
            onClick={() => {
              setOpenConfirmDeleteModal(true)
              setSelectedProvidingId(record.id)
            }}
          />
        </div>
      ),
      ellipsis: true,
    },
  ]

  const onDelete = async () => {
    //on delete about us
    try {
      if (selectedAboutUsId) {
        setIsModalLoading(true)

        const response = await dispatch(
          deleteProviding(selectedAboutUsId)
        ).unwrap()
        setShowModal(false)
        setReset((r) => !r)
        if (response?.success) {
          message.success(response.data.message)
          setIsModalLoading(false)
          setOpenConfirmDeleteModal(false)
          dispatch(getListProviding())
        }
      } else {
        setShowModal(false)
      }
    } catch (error: any) {
      message.error(error.message)
    }
  }

  const onSave = async (data: IAddProvidingRequest) => {
    //on save about us
    try {
      if (!!selectedProviding) {
        const response = await dispatch(
          updateProviding({ ...data, id: selectedProviding.id })
        ).unwrap()
        setShowModal(false)
        setReset((r) => !r)
        if (response?.aboutUsProvidingValue) message.success(response.message)
      } else {
        const response = await dispatch(addNewProviding(data)).unwrap()
        setShowModal(false)
        setReset((r) => !r)
        if (response?.aboutUsProvidingValue) message.success(response.message)
      }
      dispatch(getListProviding())
    } catch (error: any) {
      message.error(error.message)
    }
  }

  useEffect(() => {
    if (activeTab === EAboutUsPageTabs.PROVIDING_VALUE) {
      dispatch(getListProviding())
    }
  }, [activeTab])

  return (
    <Card>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        />
        <Button
          htmlType="submit"
          type="primary"
          size="middle"
          className="submit__btn loginselected-btn"
          onClick={onOpen}
          style={{
            alignSelf: 'flex-end',
          }}
        >
          {'Add new'}
        </Button>
      </div>
      {getListAboutUsLoading ? (
        <Skeleton
          paragraph={{ rows: 4 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <SharedTable
          columns={columns as any}
          dataSource={providing?.map((item) => {
            return {
              ...item,
              key: item?.id,
              no: item?.no,
              title: item?.title
                ? item?.title
                : !!item?.mediaTitle?.length
                ? item?.mediaTitle
                : `-${t('error:no_data')}-`,
              content: item?.content ?? `-${t('error:no_data')}-`,
              record: item,
              action: item,
            }
          })}
          sticky
        />
      )}
      {showModal && (
        <ProvidingValueDetailModal
          open={showModal}
          onClose={onClose}
          isLoading={isModalLoading || !!isAddAboutUsLoading}
          changeLoading={setIsModalLoading}
          onSave={onSave}
          item={selectedProviding}
          noDefault={noDefault + 1 || 1}
        />
      )}
      {openConfirmDeleteModal && (
        <ConfirmDeleteModal
          title="Delete value"
          content="Are you sure to delete this value?"
          open={openConfirmDeleteModal}
          isLoading={!!isModalLoading || !!isAddAboutUsLoading}
          onDelete={onDelete}
          onClose={onCloseConfirmModal}
        />
      )}
    </Card>
  )
}

export default ProvidingValueTab
