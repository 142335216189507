import {
  getTeacherRequestDetail,
  updateTeacherRequestStatus,
  useAppDispatch,
} from '@redux'
import { Typography } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, Input, TextArea } from 'src/common'
import { AppModal } from 'src/components/AppModal'
import { REASON_CHARACTER_LIMITATION } from '../../teacher-request-management/config'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useParams } from 'react-router-dom'
import { ERequestStatus } from 'src/interfaces/teacher-request-management'
import { toast } from 'react-toastify'

interface IProps {
  open: boolean
  onClose: () => void
}

interface IFormData {
  reason: string
}

export default function RequestUpdateTeacherRequestDialog({
  open,
  onClose,
}: IProps) {
  const { teacherId } = useParams()
  const { t } = useTranslation(['common', 'teacherRequest'])
  const dispatch = useAppDispatch()

  const schema = z.object({
    reason: z
      .string()
      .trim()
      .nonempty({
        message: t('teacherRequest:reasonIsRequired'),
      })
      .min(REASON_CHARACTER_LIMITATION.MIN_LENGTH, {
        message: t('teacherRequest:reason_min_length', {
          minLength: REASON_CHARACTER_LIMITATION.MIN_LENGTH,
        }),
      })
      .max(REASON_CHARACTER_LIMITATION.MAX_LENGTH, {
        message: t('teacherRequest:reason_max_length', {
          maxLength: REASON_CHARACTER_LIMITATION.MAX_LENGTH,
        }),
      }),
  })

  const methods = useForm<IFormData>({
    defaultValues: {
      reason: '',
    },
    resolver: zodResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = async (data: IFormData) => {
    try {
      await dispatch(
        updateTeacherRequestStatus({
          teacherRequestId: String(teacherId),
          payload: {
            reason: data?.reason,
            response: ERequestStatus.REQUEST_UPDATE,
          },
        })
      ).unwrap()

      dispatch(getTeacherRequestDetail(String(teacherId)))
      toast.success('Request update teacher information successfully!')
      onClose()
    } catch (error: any) {
      toast.error(
        error?.message ||
          error?.data?.message ||
          'Something went wrong. Please try again'
      )
    }
  }

  return (
    <AppModal open={open} onClose={onClose} contentClassName="!px-8 !py-6">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="h-auto">
          <div className="flex items-center justify-center gap-4">
            <div>
              <h1 className="m-0 text-[24px]">
                Want to request more information?
              </h1>
            </div>
            {/* <div className="hover:opacity-75 cursor-pointer">
              <XCloseIcon width={16} height={16} onClick={onClose} />
            </div> */}
          </div>
        </div>

        <div className="flex flex-col max-w-[350px] mt-4 mb-8 mx-auto">
          <Typography
            style={{ color: '#424955FF', textAlign: 'center', fontSize: 16 }}
          >
            Are you sure you want to request more information for this teacher
            creation request?
          </Typography>

          <Typography
            style={{
              color: '#424955FF',
              textAlign: 'center',
              fontSize: 16,
            }}
          >
            Once you have requested update, an email will be sent directly to
            the user.
          </Typography>
        </div>

        <div className="mt-4">
          <Controller
            name={'reason'}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <TextArea
                  placeholder="Describe detail update"
                  name="reason"
                  required
                  onChange={(e) => {
                    onChange(e?.target?.value)
                  }}
                  value={value}
                  style={{
                    marginTop: 10,
                    height: 'fit-content',
                    minHeight: 150,
                  }}
                  errors={error?.message}
                  isGrid
                  containerClassName="mt-3"
                />
              )
            }}
          />
        </div>

        <div className="flex justify-center items-center my-2 mt-4 gap-4">
          <Button type="ghost" size="small" onClick={onClose}>
            Check Again
          </Button>

          <Button
            type="primary"
            size="small"
            htmlType="submit"
            loading={isSubmitting}
            style={{
              backgroundColor: '#D4A90FFF',
              color: '#ffffff',
              borderColor: '#D4A90FFF',
            }}
          >
            Send update request
          </Button>
        </div>
      </form>
    </AppModal>
  )
}
