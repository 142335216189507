import {
  EActivityLogActionType,
  EActivityLogType,
  LIST_USER_LOG_ACTION,
} from '@configs'
import { RootState, useAppDispatch, useAppSelector } from '@redux'
import { Divider, Skeleton } from 'antd'
import { camelCase, startCase } from 'lodash'
import { useEffect } from 'react'
import { Input } from 'src/common'
import { AppModal } from 'src/components/AppModal'
import { getUserLogByIdAction } from 'src/redux/actions/user-log'
import { XCloseIcon } from '../Icon'
import { ITransaction, TransactionStatusContent } from '@interfaces'
import moment from 'moment'

type Props = {
  open: boolean
  onClose: () => void
  transactionItem: ITransaction
  titleModal?: string
}

const DetailTransactionLogModal = ({
  open,
  onClose,
  transactionItem,
  titleModal,
}: Props) => {
  const { title, type, createdAt, status, amount, currency, transactionId } =
    transactionItem
  return (
    <AppModal open={open} onClose={onClose}>
      <div className="wrapper-modal">
        <div className="flex items-center justify-between ">
          {titleModal && (
            <div>
              <h1 className="m-0 text-[20px]">{titleModal}</h1>
            </div>
          )}
          <div className="hover:opacity-75 cursor-pointer">
            <XCloseIcon width={16} height={16} onClick={onClose} />
          </div>
        </div>
        <div className="mt-6 flex flex-col gap-3 max-h-[50vh] overflow-auto">
          <Input
            label="Transaction ID:"
            name="transactionId"
            value={transactionId}
            style={{
              boxShadow: 'none',
            }}
          />
          <Input
            label="Title:"
            name="title"
            value={title}
            style={{
              boxShadow: 'none',
            }}
          />
          <Input
            label="Time:"
            name="time"
            value={moment(createdAt).format('HH:mm:ss - DD MMM YYYY')}
            style={{
              boxShadow: 'none',
            }}
          />
          <Input
            label="Status:"
            name="status"
            value={TransactionStatusContent[status]}
            style={{
              boxShadow: 'none',
            }}
          />
          <Input
            label="Amount:"
            name="amount"
            value={amount}
            style={{
              boxShadow: 'none',
            }}
          />
          <Input
            label="Currency:"
            name="currency"
            value={currency.toString().toUpperCase()}
            style={{
              boxShadow: 'none',
            }}
          />
        </div>
      </div>
    </AppModal>
  )
}

export default DetailTransactionLogModal
