import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import ChatContentHeader from './ChatContentHeader'
import ChatMainContent from './ChatMainContent'
import ChatContentFooter from './ChatContentFooter'
import { RootState, useAppDispatch, useAppSelector } from '@redux'
import { useChatContext } from '../ChatContext'
import { ESocketMessage, ETeacherRole } from '@configs'
import { getCourseDetailAction } from 'src/redux/actions/course'

export default function ChatContent() {
  const { socket, selectedConversation } = useChatContext()
  const dispatch = useAppDispatch()
  const messageListRef = useRef<HTMLDivElement>(null)
  const { id: courseId } = useParams()

  const { selectedCourse, sendingMessageIds } = useSelector(
    (state: RootState) => state.course
  )

  const isTeacherOtherCourse = useMemo(
    () => selectedCourse?.user?.client === ETeacherRole.OTHER,
    [selectedCourse?.user?.client]
  )

  const handleSeenMessage = useCallback(() => {
    if (!courseId || !selectedConversation?.userId || isTeacherOtherCourse) {
      return
    }

    socket?.emit(ESocketMessage.SEEN_MESSAGE, {
      courseId,
      userId: selectedConversation?.userId,
    })
  }, [courseId, isTeacherOtherCourse, selectedConversation?.userId, socket])

  const handleScrollToNewMessage = useCallback(() => {
    messageListRef.current?.scrollTo(0, messageListRef?.current?.scrollHeight)
  }, [])

  useEffect(() => {
    if (!socket) return

    socket.on(ESocketMessage.RECEIVE_MESSAGE, (data) => {
      handleSeenMessage()
      handleScrollToNewMessage()
    })

    handleSeenMessage()
  }, [socket, dispatch, handleSeenMessage, handleScrollToNewMessage])

  useEffect(() => {
    if (!courseId) return
    dispatch(getCourseDetailAction(courseId)).unwrap()
  }, [courseId, dispatch])

  useEffect(() => {
    // scroll to bottom when user has just sent a new message
    if (!Array.isArray(sendingMessageIds) || !sendingMessageIds?.length) return

    handleScrollToNewMessage()
  }, [handleScrollToNewMessage, sendingMessageIds])

  return (
    <div className="h-full w-full flex flex-col relative border-l border-l-slate-200/60">
      {selectedConversation ? <ChatContentHeader /> : null}

      <ChatMainContent messageListRef={messageListRef} />

      {selectedConversation && !isTeacherOtherCourse ? (
        <ChatContentFooter />
      ) : null}
    </div>
  )
}
