import { KeyboardEvent, useState } from 'react'
import IconGroup from 'src/components/chatChannel/chatContent/IconGroup'
import SendMessageGroup from 'src/components/chatChannel/chatContent/SendMessageGroup'
import {
  EMessageType,
  MESSAGE_LIMIT_CHARACTERS,
} from 'src/pages/course-management/chat/config'
import { useChatContext } from 'src/pages/course-management/chat/ChatContext'
import UploadFileButton from './UploadFileButton'
import { useParams } from 'react-router-dom'
import { ESocketMessage, KEYBOARD_EVENT_CODE } from '@configs'
import {
  addNewMessageToChat,
  addNewSendingMessageId,
  useAppDispatch,
  useAppSelector,
} from '@redux'

export default function ChatContentFooter() {
  const { socket, selectedConversation } = useChatContext()
  const { id: courseId } = useParams()
  const dispatch = useAppDispatch()

  const { isLoading } = useAppSelector(
    (state) => state.course.listMessageWithUser
  )
  const accountInfo = useAppSelector((state) => state.auth.accountInfo)

  const [message, setMessage] = useState('')
  const addEmoji = (e: any) => {
    let sym = e.unified.split('-')
    let codesArray: any[] = []
    sym.forEach((el: any) => codesArray.push('0x' + el))
    let emoji = String.fromCodePoint(...codesArray)
    setMessage((prev) => prev + emoji)
  }

  const handleSendMessage = () => {
    if (!String(message).trim()) return

    const timeStamp = Date.now()
    const sendingMessageId = String(timeStamp)
    socket?.volatile.emit(ESocketMessage.SEND_MESSAGE, {
      tempId: sendingMessageId,
      userId: selectedConversation?.userId,
      courseId: Number(courseId),
      type: EMessageType.TEXT,
      content: String(message).trim(),
      mediaId: null,
    })

    dispatch(
      addNewMessageToChat({
        message: {
          tempId: sendingMessageId,
          type: EMessageType.TEXT,
          content: String(message).trim(),
          isSendingMessage: true,
          createdAt: new Date(timeStamp).toISOString(),
          user: {
            name: accountInfo?.name,
            id: accountInfo?.id,
            profilePhoto: accountInfo?.profilePhoto,
          },
        },
      })
    )
    dispatch(addNewSendingMessageId({ sendingMessageId }))
  }

  const onSendMessage = () => {
    handleSendMessage()
    setMessage('')
  }

  const handleOnKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (
      event.keyCode === KEYBOARD_EVENT_CODE.ENTER &&
      event.shiftKey === false
    ) {
      event.preventDefault()
      onSendMessage()
    }
  }

  return (
    <div className="absolute bottom-0 w-full h-[80px]">
      <div className="flex items-center border-t border-slate-200/60 pt-4 pb-10 dark:border-darkmode-400 sm:py-4">
        <textarea
          disabled={isLoading}
          value={message}
          rows={1}
          maxLength={MESSAGE_LIMIT_CHARACTERS}
          onChange={(e) => setMessage(e?.target?.value || '')}
          onKeyDown={handleOnKeyDown}
          placeholder="Type your message..."
          id="input-message-chat-channel"
          className="disabled:bg-white disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm rounded-md placeholder:text-slate-400/90 focus:ring-primary focus:ring-opacity-20 focus:border-opacity-40 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 h-[46px] resize-none border-transparent px-5 py-3 shadow-none focus:border-transparent focus:ring-0 dark:bg-darkmode-600 outline-none"
        ></textarea>

        <div className="relative bottom-0 left-0 mb-5 ml-5 flex sm:static sm:ml-0 sm:mb-0 items-center justify-center">
          <IconGroup addEmoji={addEmoji} disabled={isLoading} />
          <UploadFileButton chatId={'chatId'} disabled={isLoading} />
          <SendMessageGroup
            message={'message'}
            isFromChatText={true}
            onSendMessage={onSendMessage}
            disabled={isLoading}
          />
        </div>
      </div>
    </div>
  )
}
