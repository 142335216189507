import { EPermissions } from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  getListPermissionRoles,
  updateRole,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Button, Input } from 'src/common'
import { AppModal } from 'src/components/AppModal'
import { XCloseIcon } from 'src/components/Icon'
import { z } from 'zod'
import { ROLE_NAME_CHARACTER_LIMITATION } from './config'
import { IPermissionRoleItem } from '@interfaces'
interface IEditRoleNameProps {
  open: boolean
  chosenRole: { id: number; name: string } | null
  onClose: () => void
}

export default function EditRoleNameModal({
  open,
  chosenRole,
  onClose,
}: IEditRoleNameProps) {
  const { t } = useTranslation(['common', 'staffAndRole'])
  const dispatch = useAppDispatch()
  const addNewRoleSchema = z.object({
    roleName: z
      .string()
      .trim()
      .nonempty({
        message: t('staffAndRole:role_name_is_required'),
      })
      .min(ROLE_NAME_CHARACTER_LIMITATION.MIN_LENGTH, {
        message: t('staffAndRole:role_name_min_length', {
          minLength: ROLE_NAME_CHARACTER_LIMITATION.MIN_LENGTH,
        }),
      })
      .max(ROLE_NAME_CHARACTER_LIMITATION.MAX_LENGTH, {
        message: t('staffAndRole:role_name_max_length', {
          maxLength: ROLE_NAME_CHARACTER_LIMITATION.MAX_LENGTH,
        }),
      }),
  })

  const { data } = useAppSelector((state) => state.role.listPermissionRoles)
  const { permissionWithRoles = [] } = data || {}

  const methods = useForm<any>({
    defaultValues: {
      roleName: chosenRole?.name,
    },
    resolver: zodResolver(addNewRoleSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = async (data: { roleName: string }) => {
    try {
      if (!chosenRole) return

      const listPermission = permissionWithRoles?.reduce(
        (acc: Record<EPermissions, boolean>, cur: IPermissionRoleItem) => {
          return {
            ...acc,
            [cur?.permission]: Boolean(cur?.roles?.[chosenRole?.name]),
          }
        },
        {} as Record<EPermissions, boolean>
      )

      const payload = {
        name: data?.roleName,
        permissions: { ...listPermission },
      }

      await dispatch(
        updateRole({
          roleId: Number(chosenRole?.id),
          payload,
        })
      ).unwrap()
      toast.success(t('staffAndRole:update_role_successfully'))
      dispatch(getListPermissionRoles())
      onClose()
    } catch (error: any) {
      toast.error(
        error?.message ||
          error?.data?.message ||
          t('staffAndRole:something_went_wrong')
      )
    }
  }

  return (
    <AppModal open={open} onClose={onClose} contentClassName="!px-8 !py-6">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-[400px] h-auto">
          <div className="flex items-center justify-between ">
            <div>
              <h1 className="m-0 text-[24px]">
                {t('staffAndRole:edit_role_name')}
              </h1>
            </div>
            <div className="hover:opacity-75 cursor-pointer">
              <XCloseIcon width={16} height={16} onClick={onClose} />
            </div>
          </div>

          <div className="mt-4">
            <Controller
              name={'roleName'}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    value={value}
                    onChange={(e) => {
                      onChange(e?.target?.value)
                    }}
                    placeholder={t('staffAndRole:enter_role_name')}
                    errors={error?.message}
                  />
                )
              }}
            />
          </div>
        </div>

        <div className="flex justify-end items-center my-2 mt-4 gap-2">
          <Button type="ghost" size="small" onClick={onClose}>
            {t('common:cancel')}
          </Button>

          <Button
            type="primary"
            size="small"
            htmlType="submit"
            loading={isSubmitting}
          >
            {t('common:save')}
          </Button>
        </div>
      </form>
    </AppModal>
  )
}
