import { UploadOutlined } from '@ant-design/icons'
import { lessonAPI } from '@api'
import { IAssignment } from '@interfaces'
import type { UploadFile, UploadProps } from 'antd'
import { Upload } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { isNumber } from 'lodash'
import { useEffect, useState } from 'react'
import { Control, UseFormSetValue, useWatch } from 'react-hook-form'
import { Button as CommonButton } from 'src/common'

type AssignmentUploadButtonProps = {
  control: Control<IAssignment, any>
  setValue: UseFormSetValue<IAssignment>
  disabled?: boolean
}

const AssignmentUploadButton = ({
  control,
  setValue,
  disabled,
}: AssignmentUploadButtonProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [isDefaultFilesLoaded, setIsDefaultFilesLoaded] =
    useState<boolean>(false)

  const attachments = useWatch({ control, name: 'attachments' })

  useEffect(() => {
    if (attachments?.length && !isDefaultFilesLoaded) {
      setFileList(
        attachments.map((attachment) => {
          return {
            name: attachment?.name,
            uid: attachment?.id.toString(),
            status: 'done',
            url: attachment?.url,
          }
        })
      )
      setIsDefaultFilesLoaded(true)
    }
  }, [attachments])

  useEffect(() => {
    const updatedAttachmentIds = fileList
      .filter((file) => file?.status === 'done' && isNumber(Number(file?.uid)))
      .map((file) => Number(file?.uid))

    setValue('attachmentIds', updatedAttachmentIds)
  }, [fileList])

  const handleChange: UploadProps['onChange'] = (info) => {
    let newFileList = [...info.fileList]

    newFileList = newFileList.map((file) => {
      if (file?.response) {
        // Component will show file?.url as link
        file.url = file?.response.url ?? ''
      }
      return file
    })

    setFileList(newFileList)
  }

  const setProgress = ({
    fileId,
    percent,
    url,
    isError,
    newId,
  }: {
    fileId: string
    percent: number
    url?: string
    isError?: boolean
    newId?: string
  }) => {
    setFileList((fileList) =>
      fileList.map((file) => {
        if (file.uid === fileId) {
          file.percent = percent
          file.url = url

          if (isError) {
            file.status = 'error'
          } else {
            file.status = percent === 100 ? 'done' : 'uploading'
            file.uid = newId && file.status === 'done' ? newId : file.uid
          }
        }
        return file
      })
    )
  }

  const customRequest: UploadProps['customRequest'] = async (options) => {
    const { onSuccess, onProgress } = options
    const file = options.file as RcFile

    const config: AxiosRequestConfig<any> = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event) => {
        console.log('onUploadProgress: ', event)
        const multerUploadPercent = 65
        const percent = Math.floor(
          (event.loaded / event.total) * multerUploadPercent
        )
        setProgress({ fileId: file.uid, percent: (percent / 100) * 65 })
        if (onProgress) {
          onProgress({ percent })
        }
      },
    }

    try {
      const res = await lessonAPI.uploadAttachment(file, config)

      if (onSuccess) {
        onSuccess(res.data)
      }
      setProgress({
        fileId: file?.uid,
        percent: 100,
        url: res?.data?.url,
        newId: res?.data?.dataValues.id.toString(),
      })

      console.log('server res: ', res?.data)
    } catch (err) {
      console.error('server error: ', err)
      setProgress({ fileId: file?.uid, percent: 0, isError: true })
    }
  }

  return (
    <Upload
      accept=".pdf,image/*,.docx,.doc,.xlsx,.pptx,.pptm,.ppt"
      fileList={fileList}
      multiple
      disabled={disabled}
      onChange={handleChange}
      customRequest={customRequest}
    >
      <CommonButton icon={<UploadOutlined />}>Upload</CommonButton>
    </Upload>
  )
}

export default AssignmentUploadButton
