import {
  DeleteOutlined,
  EditOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import {
  EHabitGoalStatus,
  PATH_SUB_HABIT_QUESTION,
  PLACEHOLDER_IMAGE_AVATAR,
  enumTableTitleHabitGoal,
  thunkActionLoading,
} from '@configs'
import { useAppDispatch, useAppSelector } from '@redux'
import { Card, Skeleton, Tooltip, message } from 'antd'
import { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, SharedTable, SwitchButton } from 'src/common'
import AddUpdateGoalModal from 'src/components/habit-management/goals/AddUpdateGoalModal'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import { useDebounce } from 'src/hooks/useDebounce'
import {
  HabitGoalFormData,
  ICreateGoalRequest,
  IGetGoalParams,
  IGoalItem,
} from 'src/interfaces/habit-management'
import {
  createGoalAction,
  deleteGoalAction,
  getListGoalsAction,
  updateGoalAction,
} from 'src/redux/actions/habit-management'
import { IPhotoMedia } from 'src/interfaces'

type Props = {}

const GoalHabitPage = (props: Props) => {
  const [showAddEditModal, setShowAddEditModal] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)

  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)

  const { t } = useTranslation(['error', 'common'])

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [deletedGoal, setDeletedGoal] = useState<IGoalItem>()

  const [selectedGoal, setSelectedGoal] = useState<IGoalItem>()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [status, setStatus] = useState<boolean | undefined>(undefined)

  const debouncedValue = useDebounce<string | null>(searchText, 800)

  const { goal } = useAppSelector((state) => state.habit)

  const goalLoading = useAppSelector(
    (state) => state.habit.loadings[thunkActionLoading.GET_LIST_GOALS_LOADING]
  )

  const getGoals = async (params?: IGetGoalParams) => {
    dispatch(
      getListGoalsAction({
        page: page,
        limit: 10,
        search:
          debouncedValue !== null && debouncedValue.trim()
            ? debouncedValue.trim()
            : undefined,
        status: status,
        ...(params || {}),
      })
    )
  }

  const onSubmitCreateUpdateGoal = async (data: ICreateGoalRequest) => {
    setIsModalLoading(true)
    try {
      if (!!selectedGoal) {
        const response = await dispatch(
          updateGoalAction({ data, id: selectedGoal?.id })
        ).unwrap()
        if (response?.success) message.success(response.message)
      } else {
        const response = await dispatch(createGoalAction(data)).unwrap()
        if (response?.success) message.success(response.message)
      }
      getGoals()
    } catch (error: any) {
      message.error(error.message)
    } finally {
      setIsModalLoading(false)
      setShowAddEditModal(false)
    }
  }

  const onDeleteGoal = async () => {
    if (!deletedGoal || !deletedGoal?.id) return
    try {
      const response: any = await dispatch(
        deleteGoalAction(deletedGoal?.id as number)
      ).unwrap()
      if (response?.success) message.success(response.message)
      getGoals()
    } catch (error: any) {
      message.error(error.message)
    } finally {
      setDeletedGoal(undefined)
      setOpenConfirmDeleteModal(false)
    }
  }

  const onCloseAddEditModal = () => {
    setShowAddEditModal(false)
    setSelectedGoal(undefined)
  }
  const onCloseDeleteModal = () => {
    setOpenConfirmDeleteModal(false)
    setDeletedGoal(undefined)
  }

  const columns = [
    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleHabitGoal.NO}</p>
          </div>
        )
      },
      dataIndex: 'no',
      align: 'center',
      key: 'no',
      width: '7%',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: false,
    },
    {
      title: enumTableTitleHabitGoal.IMAGE,
      align: 'center',
      dataIndex: 'media',
      key: 'media',
      render: (record: IPhotoMedia) => (
        <div className="flex items-center justify-center w-full">
          <img
            src={record?.url ?? PLACEHOLDER_IMAGE_AVATAR}
            alt={record?.url}
            className="object-cover w-14 h-14"
          />
        </div>
      ),
    },
    {
      title: enumTableTitleHabitGoal.GOAL_EN,
      dataIndex: 'goalEn',
      key: 'goalEn',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      width: '15%',
    },
    {
      title: enumTableTitleHabitGoal.GOAL_LN,
      dataIndex: 'tagLn',
      key: 'tagLn',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      width: '15%',
    },
    {
      title: enumTableTitleHabitGoal.STATUS,
      dataIndex: 'status',
      key: 'status',
      showSorterTooltip: false,
      ellipsis: true,
      align: 'center',
      width: '10%',
      render: (record: any, item: IGoalItem) =>
        !!item?.deletedAt ? (
          <div className="flex items-center justify-center w-full">
            <p className={`m-0 bold text-danger text-center`}>Deleted</p>
          </div>
        ) : (
          <StatusItem status={item.status as unknown as string} id={item.id} />
        ),
    },
    {
      title: enumTableTitleHabitGoal.QAA,
      dataIndex: 'status',
      key: 'status',
      showSorterTooltip: false,
      ellipsis: true,
      align: 'center',
      width: '20%',
      render: (record: any, item: IGoalItem) => (
        <div className="flex space-x-4 items-center justify-center w-full">
          <Tooltip title={t('common:view_detail')}>
            <UnorderedListOutlined
              className="text-lg font-light mr-2.5 cursor-pointer !text-[#184f64] hover:opacity-80"
              onClick={() => {
                navigate(PATH_SUB_HABIT_QUESTION, {
                  state: {
                    goalId: item?.id,
                  },
                })
              }}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      title: enumTableTitleHabitGoal.ACTION,
      key: 'action',
      dataIndex: 'action',
      width: '10%',
      align: 'center',
      render: (record: any) => (
        <div className="flex space-x-4 items-center justify-center w-full">
          <Tooltip title={t('common:edit')}>
            <EditOutlined
              className="text-lg font-light mr-2.5 cursor-pointer !text-[#184f64] hover:opacity-80"
              onClick={() => {
                setShowAddEditModal(true)
                setSelectedGoal(record)
              }}
            />
          </Tooltip>
          <Tooltip
            title={record?.status ? "Can't delete active goal" : 'Delete'}
          >
            <DeleteOutlined
              className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 ${
                record?.status || !!record?.deletedAt
                  ? '!text-red-100'
                  : '!text-red-600 '
              }`}
              disabled={!!record?.deletedAt}
              onClick={() => {
                if (record?.status || !!record?.deletedAt) return
                setOpenConfirmDeleteModal(true)
                setDeletedGoal(record)
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ]

  useEffect(() => {
    getGoals()
  }, [])
  return (
    <Card>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        />
        <Button
          htmlType="submit"
          type="primary"
          size="middle"
          className="submit__btn loginselected-btn"
          onClick={() => {
            setShowAddEditModal(true)
          }}
          style={{
            alignSelf: 'flex-end',
          }}
        >
          {'New goal'}
        </Button>
      </div>
      {goalLoading ? (
        <Skeleton
          paragraph={{ rows: 4 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <SharedTable
          columns={columns as any}
          dataSource={goal.goals?.map((item, index) => {
            return {
              ...item,
              key: item?.id,
              no: index + 1,
              goalEn: item?.goal ?? `--`,
              tagLn: item?.goalInDutch ?? `--`,
              status: item?.status
                ? EHabitGoalStatus.ACTIVE
                : EHabitGoalStatus.INACTIVE,
              record: item,
              action: item,
              media: item?.media ?? `--`,
            }
          })}
          sticky
          rowKey={(record: IGoalItem) => {
            return `${record.id}-${record.updatedAt}`
          }}
          paginationProps={{
            total: goal.total ?? 0,
            pageSize: 10,
            showSizeChanger: false,
            current: goal.page ?? 1,
            onChange(page) {
              getGoals({ page: page })
            },
          }}
        />
      )}
      {showAddEditModal && (
        <AddUpdateGoalModal
          open={showAddEditModal}
          goal={selectedGoal}
          onClose={onCloseAddEditModal}
          isLoading={isModalLoading}
          onSubmit={onSubmitCreateUpdateGoal}
        />
      )}
      {openConfirmDeleteModal && (
        <ConfirmDeleteModal
          title="Are you sure to delete this goal?"
          content="This goal is published, the deletion will affect to others participating in this!"
          open={openConfirmDeleteModal}
          isLoading={!!isModalLoading}
          onDelete={onDeleteGoal}
          onClose={onCloseDeleteModal}
          highlight
        />
      )}
    </Card>
  )
}

export default GoalHabitPage

const StatusItem = memo(({ status, id }: { status: string; id: number }) => {
  const [localStatus, setLocalStatus] = useState(
    status === 'Active' ? true : false
  )
  const dispatch = useAppDispatch()

  const onUpdateStatus = async (status: boolean) => {
    try {
      const res = await dispatch(
        updateGoalAction({
          data: {
            status,
          },
          id: id,
        })
      ).unwrap()
      message.success(res.message)
    } catch (error) {}
  }
  return (
    <div className="flex space-x-4 items-center justify-center w-full">
      <SwitchButton
        size="default"
        checked={localStatus}
        onChange={(e) => {
          setLocalStatus(e)
          onUpdateStatus(e)
        }}
      />
    </div>
  )
})
