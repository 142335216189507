import { Typography } from 'antd'
import { TextProps } from 'antd/lib/typography/Text'
import React from 'react'

import './index.style.css'

export const Text = (props: TextProps) => {
  return (
    <Typography.Text
      className={`Typography_text ${props.className}`}
      {...props}
    >
      {props.children}
    </Typography.Text>
  )
}
