import React, { useEffect, useRef, useState } from 'react'
import * as yup from 'yup'

import { AppModal } from 'src/components/AppModal'
import { XCloseIcon } from 'src/components/Icon'
import { Button, Input } from 'src/common'
import { message } from 'antd'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { EKeyBoardCode } from '@configs'
import { ICategory } from '@interfaces'
import { addCategoryAction, updateCategoryAction, useAppDispatch } from '@redux'
import { t } from 'i18next'
import { customSlugify } from 'src/utils/customSlugify'
import { AppDragger } from 'src/common/upload/Dragger'
import MetaTab from '../blog/content/MetaTab'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import SelectMedia from 'src/components/gallery/SelectMedia'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'

interface IConfirmDeleteModalProps {
  open: boolean
  isLoading: boolean
  title: string
  onClose: () => void
  item?: ICategory
  onSuccess: () => void
}

const EditCategoryModal = (props: IConfirmDeleteModalProps) => {
  const { open, isLoading, title, onClose, item, onSuccess } = props
  const [imageSrc, setImageSrc] = useState('')

  const dispatch = useAppDispatch()
  const schemaCategoryTitle = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(t('error:field_required'))
      .max(50, 'Category name may not be greater than 50 characters'),
    nameInDutch: yup
      .string()
      .trim()
      .required(t('error:field_required'))
      .max(50, 'Category name may not be greater than 50 characters'),
    slug: yup
      .string()
      .trim()
      .required(t('error:slug_required'))
      .max(50, 'Slug may not be greater than 50 characters'),
    metaTitle: yup
      .string()
      .trim()
      .max(100, t('error:metaname_length_error'))
      .required(t('error:field_required')),
    metaDescription: yup
      .string()
      .trim()
      .required(t('error:field_required'))
      .max(255, t('error:metaDescription_length_error')),
    canonical: yup
      .string()
      .trim()
      .required(t('error:field_required'))
      .max(255, t('error:canonical_length_error')),
    schemaMarkup: yup
      .string()
      .trim()
      .required(t('error:field_required'))
      .max(255, t('error:metaDescription_length_error')),
    metaImage: yup.string().trim().required(t('error:field_required')),
    keywords: yup.string().trim().required(t('error:field_required')),
    mediaId: yup
      .number()
      .required(t('error:field_required'))
      .min(1, t('error:field_required')),
  })

  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    clearErrors,
    getValues,
    formState: { isSubmitting },
  } = useForm<{
    name: string
    nameInDutch: string
    slug: string
    metaTitle: string
    metaDescription: string
    keywords: string
    canonical: string
    schemaMarkup: string
    metaImage: string
    mediaId: number
  }>({
    resolver: yupResolver(schemaCategoryTitle),
    defaultValues: {
      name: item?.name || '',
      nameInDutch: item?.nameInDutch || '',
      slug: item?.slug || '',
      metaTitle: '',
      metaDescription: '',
      keywords: '',
      canonical: '',
      schemaMarkup: 'schema mark up',
      metaImage: '',
      mediaId: item?.mediaId ?? 0,
    },
  })
  const onSelectMedia = (data: IMediaItem) => {
    setImageSrc(data?.baseUrl ?? '')
    setValue('mediaId', data?.id ?? 0)
    onChangeDesktopRef.current(data?.id ?? 0)
    setShowModalSelectMedia(false)
  }
  useEffect(() => {
    clearErrors()
    reset()
    if (item) {
      setValue('name', item?.name)
      setValue('nameInDutch', item?.nameInDutch)
      setValue('slug', item?.slug)
      setValue('metaTitle', item?.metaData?.metaTitle)
      setValue('metaDescription', item?.metaData?.metaDescription)
      setValue('keywords', item?.metaData?.keywords)
      setValue('canonical', item?.metaData?.canonical)
      setValue('mediaId', item?.mediaId ?? 0)
      // setValue('schemaMarkup', item?.metaData?.schemaMarkup)
      setValue('metaImage', item?.metaData?.metaImage)
      setImageSrc(item?.media?.url ?? '')
    }
  }, [item, setValue, open])

  const updateCategory = async (data: {
    name: string
    nameInDutch: string
    slug: string
  }) => {
    if (!item) {
      return
    }
    try {
      const response = await dispatch(
        updateCategoryAction({
          id: item.id,
          ...data,
          slug: data.slug || '',
          name: data.name || '',
          nameInDutch: data.nameInDutch || '',
        })
      ).unwrap()
      message.success({
        content: response.message,
      })
      onSuccess()
    } catch (error: any) {
      message.error({
        content: error.message,
      })
    }
  }
  const addNewCategory = async (data: {
    name: string
    nameInDutch: string
    slug: string
  }) => {
    try {
      const response = await dispatch(
        addCategoryAction({
          ...data,
          name: data.name || '',
          nameInDutch: data.nameInDutch || '',
          slug: data.slug || '',
        })
      ).unwrap()
      onSuccess()
      onCloseModal()
    } catch (error: any) {
      message.error({
        content: error.message,
      })
    }
  }
  const onChangeDesktopRef = useRef<any>(null)
  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }
  const onCloseModal = () => {
    onClose()
    reset()
    setImageSrc('')
  }
  return (
    <AppModal
      open={open}
      onClose={onCloseModal}
      title={
        <div className="flex items-center justify-between ">
          <div>
            <h1 className="m-0 text-[20px]">
              {!!item ? title : 'Add new category'}
            </h1>
          </div>
          <div className="hover:opacity-75 cursor-pointer">
            <XCloseIcon width={16} height={16} onClick={onCloseModal} />
          </div>
        </div>
      }
    >
      <div className="mt-6">
        <form>
          <div className="px-5">
            <Controller
              name="name"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="Name"
                    name="name"
                    required
                    value={value}
                    onChange={(event) => {
                      onChange(event?.target?.value)
                      setValue(
                        'slug',
                        customSlugify(event?.target?.value?.trim())
                      )
                    }}
                    style={{ marginTop: 10 }}
                    errors={error?.message}
                  />
                )
              }}
            />
          </div>

          <div className="px-5">
            <Controller
              name="nameInDutch"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="Name in Netherlands"
                    name="nameInDutch"
                    required
                    value={value}
                    onChange={onChange}
                    style={{ marginTop: 10 }}
                    errors={error?.message}
                  />
                )
              }}
            />
          </div>

          <div className="px-5">
            <Controller
              name={'slug'}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="Slug"
                    name="slug"
                    required
                    onChange={onChange}
                    value={value}
                    style={{ marginTop: 10 }}
                    errors={error?.message}
                  />
                )
              }}
            />
          </div>
          <div className="px-5">
            <Controller
              name="mediaId"
              control={control}
              render={({ field: { onChange }, fieldState: { error } }) => {
                onChangeDesktopRef.current = onChange
                return (
                  <CustomDragger
                    label="Image"
                    name="mediaId"
                    id="mediaId"
                    containerClassName="mt-3"
                    required
                    onLoadEnd={(data) => {
                      setImageSrc((prev) => data.original ?? prev)
                      setValue('mediaId', data?.id ?? 0)
                      onChange(data?.id ?? 0)
                    }}
                    errors={error?.message}
                    initResource={imageSrc}
                    reset={open}
                    allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                    limitFileSize={5}
                    uploadType={EMediaType.IMAGE as any}
                    uploadCategory={EMediaCategory.HOME_PAGE}
                  />
                )
              }}
            />
            <SelectMedia
              onClickSelectMedia={() => {
                setShowModalSelectMedia(true)
              }}
            />
          </div>

          <MetaTab
            control={control}
            getValues={getValues}
            setValue={setValue}
            isShowDivider={true}
            isShowSelectMedia={false}
          />

          <div className="flex items-center mt-5 justify-end">
            <Button
              type="ghost"
              size="large"
              className="submit__btn login-btn mr-2"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="large"
              className="submit__btn login-btn"
              loading={isLoading || isSubmitting}
              onClick={
                item
                  ? handleSubmit(updateCategory)
                  : handleSubmit(addNewCategory)
              }
            >
              Save
            </Button>
          </div>
        </form>
      </div>
      {showModalSelectMedia ? (
        <>
          <SelectMediaModal
            isOpen={showModalSelectMedia}
            onClose={onCloseModalSelectMedia}
            mediaType={EMediaType.IMAGE}
            category={EMediaCategory.HOME_PAGE}
            onSelectMedia={onSelectMedia}
          />
        </>
      ) : (
        <></>
      )}
    </AppModal>
  )
}

export default EditCategoryModal
