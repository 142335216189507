import { io, Socket } from 'socket.io-client'
import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { LLC_ACCESS_TOKEN } from '@configs'

let socketInstance: Socket | null = null

export default function useSocket({
  url,
  queryParams,
}: {
  url: string
  queryParams: {
    userId?: number | string
    courseId: number | string
  }
}) {
  const token = Cookies.get(LLC_ACCESS_TOKEN)

  useEffect(() => {
    return () => {
      socketInstance = null
    }
  }, [])

  if (token && !socketInstance) {
    socketInstance = io(url as string, {
      transports: ['websocket'],
      auth: {
        token: `Bearer ${token}`,
      },
      query: queryParams,
    })
  }

  return socketInstance
}
