import { CloseCircleOutlined } from '@ant-design/icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { Card, DatePicker, Typography, message } from 'antd'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import ReactPlayer from 'react-player'
import { useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'

import moment, { Moment } from 'moment'
import { ApiClient } from 'src/api'
import { Button, Input, Loading, TextArea } from 'src/common'
import {
  EUserPostStatus,
  PATH_POST_MANAGEMENT,
  POST_MEDIA_FILTER,
  VIDEO_FILTER,
  thunkActionLoading,
} from 'src/configs'
import { RouterParams } from 'src/interfaces'
import { IPostCreateForm, TPostDetail } from 'src/interfaces/post-management'
import {
  getAllCategoriesAction,
  useAppDispatch,
  useAppSelector,
} from 'src/redux'
import { selectPostLoading } from 'src/redux/Slices/post-management'
import {
  createPost,
  editPost,
  getPostById,
} from 'src/redux/actions/post-management'
import { AppModal, XCloseIcon } from '../../components'

type Props = {}

const addPostValue = z.object({
  content: z
    .string()
    .trim()
    .max(2200, 'The maximum length for this field is 2200 characters'),
  contentInDutch: z
    .string()
    .trim()
    .max(2200, 'The maximum length for this field is 2200 characters'),
  tagIds: z.number().array(),
})

const PostCreateScreen = (props: Props) => {
  const [images, setImages] = useState<any[]>([])
  const [isChangeImage, setIsChangeImage] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingStatusFriend, setIsLoadingStatusFriend] =
    useState<boolean>(false)
  const [postDetail, setPostDetail] = useState<TPostDetail>()
  const [openDate, setOpenDate] = useState<boolean>(false)
  const [date, setDate] = useState<Moment>()
  const [errorDate, setErrorDate] = useState<string>('')

  const { postId } = useParams<RouterParams['PostDetail']>()
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const listSubCategory = useAppSelector(
    (state) => state.categories.listSubCategory
  )

  const loadingDetails = useAppSelector((state) =>
    selectPostLoading(state, thunkActionLoading.GET_PACKAGE_POST_DETAIL_LOADING)
  )

  const isEdit = !!postId

  const defaultValue: IPostCreateForm = {
    content: '',
    contentInDutch: '',
    tagIds: [],
  }

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isValid, isDirty },
  } = useForm<IPostCreateForm>({
    defaultValues: defaultValue,
    resolver: zodResolver(addPostValue),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onChangeDate = (value: moment.Moment | null) => {
    if (moment(value).isBefore(moment())) {
      setErrorDate('Please select date after today')
      return
    }
    setErrorDate('')

    if (value) {
      setDate(value)
    }
  }

  const onClose = () => {
    setOpenDate(false)
  }

  const onOpenDate = () => {
    setOpenDate(true)
  }

  const onRemoveImage = (index: number) => {
    const newImages = cloneDeep(images)
    newImages.splice(index, 1)

    setImages(newImages)
    setIsChangeImage(true)
  }

  const handleChangeMedia = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) return
    let localImages: any = []

    if (e.target.files.length + images.length > 6) {
      message.error(`You can only upload 6 files`)
      return
    }
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i]
      const isMatchMediaSize = Number(file.size) / 1024 / 1024 < 10
      const isMatchMediaType = POST_MEDIA_FILTER.test(file.type)

      const allowedInputType = ['mp4', 'mpeg', 'jpg', 'jpeg', 'png', 'gif']
        .join('/')
        .toUpperCase()

      if (!isMatchMediaType) {
        message.error(`You can only upload ${allowedInputType}file!`)
        break
      }

      if (!isMatchMediaSize) {
        message.error(`Media file must smaller than ${10}MB!`)
        break
      }
      localImages.push({ file, src: URL.createObjectURL(file) })
    }

    setImages((prev) => [...prev, ...localImages])
    setIsChangeImage(true)
  }

  const onSave = async (data: any) => {
    setIsLoading(true)
    if (data?.content || data?.contentInDutch || images?.length) {
      const imageObj = images.reduce(
        (acc, currentItem) => {
          if (currentItem.id !== undefined) {
            // Add to the itemsWithId array
            acc?.existedImages.push(currentItem)
          } else {
            // Add to the itemsWithoutId array
            acc?.newImages.push(currentItem)
          }
          return acc
        },
        { existedImages: [], newImages: [] }
      )

      try {
        const payload = {
          ...data,
          mediaIds: imageObj?.existedImages.map((image: any) => image.id),
        }
        if (imageObj?.newImages?.length) {
          let uploadArray: any = []
          imageObj?.newImages?.forEach((image: any) => {
            const formData = new FormData()

            formData.append('file', image?.file)

            uploadArray.push(
              ApiClient.post(`/media/resize-resolution`, formData)
            )
          })
          const response = await Promise.all(uploadArray)
          payload.mediaIds = [
            ...response.map((res) => res.data?.id),
            ...imageObj?.existedImages.map((image: any) => image.id),
          ]
        }

        const response: any = isEdit
          ? await dispatch(editPost({ ...payload, id: postId })).unwrap()
          : await dispatch(createPost(payload)).unwrap()
        message.success(response.message)
        navigate(PATH_POST_MANAGEMENT)
      } catch (error: any) {
        message.error(error.message)
      } finally {
        setIsLoading(false)
        setIsLoadingStatusFriend(false)
      }

      return
    }
    message.error('Please fill content or upload image to submit')
  }

  const getDetail = async () => {
    if (postId) {
      try {
        const response = await dispatch(
          getPostById({ id: Number(postId) })
        ).unwrap()
        setPostDetail(response)
        setValue('content', response?.content ?? '')
        setValue('contentInDutch', response?.contentInDutch ?? '')

        response?.tags?.length &&
          setValue(
            'tagIds',
            response?.tags?.map((tag: any) => tag.id)
          )
        if (response?.mediaLists?.length) {
          setImages(
            response?.mediaLists.map((media: any) => {
              return {
                ...media,
                src: media?.url,
              }
            })
          )
        }
      } catch (error: any) {
        console.log(error)
        message.error(error?.data?.message)
      }
    }
  }

  useEffect(() => {
    getDetail()
    dispatch(getAllCategoriesAction())
  }, [postId])

  return (
    <Card
      style={{
        padding: '0 64px',
        minHeight: '600px',
      }}
    >
      <Typography className="text-[24px] font-semibold w-full text-center text-black">
        {isEdit ? 'Edit post' : 'Create new post'}
      </Typography>
      {loadingDetails ? (
        <div className="w-full flex items-center justify-center mt-[24px]">
          <Loading />
        </div>
      ) : (
        <>
          <div>
            <Typography className="text-[14px] font-medium">
              Photo or video
            </Typography>
            <div className="mt-[16px]">
              {!!images?.length && (
                <div className="flex items-center justify-start gap-[16px] flex-wrap mb-[12px]">
                  {images?.map((image, index) => {
                    const isVideo = VIDEO_FILTER.test(
                      image?.id ? image.type : image.file.type
                    )
                    return isVideo ? (
                      <div className="w-[200px] h-[200px] rounded-[8px] flex items-center justify-center rounded-[8px] relative border">
                        <div
                          className="flex items-center justify-center absolute right-[-10px] top-[-10px] cursor-pointer z-10 bg-white rounded-full"
                          onClick={() => {
                            onRemoveImage(index)
                          }}
                        >
                          <CloseCircleOutlined className=" text-[20px]" />
                        </div>
                        <ReactPlayer
                          url={image?.src}
                          loop
                          controls={true}
                          style={{
                            width: '100%',
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                            borderRadius: '8px',
                          }}
                          height={200}
                          width={200}
                          className="react-player !rounded-[8px] !overflow-hidden"
                        />
                      </div>
                    ) : (
                      <div
                        key={image.src}
                        className="border rounded-[8px] relative"
                      >
                        <div
                          className="flex items-center justify-center absolute right-[-10px] top-[-10px] cursor-pointer z-10 bg-white rounded-full"
                          onClick={() => {
                            onRemoveImage(index)
                          }}
                        >
                          <CloseCircleOutlined className=" text-[20px]" />
                        </div>
                        <img
                          src={image.src}
                          alt="iamge"
                          className="w-[200px] h-[200px] object-contain rounded-[8px]"
                          key={index}
                        />
                      </div>
                    )
                  })}
                </div>
              )}
              <label
                className={`bg-main border-main text-white rounded-[6px] text-[14px] font-medium py-[8px] px-[12px] min-w-[96px] inline-block h-fit cursor-pointer hover:opacity-80 transition-all duration-300 text-center ${
                  images.length === 6 || isLoading || isLoadingStatusFriend
                    ? 'opacity-80'
                    : 'opacity-100'
                }`}
                htmlFor="post-files"
              >
                Upload
              </label>
              <input
                type="file"
                className="hidden"
                id="post-files"
                multiple={true}
                disabled={
                  images.length === 6 || isLoading || isLoadingStatusFriend
                }
                onChange={handleChangeMedia}
              />
            </div>
          </div>
          {!!listSubCategory?.length && (
            <form id="create-post" onSubmit={handleSubmit(onSave)}>
              <Controller
                name="tagIds"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Tags"
                      options={listSubCategory?.map((item) => {
                        return {
                          value: item?.id,
                          label: item?.name,
                        }
                      })}
                      type="select"
                      onChangeSelect={(e) => {
                        onChange(e)
                      }}
                      value={value as any}
                      errors={error?.message}
                      alignment="col"
                      selectMode="multiple"
                      containerClassName="mt-[24px] max-w-[50%]"
                    />
                  )
                }}
              />
              <Controller
                name={'content'}
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <TextArea
                      label="Content EN"
                      onChange={onChange}
                      value={value}
                      style={{ marginTop: 10, width: '100%' }}
                      errors={error?.message}
                      containerClassName="mt-[24px]"
                      labelClassName="mb-[16px] inline-block"
                      autoSize={{ minRows: 8, maxRows: 16 }}
                    />
                  )
                }}
              />
              <Controller
                name={'contentInDutch'}
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <TextArea
                      label="Content NL"
                      name="contentInDutch"
                      onChange={onChange}
                      value={value}
                      style={{ marginTop: 10, width: '100%' }}
                      errors={error?.message}
                      containerClassName="mt-[24px]"
                      labelClassName="mb-[16px] inline-block"
                      autoSize={{ minRows: 8, maxRows: 16 }}
                    />
                  )
                }}
              />
              {!postDetail?.published && postDetail?.publishedDate && (
                <div className="flex items-center mt-5 gap-1">
                  <span className="text-[16px] ">{`This post will be posted on `}</span>
                  <span className="text-[16px] font-medium">
                    {`${moment(postDetail.publishedDate).format(
                      'YYYY-MM-DD HH:mm'
                    )}`}
                  </span>
                </div>
              )}
              <div className="mt-10 pb-10">
                <div className="flex items-center  mt-[24px] gap-[40px] justify-center">
                  <Button
                    type="primary"
                    size="middle"
                    className="submit__btn login-btn"
                    loading={isLoading}
                    disabled={isEdit && postDetail?.published}
                    onClick={handleSubmit((data) => {
                      if (
                        !data?.content &&
                        !data?.contentInDutch &&
                        !images?.length
                      ) {
                        message.error(
                          'Please fill content or upload media to submit'
                        )
                        return
                      }

                      if (data?.content && !data?.contentInDutch) {
                        setError('contentInDutch', {
                          message: 'This field is required',
                        })
                        message.error(
                          'You must enter value for both 2 fields: Content EN and Content NL'
                        )
                        return
                      }

                      if (!data?.content && data?.contentInDutch) {
                        setError('content', {
                          message: 'This field is required',
                        })
                        message.error(
                          'You must enter value for both 2 fields: Content EN and Content NL'
                        )
                        return
                      }

                      onOpenDate()
                    })}
                  >
                    {'Schedule post'}
                  </Button>

                  <Button
                    htmlType="submit"
                    type="primary"
                    size="middle"
                    className="submit__btn login-btn"
                    loading={isLoading}
                    disabled={isLoading || isLoadingStatusFriend}
                    onClick={handleSubmit((data) => {
                      if (
                        !data?.content &&
                        !data?.contentInDutch &&
                        !images?.length
                      ) {
                        message.error(
                          'Please fill content or upload media to submit'
                        )
                        return
                      }

                      if (data?.content && !data?.contentInDutch) {
                        setError('contentInDutch', {
                          message: 'This field is required',
                        })
                        message.error(
                          'You must enter value for both 2 fields: Content EN and Content NL'
                        )
                        return
                      }

                      if (!data?.content && data?.contentInDutch) {
                        setError('content', {
                          message: 'This field is required',
                        })
                        message.error(
                          'You must enter value for both 2 fields: Content EN and Content NL'
                        )
                        return
                      }

                      isEdit
                        ? onSave({ ...data, published: postDetail?.published })
                        : onSave({ ...data, published: true })
                    })}
                  >
                    {isEdit ? 'Save' : 'Publish now'}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </>
      )}
      <AppModal open={openDate} onClose={onClose}>
        <div className="flex items-center justify-between ">
          <div>
            <h1 className="m-0 text-[20px]">{'Schedule post'}</h1>
          </div>
          <div className="hover:opacity-75 cursor-pointer">
            <XCloseIcon
              width={16}
              height={16}
              onClick={() => {
                !isLoading && onClose()
              }}
            />
          </div>
        </div>
        <div className="mt-[24px]">
          <DatePicker
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            className="w-[300px]"
            onChange={(value) => {
              onChangeDate(value)
            }}
          />
          {errorDate && (
            <div className="mt-1">
              <span className="text-red-500 text-[14px]">{errorDate}</span>
            </div>
          )}
        </div>
        <div className="mt-6">
          <div className="flex items-center mt-5 justify-end">
            <Button
              type="primary"
              size="middle"
              className="submit__btn login-btn"
              loading={isLoading}
              disabled={!!errorDate}
              onClick={handleSubmit((data) => {
                if (
                  !data?.content &&
                  !data?.contentInDutch &&
                  !images?.length
                ) {
                  message.error('Please fill content or upload media to submit')
                  return
                }

                if (data?.content && !data?.contentInDutch) {
                  setError('contentInDutch', {
                    message: 'This field is required',
                  })
                  message.error(
                    'You must enter value for both 2 fields: Content EN and Content NL'
                  )
                  return
                }

                if (!data?.content && data?.contentInDutch) {
                  setError('content', {
                    message: 'This field is required',
                  })
                  message.error(
                    'You must enter value for both 2 fields: Content EN and Content NL'
                  )
                  return
                }
                if (date) {
                  onSave({
                    ...data,
                    published: isEdit ? postDetail?.published : false,
                    publishedDate: date.utc().format('YYYY-MM-DD HH:mm'),
                  })
                } else {
                  onSave({
                    ...data,
                    published: isEdit ? postDetail?.published : true,
                  })
                }
              })}
            >
              {'Save'}
            </Button>
          </div>
        </div>
      </AppModal>
    </Card>
  )
}

export default PostCreateScreen
