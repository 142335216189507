import { useAppDispatch } from '@redux'
import { Empty, Skeleton, message } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IMissionUser } from 'src/interfaces/habit-management'
import {
  getListChallengerByIdAction,
  getListMissionUserByIdAction,
} from 'src/redux/actions/habit-management'
import styled from 'styled-components'

const DetailChallengeParticipant = () => {
  const { clId, userId } = useParams<any>()

  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const [listMissions, setListMissions] = useState<IMissionUser[]>([])
  const [challengeName, setChallengeName] = useState('')

  const getChallenge = async () => {
    if (!clId || !userId) {
      return
    }
    try {
      setIsLoading(true)
      const responseV2: any = await dispatch(
        getListChallengerByIdAction(+clId)
      ).unwrap()
      setChallengeName(responseV2.data.challenge)
      const response = await dispatch(
        getListMissionUserByIdAction({
          clid: +clId,
          userId: +userId,
        })
      ).unwrap()
      setListMissions(response.data)
    } catch (error: any) {
      message.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (clId || userId) {
      getChallenge()
    }
  }, [])
  const renderContent = ({
    title,
    status,
    description,
    note,
  }: {
    title: string
    status: boolean
    description: string
    note: string
  }) => {
    return (
      <div
        className="flex items-start flex-col "
        style={{ marginTop: 10, width: '75%' }}
      >
        <div className="grid-flow-row grid-cols-2 grid w-screen">
          <p
            style={{
              fontWeight: '500',
              fontSize: '1.2rem',
            }}
            className={`  items-center  mr-[1.25rem]  text-dark col-span-1`}
          >
            {title}
          </p>
          <p
            style={{
              fontWeight: '500',
              fontSize: '1.2rem',
              color: status ? 'green' : 'red',
            }}
            className={`items-center  mr-[1.25rem]  text-dark col-span-1`}
          >
            {status ? 'Completed' : 'Not  completed'}
          </p>
        </div>
        <p className="textDetail mt-3 text-left">{description}</p>

        <p
          style={{
            fontWeight: '400',
            fontSize: '1.2rem',
          }}
          className={`  items-center  mr-[1.25rem]  text-dark col-span-1`}
        >
          Note
        </p>
        <p className="textDetail mt-3 text-left">{note}</p>
      </div>
    )
  }
  return (
    <SingleBlogStyled className="px-20 bg-white min-h-screen">
      <>
        <div className="flex items-center justify-center ">
          <div>
            <h1 className="mb-5 mt-5 text-[32px] text-center ">
              {challengeName}
            </h1>
          </div>
        </div>
        <div className="mt-6 w-full">
          <form className=" w-full">
            {isLoading ? (
              <Skeleton
                paragraph={{ rows: 4 }}
                style={{
                  marginTop: 10,
                }}
              />
            ) : listMissions.length ? (
              listMissions.map((mission) =>
                renderContent({
                  title: mission.mission.mission,
                  status: !!mission.mission.status,
                  description: mission.mission.description,
                  note: mission.note,
                })
              )
            ) : (
              <Empty description="no data" />
            )}
          </form>
        </div>
      </>
    </SingleBlogStyled>
  )
}

export default DetailChallengeParticipant

const SingleBlogStyled = styled('div')(() => {
  return {}
})
