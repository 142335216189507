import { Button, Input, Loading, SharedTable } from 'src/common'
import { Skeleton } from 'antd'
import { TableProps } from 'antd/lib/table'
import { ITeacherItem } from 'src/interfaces/teacher'
import { enumStatus } from '@configs'

interface IProps {
  columns: any
  isLoading?: boolean
  teacherData: ITeacherItem[]
  totalItem: number
  limitItem?: number
  currentPage?: number
  getTeacherPage?: (page: number) => void
}

const TeacherTable = ({
  columns,
  isLoading,
  teacherData,
  getTeacherPage,
  currentPage = 1,
  limitItem = 10,
  totalItem,
}: IProps) => {
  return (
    <div className="pb-[1.25rem]">
      {!columns || isLoading ? (
        <Skeleton paragraph={{ rows: 4 }} />
      ) : (
        <SharedTable
          columns={columns}
          dataSource={teacherData.map((item, index) => {
            return {
              ...item,
              index: index + 1,
              isActive: [enumStatus.ACTIVE.toLowerCase() as string].includes(
                item?.status.toLowerCase()
              ),
            }
          })}
          totalResults={totalItem}
          paginationProps={{
            total: !!totalItem ? +totalItem : undefined,
            pageSize: +limitItem || 10,
            current: +currentPage,
            onChange(page) {
              getTeacherPage && getTeacherPage(page)
            },
          }}
          sticky
        />
      )}
    </div>
  )
}

export default TeacherTable
