import { memo, ReactNode, useState } from 'react'
import { Dropdown, MenuProps, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import styled from 'styled-components'

interface IProps {
  title: string
  items: MenuProps['items']
  containerClassName?: string
  titleClassName?: string
  suffixIcon?: ReactNode
  showDropdown?: boolean
  triggers?: ('contextMenu' | 'click' | 'hover')[]
  onOpenChange?: (open: boolean) => void
}

export const DropdownFilter = memo(
  ({
    items,
    title,
    containerClassName,
    titleClassName,
    suffixIcon,
    showDropdown = true,
    triggers = ['click'],
    onOpenChange,
  }: IProps) => {
    const [openDropdown, setOpenDropdown] = useState(false)

    return (
      <Dropdown
        menu={{ items }}
        trigger={triggers}
        onOpenChange={(open) => {
          setOpenDropdown(open)
          onOpenChange && onOpenChange(open)
        }}
        open={openDropdown}
      >
        <FilterContainer className={`min-w-[8rem] ${containerClassName}`}>
          <Space
            className={`w-full justify-between capitalize ${titleClassName}`}
          >
            {title}
            {!!suffixIcon ? suffixIcon : showDropdown ? <DownOutlined /> : null}
          </Space>
        </FilterContainer>
      </Dropdown>
    )
  }
)

DropdownFilter.displayName = 'DropdownFilter'

const FilterContainer = styled.div`
  width: max-content;
  padding: 0.5rem 0.75rem;
  border: 1px solid #e2e8f0;
  background-color: #fff;
  border-radius: 6px;
`
