import { z } from 'zod'
import { t } from 'i18next'
import { ETestSectionType } from '@configs'

export const answersSchema = z.object({
  content: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(500, {
      message: t('error:max_length_500') as string,
    })
    .min(1, {
      message: t('error:min_length_1') as string,
    }),
  contentInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(500, {
      message: t('error:max_length_500') as string,
    })
    .min(1, {
      message: t('error:min_length_1') as string,
    }),
  isCorrected: z.boolean(),
})

export const multipleChoiceQuestionSchema = z.object({
  content: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(200, {
      message: t('error:max_length_200') as string,
    })
    .min(2, {
      message: t('error:content_min_2') as string,
    }),
  contentInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(200, {
      message: t('error:max_length_200') as string,
    })
    .min(2, {
      message: t('error:content_min_2') as string,
    }),
  isMultipleChoice: z.boolean(),
  answers: z
    .array(answersSchema)
    .min(1, {
      message: t('error:answer_min_length_error') as string,
    })
    .max(5, {
      message: t('error:answer_max_length_error') as string,
    }),
})

export const practiceQuestionSchema = z.object({
  content: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(1000, {
      message: t('error:max_length_1000') as string,
    })
    .min(2, {
      message: t('error:content_min_2') as string,
    }),
  contentInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(1000, {
      message: t('error:max_length_1000') as string,
    })
    .min(2, {
      message: t('error:content_min_2') as string,
    }),
  type: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    }),
})

export const questionSchema = z.union([
  practiceQuestionSchema,
  multipleChoiceQuestionSchema,
])

export const sectionSchema = z
  .object({
    type: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      }),
    title: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(150, {
        message: t('error:name_max_length_150_error') as string,
      })
      .min(2, {
        message: t('error:name_min_length_2_error') as string,
      }),
    titleInDutch: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(150, {
        message: t('error:name_max_length_150_error') as string,
      })
      .min(2, {
        message: t('error:name_min_length_2_error') as string,
      }),
    restrictTimer: z.number().nullable(),
    questions: z.array(questionSchema).optional(),
  })
  .refine(
    (data) => {
      if (
        data.type === ETestSectionType.MULTIPLE_CHOICE &&
        !data.restrictTimer
      ) {
        return false
      }
      return true
    },
    {
      path: ['restrictTimer'],
      message: t('error:field_required') as string,
    }
  )
