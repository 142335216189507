import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import {
  EHabitGoalStatus,
  PLACEHOLDER_IMAGE_AVATAR,
  enumTableTitleHabitGoal,
  thunkActionLoading,
} from '@configs'
import { useAppDispatch, useAppSelector } from '@redux'
import { Card, Skeleton, Tooltip, message } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button, Input, Loading, SharedTable } from 'src/common'
import { useDebounce } from 'src/hooks/useDebounce'
import { IPhotoMedia } from 'src/interfaces'
import {
  IGetRoutineParams,
  IRoutineItem,
  TJobRoutineItem,
} from 'src/interfaces/habit-management'
import {
  deleteJobDetailDailyRoutineAction,
  getDetailDailyRoutineAction,
  getListJobsDailyRoutineAction,
} from 'src/redux/actions/habit-management'
import ConfirmDeleteModal from '../../../components/staff-and-role-management/ConfirmDeleteModal'
type Props = {}

const JobPage = (props: Props) => {
  const params = useParams<Record<string, any>>()

  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)

  const { t } = useTranslation(['error', 'common'])

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { state, pathname } = useLocation()

  const [deletedGoal, setDeletedGoal] = useState<TJobRoutineItem>()

  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [status, setStatus] = useState<boolean | undefined>(undefined)
  const [isSearching, setIsSearching] = useState(false)
  const [routine, setRoutine] = useState<IRoutineItem>()

  const debouncedValue = useDebounce<string | null>(searchText, 800)

  const { job } = useAppSelector((state) => state.habit)

  const jobLoading = useAppSelector(
    (state) =>
      state.habit.loadings[
        thunkActionLoading.GET_LIST_JOBS_DAILY_ROUTINE_LOADING
      ]
  )

  const deleteJobLoading = useAppSelector(
    (state) =>
      state.habit.loadings[
        thunkActionLoading.DELETE_JOB_DETAIL_DAILY_ROUTINE_LOADING
      ]
  )

  const onNavigateCreate = () => {
    navigate(`${pathname}/create`)
  }

  const getJobs = async (param?: IGetRoutineParams) => {
    dispatch(
      getListJobsDailyRoutineAction({
        page: page,
        limit: 10,
        status: status,
        search:
          debouncedValue !== null && debouncedValue.trim()
            ? debouncedValue.trim()
            : undefined,
        dailyRoutineId: params?.routineId,
        ...(param || {}),
      })
    )
  }

  const onDeleteJob = async () => {
    if (!deletedGoal || !deletedGoal?.id) return
    try {
      const response: any = await dispatch(
        deleteJobDetailDailyRoutineAction(deletedGoal?.id as number)
      ).unwrap()
      if (response?.success) message.success(response.message)
      getJobs()
    } catch (error: any) {
      message.error(error.message)
    } finally {
      setDeletedGoal(undefined)
      setOpenConfirmDeleteModal(false)
    }
  }

  const onCloseDeleteModal = () => {
    setOpenConfirmDeleteModal(false)
    setDeletedGoal(undefined)
  }

  const getRoutineDetail = async () => {
    try {
      if (params?.routineId) {
        const response = await dispatch(
          getDetailDailyRoutineAction(+params?.routineId)
        ).unwrap()
        setRoutine(response.data)
      }
    } catch (error: any) {
      message.error(error && error?.message)
    }
  }

  const columns = [
    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleHabitGoal.NO}</p>
          </div>
        )
      },
      dataIndex: 'no',
      align: 'center',
      key: 'no',
      width: '7%',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: false,
    },
    {
      title: enumTableTitleHabitGoal.IMAGE,
      align: 'center',
      dataIndex: 'media',
      key: 'media',
      render: (record: IPhotoMedia) => (
        <div className="flex items-center justify-center w-full">
          <img
            src={record?.url ?? PLACEHOLDER_IMAGE_AVATAR}
            alt={record?.url}
            className="object-cover w-14 h-14"
          />
        </div>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      showSorterTooltip: false,
      ellipsis: true,
      width: '15%',
    },
    {
      title: 'Title EN',
      dataIndex: 'titleInDutch',
      key: 'titleInDutch',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      width: '15%',
    },
    {
      title: 'Title NL',
      dataIndex: 'titleInDutch',
      key: 'titleInDutch',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      width: '15%',
    },
    {
      title: enumTableTitleHabitGoal.ACTION,
      key: 'action',
      dataIndex: 'action',
      width: '10%',
      align: 'center',
      render: (record: TJobRoutineItem) => (
        <div className="flex space-x-4 items-center justify-center w-full">
          <Tooltip
            title={
              routine?.isDraft ? "Can't edit job in drafted routine" : 'View'
            }
          >
            {routine?.isDraft ? (
              <EditOutlined
                className={`text-lg font-light mr-2.5 cursor-pointer !text-[#184f64] hover:opacity-80 opacity-100`}
                onClick={() => {
                  navigate(`${pathname}/${record.id}`, {
                    state: {
                      viewOnly: false,
                    },
                  })
                }}
              />
            ) : (
              <EyeOutlined
                className={`text-lg font-light mr-2.5 cursor-pointer !text-[#184f64] hover:opacity-80 opacity-100`}
                onClick={() => {
                  navigate(`${pathname}/${record.id}`, {
                    state: {
                      viewOnly: true,
                    },
                  })
                }}
              />
            )}
          </Tooltip>
          {!record?.status && (
            <Tooltip
              title={record?.status ? "Can't delete active job" : 'Delete'}
            >
              <DeleteOutlined
                className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 ${
                  record?.status ? '!text-red-100' : '!text-red-600 '
                }`}
                onClick={() => {
                  if (record?.status) return
                  setOpenConfirmDeleteModal(true)
                  setDeletedGoal(record)
                }}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    if (debouncedValue !== null) {
      getJobs()
    }
  }, [debouncedValue])

  useEffect(() => {
    getRoutineDetail()
  }, [])

  return (
    <Card>
      <div className="flex items-center justify-between py-[1.25rem] w-full">
        <>
          <div className="max-w-[700px] flex-1 flex gap-8">
            <Input
              prefix={<SearchOutlined className="pl-2" />}
              placeholder="Enter job title"
              value={searchText === null ? '' : searchText}
              onChange={(e) => setSearchText(e.target.value)}
              suffix={isSearching ? <Loading /> : undefined}
            />
          </div>
          {!state?.isRoutinePublished && (
            <div>
              <Button
                type="primary"
                disabled={job.total >= 30 || state?.isRoutinePublished}
                onClick={onNavigateCreate}
              >
                Add new
              </Button>
            </div>
          )}
        </>
      </div>
      {jobLoading ? (
        <Skeleton
          paragraph={{ rows: 4 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <SharedTable
          columns={columns as any}
          dataSource={job.jobs?.map((item, index) => {
            return {
              ...item,
              key: item?.id,
              no: index + 1,
              status: item?.status
                ? EHabitGoalStatus.ACTIVE
                : EHabitGoalStatus.INACTIVE,
              record: item,
              action: item,
              media: item?.media ?? `--`,
            }
          })}
          sticky
          paginationProps={{
            total: job.total ?? 0,
            pageSize: 10,
            showSizeChanger: false,
            current: job.page ?? 1,
            onChange(page) {
              getJobs({ page: page })
            },
          }}
        />
      )}
      {openConfirmDeleteModal && (
        <ConfirmDeleteModal
          title="Delete this job"
          content="Are you sure to delete this job?"
          open={openConfirmDeleteModal}
          isLoading={!!deleteJobLoading}
          onDelete={onDeleteJob}
          onClose={onCloseDeleteModal}
        />
      )}
    </Card>
  )
}

export default JobPage
