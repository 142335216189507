import { objectToQueryString } from '@utils'
import { ApiClient } from 'src/api/axiosClient'
import {
  AboutUsLanding,
  IAddLandingParams,
  IAddUpdateAboutUsPageSeoResponse,
  IResponseGetLanding,
  ISeoMetaData,
} from 'src/interfaces/aboutus-management'
import {
  IAddNewCourse,
  ICourseParams,
  ICourseSections,
  IGetCourseSuccessData,
  IResponseCreateCourse,
  IResponseGetCourseDetail,
  IResponseUploadThumbnail,
  ITrailLesson,
} from 'src/interfaces/course'
import {
  API_COURSES,
  API_LIST_CHAT,
  API_LIST_MESSAGE_WITH_USER,
} from 'src/routes/api'

export const courseManagementAPI = {
  getListCourse: async (params: ICourseParams) => {
    return await ApiClient.get<IGetCourseSuccessData>('/courses', {
      params: {
        ...params,
        sort: objectToQueryString(params.sort || {}) || undefined,
      },
    })
  },

  uploadMedia: async (file: File) => {
    const formData = new FormData()
    formData.append('file', file)
    return await ApiClient.post<IResponseUploadThumbnail>(
      '/media/resize-resolution',
      formData
    )
  },
  getCoursePageSeo: async () => {
    return await ApiClient.get<ISeoMetaData>(`/common-contents/seo-academy`)
  },
  updateCoursePageSeo: async (data: Partial<ISeoMetaData>) => {
    return await ApiClient.post<IAddUpdateAboutUsPageSeoResponse>(
      `/common-contents/seo-academy`,
      data
    )
  },
  getCourseSection: async (courseId: number) => {
    return await ApiClient.get<ICourseSections[]>(
      `/sections/course/${courseId}`
    )
  },

  addSections: async (data: {
    title: string
    description: string
    titleInDutch: string
    descriptionInDutch: string
    courseId: number
  }) => {
    return await ApiClient.post(`/sections`, data)
  },

  editSections: async (
    sectionId: number,
    data: {
      title: string
      description: string
      titleInDutch: string
      descriptionInDutch: string
    }
  ) => {
    return await ApiClient.put(`/sections/${sectionId}`, data)
  },

  updateSectionTrialLessons: async (
    sectionId: number | string,
    body: ITrailLesson[]
  ) => {
    return await ApiClient.put(
      `/sections/${sectionId}/lessons/set-trial-lesson`,
      { lessons: body }
    )
  },

  getLanding: async () => {
    return await ApiClient.get<IResponseGetLanding>(
      `/common-contents/academy-banner`
    )
  },
  createLanding: async (params: IAddLandingParams) => {
    return await ApiClient.post<AboutUsLanding>(
      `/common-contents/academy-banner`,
      params
    )
  },
  deleteSections: async (id: number) => {
    return await ApiClient.delete(`/sections/${id}`)
  },

  addNewCourse: async (params: IAddNewCourse) => {
    return await ApiClient.post<IResponseCreateCourse>('/courses', {
      title: params.title,
      description: params.description,
      titleInDutch: params.titleInDutch,
      descriptionInDutch: params.descriptionInDutch,
      userId: params.userId,
      type: params.type,
      eurPrice: params.eurPrice,
      usdPrice: params.usdPrice,
      difficulty: params.difficulty,
      thumbnailId: params.thumbnailId,
      demoVideoId: params.demoVideoId,
      categoryIds: params.categoriesIds,
      subCategoryIds: params.subCategoriesIds,
      slug: params.slug,
      nationalLanguage: params.nationalLanguage,
      hasFinalTest: params.hasFinalTest,
      targetUser: params.targetUser,
      packageIds: params.packageIds,
      userCreationFrom: params.userCreationFrom,
      userCreationTo: params.userCreationTo,
      isBestSeller: params.isBestSeller,
      subTitle: params.subTitle,
    })
  },

  getCourseDetail: async (courseId: string) => {
    return await ApiClient.get<IResponseGetCourseDetail>(`/courses/${courseId}`)
  },

  updateCourseById: async (
    courseId: string | number,
    params: IAddNewCourse
  ) => {
    return await ApiClient.put<IResponseCreateCourse>(`/courses/${courseId}`, {
      title: params.title,
      description: params.description,
      titleInDutch: params.titleInDutch,
      descriptionInDutch: params.descriptionInDutch,
      userId: params.userId,
      type: params.type,
      eurPrice: params.eurPrice,
      usdPrice: params.usdPrice,
      difficulty: params.difficulty,
      thumbnailId: params.thumbnailId,
      demoVideoId: params.demoVideoId,
      categoryIds: params.categoriesIds,
      subCategoryIds: params.subCategoriesIds,
      isActived: params.isActived,
      isDrafted: params.isDrafted,
      slug: params.slug,
      nationalLanguage: params.nationalLanguage,
      hasFinalTest: params.hasFinalTest,
      targetUser: params.targetUser,
      packageIds: params.packageIds,
      userCreationFrom: params.userCreationFrom,
      userCreationTo: params.userCreationTo,
      isBestSeller: params.isBestSeller,
      subTitle: params.subTitle,
    })
  },

  deleteCourseById: async (courseId: string | number) => {
    return await ApiClient.delete(`/courses/${courseId}`)
  },
  highLightCourseId: async (courseId: string | number) => {
    return await ApiClient.put(`/courses/highlight/${courseId}`)
  },
  duplicateCourseById: async (courseId: string | number) => {
    return await ApiClient.post(`/courses/${courseId}`)
  },
  getListMessageWithUser: async ({
    userId,
    courseId,
    params,
    config,
  }: {
    userId: number | string
    courseId: number | string
    params: {
      limit: number
      page: number
    }
    config: any
  }) => {
    return await ApiClient.get(
      `${API_LIST_MESSAGE_WITH_USER}/${userId}/courses/${courseId}`,
      { params, ...config }
    )
  },
  getListChat: async ({
    courseId,
    params,
  }: {
    courseId: number | string
    params: {
      limit: number
      page: number
      search?: string
    }
  }) => {
    const response = await ApiClient.get(`${API_LIST_CHAT}/${courseId}`, {
      params,
    })

    return response || {}
  },
  uploadAttachment: async (file: File) => {
    const formData = new FormData()
    formData.append('file', file)

    const response = await ApiClient.post('/media/attachment', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response || {}
  },
  checkDeletedCourse: async ({
    courseId,
    params,
  }: {
    courseId: number | string
    params: {
      checkDeleted: boolean
    }
  }) => {
    const response = await ApiClient.get(`${API_COURSES}/${courseId}`, {
      params,
    })

    return response || {}
  },
}
