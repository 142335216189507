import styled from 'styled-components'

export const TeacherContainer = styled('div')(() => {
  return {
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: '#fff',

    '& .ant-table-wrapper': {
      margin: 0,
      '& .ant-table-content': {
        marginRight: 0,
      },
    },

    '& .ant-table-cell': {
      whiteSpace: 'wrap',
    },
  }
})

export const INPUT_CLASSNAME =
  'disabled:bg-slate-100 disabled:!text-neutral-400 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]'
