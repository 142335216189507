import { Typography } from 'antd'
import {
  Control,
  Controller,
  FieldValues,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { Button, TextArea } from 'src/common'
import { Fragment, useEffect } from 'react'
import { ArrowRightOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { ShareSelectInput } from '@components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState, updateListGradedTestData, useAppDispatch } from '@redux'
import {
  EStudentAnswerResult,
  FinalTestSectionResults,
} from 'src/pages/test-management/type'
import {
  STUDENT_ANSWER_RESULT_OPTION,
  validateAllGradedTestData,
} from 'src/pages/test-management/config'
import { useDebounce } from 'src/hooks/useDebounce'
import useDeepCompareEffect from 'use-deep-compare-effect'
import PhotoCommentForm from './PhotoCommentForm'

interface IFeedback {
  photoImage: string
  photoCaption: string
  mediaId: number
}

export default function GradeForm({
  control,
  watch,
  reset,
  setValue,
  onOpenSubmitConfirm,
  isSubmitting,
}: {
  control: Control<FieldValues>
  watch: UseFormWatch<FieldValues>
  reset: UseFormReset<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  onOpenSubmitConfirm: () => void
  isSubmitting: boolean
}) {
  const { t } = useTranslation(['testManagement'])
  const dispatch = useAppDispatch()
  const { currentQuestion, gradedTestData, gradeTestDetail } = useSelector(
    (state: RootState) => state.testManagement
  )
  const commentValue = useDebounce(watch('comment'))
  const isPassGradedTestValidation = validateAllGradedTestData(
    gradedTestData,
    gradeTestDetail?.data?.finalTestSectionResults as FinalTestSectionResults[]
  )

  const feedbackValue = useWatch({ name: 'feedback', control })
  const feedbackValueDb = useDebounce(feedbackValue, 500)
  const statusValue = useDebounce(watch('status'))

  const { fields, append, remove } = useFieldArray<{
    feedback: IFeedback[]
  }>({
    control: control as any,
    name: 'feedback',
  })
  const { trigger } = useFormContext()

  useDeepCompareEffect(() => {
    trigger()
    const timerId = setTimeout(() => {
      dispatch(
        updateListGradedTestData({
          id: currentQuestion,
          result: {
            comment: commentValue,
            feedback: feedbackValueDb,
            status: statusValue,
          },
        })
      )
    }, 200)

    return () => {
      clearTimeout(timerId)
    }
  }, [commentValue, dispatch, feedbackValueDb, statusValue, watch])

  useEffect(() => {
    const currentStudentAnswer = gradedTestData?.find(
      (it) => String(it?.id) === String(currentQuestion)
    )
    if (!currentStudentAnswer) {
      reset({
        comment: '',
        feedback: [],
        status: EStudentAnswerResult.NOT_YET,
      })
      return
    }

    reset({
      comment: currentStudentAnswer?.comment,
      feedback: ([] as IFeedback[]).concat(
        currentStudentAnswer?.feedback as IFeedback[]
      ),
      status: currentStudentAnswer?.status,
    })
  }, [currentQuestion, reset])

  return (
    <>
      <div>
        <Typography
          style={{
            fontSize: 24,
            fontWeight: 700,
            marginBottom: 8,
          }}
        >
          {t('testManagement:grading')}
        </Typography>
      </div>

      <div className="flex flex-col mb-4">
        <Typography
          style={{
            fontSize: 16,
            fontWeight: 500,
            marginBottom: 8,
          }}
        >
          {t('testManagement:status')}
        </Typography>

        <Controller
          name="status"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <ShareSelectInput
                data={STUDENT_ANSWER_RESULT_OPTION}
                onChange={(value: string) => {
                  onChange(value)
                }}
                value={value}
                style={{ minWidth: 150, maxWidth: 150 }}
                showArrow
                errors={error?.message}
              />
            )
          }}
        />
      </div>

      <div className="flex flex-col items-start justify-center mb-4">
        <Typography
          style={{
            fontSize: 18,
            fontWeight: 500,
            marginBottom: 8,
          }}
        >
          {t('testManagement:teacherComment')}
        </Typography>

        <Controller
          name={'comment'}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextArea
                name={'comment'}
                required
                onChange={onChange}
                value={value}
                errors={error?.message}
                style={{ minHeight: 120 } as any}
              />
            )
          }}
        />
      </div>

      <div>
        {fields?.map((item, index) => {
          return (
            <PhotoCommentForm
              key={item.id}
              index={index}
              remove={remove}
              id={item.id}
            />
          )
        })}
      </div>

      <div className="flex justify-center">
        {fields?.length < 3 ? (
          <Button
            icon={
              <PlusCircleOutlined
                style={{
                  marginRight: 8,
                }}
              />
            }
            type="text"
            onClick={() =>
              append({ photoCaption: '', photoImage: '' } as IFeedback)
            }
          >
            {t('testManagement:addPhotocomment')}
          </Button>
        ) : null}
      </div>

      <div className="flex justify-end items-center">
        <Button
          icon={
            <ArrowRightOutlined
              style={{
                marginRight: 8,
              }}
            />
          }
          type="primary"
          htmlType="submit"
          danger
          onClick={async () => {
            if (
              currentQuestion !==
              gradeTestDetail?.data?.finalTestSectionResults?.length
            ) {
              const isPassFormValidation = await trigger()
              if (isPassFormValidation) setValue('feedback', [])
              return
            }

            onOpenSubmitConfirm()
          }}
          loading={isSubmitting}
          disabled={
            currentQuestion ===
            gradeTestDetail?.data?.finalTestSectionResults?.length
              ? !isPassGradedTestValidation
              : false
          }
        >
          {currentQuestion ===
          gradeTestDetail?.data?.finalTestSectionResults?.length
            ? t('testManagement:submit')
            : t('testManagement:nextQuestion')}
        </Button>
      </div>
    </>
  )
}
