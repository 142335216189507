import { zodResolver } from '@hookform/resolvers/zod'
import { RootState, useAppDispatch, useAppSelector } from '@redux'
import { message, Skeleton } from 'antd'
import { t } from 'i18next'
import { Fragment, useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { EMediaCategory } from 'src/interfaces/gallery'
import { ISeoConfigDetail } from 'src/interfaces/seo-config'
import {
  getSeoConfigByCourse,
  updateSeoConfigByCourseId,
} from 'src/redux/actions/seo-config'
import * as z from 'zod'
import { COURSE_DETAIL_TABS } from '../config'
import SeoConfigForm from './SeoConfigForm'

interface IHomeSeoPageProps {
  activeTab?: string
}

const CourseSeoConfig = ({ activeTab }: IHomeSeoPageProps) => {
  const dispatch = useAppDispatch()

  const detailSeoConfig = useAppSelector((state: RootState) => state.seoConfig)

  const isGetSeoConfigLoading = useAppSelector(
    (state: RootState) =>
      state.seoConfig?.loadings?.getSeoConfigDetailActionLoading
  )

  const isUpdateSeoConfigLoading = useAppSelector(
    (state: RootState) => state.seoConfig?.loadings?.UPDATE_SEO_CONFIG_LOADING
  )

  const homeSeoSchema = z.object({
    metaTitle: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(100, {
        message: t('error:metaTitle_length_error') as string,
      }),
    metaDescription: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:metaDescription_length_error') as string,
      }),
    canonical: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:canonical_length_error') as string,
      }),
    metaImage: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      }),
    keywords: z
      .string({
        invalid_type_error: t('error:field_required') as string,
      })
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:metaKeyword_length_error') as string,
      }),
  })

  const { courseId } = useParams()

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { isDirty },
  } = useForm<ISeoConfigDetail>({
    defaultValues: {
      metaTitle: '',
      metaDescription: '',
      canonical: '',
      metaImage: '',
      keywords: '',
    },
    resolver: zodResolver(homeSeoSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const fetchSeoConfigData = useCallback(async () => {
    try {
      const response = await dispatch(
        getSeoConfigByCourse(Number(courseId))
      ).unwrap()
      if (response.success) {
        setValue('metaTitle', response.data?.metaData?.metaTitle || '')
        setValue(
          'metaDescription',
          response.data?.metaData?.metaDescription || ''
        )
        setValue('canonical', response.data?.metaData?.canonical || '')
        setValue('metaImage', response.data?.metaData?.metaImage || '')
        setValue('keywords', response.data?.metaData?.keywords || '')
      }
    } catch (error: any) {
      message.error(error?.message ?? '')
    }
  }, [courseId, dispatch, setValue])

  useEffect(() => {
    if (activeTab !== COURSE_DETAIL_TABS.COURSE_SEO_CONFIG) return

    fetchSeoConfigData()
  }, [activeTab, fetchSeoConfigData])

  return (
    <Fragment>
      {isGetSeoConfigLoading ? (
        <>
          <Skeleton
            paragraph={{ rows: 4 }}
            style={{
              marginTop: 10,
            }}
          />
        </>
      ) : (
        <div className="bg-white rounded-lg">
          <SeoConfigForm
            activeTab={activeTab}
            control={control}
            getValues={getValues}
            setValue={setValue}
            isShowSelectMedia
            category={EMediaCategory.HOME_PAGE}
          />
        </div>
      )}
    </Fragment>
  )
}

export default CourseSeoConfig
