import { DownOutlined } from '@ant-design/icons'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { Fragment, useEffect } from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form'
import Editor from 'src/components/editor/Editor'
import { useSettings } from 'src/components/editor/context/SettingsContext'
import PlaygroundNodes from 'src/components/editor/nodes/PlaygroundNodes'
import PlaygroundEditorTheme from 'src/components/editor/themes/PlaygroundEditorTheme'

interface IContentTabProps {
  activeTab?: string
  control: Control<any>
  setValue: UseFormSetValue<any>
  clearErrors: UseFormClearErrors<any>
  setError: UseFormSetError<any>
  errors: FieldErrors<any>
  getValues: UseFormGetValues<any>
  contentName?: string
  value?: string
}

const ContentTab = ({
  activeTab,
  control,
  setValue,
  clearErrors,
  setError,
  errors,
  getValues,
  contentName,
  value,
}: IContentTabProps) => {
  const {
    settings: { isCollab, emptyEditor, measureTypingPerf },
  } = useSettings()
  const initialConfig: any = {
    editorState: isCollab ? null : emptyEditor,
    namespace: 'Playground',
    nodes: [...PlaygroundNodes],
    onError: (error: Error) => {
      throw error
    },
    theme: PlaygroundEditorTheme,
  }

  return (
    <div className="p-5 bg-white">
      <div className="text-content rounded-md border border-slate-200/60 p-5 dark:border-darkmode-400">
        {/* <div className="text-content title-content-top flex items-center border-b border-slate-200/60 pb-5 font-medium dark:border-darkmode-400">
          <div className="text-content title-wrapper-icon flex items-center justify-center mr-2">
            <DownOutlined />
          </div>
          <div className="text-content title-wrapper-content">Text content</div>
        </div> */}
        {/* Start of editor */}
        <div className="editor-content mt-[-20px] h-[750px] overflow-hidden">
          <LexicalComposer initialConfig={initialConfig}>
            <div className="editor-shell">
              <Controller
                name={contentName || 'content'}
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Fragment>
                      <Editor
                        setValue={setValue}
                        value={value}
                        clearErrors={clearErrors}
                        setError={setError}
                        contentName={contentName}
                      />
                      {error && error.message && (
                        <p className="text-[#B91C1C] mt-2 text-sm">
                          {error.message}
                        </p>
                      )}
                    </Fragment>
                  )
                }}
              />
            </div>
          </LexicalComposer>
        </div>
      </div>
    </div>
  )
}

export default ContentTab
