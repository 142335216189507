import { createContext, useContext } from 'react'
import { useSelector } from 'react-redux'

import { ENavBarRole } from '@configs'
import { RootState } from '@redux'

interface TestContextProps {
  currentUserRole?: ENavBarRole | string
}

const initTestContext = {
  currentUserRole: '',
}

const TestContext = createContext<TestContextProps>(initTestContext)

interface TestProviderProps extends React.PropsWithChildren<any> {}

function TestProvider(props: TestProviderProps) {
  const { children } = props
  const { accountInfo } = useSelector((state: RootState) => state.auth)
  const currentUserRole = accountInfo?.client ?? ''

  return (
    <TestContext.Provider value={{ currentUserRole }}>
      {children}
    </TestContext.Provider>
  )
}

function useTestContext() {
  const context = useContext(TestContext)

  if (!context) {
    throw new Error('useTestContext must be used within a SectionsProvider')
  }

  return context
}

export { TestProvider, useTestContext }
