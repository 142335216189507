import { PlusIcon } from '@components'
import { Col, Row } from 'antd'
import { useState } from 'react'
import { Button } from 'src/common'
import ModalAddSection from './ModalAddSection'

export function EmptyLesson({
  isNotEmpty,
  isActive,
  onReset,
}: {
  isNotEmpty?: boolean
  isActive?: boolean
  onReset: () => void
}) {
  const [openAddSectionModal, setOpenAddSectionModal] = useState<boolean>(false)

  const onCloseUserModal = () => {
    setOpenAddSectionModal(false)
    // onReset()
  }
  const onOpenUserModal = () => {
    setOpenAddSectionModal(true)
  }

  if (isNotEmpty) {
    return (
      <>
        <div className="flex flex-col gap-3">
          <Button onClick={onOpenUserModal}>
            <Row className="justify-center items-center">
              <PlusIcon />
              Add section
            </Row>
          </Button>
        </div>

        <ModalAddSection
          open={openAddSectionModal}
          onClose={onCloseUserModal}
          onSave={onReset}
          isLoading={true}
        />
      </>
    )
  }

  return (
    <>
      <Col className="items-center flex-col flex justify-center w-[70%] py-5">
        <h1 className="text-[18px] font-bold text-center">
          Add your course content
        </h1>
        <p className=" text-[14px] text-center">
          Create a high-quality course that will engage your learners and will
          achieve your educational objectives efficiently and effectively.
        </p>
        <Button
          type="primary"
          onClick={onOpenUserModal}
          className="bg-main text-white"
        >
          <Row className="justify-center items-center">
            <PlusIcon width={16} height={16} color="white" />
            Add section
          </Row>
        </Button>
      </Col>
      <ModalAddSection
        open={openAddSectionModal}
        onClose={onCloseUserModal}
        isLoading={true}
        onSave={onReset}
      />
    </>
  )
}
