import styled from 'styled-components'
import { SwitchProps, Typography } from 'antd'

import { HookFormSwitchButton } from 'src/common'
import { Control } from 'react-hook-form'

export const StyledHookFormSwitch = ({
  control,
  controlName,
  onChange,
  label,
  align = 'col',
  containerClassName,
  ...props
}: SwitchProps & {
  control: Control<any, any>
  controlName: string
  onChange: (checked: boolean) => void
  label?: string
  align?: 'row' | 'col'
  containerClassName?: string
}) => {
  return (
    <StyledSwitch className={containerClassName} $align={align}>
      {!!label ? (
        <Typography.Text className="Input__label text-left text-sm text-dark">
          {label}
        </Typography.Text>
      ) : null}
      <HookFormSwitchButton
        control={control}
        controlName="isActive"
        onChange={onChange}
        {...props}
      />
    </StyledSwitch>
  )
}

const StyledSwitch = styled.div<{ $align?: 'row' | 'col' }>`
  width: max-content;
  display: flex;
  flex-direction: ${(props) => (props.$align === 'row' ? 'row' : 'column')};
  gap: 8px;
`
