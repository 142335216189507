import { Card, Typography } from 'antd'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { SignatureView } from './SignatureView'

export const SignatureSection = () => {
  const { watch } = useFormContext()
  const signatureData = watch('specificInformation.teacherSignature')

  return (
    <SignatureSectionContainerStyled className="flex items-center justify-center w-full">
      <div className="flex justify-between w-full">
        <Typography.Title level={4} className="text-left !mb-0">
          Teacher Signature
        </Typography.Title>
      </div>

      <SignatureContentStyled className="!border-none flex items-center justify-center w-full">
        <SignatureView data={signatureData} />
      </SignatureContentStyled>
    </SignatureSectionContainerStyled>
  )
}

const SignatureSectionContainerStyled = styled(Card)`
  .ant-card-body {
  }

  .reset-form {
    > div:last-child {
      justify-content: center;
    }
  }
`

const SignatureContentStyled = styled(Card)`
  .ant-card-body {
    padding: 0;
  }

  .reset-form {
    > div:last-child {
      justify-content: center;
    }
  }
`
