import { LexicalComposer } from '@lexical/react/LexicalComposer'
import Editor from 'src/components/editor/Editor'
import { useSettings } from 'src/components/editor/context/SettingsContext'
import PlaygroundEditorTheme from 'src/components/editor/themes/PlaygroundEditorTheme'

import PlaygroundNodes from 'src/components/editor/nodes/PlaygroundNodes'
import '../../components/editor/editor.css'

const EditorPage = () => {
  const {
    settings: { isCollab, emptyEditor, measureTypingPerf },
  } = useSettings()
  const initialConfig: any = {
    editorState: isCollab ? null : emptyEditor,
    namespace: 'Playground',
    nodes: [...PlaygroundNodes],
    onError: (error: Error) => {
      throw error
    },
    theme: PlaygroundEditorTheme,
  }
  return (
    <LexicalComposer initialConfig={initialConfig}>
      <div className="editor-shell">
        <Editor />
      </div>
    </LexicalComposer>
  )
}

export default EditorPage
