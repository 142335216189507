import {
  TPersonalInformation,
  TResponseData,
} from 'src/pages/teacher-request-detail/fill-in-teacher-information/types'
import { IResponseGetCourseDetail } from './course'

export enum ECourseRequestStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export interface ICourseRequestStatus {
  color: string
  label: React.ReactElement
}

export interface ICourse {
  id: number | string
  title: string
}

export interface ITeacher {
  name: string
}

export interface ICourseRequestItem {
  id: number
  userId: number
  createdAt: null | string
  updatedAt: null | string
  deletedAt: null | string
  personalInformation: TPersonalInformation
  responseData: TResponseData
  status: ECourseRequestStatus
  course: ICourse
  user: ITeacher
}

export interface IListCourseRequestData {
  items: ICourseRequestItem[]
  total: number
}

export interface ICourseDetailData {
  id: number | string
  userId: number | string
  courseId: number | string
  createdAt: null | string
  updatedAt: null | string
  deletedAt: null | string
  personalInformation: TPersonalInformation
  responseData: TResponseData
  status: ECourseRequestStatus
  course: IResponseGetCourseDetail & {
    seoConfig: { slug: string }
  }
  user: ITeacher
}

export enum ECourseRequestModalType {
  ACCEPTED = ECourseRequestStatus.ACCEPTED,
  REJECTED = ECourseRequestStatus.REJECTED,
}

export interface ICourseRequestFilterValue {
  requestStatus: ECourseRequestStatus[]
  dateRange: any
  search: string
}
