import { duplicateRole, getListPermissionRoles, useAppDispatch } from '@redux'
import { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { FORM_FIELDS } from './config'
import { Input } from 'src/common'
import {
  CopyOutlined,
  CreditCardOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  LoadingOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { PopoverPopup } from 'src/components/AppPopover'
import { Typography } from 'antd'
import styled from 'styled-components'
import { useDebounce } from 'src/hooks/useDebounce'

interface IHeaderCellCustomized {
  canEdit: boolean
  editing: boolean
  title: string
  key: string
  roleId: number
  onEditRolePermissions: () => void
  onOpenEditRoleNameDialog: () => void
  onOpenDeleteRoleDialog: () => void
  onDuplicateRole: () => void
  style: any
  children: any
}

export default function HeaderCellCustomized({
  editing,
  key,
  title,
  canEdit,
  roleId,
  onEditRolePermissions,
  onOpenEditRoleNameDialog,
  onOpenDeleteRoleDialog,
  onDuplicateRole,
  children,
  style,
  ...restProps
}: IHeaderCellCustomized) {
  const { t } = useTranslation(['staffAndRole'])
  const dispatch = useAppDispatch()

  const [isOpen, setIsOpen] = useState(false)
  const [isSubmittingDuplication, setIsSubmittingDuplication] = useState(false)

  const [inputValue, setInputValue] = useState('')
  const inputDBValue = useDebounce(inputValue, 200)

  const { setValue } = useFormContext()

  const handleDuplicateRole = useCallback(
    async (roleId: number) => {
      try {
        setIsSubmittingDuplication(true)
        await dispatch(duplicateRole(roleId)).unwrap()

        setIsSubmittingDuplication(false)
        toast.success(t('staffAndRole:duplicate_role_successfully'))
        dispatch(getListPermissionRoles())
        setIsOpen(false)
      } catch (error: any) {
        setIsSubmittingDuplication(false)
        toast.error(
          error?.message ||
            error?.data?.message ||
            t('staffAndRole:something_went_wrong')
        )
      }
    },
    [dispatch, t]
  )

  useEffect(() => {
    if (!editing) return
    setValue(FORM_FIELDS.PERMISSION_NAME, inputDBValue || '')
  }, [inputDBValue, editing, setValue])

  return (
    <td
      {...restProps}
      style={{
        minWidth: editing ? 250 : 150,
        maxWidth: editing ? 250 : 150,
        backgroundColor: '#eeeeee',
        position: 'sticky',
        top: 0,
        zIndex: editing ? 33 : 22,
        ...style,
      }}
    >
      {editing ? (
        <div className="p-4">
          <Input
            suffix={<SearchOutlined className="pr-2 pl-0" />}
            placeholder={'Type permission name'}
            onChange={(e) => setInputValue(e?.target?.value)}
            value={inputValue}
            style={{
              maxWidth: 380,
              width: '100%',
              borderRadius: 6,
              borderColor: '#e2e8f0',
            }}
          />
        </div>
      ) : (
        <>
          <div className="p-4">{children}</div>

          {canEdit ? (
            <div style={{ position: 'absolute', top: 0, right: 8 }}>
              <PopoverPopup
                open={isOpen}
                onOpenChange={(open: boolean) => setIsOpen(open)}
                content={
                  <div className="flex flex-col gap-2">
                    <RolePopoverActionStyled
                      onClick={() => {
                        onOpenDeleteRoleDialog()
                        setIsOpen(false)
                      }}
                    >
                      <DeleteOutlined />
                      <Typography>Delete role</Typography>
                    </RolePopoverActionStyled>

                    <RolePopoverActionStyled
                      onClick={() => {
                        if (isSubmittingDuplication) return

                        handleDuplicateRole(roleId)
                      }}
                    >
                      {isSubmittingDuplication ? (
                        <LoadingOutlined />
                      ) : (
                        <CopyOutlined />
                      )}
                      <Typography>Duplicate role</Typography>
                    </RolePopoverActionStyled>

                    <RolePopoverActionStyled
                      onClick={() => {
                        onOpenEditRoleNameDialog()
                        setIsOpen(false)
                      }}
                    >
                      <CreditCardOutlined />
                      <Typography>Edit role name</Typography>
                    </RolePopoverActionStyled>

                    <RolePopoverActionStyled
                      onClick={() => {
                        onEditRolePermissions()
                        setIsOpen(false)
                      }}
                    >
                      <EditOutlined />
                      <Typography>Edit role permission</Typography>
                    </RolePopoverActionStyled>
                  </div>
                }
                placement="bottomRight"
              >
                <EllipsisOutlined style={{ fontSize: 24 }} />
              </PopoverPopup>
            </div>
          ) : null}
        </>
      )}
    </td>
  )
}

const RolePopoverActionStyled = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
  cursor: 'pointer',
  borderRadius: 6,
  padding: '4px 8px',

  '&:hover': {
    backgroundColor: '#e8e8e8',
  },

  '& > span': {
    fontSize: 16,
  },
}))
