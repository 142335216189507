import TeacherInfoForm from 'src/components/form/teacher-info/TeacherInfoForm'
import { ITeacherItem } from 'src/interfaces/teacher'

export default function TeacherOtherForm({
  teacher,
  isLoading,
}: {
  teacher: ITeacherItem
  isLoading?: boolean
}) {
  return (
    <TeacherInfoForm
      teacherDetail={teacher?.requests?.[0]}
      isLoadingTeacherDetail={isLoading}
      isViewOnly
    />
  )
}
