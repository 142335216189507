import { Card } from 'antd'
import styled from 'styled-components'
import { ExperienceForm } from './ExperienceForm'
import { TExperienceSection } from 'src/pages/teacher-request-detail/fill-in-teacher-information/types'
import { ExperienceView } from './ExperienceView'

export const ExperienceSection = (props: TExperienceSection) => {
  return (
    <ExperienceSectionStyled className="!border-none flex items-center justify-center w-full">
      {!!props?.data?.editable ? (
        <ExperienceForm {...props} />
      ) : (
        <ExperienceView {...props} />
      )}
    </ExperienceSectionStyled>
  )
}

const ExperienceSectionStyled = styled(Card)`
  .ant-card-body {
  }

  .reset-form {
    > div:last-child {
      justify-content: center;
    }
  }
`
