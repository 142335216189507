import { ApiClient } from '@api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  ECourseRequestStatus,
  ICourseDetailData,
  ICourseRequestFilterValue,
  IListCourseRequestData,
} from 'src/interfaces/course-request-management'
import { IMiniDashboardData } from 'src/interfaces/teacher-request-management'
import {
  API_COURSE_REQUESTS,
  API_COURSE_REQUESTS_MINI_DASHBOARD,
} from 'src/routes/api'

interface ICourseRequest {
  listCourseRequest: {
    data: IListCourseRequestData
    isLoading: boolean
  }
  miniDashboard: {
    data: any
    isLoading: boolean
  }
  courseRequestDetail: {
    data: ICourseDetailData
    isLoading: boolean
    error: any
  }
  courseRequestFilterValue: ICourseRequestFilterValue
}

export const getListCourseRequest = createAsyncThunk(
  'courseRequestManagement/getListCourseRequest',
  async (params: any) => {
    const response = await ApiClient.get(API_COURSE_REQUESTS, {
      params,
    })

    return response?.data || []
  }
)

export const getMiniDashboardCourseRequest = createAsyncThunk(
  'courseRequestManagement/getMiniDashboard',
  async (params: { startDate?: string; endDate?: string }) => {
    const response = await ApiClient.get(API_COURSE_REQUESTS_MINI_DASHBOARD, {
      params,
    })

    return response?.data || {}
  }
)

export const getCourseRequestDetail = createAsyncThunk(
  'courseRequestManagement/getCourseRequestDetail',
  async (courseId: number | string, { rejectWithValue }) => {
    try {
      const response = await ApiClient.get(`${API_COURSE_REQUESTS}/${courseId}`)

      return response?.data || {}
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateCourseRequestStatus = createAsyncThunk(
  'courseRequestManagement/updateCourseRequestStatus',
  async ({
    courseRequestId,
    payload,
  }: {
    courseRequestId: number | string
    payload: {
      response: ECourseRequestStatus
      reason?: string
    }
  }) => {
    const response = await ApiClient.put(
      `${API_COURSE_REQUESTS}/${courseRequestId}`,
      payload
    )

    return response?.data || {}
  }
)

const initialState: ICourseRequest = {
  listCourseRequest: {
    data: {} as IListCourseRequestData,
    isLoading: false,
  },
  miniDashboard: {
    data: {} as IMiniDashboardData,
    isLoading: false,
  },
  courseRequestDetail: {
    data: {} as ICourseDetailData,
    isLoading: false,
    error: {} as any,
  },
  courseRequestFilterValue: {} as ICourseRequestFilterValue,
}

const courseRequestManagementSlice = createSlice({
  name: 'courseRequestManagement',
  initialState,
  reducers: {
    updateCourseRequestFilterValue: (state, action) => {
      state.courseRequestFilterValue = action.payload || {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListCourseRequest.pending, (state) => {
        state.listCourseRequest.data = {} as IListCourseRequestData
        state.listCourseRequest.isLoading = true
      })
      .addCase(getListCourseRequest.fulfilled, (state, action) => {
        state.listCourseRequest.data = action.payload as IListCourseRequestData
        state.listCourseRequest.isLoading = false
      })
      .addCase(getListCourseRequest.rejected, (state) => {
        state.listCourseRequest.isLoading = false
      })
      .addCase(getMiniDashboardCourseRequest.pending, (state) => {
        state.miniDashboard.data = {} as IMiniDashboardData
        state.miniDashboard.isLoading = true
      })
      .addCase(getMiniDashboardCourseRequest.fulfilled, (state, action) => {
        state.miniDashboard.data = action.payload as IMiniDashboardData
        state.miniDashboard.isLoading = false
      })
      .addCase(getMiniDashboardCourseRequest.rejected, (state) => {
        state.miniDashboard.isLoading = false
      })
      .addCase(getCourseRequestDetail.pending, (state) => {
        state.courseRequestDetail.data = {} as ICourseDetailData
        state.courseRequestDetail.isLoading = true
      })
      .addCase(getCourseRequestDetail.fulfilled, (state, action) => {
        state.courseRequestDetail.data = action.payload as ICourseDetailData
        state.courseRequestDetail.isLoading = false
      })
      .addCase(getCourseRequestDetail.rejected, (state, action) => {
        state.courseRequestDetail.isLoading = false
        state.courseRequestDetail.error = action.payload
      })
  },
})

export const { updateCourseRequestFilterValue } =
  courseRequestManagementSlice.actions

export default courseRequestManagementSlice.reducer
