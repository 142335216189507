import { Typography } from 'antd'
import { format } from 'date-fns'
import { Trans } from 'react-i18next'
import {
  ERequestStatus,
  IRequestStatus,
  ITeacherRequestRowData,
} from 'src/interfaces/teacher-request-management'

export const SEARCH_FORM_FIELDS = {
  SEARCH: 'search',
  DATE_RANGE: 'dateRange',
  REQUEST_STATUS: 'requestStatus',
}

export const SEARCH_FORM_FIELD_DEFAULT_VALUES = {
  [SEARCH_FORM_FIELDS.SEARCH]: '',
  [SEARCH_FORM_FIELDS.DATE_RANGE]: null,
  [SEARCH_FORM_FIELDS.REQUEST_STATUS]: [],
}

export const EMPTY_VALUE = 'N/A'

export const RequestStatusObj: Record<ERequestStatus, IRequestStatus> = {
  [ERequestStatus.ACCEPTED]: {
    color: '#1DD75BFF',
    label: <Trans i18nKey={'teacherRequest:accepted' as any} />,
  },
  [ERequestStatus.PENDING]: {
    color: '#FE763EFF',
    label: <Trans i18nKey={'teacherRequest:pending' as any} />,
  },
  [ERequestStatus.REJECTED]: {
    color: '#DE3B40FF',
    label: <Trans i18nKey={'teacherRequest:rejected' as any} />,
  },
  [ERequestStatus.REQUEST_UPDATE]: {
    color: '#2ACCCFFF',
    label: <Trans i18nKey={'teacherRequest:requestUpdate' as any} />,
  },
  [ERequestStatus.UPDATED]: {
    color: '#EFB034FF',
    label: <Trans i18nKey={'teacherRequest:updated' as any} />,
  },
}

export const REQUEST_STATUS_OPTIONS = Object.entries(ERequestStatus).map(
  (it) => {
    return {
      label: RequestStatusObj?.[it[0] as ERequestStatus]
        ?.label as React.ReactElement,
      value: it[1],
    }
  }
)

export const REASON_CHARACTER_LIMITATION = {
  MAX_LENGTH: 500,
  MIN_LENGTH: 2,
}

export enum EMiniDashBoardColor {
  LIGHT_BLUE = '#C7F9EE',
  LIGHT_PINK = '#FCEAE6',
  LIGHT_YELLOW = '#F7F4BF',
  LIGHT_PURPLE = '#F1F2FDFF',
  BLUE = '#6DFOO2',
  PINK = '#F0A58F',
  YELLOW = '#E7E34E',
}

export const TABLE_COLUMNS = {
  NO: {
    title: 'teacherRequest:no',
    dataIndex: 'index',
    key: 'index',
    align: 'center',
    width: '10%',
  },
  REQUESTER_NAME: {
    title: 'teacherRequest:requesterName',
    dataIndex: 'requesterName',
    key: 'index',
    align: 'center',
    width: '25%',
    render: (_: any, record: ITeacherRequestRowData) => {
      const { requesterName } = record || {}
      return (
        <Typography
          style={{
            maxWidth: 280,
            textWrap: 'wrap',
          }}
        >
          {requesterName || EMPTY_VALUE}
        </Typography>
      )
    },
  },
  REQUESTED_DATE: {
    title: 'teacherRequest:requestedDate',
    dataIndex: 'requestedDate',
    key: 'requestedDate',
    align: 'center',
    width: '15%',
    render: (_: any, record: ITeacherRequestRowData) => {
      const { requestedDate } = record || {}
      return (
        <div className="flex items-center justify-center h-full w-full gap-2">
          <Typography>
            {requestedDate
              ? format(new Date(requestedDate), 'dd/MM/yyyy')
              : EMPTY_VALUE}
          </Typography>
        </div>
      )
    },
  },
  RESPONSE_DATE: {
    title: 'teacherRequest:responseDate',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    align: 'center',
    width: '20%',
    render: (_: any, record: ITeacherRequestRowData) => {
      const { updatedAt } = record || {}
      return (
        <div className="flex items-center justify-center h-full w-full gap-2">
          <Typography>
            {updatedAt
              ? format(new Date(updatedAt), 'dd/MM/yyyy')
              : EMPTY_VALUE}
          </Typography>
        </div>
      )
    },
  },
  RESPONSER_NAME: {
    title: 'teacherRequest:responserName',
    dataIndex: 'responserName',
    key: 'responserName',
    align: 'center',
    width: '25%',
    render: (_: any, record: ITeacherRequestRowData) => {
      const { responseData } = record || {}
      return (
        <div className="flex items-center justify-center h-full w-full gap-2">
          <Typography
            style={{
              maxWidth: 280,
              textWrap: 'wrap',
            }}
          >
            {responseData?.updatedBy?.name || EMPTY_VALUE}
          </Typography>
        </div>
      )
    },
  },
  REQUEST_STATUS: {
    title: 'teacherRequest:status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    width: '10%',
    render: (_: any, record: ITeacherRequestRowData) => {
      const { status } = record || {}
      return (
        <div className="flex items-center justify-center h-full w-full gap-2">
          <Typography
            style={{
              color:
                RequestStatusObj?.[status as ERequestStatus]?.color ||
                '#000000',
            }}
          >
            {RequestStatusObj?.[status as ERequestStatus]?.label || EMPTY_VALUE}
          </Typography>
        </div>
      )
    },
  },
  TEACHER_REQUEST_ACTION: {
    title: '',
    dataIndex: 'teacherRequestAction',
    key: 'teacherRequestAction',
    align: 'center',
    width: '10%',
  },
}
