import {
  CalendarOutlined,
  DeleteTwoTone,
  EditOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons'
import { Card, Typography } from 'antd'
import moment from 'moment'
import styled from 'styled-components'
import { TEducationView } from 'src/pages/teacher-request-detail/fill-in-teacher-information/types'
import { educationLevelOptions } from './config'
import { DegreeView } from 'src/pages/teacher-request-detail/fill-in-teacher-information/degree/DegreeView'

const monthYearFormat = 'MMM YYYY'

export const EducationView = ({
  data,
  index,
  onRemove,
  onEdit,
}: TEducationView) => {
  const { isPresent: isCurrentEducation } = data || {}

  const lifeTime = `${moment(data?.startDate).format(monthYearFormat)} - ${
    isCurrentEducation
      ? 'Present'
      : moment(data?.endDate).format(monthYearFormat)
  }`

  const educationLevel = educationLevelOptions?.find(
    (level) => level?.value === data?.educationLevel
  )
  return (
    <StyledExperienceView>
      <div className="flex flex-row justify-between">
        <Typography.Text className="text-base font-semibold capitalize">
          {data?.institution} -
          <Typography.Text className="text-base font-semibold !text-purple-600 capitalize">
            {` ${educationLevel?.label}`}
          </Typography.Text>
        </Typography.Text>
        <div className="ml-5 min-w-14 items-center">
          {!!onEdit && (
            <EditOutlined
              className="self-end mr-3 text-base text-gray-500"
              onClick={() => onEdit?.(index)}
            />
          )}
          {!!onRemove && (
            <DeleteTwoTone
              onClick={() => onRemove?.(index)}
              className="self-end text-base"
            />
          )}
        </div>
      </div>
      <div className="flex flex-row items-center">
        <MenuFoldOutlined className="text-gray-500 text-base" />
        <Typography.Text className="text-base ml-3 !text-gray-500">
          {`${data?.qualification}`}
        </Typography.Text>
      </div>
      <div className="flex flex-row items-center">
        <CalendarOutlined className="text-gray-500 text-base" />
        <Typography.Text className="text-base ml-3 !text-gray-500">
          {lifeTime}
        </Typography.Text>
      </div>
      <Typography.Text>{data?.description}</Typography.Text>

      <DegreeView data={data?.degree} />
    </StyledExperienceView>
  )
}

const StyledExperienceView = styled(Card)`
  .ant-card-body {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`
