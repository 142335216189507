import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { t } from 'i18next'
import { message } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

import { AppModal, ShareSelectInput } from '@components'
import { Button, Text } from 'src/common'
import { packageManagementAPI } from 'src/api/package-management'
import { IPackage } from 'src/interfaces/package-management'

interface IProps {
  open: boolean
  onClose: () => void
  currentPackageId: number
  onConfirmDelete: (selectPackageId: number) => void
}

const deleteUserPackageSchema = z.object({
  selectPackageId: z.number().min(1, {
    message: t('error:field_required') as string,
  }),
})

const DeleteUserPackageModal = ({
  onClose,
  open,
  currentPackageId,
  onConfirmDelete,
}: IProps) => {
  const [listpackages, setListpackages] = useState<IPackage[]>([])
  const [isLoadingPackages, setIsLoadingPackages] = useState(false)

  const {
    reset,
    handleSubmit,
    getValues,
    control,
    formState: { isValid },
    clearErrors,
  } = useForm({
    defaultValues: { selectPackageId: 0 },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(deleteUserPackageSchema),
  })

  const handleOnCloseModal = useCallback(() => {
    setListpackages([])
    reset()
    onClose()
  }, [onClose, reset])

  const handleOnSubmit = handleSubmit((value) => {})

  const getAllListPackages = async () => {
    setIsLoadingPackages(true)
    try {
      const res = await packageManagementAPI.getListPackage({
        limit: 9999,
        page: 1,
      })
      if (res?.data) {
        setListpackages(res.data.items)
      }
    } catch (error) {
      message.error(t('error:error_connect_server'))
    } finally {
      setIsLoadingPackages(false)
    }
  }

  const userPackageOptions = useMemo(
    () =>
      listpackages
        .filter((item) => item.id !== currentPackageId && item.isActive)
        .map((it) => ({ label: it.name, value: it.id })),
    [currentPackageId, listpackages]
  )

  const modalContent = useMemo(
    () => (
      <ModalContentWrapper onSubmit={handleOnSubmit}>
        <Text className="!text-[16px]">
          {t('teacher:confirm_delete_package')}
        </Text>
        <Controller
          name="selectPackageId"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <ShareSelectInput
              data={userPackageOptions}
              onChange={(value) => onChange(Number(value))}
              value={value > 0 ? value : undefined}
              placeholder={
                isLoadingPackages
                  ? (t('teacher:getting_data') as string)
                  : (t(
                      'teacher:select_another_package_before_delete'
                    ) as string)
              }
              errors={error?.message}
              className="w-max min-w-[30dvw]"
              disabled={isLoadingPackages}
              showArrow
            />
          )}
        />
        <ButtonWrapper>
          <Button
            type="default"
            size="small"
            id="blog-form"
            onClick={handleOnCloseModal}
          >
            {t('common:cancel')}
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            size="small"
            id="blog-form"
            onClick={() => {
              if (!isValid) return
              onConfirmDelete(getValues('selectPackageId'))
              handleOnCloseModal()
            }}
            loading={isLoadingPackages}
          >
            {t('common:confirm')}
          </Button>
        </ButtonWrapper>
      </ModalContentWrapper>
    ),
    [
      control,
      handleOnCloseModal,
      handleOnSubmit,
      isLoadingPackages,
      userPackageOptions,
      isValid,
      onConfirmDelete,
      getValues,
    ]
  )

  useEffect(() => {
    if (open) {
      clearErrors()
      getAllListPackages()
    }
  }, [clearErrors, open])

  return (
    <AppModal
      open={open}
      onClose={handleOnCloseModal}
      title={t('teacher:delete_package')}
      haveCloseIcon
    >
      {modalContent}
    </AppModal>
  )
}

export default DeleteUserPackageModal

const ModalContentWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
`
