import { t } from 'i18next'
import { EEducationLevel } from 'src/pages/teacher-request-detail/fill-in-teacher-information/types'

export const educationLevelOptions: Array<{ value: string; label: string }> = [
  {
    label: t('common:bachelorDegree'),
    value: EEducationLevel.BACHELOR_DEGREE,
  },
  {
    label: t('common:diploma'),
    value: EEducationLevel.DIPLOMA,
  },
  {
    label: t('common:masterDegree'),
    value: EEducationLevel.MASTER_DEGREE,
  },
  {
    label: t('common:other'),
    value: EEducationLevel.OTHER,
  },
]
