import moment from 'moment'
import * as z from 'zod'

import { AppModal, ShareSelectInput } from '@components'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  RootState,
  assignTeacher,
  getListRejection,
  getListTeacher,
  useAppDispatch,
} from '@redux'
import { Typography } from 'antd'
import { format } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Button, Input } from 'src/common'
import {
  ASSIGN_TEACHER_FORM_FIELDS,
  ASSIGN_TEACHER_FORM_FIELD_DEFAULT_VALUES,
} from 'src/pages/test-management/config'
import styled from 'styled-components'
import { IRejectItem } from 'src/pages/test-management/type'
import { FORMAT_DATE_TABLE } from '@configs'
import { convertToMoment } from 'src/utils/convertDatestringToMoment'

interface IAssignTeacherModalProps {
  open: boolean
  finalTestHistoryId: number
  onClose: () => void
  handleReloadData: () => void
}

interface IConfirmAssignTeacherModalProps {
  open: boolean
  teacherName: string
  isSubmitting: boolean
  onClose: () => void
  onSubmit: () => void
}

export default function AssignTeacherModal({
  open,
  finalTestHistoryId,
  onClose,
  handleReloadData,
}: IAssignTeacherModalProps) {
  const { t } = useTranslation(['testManagement', 'error'])
  const dispatch = useAppDispatch()
  const { listTeacher, listRejection } = useSelector(
    (state: RootState) => state.testManagement
  )
  const { data: listTeacherData } = listTeacher || {}

  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false)

  const currentDate = useMemo(() => {
    const today = new Date()
    today.setDate(today.getDate() - 1)
    return today
  }, [])

  const AssignTeacherFormSchema = z.object({
    [ASSIGN_TEACHER_FORM_FIELDS.ASSIGN_TO]: z
      .number()
      .min(1, t('testManagement:teacherRequired')),
    [ASSIGN_TEACHER_FORM_FIELDS.DEADLINE]: z
      .date()
      .nullable()
      .refine((date) => !!date, {
        message: t('error:field_required'),
      })
      .refine((date) => (!!date ? date >= currentDate : false), {
        message: t('testManagement:date_greater_than_today'),
      }),
  })

  const {
    control,
    handleSubmit,
    trigger,
    watch,
    formState: { isSubmitting },
  } = useForm<{
    deadline: string
    assignTo: string
  }>({
    resolver: zodResolver(AssignTeacherFormSchema),
    defaultValues: ASSIGN_TEACHER_FORM_FIELD_DEFAULT_VALUES,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const teacherIdValue = watch('assignTo')

  const listTeacherOptions = useMemo(() => {
    if (
      !Array.isArray(listTeacherData?.items) ||
      !listTeacherData?.items?.length
    )
      return []

    return listTeacherData?.items.map((teacher: any) => ({
      value: teacher?.id,
      label: teacher?.name,
    }))
  }, [listTeacherData?.items])

  const onSubmit = async () => {
    const { assignTo, deadline } = watch() || {}
    const deadlineValue = moment(deadline).endOf('day')

    try {
      const reqData = {
        teacherId: Number(assignTo),
        deadline: !!deadlineValue ? deadlineValue.toISOString() : null,
        finalTestHistoryId: Number(finalTestHistoryId),
      }
      await dispatch(assignTeacher(reqData)).unwrap()

      handleReloadData()
      toast.success(t('testManagement:assignTeacherSuccess'))
      onClose()
    } catch (error: any) {
      setIsShowConfirmModal(false)
      toast.error(error?.message)
    }
  }

  const selectedTeacher = useMemo(() => {
    return listTeacherOptions?.find(
      (it: any) => Number(it.value) === Number(teacherIdValue)
    )
  }, [listTeacherOptions, teacherIdValue])

  useEffect(() => {
    dispatch(getListRejection(finalTestHistoryId))
  }, [dispatch, finalTestHistoryId])

  useEffect(() => {
    dispatch(getListTeacher())
  }, [dispatch])

  return (
    <>
      <ModalContainer>
        <AppModal
          open={open}
          onClose={onClose}
          title={t('testManagement:assignGrading')}
          haveCloseIcon
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name={ASSIGN_TEACHER_FORM_FIELDS.ASSIGN_TO as any}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <ShareSelectInput
                    data={listTeacherOptions}
                    onChange={(value: string) => {
                      onChange(value)
                    }}
                    errors={error?.message}
                    value={value || ''}
                    style={{ minWidth: 150, marginBottom: 16 }}
                    placeholder={t('testManagement:assignTeacherPlaceholder')}
                    label={t('testManagement:assignedTo')}
                    showArrow
                  />
                )
              }}
            />

            <div className="flex flex-col gap-2 mb-6">
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                {t('testManagement:deadlineLabel')}
              </Typography>

              <Controller
                name={ASSIGN_TEACHER_FORM_FIELDS.DEADLINE as any}
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      type="date"
                      errors={error?.message}
                      value={value}
                      onChangeDate={(value) => {
                        if (!value) {
                          onChange(null)
                          return
                        }
                        const newDateMoment = convertToMoment(
                          value,
                          FORMAT_DATE_TABLE
                        ) as any
                        onChange(newDateMoment._d)
                      }}
                      dateFormat={FORMAT_DATE_TABLE}
                    />
                  )
                }}
              />
            </div>

            <div className="flex justify-center">
              <Button
                type="primary"
                onClick={async () => {
                  try {
                    const isPassedValidation = await trigger()

                    if (!isPassedValidation) return

                    setIsShowConfirmModal(true)
                  } catch (error: any) {
                    toast.error(error?.message)
                  }
                }}
              >
                {t('testManagement:assignSelectedTeacher')}
              </Button>
            </div>

            {(() => {
              if (
                !Array.isArray(listRejection?.data) ||
                !listRejection?.data?.length
              )
                return null

              return (
                <div className="flex flex-col mt-6 gap-4">
                  <Typography
                    style={{
                      fontSize: 24,
                      fontWeight: 700,
                    }}
                  >
                    {t('testManagement:rejectReason')}
                  </Typography>

                  {listRejection?.data?.map((it: IRejectItem, idx: number) => {
                    return (
                      <div
                        className="flex flex-col items-start justify-center gap-2 border border-[#e3e3e3] rounded-md p-4"
                        key={it?.id}
                      >
                        <div className="flex gap-1">
                          <Typography
                            style={{
                              fontSize: 14,
                              fontWeight: 700,
                              textWrap: 'nowrap',
                            }}
                          >
                            {t('testManagement:teacherName')}:{' '}
                          </Typography>

                          <Typography
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                            }}
                          >
                            {it?.teacher?.name || ''}
                          </Typography>
                        </div>

                        <div className="flex gap-1">
                          <Typography
                            style={{
                              fontSize: 14,
                              fontWeight: 700,
                              textWrap: 'nowrap',
                            }}
                          >
                            {t('testManagement:rejectReason')}:{' '}
                          </Typography>

                          <Typography
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                            }}
                          >
                            {it?.reason}
                          </Typography>
                        </div>

                        <div className="flex gap-1">
                          <Typography
                            style={{
                              fontSize: 14,
                              fontWeight: 700,
                              textWrap: 'nowrap',
                            }}
                          >
                            {t('testManagement:rejectDate')}:{' '}
                          </Typography>

                          <Typography
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                            }}
                          >
                            {!!it?.updatedAt
                              ? format(new Date(it?.updatedAt), 'dd/MM/yyyy')
                              : null}
                          </Typography>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })()}
          </form>
        </AppModal>
      </ModalContainer>

      {isShowConfirmModal ? (
        <ConfirmAssignTeacherModal
          teacherName={selectedTeacher?.label}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          open={isShowConfirmModal}
          onClose={() => setIsShowConfirmModal(false)}
        />
      ) : null}
    </>
  )
}

function ConfirmAssignTeacherModal({
  open,
  teacherName,
  onClose,
  onSubmit,
  isSubmitting,
}: IConfirmAssignTeacherModalProps) {
  const { t } = useTranslation(['testManagement'])
  return (
    <ConfirmModalContainer>
      <AppModal
        open={open}
        onClose={onClose}
        title={t('testManagement:confirmAssignTitle', { teacherName })}
        haveCloseIcon
      >
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 400,
            marginBottom: 16,
          }}
        >
          {t('testManagement:confirmAssignMessage')}
        </Typography>
        <div className="flex justify-center items-center gap-4">
          <Button type="ghost" onClick={onClose}>
            {t('testManagement:checkAgain')}
          </Button>

          <Button type="primary" onClick={onSubmit} loading={isSubmitting}>
            {t('testManagement:confirmAssigning')}
          </Button>
        </div>
      </AppModal>
    </ConfirmModalContainer>
  )
}

const ModalContainer = styled.div`
  & .content {
    width: 500px;
  }
  & .modal__title {
    font-size: 24px !important;
    margin-bottom: 20px !important;
  }
`

const ConfirmModalContainer = styled.div`
  & .content {
    width: 420px;
  }
  & .modal__title {
    font-size: 20px !important;
    margin-bottom: 12px !important;
  }
`
